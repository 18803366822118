import  { FC } from "react";
import { NavigateNext } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Stack,
  Typography,
} from "@mui/material";

import { Clients } from "./Clients";

export const ClientsComponent: FC<{ breadcrumbs?: boolean }> = ({
  breadcrumbs = true,
}) => {
  return (
    <>
      <Box sx={{ padding: "0 50px 0 305px" }}>
        {breadcrumbs && (
          <Box
            sx={{
              display: "flex",
              borderBottom: 1,
              borderColor: "divider",
              justifyContent: "space-between",
            }}
          >
            <Stack spacing={2}>
              <Breadcrumbs
                separator={<NavigateNext fontSize="small" color={"success"} />}
                aria-label="breadcrumb"
              >
                <Typography
                  key="1"
                  color="text.primary"
                  sx={{ padding: "14px 0" }}
                >
                  Clients
                </Typography>
                {/*{value === 1 && (*/}
                {/*  <Typography key="2" color="text.primary" sx={{ padding: '14px 0' }}>*/}
                {/*    Client info*/}
                {/*  </Typography>*/}
                {/*)}*/}
              </Breadcrumbs>
            </Stack>
          </Box>
        )}
        <Clients />
      </Box>
    </>
  );
};
