import {
  Action,
  combineReducers,
  configureStore,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { formSlice } from "./features/form/formSlice";
import { stepsSlice } from "./features/steps/stepSlice";
import { clientSlice } from "./features/client/clientSlice";
import { thunkSlice } from "./features/thunkSlice/thunkSlice";
import { companySlice } from "./features/company/companySlice";
import { locationSlice } from "./features/location/locationSlice";
import { workflowSlice } from "./features/workflow/workflowSlice";
import { documentSlice } from "./features/document/documentSlice";
import { appearanceSlice } from "./features/appearance/appearanceSlice";
import { notificationSlice } from "./features/notification/notificationSlice";

import authApi from "./features/api/authApi/authApi";
import userApi from "./features/api/userApi/userApi";
import clientApi from "./features/api/clientApi/clientApi";
import workflowApi from "./features/api/workflowApi/workflowApi";
import documentApi from "./features/api/documentApi/documentApi";
import locationApi from "./features/api/locationApi/locationApi";
import { paymentSlice } from "./features/payment/paymentSlice";
import paymentApi from "./features/api/paymentApi/paymentApi";
import serviceAgreementApi from "./features/api/serviceAgreementApi/serviceAgreementApi";
import { serviceAgreementSlice } from "./features/serviceAgreement.ts/serviceAgreementSlice";

const rootReducer = combineReducers({
  form: formSlice.reducer,
  thunk: thunkSlice.reducer,
  steps: stepsSlice.reducer,
  client: clientSlice.reducer,
  location: locationSlice.reducer,
  company: companySlice.reducer,
  document: documentSlice.reducer,
  payment: paymentSlice.reducer,
  workflow: workflowSlice.reducer,
  appearance: appearanceSlice.reducer,
  notification: notificationSlice.reducer,
  serviceAgreement: serviceAgreementSlice.reducer,

  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [clientApi.reducerPath]: clientApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  [locationApi.reducerPath]: locationApi.reducer,
  [workflowApi.reducerPath]: workflowApi.reducer,
  [documentApi.reducerPath]: documentApi.reducer,
  [serviceAgreementApi.reducerPath]: serviceAgreementApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true }).concat([
      userApi.middleware,
      authApi.middleware,
      clientApi.middleware,
      paymentApi.middleware,
      workflowApi.middleware,
      documentApi.middleware,
      locationApi.middleware,
      serviceAgreementApi.middleware,
    ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();
