const sharedStyle = {
  display: "block",
  fontWeight: "bold",
  fontFamily: "Open Sans",
  margin: 0,
  padding: 0,
  boxSizing: "border-box",
  letterSpacing: 0,
  marginInlineStart: "0px",
  marginInlineEnd: "0px"
};

export function getHeadingStyle(level: number) {
  let style = {};

  const baseFontSize = 16;

  switch (level) {
    case 1:
      style = {
        ...sharedStyle,
        fontSize: `${2 * baseFontSize}px`,
        marginBlockStart: `${0.67 * baseFontSize}px`,
        marginBlockEnd: `${0.67 * baseFontSize}px`,
        lineHeight: "2"
      };
      break;
    case 2:
      style = {
        ...sharedStyle,
        fontSize: `${1.5 * baseFontSize}px`,
        marginBlockStart: `${0.83 * baseFontSize}px`,
        marginBlockEnd: `${0.83 * baseFontSize}px`,
        lineHeight: "1.5"
      };
      break;
    case 3:
      style = {
        ...sharedStyle,
        fontSize: `${1.17 * baseFontSize}px`,
        marginBlockStart: `${1 * baseFontSize}px`,
        marginBlockEnd: `${1 * baseFontSize}px`,
        lineHeight: "1.4"
      };
      break;
    case 4:
      style = {
        ...sharedStyle,
        fontSize: `${0.97 * baseFontSize}px`,
        marginBlockStart: `${1.33 * baseFontSize}px`,
        marginBlockEnd: `${1.33 * baseFontSize}px`,
        lineHeight: "1.3"
      };
      break;
    case 5:
      style = {
        ...sharedStyle,
        fontSize: `${0.83 * baseFontSize}px`,
        marginBlockStart: `${1.67 * baseFontSize}px`,
        marginBlockEnd: `${1.67 * baseFontSize}px`,
        lineHeight: "1.2"
      };
      break;
    default:
      // Use default styles for unsupported levels
      break;
  }

  return style;
}
