import { FC, MouseEvent, ChangeEvent, useState } from "react";

import { Editor } from "@tiptap/core";
import { Box, Button, Dialog, Grid, IconButton, Menu } from "@mui/material";

import { TipTapEditorContext } from "../../../types";
import { useUploadFileMutation } from "store/features/api/documentApi/documentApi";

import { MenuButton } from "../../MenuButton";
import { MenuUrlPromptImageButton } from "./MenuUrlPromptImageButton";

import { Delete } from "@mui/icons-material";
import { determineOriginalImgSizeAsync } from "./imageSizeDeterminator";

interface MenuAddImageButtonProps {
  editor: Editor;
  editorContext: TipTapEditorContext;
}

export const MenuAddImageButton: FC<MenuAddImageButtonProps> = ({ editor }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const [uploadFile, { isLoading }] = useUploadFileMutation();

  const [popoverVisible, setPopoverVisible] = useState(false);
  const [imageUploadDialogVisible, setImageUploadDialogVisible] =
    useState(false);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setPopoverVisible(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverVisible(false);
  };

  const openUploadDialog = () => {
    setImageUploadDialogVisible(true);
  };

  const closeUploadDialog = () => {
    setSelectedFile(null);
    setImageUploadDialogVisible(false);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      setSelectedFile(event.dataTransfer.files[0]);
    }
  };

  const handleFileDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const convertToBase64 = async (
    file: File,
  ): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  };

  //create function that will return preview image url asignable to type SVGProps<SVGImageElement>
  const getPreviewImageUrl = (file: File): string => {
    return URL.createObjectURL(file);
  };

  const uploadImage = async () => {
    if (selectedFile) {
      try {
        setIsUploading(true);
        let attrs: any = { src: "" };
        const file = await convertToBase64(selectedFile);
        if (file) {
          const resizeBy = await uploadFile({ file }).unwrap();
          if (resizeBy?.error) {
            console.error("Failed to upload image", resizeBy?.error);
            return;
          }
          if (resizeBy.imageUrl) {
            attrs.src = resizeBy.imageUrl;
            const size = await determineOriginalImgSizeAsync(attrs.src);
            attrs = { ...attrs, width: size.width, height: size.height };
          }
          editor.chain().focus().setImage(attrs).run();
          closeUploadDialog();
        } else {
          console.error("Failed to upload image");
        }
      } catch (e) {
        console.error("Failed to upload image", e);
      } finally {
        setIsUploading(false);
      }
    }
  };

  // @ts-ignore
  return (
    <Box>
      <MenuButton title="Image" icon="image" command={handleClick} />
      <Menu
        anchorEl={anchorEl}
        open={popoverVisible}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div className="el-tiptap-popper__menu">
          {/*TODO: need to be improved*/}
          <MenuUrlPromptImageButton editor={editor} />
          <div
            className="el-tiptap-popper__menu__item"
            onClick={() => openUploadDialog()}
          >
            <span>Upload Image</span>
          </div>
        </div>
      </Menu>

      <Dialog open={imageUploadDialogVisible} onClose={closeUploadDialog}>
        <Box
          sx={{
            minWidth: "400px",
            minHeight: "200px",
            padding: "20px 10px 10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              border: "1px dashed #ccc",
              borderRadius: "5px",
              alignItems: "center",
              minWidth: "200px",
              minHeight: "150px",
            }}
            className="drag-drop-area"
            onDrop={handleFileDrop}
            onDragOver={handleFileDragOver}
          >
            {selectedFile ? (
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"5px"}
              >
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => setSelectedFile(null)}
                >
                  <Delete fontSize="inherit" color={"error"} />
                </IconButton>
                <span>{selectedFile.name}</span>
                <img
                  style={{ width: "200px", height: "100px" }}
                  src={getPreviewImageUrl(selectedFile)}
                  alt={selectedFile.name}
                />
                {isLoading && <>Uploading in progress...</>}
              </Box>
            ) : (
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <span>Drag and drop image file here</span>
                <span>or</span>
                <Button variant="contained" component="label">
                  Choose File
                  <input
                    type="file"
                    accept="image/jpeg, image/png, image/jpg"
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
              </Box>
            )}
          </Box>
        </Box>

        <Grid container spacing={2} justifyContent="flex-end" padding={"10px"}>
          <Grid item>
            <Button
              variant="outlined"
              color="inherit"
              onClick={closeUploadDialog}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={isLoading}
              variant="contained"
              color="success"
              onClick={uploadImage}
            >
              Upload
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
};
