import React, { FC, useEffect, useState } from "react";

import { Box, Button, InputAdornment, Modal, Typography } from "@mui/material";
import {
  FieldValue,
  FieldValues,
  FormProvider,
  useForm,
} from "react-hook-form";
import { InputWidget } from "../widgets/FormWidgets/InputWidget";
import { useAppDispatch } from "../../store";
import {
  changePassword,
  changePasswordConfirm,
} from "../../store/features/api/clientApi/clientApi";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../store/features/notification/notificationSlice";
import { LoadingButton } from "@mui/lab";

const DEFAULT_INTERVAL = 60;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "8px",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "40px 24px",
};

export type PasswordModalProps = {
  isPasswordModalOpen: boolean;
  setIsPasswordModalOpen: (value: boolean) => void;
  userEmail: string;
};

export const ChangePasswordModal: FC<PasswordModalProps> = ({
  isPasswordModalOpen,
  setIsPasswordModalOpen,
  userEmail,
}) => {
  let interval: any = null;
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [sec, setSec] = useState(0);
  const [showNextStep, setShowNextStep] = useState(false);
  const [passwordChangeResult, setPasswordChangeResult] = useState<any>();

  const formMethods = useForm();
  const { handleSubmit, watch, reset } = formMethods;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (sec <= 0) {
      clearTimeout(interval);
      return;
    }

    handleTick();

    return () => {
      clearInterval(interval);
    };
  }, [sec]);

  useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleTick = () => {
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(() => {
      setSec((prevState) => prevState - 1);
    }, 1000);
  };

  const handleModalClose = () => {
    setIsPasswordModalOpen(false);
    setShowNextStep(false);
    reset();
    setSec(0);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const initiatePasswordChange = async (formData: any) => {
    setIsLoading(true);
    try {
      const res = await dispatch(
        changePassword.initiate({
          email: userEmail,
        }),
      ).unwrap();

      setPasswordChangeResult(res?.methods);
    } catch (error) {
      dispatch(
        showErrorNotification({
          message: (error as any)?.data?.message ?? (error as any)?.data?.error,
        }),
      );
      console.error(error);
    }
    setShowNextStep(true);
    setIsLoading(false);
    setSec(DEFAULT_INTERVAL);
  };

  const confirmPasswordChange = async (formData: any) => {
    setIsLoading(true);
    try {
      const res = await dispatch(
        changePasswordConfirm.initiate({
          verificationCodes: { email: formData.verificationCode },
          email: userEmail,
          password: formData.password,
          passwordConfirm: formData.passwordConfirm,
        }),
      ).unwrap();

      dispatch(showSuccessNotification({ message: res?.message }));
    } catch (error) {
      dispatch(
        showErrorNotification({
          message: (error as any)?.data?.message ?? (error as any)?.data?.error,
        }),
      );
      console.error(error);
    }
    setIsLoading(false);
    handleModalClose();
  };

  const onSubmit = async (formData: FieldValues) => {
    if (!showNextStep) {
      await initiatePasswordChange(formData);
    } else {
      await confirmPasswordChange(formData);
    }
  };

  const sendVerificationCode = async () => {
    if (sec) return;

    setSec(DEFAULT_INTERVAL);
    await dispatch(
      changePassword.initiate({
        email: userEmail,
      }),
    ).unwrap();
  };

  const matchPasswords = (confPass: FieldValue<any>) =>
    watch("password") === confPass;

  return (
    <>
      <Modal
        open={isPasswordModalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ width: "100%", marginBottom: "44px" }}>
                  <Box sx={{ textAlign: "start", marginBottom: "24px" }}>
                    <Typography
                      component={"span"}
                      variant={"h6"}
                      sx={{ fontWeight: "bold" }}
                    >
                      Change password
                    </Typography>
                  </Box>
                  {/*<FormControl fullWidth variant="outlined">*/}
                  {/*  <InputLabel htmlFor="existing-password">*/}
                  {/*    Existing password*/}
                  {/*  </InputLabel>*/}
                  {/*  <OutlinedInput*/}
                  {/*    id="existing-password"*/}
                  {/*    type={showPassword ? "text" : "password"}*/}
                  {/*    endAdornment={*/}
                  {/*      <InputAdornment position="end">*/}
                  {/*        <IconButton*/}
                  {/*          aria-label="toggle password visibility"*/}
                  {/*          onClick={handleClickShowPassword}*/}
                  {/*          onMouseDown={handleMouseDownPassword}*/}
                  {/*          edge="end"*/}
                  {/*        >*/}
                  {/*          {showPassword ? <VisibilityOff /> : <Visibility />}*/}
                  {/*        </IconButton>*/}
                  {/*      </InputAdornment>*/}
                  {/*    }*/}
                  {/*    label="Existing password"*/}
                  {/*  />*/}
                  {/*</FormControl>*/}

                  {!showNextStep && (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "24px",
                        }}
                      >
                        <InputWidget
                          fieldId={"password"}
                          label={"New password"}
                          type={"password"}
                          value={""}
                        />
                        <InputWidget
                          fieldId={"passwordConfirm"}
                          label={"Confirm password"}
                          type={"password"}
                          value={""}
                          custom_validator={{
                            value: matchPasswords,
                            message: "Your passwords do no match",
                          }}
                        />
                      </Box>
                      <Box sx={{ textAlign: "start" }}>
                        <Typography
                          variant={"subtitle2"}
                          sx={{ marginBottom: "17px" }}
                        >
                          Password must be:
                        </Typography>
                        <ul style={{ listStyle: "inside", fontSize: "14px" }}>
                          <li>at least 8 characters long</li>
                          <li>contain an uppercase letter</li>
                          <li>contain number</li>
                          <li>contain spacial character [!@#$%]</li>
                        </ul>
                      </Box>
                    </>
                  )}

                  {showNextStep && (
                    <Box>
                      <Typography>
                        {passwordChangeResult?.[0]?.message}
                      </Typography>
                      <InputWidget
                        fieldId={"verificationCode"}
                        label={"Enter verification code"}
                        type={"text"}
                        value={""}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Box
                                sx={{
                                  textDecoration: !sec ? "underline" : "none",
                                  lineHeight: "21px",
                                  fontSize: "14px",
                                  cursor: "pointer",
                                  color: !sec ? "#3874CB" : "#3FBA73",
                                }}
                                onClick={sendVerificationCode}
                              >
                                {!sec ? "Resend Code" : "Code sent"}
                              </Box>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {Boolean(sec) && (
                        <Box>
                          <Typography variant={"body2"}>
                            You can request a new code in {sec} seconds.
                          </Typography>
                          <Typography variant={"body2"}>
                            The code will expire in 5 minutes.
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "12px",
                  }}
                >
                  <Button
                    variant={"outlined"}
                    color={"inherit"}
                    onClick={() => {
                      showNextStep
                        ? setShowNextStep(false)
                        : handleModalClose();
                    }}
                    sx={{ textTransform: "capitalize", padding: "7px 17px" }}
                  >
                    {showNextStep ? "Back" : "Cancel"}
                  </Button>
                  <LoadingButton
                    type={"submit"}
                    variant={"contained"}
                    color={"success"}
                    loading={isLoading}
                    sx={{ textTransform: "none", padding: "7px 17px" }}
                    disabled={
                      showNextStep
                        ? !Boolean(watch("verificationCode")) ||
                          watch("verificationCode").length !== 6
                        : false
                    }
                  >
                    {showNextStep ? "Set a new Password" : "Next"}
                  </LoadingButton>
                </Box>
              </Box>
            </form>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
};
