import { Dropdown } from "element-react";
import { Editor } from "@tiptap/core";
import { useState, useRef, useEffect, MouseEvent } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { DEFAULT_FONT_SIZE, FONT_SIZES } from "../../constants";
import { TipTapEditorContext } from "../../types";

interface MenuFontSizeDropdownProps {
  editor: Editor;
  editorContext: TipTapEditorContext;
}

export const MenuFontSizeDropdown = (props: MenuFontSizeDropdownProps) => {
  const { editor, editorContext } = props;
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [selectedFontSize, setSelectedFontSize] =
    useState<number>(DEFAULT_FONT_SIZE);
  const [tableFontSize, setTableFontSize] = useState<number>(DEFAULT_FONT_SIZE);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dropdownRef = useRef<Dropdown>(null);

  useEffect(() => {
    setFontSize(selectedFontSize);
  }, []);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsVisible(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsVisible(false);
  };

  const setFontSize = (size: number) => {
    editor.chain().focus().setFontSize(size).run();
    setSelectedFontSize(size);
    handleClose();
  };

  const isActive = (fontSize?: number): boolean => {
    if (fontSize) {
      return editor.isActive("fontStyle", { fontSize });
    }
    return editor.isActive("fontStyle", { fontSize: null });
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={isVisible ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isVisible ? "true" : undefined}
        onClick={handleClick}
      >
        {selectedFontSize}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        // onChange={handleVisibleChange}
        open={isVisible}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {FONT_SIZES.map((size: number) => (
          <MenuItem
            key={size}
            // command={item}
            onClick={() => setFontSize(size)}
            className={
              isActive(size)
                ? "el-tiptap-dropdown-menu__item--active el-tiptap-dropdown-menu__item"
                : "el-tiptap-dropdown-menu__item"
            }
          >
            <span>{size}</span>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
