import { SyntheticEvent } from "react";

import { Coordinates, DefaultFormWidgetProps } from "../../@types/Widget";

type DefaultProps = {
  onClose?: (event: SyntheticEvent) => void;
  onOpen?: (event: SyntheticEvent) => void;
};

export type SelectWidgetType = {
  type: "field";
  position: Coordinates;
  config: SelectWidgetConfig;
};

export type SelectWidgetProps = {
  type: "select";
  value?: SelectFieldValues;
  option_list?: SelectOptionList[];
  country?: string;
} & DefaultFormWidgetProps &
  DefaultProps;

export type SelectOptionList = {
  label: string;
  value: string;
  disabled?: boolean;
  checked?: boolean;
  description?: string;
};

export type SelectWidgetConfig = {
  props: SelectWidgetProps;
};

export type SelectFieldValues = string;
export type SelectFieldType = "select";

export enum SelectFieldTypes {
  SELECT = "select",
}
