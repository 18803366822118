// @ts-ignore
import { TableHeader as TiptapTableHeader } from "@tiptap/extension-table-header";
import { mergeAttributes } from "@tiptap/core";

const transparentStyle = "border: 2px dashed #ebeef5;";
const normalStyle = "border: 2px solid #ebeef5;";

const TableHeader = TiptapTableHeader.extend({
  addAttributes() {
    return {
      ...(this.parent ? this.parent() : {}),
      borderStyle: {
        default: null,
        parseHTML: (element: Element) => {
          return element.getAttribute("borderStyle");
        },
        renderHTML: (attributes: any) => {
          return { borderStyle: attributes.borderStyle };
        },
      },
      style: {
        default: null,
        parseHTML: (element: Element) => {
          return element.getAttribute("style");
        },
        renderHTML: (attributes: any) => {
          return { style: attributes.style };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "th",
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      "th",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        style: `${node.attrs.borderStyle ? transparentStyle : normalStyle}`,
      }),
      0,
    ];
  },
});

export default TableHeader;
