import { ChangeEvent, FC, AnimationEvent, useState, RefObject } from "react";

import { InputLabelProps, TextField } from "@mui/material";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, useFormContext } from "react-hook-form";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";

import { ControllerRenderProps } from "../../@types/Widget";
import { InputFieldTypes, InputWidgetProps } from "./InputWidget.types";

import { getValidationRule } from "../../../../helper/FormValidators/formHelper";
import { useAppSelector } from "../../../../store";

const defaultProps = {
  type: InputFieldTypes.TEXT,
  label: "FIRST",
  fieldId: "FIRST",
  required: false,
  value: "",
};

export const InputWidget: FC<
  InputWidgetProps & {
    InputLabelProps?: Partial<InputLabelProps>;
    InputProps?: Partial<StandardInputProps>;
    inputRef?: RefObject<HTMLInputElement>;
  }
> = (field = defaultProps) => {
  const {
    type,
    label,
    fieldId,
    required,
    value,
    rules,
    fullWidth = true,
    InputProps = {} as Partial<StandardInputProps>,
    InputLabelProps,
    inputRef,
  } = field;

  const [hasValue, setHasValue] = useState(!!value);
  const { clients } = useAppSelector(({ client }) => client);
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const makeAnimationStartHandler =
    (setHasValue: (value: boolean) => void) =>
    (e: AnimationEvent<HTMLInputElement>) => {
      if (e.animationName === "mui-auto-fill") {
        setHasValue(true);
      }
    };

  return (
    <>
      <Controller
        render={({
          field: { value, onChange, ...rest },
        }: {
          field: ControllerRenderProps<
            string,
            ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          >;
        }) => (
          <>
            <TextField
              id={fieldId}
              size={"small"}
              type={type}
              value={value || ""}
              error={!!errors?.[fieldId]}
              label={label}
              margin="normal"
              required={required}
              inputProps={{
                onAnimationStart: makeAnimationStartHandler(setHasValue),
                readOnly: label === "E-mail" || label === "ID"
              }}
              InputLabelProps={{
                shrink: hasValue || !!value,
                ...InputLabelProps,
              }}
              fullWidth={fullWidth}
              onChange={(e) => {
                onChange(e);
                setHasValue(false);
              }}
              InputProps={InputProps}
              inputRef={inputRef}
              {...rest}
            />
          </>
        )}
        control={control}
        defaultValue={value}
        name={fieldId}
        rules={rules ?? getValidationRule(field)}
      />
      <ErrorMessage
        errors={errors}
        name={fieldId}
        render={({ message }) => (
          <p style={{ color: "red", fontSize: "13px", marginTop: "-7px" }}>
            {message}
          </p>
        )}
      />
    </>
  );
};
