import { FC } from "react";
import DirectionsSharpIcon from "@mui/icons-material/DirectionsSharp";

import "./StubRedirecting.scss";
import { Box, LinearProgress, Typography } from "@mui/material";

export const StubRedirecting: FC = () => {
  return (
    <Box style={{ height: "100%", position: "relative" }}>
      <Box
        style={{
          position: "absolute",
          transform: "translate(-50%, -60%)",
          top: "50%",
          left: '50%'
        }}
      >
        <DirectionsSharpIcon
          style={{ fontSize: "7em" }}
          className="rotating-icon"
        />
        <Box sx={{ margin: "44px 0 8px" }}>
          <Typography fontSize="1.3rem">Redirecting</Typography>
        </Box>
        <Box sx={{ width: "80%", margin: "0 auto" }}>
          <LinearProgress color="inherit" />
        </Box>
      </Box>
    </Box>
  );
};
