import { FC } from "react";

import { Box, Button } from "@mui/material";
import {
  InputWidget,
  InputWidgetProps,
} from "../../../FormWidgets/InputWidget";
import { useAppDispatch } from "../../../../../store";
import {
  AuthTypes,
  setAuthMethod,
} from "../../../../../store/features/form/formSlice";

const signInFormData: InputWidgetProps[] = [
  {
    type: "email",
    // validator: "",//REG
    label: "Email",
    fieldId: "email",
    required: true, //boolean
    value: "",
    // validation_message: '',
    // custom_validator: {
    //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
    //   message: '',//string;
    //   flags: ''//string;
    // },
    // maxlength: 50
  },
  {
    type: "password",
    // validator: "",//REG
    label: "Password",
    fieldId: "password",
    required: true, //boolean
    value: "",
    // validation_message: '',
    // custom_validator: {
    //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
    //   message: '',//string;
    //   flags: ''//string;
    // },
    // maxlength: 50
  },
];

export const SignInWidget: FC = () => {
  const dispatch = useAppDispatch();
  const resetPassword = () => {
    dispatch(setAuthMethod(AuthTypes.RESET));
  };

  return (
    <Box sx={{ maxWidth: "350px", width: "100%" }}>
      <InputWidget {...signInFormData[0]} />
      <InputWidget {...signInFormData[1]} />
      <Button onClick={resetPassword}>Reset Password?</Button>
    </Box>
  );
};
