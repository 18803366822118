import { FC, useState } from "react";
import { Editor } from "@tiptap/react";
import { Level } from "@tiptap/extension-heading";
import { TipTapEditorContext } from "../../types";
import { Button, Menu, MenuItem } from "@mui/material";

type MenuHeadingDropdownProps = {
  editorContext: TipTapEditorContext;
  editor: Editor;
};

export const MenuHeadingDropdown: FC<MenuHeadingDropdownProps> = ({
  editorContext,
  editor,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [headingLevel, setHeadingLevel] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const NORMAL_TEXT = "Normal text";

  const level = 5;

  const isHeadingActive = (level: number) => {
    const map = [
      editorContext.state.isActiveHeading1,
      editorContext.state.isActiveHeading2,
      editorContext.state.isActiveHeading3,
      editorContext.state.isActiveHeading4,
      editorContext.state.isActiveHeading5,
    ];
    return level ? map[level - 1] : editorContext.state.isActiveHeading;
  };

  // const handleVisibleChange = () => {
  //   setIsVisible(!isVisible);
  // };

  const updateHeadingLevel = (level: Level) => {
    level > 0
      ? editor.chain().focus().setHeadingAndClearMarks({ level }).run()
      : editor.chain().focus().setParagraph().run();
    setHeadingLevel(level);
  };

  const selectedTitle =
    headingLevel < 1 ? `${NORMAL_TEXT}` : `Heading ${headingLevel}`;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsVisible(true);
  };

  const handleClose = (level: Level) => {
    updateHeadingLevel(level);
    setAnchorEl(null);
    setIsVisible(false);
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={isVisible ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isVisible ? "true" : undefined}
        onClick={handleClick}
      >
        {selectedTitle}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        // onChange={handleVisibleChange}
        open={isVisible}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          className={"dropdown-button"}
          onClick={() => handleClose(0 as Level)}
        >
          <span>{NORMAL_TEXT}</span>
        </MenuItem>

        {Array.from(Array(level), (_, i) => (
          <MenuItem
            key={i}
            className={
              "el-tiptap-dropdown-menu__item" +
              (isHeadingActive(i + 1)
                ? " el-tiptap-dropdown-menu__item--active"
                : "")
            }
            onClick={() => handleClose((i + 1) as Level)}
          >
            <h1 data-item-type="heading">Heading {i + 1}</h1>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
