import { ChangeEvent, FC, useEffect, useState } from "react";

import { Delete } from "@mui/icons-material";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import { useAppThunkDispatch } from "../../store";
import {
  removeImageThunk,
  updateWorkflowThunk,
  uploadFileThunk,
} from "../../store/features/thunk/workflowThunk/workflowThunk";
import { useGetWorkflowMutation } from "../../store/features/api/workflowApi/workflowApi";

export type AppearanceExtensionProps = {
  workflowId: string;
};

export const AppearanceExtension: FC<AppearanceExtensionProps> = ({
  workflowId,
}) => {
  const [appearance, setAppearance] = useState<any>(null);
  const [isInputReady, setIsInputReady] = useState<boolean>(true);
  const formMethods = useForm({
    defaultValues: {
      backgroundImagePosition: "cover",
      showLogoInHeader: false,
    },
  });
  const { handleSubmit, watch, setValue } = formMethods;

  const watchCover = watch("backgroundImagePosition");
  const watchLogoInHeader = watch("showLogoInHeader");
  const dispatchThunk = useAppThunkDispatch();

  const [getWorkflow, { data }] = useGetWorkflowMutation();

  useEffect(() => {
    getWorkflow({ id: workflowId });
  }, [workflowId]);

  useEffect(() => {
    if (!data) return;
    setAppearance(data.extensions.appearance);
  }, [data]);

  useEffect(() => {
    if (!appearance) return;

    setValue(
      "backgroundImagePosition",
      appearance.backgroundImagePosition || "cover",
    );
  }, [appearance]);

  useEffect(() => {
    if (!appearance) return;

    setValue("showLogoInHeader", appearance.showLogoInHeader || false);
  }, [appearance]);

  const onSubmit = async (data: FieldValues) => {
    await dispatchThunk(
      updateWorkflowThunk({
        data,
        id: workflowId,
        path: "/extension/appearance",
      }),
    );
  };

  const uploadImage = async (file: File, fieldName: string) => {
    const cd = async (file: string | ArrayBuffer | null) => {
      file
        ? await uploadFileHandler(file, fieldName)
        : console.error("file is null");
    };

    convertToBase64(file, cd);

    setIsInputReady(false);
    setTimeout(() => {
      setIsInputReady(true);
    }, 100);
  };

  const uploadFileHandler = async (
    file: string | ArrayBuffer,
    fieldName: string,
  ) => {
    await dispatchThunk(uploadFileThunk({ file, workflowId, fieldName }));
    await getWorkflow({ id: workflowId });
  };

  const convertToBase64 = (
    file: File,
    onloadCb: (file: string | ArrayBuffer | null) => void,
  ) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      onloadCb(reader.result);
    };
  };

  const removeImage = async (fieldName: string) => {
    await dispatchThunk(removeImageThunk({ workflowId, fieldName }));
    await getWorkflow({ id: workflowId });
    await dispatchThunk(
      updateWorkflowThunk({
        data: {
          backgroundImagePosition: appearance.backgroundImagePosition,
          showLogoInHeader: false,
        },
        id: workflowId,
        path: "/extension/appearance",
      }),
    );
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue("backgroundImagePosition", event.target.value);
  };

  const handleShowLogo = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(
      "showLogoInHeader",
      Boolean(JSON.parse(event.target.value.toLowerCase())),
    );
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      gap={"20px"}
      textAlign={"start"}
      paddingTop="20px"
    >
      <Box>
        <FormProvider {...formMethods}>
          <form noValidate onChange={handleSubmit(onSubmit)}>
            <Box display={"flex"} gap={"30px"}>
              <Box>
                <FormControl>
                  <FormLabel>
                    Choose the location of the image on the screen:
                  </FormLabel>
                  <RadioGroup
                    name="backgroundImagePosition"
                    sx={{ my: 1, textAlign: "start" }}
                    onChange={handleChange}
                  >
                    <div>
                      <Radio value="cover" checked={watchCover === "cover"} />
                      <FormLabel>Cover</FormLabel>
                    </div>
                    <div>
                      <Radio value="right" checked={watchCover === "right"} />
                      <FormLabel>Right</FormLabel>
                    </div>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Box>
      <Box>
        <Box>
          <Typography variant={"subtitle1"} sx={{ textAlign: "start" }}>
            Upload an image
          </Typography>
          <Typography variant={"subtitle2"} sx={{ textAlign: "start" }}>
            Format: PNG, JPG. Size: 1920x1024px
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"flex-start"}>
          <Button
            variant="outlined"
            color="inherit"
            component="label"
            sx={{ mt: "14px" }}
          >
            Upload
            {isInputReady && (
              <input
                hidden
                accept="image/jpeg, image/png, image/jpg"
                multiple
                type="file"
                onChange={(e: any) =>
                  uploadImage(e.target.files[0], "backgroundUrl")
                }
              />
            )}
          </Button>
        </Box>
        {appearance?.backgroundUrl && (
          <Box sx={{ position: "absolute", marginTop: "20px" }}>
            <IconButton
              aria-label="delete"
              color="primary"
              sx={{ position: "absolute", top: "-30px", right: "-30px" }}
              onClick={() => removeImage("backgroundUrl")}
            >
              <Delete />
            </IconButton>
            <img
              src={appearance.backgroundUrl}
              width={"150px"}
              alt={"Background image"}
            />
          </Box>
        )}
      </Box>
      <Box>
        <Box>
          <Typography variant={"subtitle1"} sx={{ textAlign: "start" }}>
            Upload company logo
          </Typography>
          <Typography variant={"subtitle2"} sx={{ textAlign: "start" }}>
            Format: PNG, JPG. Size: 1920x1024px
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"flex-start"}>
          <Button
            variant="outlined"
            color="inherit"
            component="label"
            sx={{ mt: "14px" }}
          >
            Upload
            <input
              hidden
              accept="image/jpeg, image/png, image/jpg"
              multiple
              type="file"
              onChange={(e: any) => uploadImage(e.target.files[0], "logoUrl")}
            />
          </Button>
        </Box>
        {appearance?.logoUrl && (
          <>
            <Box marginTop={"15px"}>
              <FormProvider {...formMethods}>
                <form noValidate onChange={handleSubmit(onSubmit)}>
                  <Box display={"flex"} gap={"30px"}>
                    <Box>
                      <FormControl>
                        <FormLabel>Show logo in header:</FormLabel>
                        <RadioGroup
                          name="showLogoInHeader"
                          sx={{ my: 1, textAlign: "start" }}
                          onChange={handleShowLogo}
                        >
                          <div>
                            <Radio
                              value={true}
                              checked={watchLogoInHeader === true}
                            />
                            <FormLabel>Yes</FormLabel>
                          </div>
                          <div>
                            <Radio
                              value={false}
                              checked={watchLogoInHeader === false}
                            />
                            <FormLabel>No</FormLabel>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </form>
              </FormProvider>
            </Box>
            <Box sx={{ position: "absolute", marginTop: "20px" }}>
              <IconButton
                aria-label="delete"
                color="primary"
                sx={{ position: "absolute", top: "-30px", right: "-30px" }}
                onClick={() => removeImage("logoUrl")}
              >
                <Delete />
              </IconButton>
              <img
                src={appearance.logoUrl}
                width={"150px"}
                alt={"Background logo for workflow"}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
