import React, { FC, useEffect } from "react";

import LogoutIcon from "@mui/icons-material/Logout";
import GroupsIcon from "@mui/icons-material/Groups";
import { Drawer, List, Toolbar } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import { useLocation, useNavigate } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DescriptionIcon from "@mui/icons-material/Description";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import RoundaboutRightIcon from "@mui/icons-material/RoundaboutRight";
import { getCompanyDetailsThunk } from "../../store/features/thunk/thunkApi";
import { useAppDispatch, useAppSelector } from "../../store";
import { setIsNewUser } from "../../store/features/company/companySlice";
import { getIsNewUserFromLC } from "helper/isNewUserHelper";
import Cookies from 'js-cookie'

//@ts-ignore
import wkLogo from "../../assets/img/waiverking-logo.svg";
import { cleanStorageAndGoToLanding } from "helper/urlHelper";
import "./NavigationComponent.scss";

export const NavigationComponent: FC = () => {
  const { isNewUser, companyDetails, isSuperAdmin } = useAppSelector(
    ({ company }) => company,
  );

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCompanyDetailsThunk());
  }, []);

  useEffect(() => {
    const value = getIsNewUserFromLC();
    dispatch(setIsNewUser(value));
  }, [dispatch]);

  const setActiveLink = (pathname: string) => {
    return location.pathname.includes(pathname) ? "green" : "";
  };

  return (
    <Drawer
      variant="permanent"
      open={true}
      sx={{ background: "rgba(249, 250, 249, 1)" }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "255px",
          px: [1],
        }}
      >
        {companyDetails && companyDetails.name && isSuperAdmin ? (
          <div>
            <div className="company-name-container">{companyDetails.name}</div>
            <div className="company-name-underline"></div>
          </div>
        ) : (
          <img style={{ height: "30px" }} alt={"logo"} src={wkLogo} />
        )}
      </Toolbar>
      <List
        component="nav"
        sx={{
          paddingTop: "30px",
          height: "100%",
        }}
      >
        <>
          {/*<ListItemButton
            disabled={isNewUser || !!companyDetails?.unsignedDocuments}
            onClick={() => navigate("/home")}
            sx={{
              color: setActiveLink("/home"),
            }}
          >*/}
          {/*<ListItemIcon
              sx={{
                color: setActiveLink("/home"),
              }}
            >
              <GridViewIcon />
            </ListItemIcon>*/}
          {/*<ListItemText primary="Home" />*/}
          {/*</ListItemButton>*/}
          <ListItemButton
            disabled={isNewUser || !!companyDetails?.unsignedDocuments}
            onClick={() => navigate("/locations")}
            sx={{
              color: setActiveLink("/locations"),
            }}
          >
            <ListItemIcon
              sx={{
                color: setActiveLink("/locations"),
              }}
            >
              <LocationOnIcon />
            </ListItemIcon>
            <ListItemText primary="Locations" />
          </ListItemButton>
          <ListItemButton
            disabled={isNewUser || !!companyDetails?.unsignedDocuments}
            onClick={() => navigate("/documents")}
            sx={{
              color: setActiveLink("/documents"),
            }}
          >
            <ListItemIcon
              sx={{
                color: setActiveLink("/documents"),
              }}
            >
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Documents" />
          </ListItemButton>
          <ListItemButton
            disabled={isNewUser || !!companyDetails?.unsignedDocuments}
            onClick={() => navigate("/workflows")}
            sx={{
              color: setActiveLink("/workflows"),
            }}
          >
            <ListItemIcon
              sx={{
                color: setActiveLink("/workflows"),
              }}
            >
              <RoundaboutRightIcon />
            </ListItemIcon>
            <ListItemText primary="Workflows" />
          </ListItemButton>
          <ListItemButton
            disabled={isNewUser || !!companyDetails?.unsignedDocuments}
            onClick={() => navigate("/clients")}
            sx={{
              color: setActiveLink("/clients"),
            }}
          >
            <ListItemIcon
              sx={{
                color: setActiveLink("/clients"),
              }}
            >
              <GroupsIcon />
            </ListItemIcon>
            <ListItemText primary="Clients" />
          </ListItemButton>
          <ListItemButton
            disabled={isNewUser || !!companyDetails?.unsignedDocuments}
            sx={{
              color: setActiveLink("/account"),
            }}
            onClick={() => navigate("/account")}
          >
            <ListItemIcon
              sx={{
                color: setActiveLink("/account"),
              }}
            >
              <BusinessCenterIcon />
            </ListItemIcon>
            <ListItemText>Account</ListItemText>
          </ListItemButton>
          <ListItemButton
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              marginBottom: "20px",
            }}
            onClick={() => {
              cleanStorageAndGoToLanding(true);
              Cookies.remove('authWK');
            }}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </>
      </List>
    </Drawer>
  );
};
