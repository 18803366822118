import { FC, RefObject, useEffect, useState } from "react";
import Heading from "../../shared/Heading";
import { OverviewType, FeatureType } from "../../shared/types";
import featuresData from "../../data/featuresData";
import Feature from "./Feature";
import Overview from "../../shared/Overview";
import useMediaQuery from "../../hooks/useMediaQuery";
import { CommentsSlider } from "../aboutUs/CommentsSlider";

// @ts-ignore
import automateimg from "../../assets/automateimg.png";

const benefits: Array<OverviewType> = [
  {
    title: "eSign anywhere, anytime, on any device",
    description:
      "Experience the convenience of eSignatures wherever and whenever you need them. Our user-friendly platform allows you to securely sign documents on any device, providing a seamless and efficient signing experience",
  },
  {
    title: "Support for Multiple Locations",
    description:
      "Our comprehensive solution offers robust support for multiple locations, enabling seamless management and coordination it. ",
  },
  {
    title: "Flexible Billing",
    description:
      "Expand your business confidently! WaiverKing offers flexible billing options, enabling seamless growth and management",
  },
  {
    title: "Security",
    description:
      "Your security is our top priority. Rest easy knowing that our robust security protocols protect your information, providing you with a safe and worry-free experience",
  },
];

interface FeaturesProps {
  featuresRef: RefObject<HTMLDivElement>;
}

const Features: FC<FeaturesProps> = ({ featuresRef }) => {
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const isMediaQueryLgMin = useMediaQuery("(min-width: 1025px)");
  const isMediaQueryXsMin = useMediaQuery("(min-width: 600px)");
  const isAboveLargeScreen = useMediaQuery("(min-width: 1024px");

  useEffect(() => {
    if (!isMediaQueryLgMin && isMediaQueryXsMin) {
      setItemsPerPage(2);
    } else if (!isMediaQueryXsMin) {
      setItemsPerPage(1);
    }
  }, [isMediaQueryLgMin, isMediaQueryXsMin]);

  return (
    <section id="features" ref={featuresRef}>
      {/*<div className="xs:px-[23px] sm:px-[96px] md:px-[139px] lg:px-[153px] lg:pb-0 lg:pt-[100px]">*/}
      {/*  {isAboveLargeScreen && (*/}
      {/*    <Heading>Let us automate all your forms</Heading>*/}
      {/*  )}*/}
      {/*  {!isAboveLargeScreen && (*/}
      {/*    <Heading>*/}
      {/*      Let us automate*/}
      {/*      <br /> all your forms*/}
      {/*    </Heading>*/}
      {/*  )}*/}
      {/*  <div className="flex xs:mb-[100px] xs:mt-[-35px] xs:flex-col xs:gap-8 sm:mt-[-25px] md:mt-0 md:flex-row-reverse lg:mb-0">*/}
      {/*    <div className="flex justify-center font-poppins font-semibold text-primary-700 xs:text-base sm:mb-6 sm:text-xl md:w-1/2 md:text-base lg:text-xl">*/}
      {/*      <ul className="list-disc pl-6">*/}
      {/*        <li>Waivers</li>*/}
      {/*        <li>Intake Forms</li>*/}
      {/*        <li>PARQ</li>*/}
      {/*        <li>Membership Agreements</li>*/}
      {/*        <li>Cancellation Forms</li>*/}
      {/*        <li>Freeze Forms</li>*/}
      {/*        <li>Treatment Notes</li>*/}
      {/*        <li>Automatic Debit Authorization Forms</li>*/}
      {/*        <li>Photo Release Forms</li>*/}
      {/*        <li>Registration Forms</li>*/}
      {/*        <li>Plus</li>*/}
      {/*        <li>Plus</li>*/}
      {/*        <li>Plus</li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*    <div className="md:w-1/2">*/}
      {/*      <img className="w-full" src={automateimg} alt="automateimg" />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {isMediaQueryLgMin ? (
        <div className="flex flex-wrap gap-x-7 gap-y-20 pt-16 xs:px-[24px] xs:py-[40px] sm:px-[96px] sm:py-[72px] md:px-[139px] lg:px-[153px] lg:py-[100px]">
          <Heading isMarginNone>
            eSignature anytime and
            <br /> anywhere, on any device
          </Heading>
          {featuresData.map((feature: FeatureType) => (
            <Feature
              key={feature.title}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </div>
      ) : (
        <div className="pb-[100px]">
          <Heading>
            eSignature anytime and
            <br /> anywhere, on any device
          </Heading>
          <CommentsSlider itemsPerPage={itemsPerPage}>
            {featuresData.map((feature: FeatureType) => (
              <div className={"pb-[20px]"} key={feature.title}>
                <Feature
                  icon={feature.icon}
                  title={feature.title}
                  description={feature.description}
                />
              </div>
            ))}
          </CommentsSlider>
        </div>
      )}
      <div className="bg-seconary-100 shadow-[0px_-4px_20px_0px_rgba(0,0,0,0.15)_inset] xs:px-[24px] xs:pb-[70px] xs:pt-[10px] sm:px-[80px] sm:pb-[90px] md:px-[139px] lg:px-[153px] lg:py-[100px]">
        <Heading>Benefits / Why clients choose WaiverKing</Heading>
        <div className="flex xs:mt-[-35px] xs:w-[100%] xs:flex-wrap xs:justify-center xs:gap-6 sm:mt-0 sm:justify-between sm:gap-y-12 md:gap-3 md:gap-y-12 lg:flex-nowrap lg:justify-evenly lg:gap-0">
          {benefits.map((benefit: OverviewType) => (
            <Overview
              key={benefit.title}
              title={benefit.title}
              description={benefit.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
export default Features;
