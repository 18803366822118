import React from "react";

import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EventIcon from "@mui/icons-material/Event";
import { WidgetTypeEnum } from "tiptap/types/enums/WidgetTypeEnum";

type WidgetProps = {
  filterType: string;
  value?: string;
  label: string;
};

export const WidgetInEditorRepresentation: React.FC<WidgetProps> = ({
  filterType,
  value,
  label,
}) => {
  let icon;
  let widgetLabel = label;
  switch (filterType) {
    case WidgetTypeEnum.RADIO:
      icon = (
        <RadioButtonCheckedIcon
          sx={{ width: "16px", height: "16px" }}
          className="mb-[2px]"
        />
      );
      widgetLabel = value ?? label;
      break;
    case WidgetTypeEnum.CHECKBOX:
      icon = (
        <CheckBoxIcon
          sx={{ width: "20px", height: "20px" }}
          className="mb-[2px]"
        />
      );
      widgetLabel = "";
      break;
    case WidgetTypeEnum.DATEPICKER:
      icon = (
        <EventIcon
          sx={{ width: "20px", height: "20px" }}
          className="mb-[2px]"
        />
      );
      break;
    case WidgetTypeEnum.TEXT:
    case WidgetTypeEnum.SIGNATURE:
    case WidgetTypeEnum.TEXTAREA:
      widgetLabel = `${label} ${value}`;
      break;
    default:
      widgetLabel = `${value}`;
      icon = null;
  }

  return (
    <>
      <p
        className={`overflow-hidden text-ellipsis whitespace-nowrap ${
          filterType === "checkbox" ? "mx-auto" : "mx-[2px]"
        }`}
      >
        {icon}
        <>{widgetLabel}</>
      </p>
    </>
  );
};
