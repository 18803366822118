import { FC, useCallback, useEffect, useRef } from "react";

import { Box } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";

import { useAppThunkDispatch } from "../../../store";
import {
  refreshTokenThunk,
  setCompanyDataThunk,
} from "../../../store/features/thunk/thunkApi";
import { apiService } from "../../../store/features/api/service/apiService";
import {
  cleanStorageAndGoToLanding,
  getRouteBasedOnUnsignedDocumentsAndIsNewUser,
  redirectToMainPage,
} from "helper/urlHelper";
import { getIsNewUserFromLC } from "helper/isNewUserHelper";

import { NavigationComponent } from "../../NavigationComponent/NavigationComponent";

const TIMEOUT = 240000;
const TIMEOUT1 = 1500;

export const CompanyComponent: FC = () => {
  const navigate = useNavigate();
  const thunkDispatch = useAppThunkDispatch();

  const intervalRef = useRef<NodeJS.Timeout | null>(null); // Use ref to store interval id
  const timeoutRef = useRef<NodeJS.Timeout | null>(null); // Use ref to store timeout id

  useEffect(() => {
    // Start interval and store interval id
    intervalRef.current = setInterval(checkAndRefreshToken, TIMEOUT);
    timeoutRef.current = setTimeout(checkAndRefreshToken, TIMEOUT1);
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  const clearAccess = useCallback(() => {
    localStorage.removeItem("SUBDOMAIN");
    apiService.clear();
    apiService.clearRefresh();
  }, []);

  const checkAndRefreshToken = useCallback(async () => {
    const isOpenedFromDomain = localStorage.getItem("IS_OPENED_FROM_DOMAIN");
    if (isOpenedFromDomain) {
      const isNewUser = getIsNewUserFromLC();
      localStorage.removeItem("IS_OPENED_FROM_DOMAIN");
      await thunkDispatch(
        setCompanyDataThunk({
          cb: redirectToMainPage,
          isNewUser: isNewUser,
          companyDetailsNavigate: (
            unsginedDocsCount: number,
            isNewUser: boolean,
          ) =>
            navigate(
              `/${getRouteBasedOnUnsignedDocumentsAndIsNewUser(
                unsginedDocsCount,
                isNewUser,
              )}`,
            ),
        }),
      );
      return;
    }
    const res = await thunkDispatch(refreshTokenThunk());
    const token = apiService.getToken();
    const refresh = apiService.getRefresh();

    if (!token || !refresh || (res && res.type.includes("/rejected"))) {
      cleanStorageAndGoToLanding();
      if (intervalRef.current) clearInterval(intervalRef.current);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    }
  }, [navigate, thunkDispatch]);

  return (
    <Box>
      <NavigationComponent />
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
};
