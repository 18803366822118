import { FC, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { LoaderContext } from "../../context/LoaderContext";
import { Alert, Box, Button, InputAdornment, Typography } from "@mui/material";
import {
  AuthTypes,
  OtpMethod,
  setAuthMethod,
} from "../../store/features/form/formSlice";
import { InputWidget } from "../widgets/FormWidgets/InputWidget";
import { LoadingButton } from "@mui/lab";
import { useFormContext } from "react-hook-form";

const messages = [
  "Your account and company are in the works...",
  "Hang tight, we're crafting your account and company...",
  "Configuring settings to match your needs...",
  "Securing your data for peace of mind...",
  "Almost there—full access coming soon!",
  "Thanks for waiting, it won't be long!",
];

const OtpMethodsLabel = {
  email: "Email verification code",
  phone_number: "Phone number verification code",
  google_authenticator: "Google Authenticator verification code",
};

type OtpItemProps = {
  method: OtpMethod;
  onSendCode: (
    value: "email" | "phone_number" | "google_authenticator",
  ) => void;
};
const OtpItem: FC<OtpItemProps> = ({ method, onSendCode }) => {
  let interval: any = null;
  const { type, timeout, message } = method;
  const [sec, setSec] = useState(timeout);

  useEffect(() => {
    if (sec <= 0) {
      clearTimeout(interval);
      return;
    }

    handleTick();

    return () => {
      clearInterval(interval);
    };
  }, [sec]);

  useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleTick = () => {
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(() => {
      setSec((prevState) => prevState - 1);
    }, 1000);
  };
  return (
    <>
      <div style={{ width: "100%", paddingTop: "30px" }}>
        <Typography
          sx={{ textAlign: "start", color: "#6F6869" }}
          variant={"subtitle2"}
        >
          {message}
        </Typography>
        <InputWidget
          label={OtpMethodsLabel[type]}
          fieldId={`verificationCodes[${type}]`}
          type={"text"}
          required={true}
          value={""}
          InputProps={{
            endAdornment: !timeout ? undefined : (
              <InputAdornment position="end">
                <Box
                  sx={{
                    textDecoration: !sec ? "underline" : "none",
                    lineHeight: "21px",
                    fontSize: "14px",
                    cursor: "pointer",
                    color: !sec ? "#3874CB" : "#3FBA73",
                  }}
                  onClick={() => {
                    if (sec) return;
                    setSec(timeout);
                    onSendCode(type);
                  }}
                >
                  {!sec ? "Resent Code" : "Code sent"}
                </Box>
              </InputAdornment>
            ),
          }}
        />
      </div>
      {timeout && sec > 0 && (
        <Box display={"flex"} flexDirection={"column"} alignItems={"start"}>
          <Typography
            variant={"subtitle2"}
            sx={{ color: "#6F6869", fontSize: "12px" }}
          >
            You can request a new code in {sec} seconds.{" "}
          </Typography>
          <Typography
            variant={"subtitle2"}
            sx={{ color: "#6F6869", fontSize: "12px" }}
          >
            The code will expire in 5 minutes.
          </Typography>
        </Box>
      )}
    </>
  );
};

type OtpWidgetProps = {
  otpMethods: OtpMethod[] | undefined;
  onSendCode: (
    value: "email" | "phone_number" | "google_authenticator",
  ) => void;
  authMethod?: AuthTypes | null;
  isModal?: boolean;
  setIsOpenModal?: (value: boolean) => void;
  isModalLoading?: boolean;
};

export const OtpWidget: FC<OtpWidgetProps> = ({
  otpMethods,
  onSendCode,
  authMethod,
  isModal,
  setIsOpenModal,
  isModalLoading,
}) => {
  const dispatch = useDispatch();
  const { isLoading } = useContext(LoaderContext);

  const [showMessage, setShowMessage] = useState(false);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  // @ts-ignore
  const authType: AuthTypes | null = authMethod?.substring(
    authMethod.indexOf("_") + 1,
  );

  useEffect(() => {
    let messageInterval: string | number | NodeJS.Timeout | undefined;
    let blockTimeout: string | number | NodeJS.Timeout | undefined;

    if (isLoading) {
      blockTimeout = setTimeout(() => {
        setShowMessage(true);
      }, 4000);

      messageInterval = setInterval(() => {
        setCurrentMessageIndex(
          (prevIndex) => (prevIndex + 1) % messages.length,
        );
      }, 4000);
    }

    return () => {
      clearInterval(blockTimeout);
      clearInterval(messageInterval);
    };
  }, [isLoading]);

  const showMessageStyle = {
    opacity: showMessage ? 1 : 0,
    transition: "opacity 0.5s ease-in-out",
  };

  return (
    <Box sx={{ maxWidth: "400px", width: "100%", minWidth: "400px" }}>
      {!isModal ? (
        <>
          <Button
            onClick={() => {
              dispatch(setAuthMethod(authType));
            }}
            variant={"text"}
            color={"inherit"}
            sx={{ display: "flex", marginBottom: "40px" }}
          >
            {"< Back"}
          </Button>
          <Typography variant={"h5"} textAlign={"center"}>
            Security verification
          </Typography>
          {otpMethods?.map((item) => (
            <OtpItem method={item} key={item.type} onSendCode={onSendCode} />
          ))}
          <LoadingButton
            fullWidth
            variant={"contained"}
            type={"submit"}
            color={"inherit"}
            sx={{ marginTop: "20px" }}
            loading={isLoading}
            loadingPosition="end"
            endIcon={<></>}
          >
            Confirm
          </LoadingButton>
          <Alert
            style={showMessageStyle}
            sx={{
              marginTop: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            variant="filled"
            severity="info"
            icon={false}
          >
            <span>{messages[currentMessageIndex]}</span>
          </Alert>
        </>
      ) : (
        <>
          <Typography
            variant={"h6"}
            textAlign={"start"}
            sx={{ fontWeight: "bold" }}
          >
            Security verification
          </Typography>
          {otpMethods?.map((item) => (
            <OtpItem method={item} key={item.type} onSendCode={onSendCode} />
          ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "12px",
              marginTop: "20px",
            }}
          >
            <Button
              variant={"outlined"}
              color={"inherit"}
              onClick={() => setIsOpenModal && setIsOpenModal(false)}
              sx={{ textTransform: "capitalize", padding: "7px 17px" }}
            >
              Cancel
            </Button>
            <LoadingButton
              type={"submit"}
              variant={"contained"}
              color={"error"}
              loading={isModalLoading}
              sx={{ textTransform: "capitalize", padding: "7px 34px" }}
            >
              Confirm
            </LoadingButton>
          </Box>
        </>
      )}
    </Box>
  );
};
