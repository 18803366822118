import ListItem from "@tiptap/extension-list-item";

// TODO?: rewrite listitem as tasklist, marker - none
// and then being able to customize custom marker
// that can be done as label OR try to use clientX and Y 
// and get position
export const ExtendedListItem = ListItem.extend({
  addAttributes() {
    return {
      ...(this.parent ? this.parent() : {}),
      color: {
        default: null,
        parseHTML: (element) => element.style.color?.replace(/['"]+/g, ""),
        renderHTML: (attributes) => {
          let color = attributes.color;
          if (!attributes.color) {
            return;
          }
          return {
            style: `color: ${color}`,
          };
        },
      },
      fontSize: {
        default: null,
        parseHTML: (element) => {
          const fontSize = element.style.fontSize;
          return fontSize;
        },
        renderHTML: (attributes) => {
          if (!attributes.fontSize) {
            return {};
          }

          return {
            style: `font-size: ${attributes.fontSize}`,
          };
        },
      },
    };
  },
});

export default ExtendedListItem;
