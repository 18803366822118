import { FC, useEffect, useState } from "react";

import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useAppSelector, useAppThunkDispatch } from "../../../store";

import { TableComponent } from "../../TableComponent/TableComponent";
import { FormProvider, useForm } from "react-hook-form";
import { InputWidget } from "../../widgets/FormWidgets/InputWidget";
import { SelectWidget } from "../../widgets/FormWidgets/SelectWidget";
import {
  deleteWorkflowThunk,
  getWorkflowsThunk,
} from "../../../store/features/thunk/workflowThunk/workflowThunk";
import { getLocationsThunk } from "../../../store/features/thunk/locationThunk/locationThunk";
import { Loader } from "../../Loader/Loader";

const header = [
  "WORKFLOW NAME",
  "DOCUMENTS COUNT",
  "CREATED AT",
  "UPDATED AT",
  "LINK",
  "",
];

export const Workflows: FC<{ breadcrumbs?: boolean }> = () => {
  const [workflowFilter, setWorkflowFilter] = useState<Record<
    string,
    any
  > | null>(null);
  const [optionList, setOptionList] = useState<
    { label: string; value: string }[] | []
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const { workflows } = useAppSelector(({ workflow }) => workflow);
  const { locations } = useAppSelector(({ location }) => location);

  const navigate = useNavigate();
  const dispatchThunk = useAppThunkDispatch();

  const methods = useForm();
  const { handleSubmit } = methods;

  useEffect(() => {
    const optionList = locations.length
      ? [
          { label: "Global", value: "0" },
          ...locations.map((location) => ({
            label: location.name,
            value: `${location.id}`,
          })),
        ]
      : [];

    setOptionList(optionList);
  }, [locations]);

  useEffect(() => {
    handleWorkflows();
  }, [workflowFilter]);

  useEffect(() => {
    dispatchThunk(getLocationsThunk());
  }, []);

  const handleWorkflows = async () => {
    setIsLoading(true);
    await dispatchThunk(getWorkflowsThunk(workflowFilter));
    setIsLoading(false);
  };

  const handleDelete = async (id: string) => {
    setIsLoading(true);
    await dispatchThunk(deleteWorkflowThunk(id));
    setIsLoading(false);
  };

  const handleEdit = (id: string | number) => {
    navigate(`/workflow/${id}`);
  };

  const onSubmit = (data: Record<string, any>) => {
    setWorkflowFilter((prevState) => ({ ...prevState, filter: data }));
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Box
        sx={{
          width: "100%",
          paddingBottom: "30px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <FormProvider {...methods}>
          <form
            onChange={handleSubmit(onSubmit)}
            onSelect={handleSubmit(onSubmit)}
            noValidate
          >
            <div
              style={{
                display: "flex",
                minWidth: "150px",
                maxHeight: "60px",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  minWidth: "70px",
                }}
              >
                Filter by:
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  gap: "15px",
                }}
              >
                <div style={{ minWidth: "200px" }}>
                  <InputWidget
                    type={"text"}
                    fieldId={"title"}
                    label={"Workflow name"}
                    value={""}
                  />
                </div>
                <div style={{ minWidth: "200px" }}>
                  <SelectWidget
                    onClose={handleSubmit(onSubmit)}
                    label={"Location"}
                    value={""}
                    fieldId={"locationId"}
                    type={"select"}
                    option_list={optionList}
                  />
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
        <Box display={"flex"} alignItems={"center"}>
          <Button
            variant={"contained"}
            color={"success"}
            onClick={() => {
              navigate("/workflow/new");
            }}
          >
            + Create Workflow
          </Button>
        </Box>
      </Box>
      <TableComponent
        type={"workflows"}
        rows={workflows}
        headers={header}
        isEditDelete={true}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
    </>
  );
};
