class UniqKeyService {
  protected existingKeys: string[] = [];

  isExists(key: string) {
    return this.existingKeys.includes(key);
  }

  add(key: string) {
    this.existingKeys.push(key);
  }

  clear() {
    this.existingKeys = [];
  }
}

export default new UniqKeyService();
