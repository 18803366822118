export function getTitleForDocumentWidgetModalForm(type: string) {
  switch (type) {
    case "text":
      return "Input Field";
    case "textarea":
      return "Input Text Area";
    case "radio":
      return "Radio Input";
    case "checkbox":
      return "Checkbox";
    case "signature":
      return "Signature";
    case "textarea":
      return "Input Text Area";
    case "datepicker":
      return "Date Picker";
    default:
      return "Default Modal Title";
  }
}
