import { FC, RefObject } from "react";

import { OverviewType, PropsCommentItem } from "../../shared/types";
import Heading from "../../shared/Heading";
import Overview from "../../shared/Overview";
import Button from "../../shared/Button";
import { CommentsSlider } from "./CommentsSlider";
import { CommentItem } from "./CommentItem";
import { comments } from "../../data/commentsData";
import useMediaQuery from "../../hooks/useMediaQuery";

// @ts-ignore
import handsomeblondbeardedmanshowingokgesture from "../../assets/handsomeblondbeardedmanshowingokgesture.png";

const aboutInfos: Array<OverviewType> = [
  {
    description: `After hearing concerns from business professionals about document management and integration, waiver form headaches, and even paper conservation, we began building WaiverKing <span style="color: #ED9645">from the ground up to fully accommodate the new fast-paced digital business climate.</span>`,
  },
  {
    description: `With strong word-of-mouth fueling our growth and innovation, things really took off <span style="color: #ED9645">in 2010</span> when we were contracted to build a waiver form kiosk for a trampoline park in Dublin, California. The results were exceptional and <span style="color: #ED9645">our budding software service was suddenly in high demand.</span>`,
  },
  {
    description: `Since then, <span style="color: #ED9645">we have worked tirelessly</span> to stay at the forefront of the industry by advancing our functionality and perfecting our user experience.<br />
Now, we have offices on the east and west coasts of the United States with continued growth planned <span style="color: #ED9645">in Canada, Europe, and Australia.<br />`,
  },
  {
    description: `<span style="color: #ED9645">Our diverse customer</span> base includes everything from Spa’s, Gyms, and Yoga Studios, to Schools, Vacation Resorts, Golf Clubs, Tattoo Parlors, and many more.`,
  },
];

interface AboutUsProps {
  aboutUsRef: RefObject<HTMLDivElement>;
}

const AboutUs: FC<AboutUsProps> = ({ aboutUsRef }) => {
  const isUnderMediumScreen = useMediaQuery("(min-width: 1024px");

  return (
    <section id="aboutus" ref={aboutUsRef}>
      <div className="bg-seconary-100 shadow-[0px_-4px_20px_0px_rgba(0,0,0,0.15)_inset] xs:px-[24px] xs:pb-[70px] xs:pt-[10px] sm:px-[80px] sm:pb-[90px] md:px-[139px] lg:px-[153px] lg:py-[100px]">
        <Heading isMarginNone>About us</Heading>
        <h3 className="pb-[72px] pt-[40px] text-center font-poppins font-bold text-primary-700 xs:text-sm sm:px-7 sm:text-xl md:px-0">
          WaiverKing evolved quickly from an inspired idea into a dynamic
          {isUnderMediumScreen ? <br /> : " "}
          solution to a host of business challenges
        </h3>
        <div className="flex xs:mt-[-35px] xs:w-[100%] xs:flex-wrap xs:justify-center xs:gap-6 sm:mt-0 sm:justify-between sm:gap-y-12 md:gap-3 md:gap-y-12 lg:flex-nowrap lg:justify-evenly lg:gap-0">
          {aboutInfos.map((aboutInfo: OverviewType) => (
            <Overview
              description={aboutInfo.description}
              key={aboutInfo.description}
            />
          ))}
        </div>
      </div>
      <div className="xs:px-0 xs:pb-[70px] xs:pt-0 sm:px-[66px] md:px-[109px] md:pb-[100px] md:pt-[40px] lg:px-[153px] lg:py-[100px]">
        <Heading>What client’s say</Heading>
        <CommentsSlider>
          {comments.map((comment: PropsCommentItem) => (
            <CommentItem {...comment} key={comment.name} />
          ))}
        </CommentsSlider>
      </div>
      <div className="relative flex justify-end gap-8 bg-seconary-100 shadow-[0px_-4px_20px_0px_rgba(0,0,0,0.15)_inset] xs:flex-col-reverse xs:px-[23px] sm:flex-row sm:px-[96px] sm:py-[100px] md:px-[139px] lg:px-[153px] lg:py-[100px] lg:pb-0">
        <div className="flex xs:mx-auto xs:w-4/5 sm:bottom-0 sm:left-[-135px] sm:mx-0 sm:w-full sm:max-lg:absolute md:left-[-100px] lg:left-[-80px] lg:w-2/5">
          <img
            src={handsomeblondbeardedmanshowingokgesture}
            alt="handsomeblondbeardedmanshowingokgesture"
          />
        </div>
        <div className="xs:w-full sm:w-4/5 lg:w-3/5">
          <h1
            className="mx-auto text-center font-poppins font-bold text-primary-700 xs:mt-[60px] xs:text-2xl sm:mb-6
           sm:mt-0 sm:text-2xl sm:leading-normal md:text-3xl lg:mt-0 lg:text-4xl"
          >
            Don't miss out on revolutionizing
            <br /> your business.
          </h1>
          <p className="py-6 font-poppins font-normal leading-8 text-primary-700 xs:text-sm sm:pl-20 sm:text-base md:pl-8 md:text-xl lg:pb-12 lg:pl-0">
            Join WKForm today at{" "}
            <a
              className="text-primary-400 underline"
              href="https://www.wkform.com"
            >
              www.wkform.com
            </a>{" "}
            and witness the power of HTML5 technology - no app installations
            required! Embrace the future of waiver and form management while
            unlocking the full potential of your health and wellness or activity
            business. Experience WaiverKing - Where Efficiency Meets Excellence!
          </p>
          <div>
            <Button
              text={"Start free trial"}
              style={
                "bg-primary-400 text-primary-010 w-[164px] m-auto sm:h-auto xs:h-[42px]"
              }
              href={"/signup"}
            />
            <p className="text-center font-roboto font-normal text-primary-300 xs:text-xs sm:text-sm">
              No credit card required
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AboutUs;
