import { FC } from "react";

import { Box, Typography } from "@mui/material";
import {
  InputWidget,
  InputWidgetProps,
} from "../../../FormWidgets/InputWidget";

const signInFormData: InputWidgetProps[] = [
  {
    type: "email",
    // validator: "",//REG
    label: "Email",
    fieldId: "email",
    required: true, //boolean
    value: "",
    // validation_message: '',
    // custom_validator: {
    //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
    //   message: '',//string;
    //   flags: ''//string;
    // },
    // maxlength: 50
  },
];

export const ResetPasswordWidget: FC = () => {
  return (
    <Box>
      <Typography variant={"h4"} textAlign={"center"}>
        Reset Password
      </Typography>
      <Typography variant={"subtitle1"} sx={{ paddingTop: "20px" }}>
        Enter the email associated with your account and we’ll send an email
        with instructions to reset your password
      </Typography>
      <div style={{ maxWidth: 300, margin: "0 auto", paddingTop: "30px" }}>
        <InputWidget {...signInFormData[0]} />
      </div>
    </Box>
  );
};
