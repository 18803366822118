import { UniqParagraphKeyPlugin } from "./UniqParagraphKeyPlugin";
import { Extension } from "@tiptap/core";

const UniqKey = Extension.create({
  name: "UniqKey",
  addProseMirrorPlugins() {
    return [UniqParagraphKeyPlugin(this.editor.isEditable)];
  },
});

export default UniqKey;
