import { Instance, Plugin } from "tippy.js";
import { debounce, DebouncedFunc } from "lodash";

interface UpdateBubbleMenuPosition extends Plugin {
  name: "UpdateBubbleMenuPosition";
  defaultValue: true;
}

export const UpdateBubbleMenuPositionPlugin: UpdateBubbleMenuPosition = {
  name: "UpdateBubbleMenuPosition",
  defaultValue: true,
  fn(instance: Instance) {
    const updatePosition = debounce(() => {
      instance.popperInstance?.update();
      instance.popper.style.display = "block";
    }, 200) as DebouncedFunc<() => void>;
    return {
      onMount() {
        updatePosition();
      },
      onAfterUpdate() {
        instance.popper.style.display = "none";
        updatePosition();
      },
    };
  },
};
