import { useState, useEffect, FC } from "react";
import tipTapEditorService from "./TipTapEditorService";
import {
  TipTapEditorContext,
  tipTapEmptyState,
  TipTapSelection,
} from "./types";

import TableRow from "@tiptap/extension-table-row";
import Gapcursor from "@tiptap/extension-gapcursor";
import DropCursor from "@tiptap/extension-dropcursor";
import { Editor, EditorContent, useEditor } from "@tiptap/react";

import { extensions as commonExtensions } from "./shared/extensions";

import Heading from "./extensions/Heading";
import Paragraph from "./extensions/Paragraph";

import { MenuBubble } from "./Menu/MenuBubble/MenuBubble";
import { MenuBar } from "./Menu/MenuBar";

import Table from "./extensions/Table";
import ImageWidget from "./extensions/Image";
import TableCell from "./extensions/TableCell";
import TableHeader from "./extensions/TableHeader";
import UniqKey from "./extensions/UniqKey/UniqKey";
import OnPressEnter from "./extensions/OnPressEnter";
import ReplacePhrase from "./extensions/ReplacePhrase";
import FileAttachment from "./extensions/FileAttachment";
import uniqKeyService from "./extensions/UniqKey/UniqKeyService";
import SelectLinkPlugin from "./plugins/SelectLinkPlugin";
import EditorContentPlugin from "./plugins/EditorContentPlugin";
import IndentElement from "./extensions/IndentElelement";

import "./styles/Editor.scss";
import "./styles/Extensions.scss";
import "codemirror/mode/xml/xml.js"; // language
import "codemirror/lib/codemirror.css"; // import base style
import "codemirror/addon/edit/closetag.js";
import "codemirror/addon/selection/active-line.js";

interface TipTapProps {
  content?: string;
  isEditable?: boolean;
  setEditor: (editor: Editor) => void;
  setIsContent: (boolean: boolean) => void;
  isContent?: boolean;
  isSaveBtnHit: boolean;
  setWasDocumentChanged: (value: any) => void;
}

function doShowValidation(
  isContent: boolean | undefined,
  isSaveBtnHit: boolean,
) {
  return !isContent && isSaveBtnHit;
}
// used for debounce and reduce quantity of re-rendering child components
const CONTEXT_UPDATE_TIMEOUT = 100; 

export const TipTap: FC<TipTapProps> = ({
  content = "",
  isEditable = true,
  setEditor,
  setIsContent,
  isContent,
  isSaveBtnHit,
  setWasDocumentChanged,
}) => {
  const editor = useEditor(
    {
      extensions: [
        ...commonExtensions,
        Paragraph,
        IndentElement,
        ReplacePhrase,
        DropCursor,
        Gapcursor,
        Table.configure({
          resizable: true
        }),
        TableRow.configure({
        }),
        TableCell.configure({
        }),
        TableHeader.configure({
        }),
        ImageWidget,
        FileAttachment,
        Heading,
        UniqKey,
        //@ts-ignore
        // FloatingMenuExtension.configure({ element: document.querySelector('.menu') }),
        // CodeBlock.configure({ lowlight }),
        OnPressEnter,
      ],
      // empty paragraph needed in view mode to fix issue with adding comment for last paragraph
      content: content + (!isEditable ? "<p></p>" : ""),
      editable: isEditable,
      onUpdate: () => onUpdate(),
      onSelectionUpdate: () => onSelectionUpdate(),
    },
    [content],
  );
  const [editorKey] = useState(tipTapEditorService.generateKey());
  const [charactersCount, setCharactersCount] = useState<number>(0);
  const editorContext: TipTapEditorContext = {
    state: tipTapEmptyState(),
    selection: null,
  };

  let updateContextTimeout: NodeJS.Timeout | null = null;

  useEffect(() => {
    if (!editor) {
      return;
    }
    uniqKeyService.clear();
    editor?.registerPlugin(SelectLinkPlugin);
    editor?.registerPlugin(EditorContentPlugin);
    setCharactersCount(editor?.storage.characterCount.characters());

    tipTapEditorService.registerEditor(editorKey, editor);
    setEditor(editor);
    return () => {
      tipTapEditorService.destroyEditor(editorKey);
    };
  }, [editor]);

  useEffect(() => {
    const isContentInEditor = Boolean(
      editor?.state.doc.textContent.trim().length,
    );
    setIsContent(isContentInEditor);
  }, [charactersCount]);

  const updateContext = () => {
    const editorCOP = editor;
    if (!editorCOP) {
      return;
    }

    editorContext.selection =
      editorCOP?.state.selection.toJSON() as TipTapSelection;
    const activeTextStyles = editorCOP.getAttributes("textStyle");
    editorContext.state = {
      isActiveBold: editorCOP.isActive("bold"),
      isActiveBlockquote: editorCOP.isActive("blockquote"),
      isActiveFontFamily: !!activeTextStyles.fontFamily,
      activeFontFamily: activeTextStyles.fontFamily || "",
      isActiveItalic: editorCOP.isActive("italic"),
      isActiveImage: editorCOP.isActive("image"),
      isActiveStrike: editorCOP.isActive("strike"),
      isActiveLink: editorCOP.isActive("link"),
      isActiveUnderline: editorCOP.isActive("underline"),
      isActiveHeading: editorCOP.isActive("heading"),
      isActiveHeading1: editorCOP.isActive("heading", { level: 1 }),
      isActiveHeading2: editorCOP.isActive("heading", { level: 2 }),
      isActiveHeading3: editorCOP.isActive("heading", { level: 3 }),
      isActiveHeading4: editorCOP.isActive("heading", { level: 4 }),
      isActiveHeading5: editorCOP.isActive("heading", { level: 5 }),
      isActiveTextAlignLeft: editorCOP.isActive({ textAlign: "left" }),
      isActiveTextAlignCenter: editorCOP.isActive({ textAlign: "center" }),
      isActiveTextAlignRight: editorCOP.isActive({ textAlign: "right" }),
      isActiveTextAlignJustify: editorCOP.isActive({ textAlign: "justify" }),
      isActiveBulletList: editorCOP.isActive("bulletList"),
      isActiveOrderedList: editorCOP.isActive("orderedList"),
      isActiveTodoList: editorCOP.isActive("taskList"),
      isActiveCodeBlock: editorCOP.isActive("codeblock"),
      isActiveHighlight: editorCOP.isActive("highlight"),
      isActiveTextStyle: editorCOP.isActive("textStyle"),
      isActiveTable: editorCOP.isActive("table"),
      isCanMergeCells: editorCOP.can().mergeCells(),
      isCanSplitCell: editorCOP.can().splitCell(),
    };
  };

  const requestUpdateContext = () => {
    updateContextTimeout && window.clearTimeout(updateContextTimeout);
    updateContextTimeout = setTimeout(() => {
      updateContext();
    }, CONTEXT_UPDATE_TIMEOUT);
  };

  // const emitUpdateEvent = (delay = 0) => {
  //   emitUpdateTimeout && window.clearTimeout(emitUpdateTimeout);
  //
  //   emitUpdateTimeout = setTimeout(() => {
  //     const html = editor?.getHTML();
  //   }, delay);
  // };

  const onUpdate = () => {
    setCharactersCount(editor?.storage.characterCount.characters());
    requestUpdateContext();
    setWasDocumentChanged(true)
    // emitUpdateEvent(EMIT_UPDATE_TIMEOUT);
  };

  const onSelectionUpdate = () => {
    requestUpdateContext();
  };

  return (
    <>
      {editor && (
        <>
          {doShowValidation(isContent, isSaveBtnHit) && (
            <p style={{ color: "red" }}>
              You must enter text in the editor to save document
            </p>
          )}
          <div
            className="el-tiptap-editor"
            style={{
              border: doShowValidation(isContent, isSaveBtnHit)
                ? "1px solid red"
                : "",
            }}
          >
            <MenuBubble
              isEditable={isEditable}
              editor={editor}
              editorContext={editorContext}
            />
            {isEditable && (
              <MenuBar
                className="el-tiptap-editor__menu-bar border-top-radius"
                editor={editor}
                editorContext={editorContext}
              />
            )}
            {/*<FloatingMenu editor={editorRef.current} editorContext={editorContext} />*/}
            <EditorContent
              className={`el-tiptap-editor__content ${
                isEditable ? "is-editable" : ""
              }`}
              editor={editor}
            />
            {isEditable && (
              <div className="el-tiptap-editor__footer border-bottom-radius">
                <span className="el-tiptap-editor__characters-count">
                  {charactersCount}
                </span>
                <span className="el-tiptap-editor__characters-label">
                  &nbsp;characters
                </span>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
