import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FieldValues } from "react-hook-form";

export enum AuthTypes {
  RESET = "RESET",
  SIGN_IN = "SIGN_IN",
  SIGN_UP = "SIGN_UP",
  OTP_RESET = "OTP_RESET",
  OTP_SIGN_UP = "OTP_SIGN_UP",
  OTP_SIGN_IN = "OTP_SIGN_IN",
  NEW_PASSWORD = "NEW_PASSWORD",
  SEND_RESET_OTP = "SEND_RESET_OTP",
}

export type OtpMethod = {
  type: "email" | "phone_number" | "google_authenticator";
  message: string;
  destination?: string;
  timeout: number;
  expiresIn: number;
};

export type FormStateType = {
  workflowId: string;
  isFormValid: boolean;
  documentId: string | null;
  formData: FieldValues | null;
  authMethod: AuthTypes | null;
  otpMethods: OtpMethod[];
};

const initialState: FormStateType = {
  workflowId: "",
  formData: null,
  authMethod: null,
  documentId: null,
  isFormValid: false,
  otpMethods: [],
};

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setAuthMethod: (state, { payload }: PayloadAction<AuthTypes | null>) => {
      state.authMethod = payload;
    },
    setIsFormValid: (state, { payload }: PayloadAction<boolean>) => {
      state.isFormValid = payload;
    },
    setFormData: (state, { payload }: PayloadAction<FieldValues>) => {
      state.formData = state.formData
        ? { ...state.formData, ...payload }
        : payload;
    },
    setDocumentId: (state, { payload }: PayloadAction<string | null>) => {
      state.documentId = payload;
    },
    setWorkflowId: (state, { payload }: PayloadAction<string>) => {
      state.workflowId = payload;
    },
    setOtpMethods: (state, { payload }: PayloadAction<OtpMethod[]>) => {
      state.otpMethods = payload;
    },
  },
});

export const {
  setAuthMethod,
  setOtpMethods,
  setIsFormValid,
  setFormData,
  setDocumentId,
  setWorkflowId,
} = formSlice.actions;
