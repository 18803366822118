import { findParentNode, mergeAttributes, ParentConfig } from "@tiptap/core";
import { TableCell as TiptapTableCell } from "@tiptap/extension-table-cell";

const transparentStyle = "border: none;";
const normalStyle = "border: 2px solid #ebeef5;";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    tableCell: {
      setBorderStyleToAllTableCells: () => ReturnType;
    };
  }
}

const TableCell = TiptapTableCell.extend({
  addAttributes() {
    return {
      ...(this.parent ? this.parent() : {}),
      borderStyle: {
        default: null,
        parseHTML: (element: Element) => {
          return element.getAttribute("borderStyle");
        },
        renderHTML: (attributes: any) => {
          return { borderStyle: attributes.borderStyle };
        },
      },
      style: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "td",
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      "td",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        style: `${node.attrs.borderStyle ? transparentStyle : normalStyle}`,
        class: `${node.attrs.borderStyle ? "transparent-cell" : ""}`,
      }),
      0,
    ];
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setBorderStyleToAllTableCells:
        () =>
        ({ tr, editor }) => {
          const tableNode = findParentNode(({ type }) => type.name === "table")(
            this.editor.state.selection,
          );
          const getNodePos = (node: Node) => {
            let pos = -1;
            tr.doc.forEach((childNode: any, _pos: any) => {
              if (childNode.eq(node)) {
                pos = _pos;
              }
            });
            return pos;
          };

          const tablePos = getNodePos(tableNode?.node as any);

          tr.doc.nodesBetween(
            tablePos,
            //@ts-ignore
            tablePos + tableNode?.node?.nodeSize,
            (node: any, pos: number) => {
              if (["tableCell"].includes(node.type.name)) {
                tr.setNodeMarkup(pos, null, {
                  borderStyle:
                    node?.attrs.borderStyle === undefined
                      ? false
                      : !node.attrs.borderStyle,
                });
              }
            },
          );
          return true;
        },
    };
  },
});

export default TableCell;
