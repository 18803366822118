import { ReactNode } from "react";
import {
  Box,
  createTheme,
  styled,
  Tab,
  TabProps,
  Tabs,
  TabsProps,
} from "@mui/material";
import { green } from "@mui/material/colors";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}
export const StyledTabs = styled((props: TabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-flexContainer": {},
  "& .MuiTabs-indicator": {
    backgroundColor: "#3FBA73",
  },
  "& .MuiTabs-indicatorSpan": {},
});

export const StyledTab = styled((props: TabProps) => (
  <Tab disableRipple {...props} />
))(() => ({
  textTransform: "none",
  "&.Mui-selected": {
    color: "#3FBA73",
  },
}));

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}
