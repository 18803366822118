import React from "react";
import { Editor } from "@tiptap/core";
import { MenuButton } from "../../MenuButton";

interface MenuUndoButtonProps {
  editor: Editor;
}

export const MenuUndoButton: React.FC<MenuUndoButtonProps> = ({ editor }) => {
  const runCommand = () => {
    editor.chain().undo().run();
  };

  return <MenuButton title="Undo" icon="undo" command={runCommand} />;
};
