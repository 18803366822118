import { useState, useEffect } from "react";

const useScrollPosition = () => {
  const [scrollY, setScrollY] = useState(0);
  const [container, setContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const containerElement = document.querySelector(
      ".landing-wrapper",
    ) as HTMLElement;
    setContainer(containerElement);
  }, []);

  const handleScroll = () => {
    if (container) {
      setScrollY(container.scrollTop);
    }
  };

  useEffect(() => {
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [container]);

  return scrollY;
};

export default useScrollPosition;
