import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { Tooltip } from "@mui/material";

interface DropdownButtonProps {
  options: { label: string; method: () => void }[];
  disabled?: boolean;
  sx?: React.CSSProperties;
  tooltipMessage?: string;
}

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  options,
  disabled,
  sx,
  tooltipMessage,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleClick = () => {
    options[selectedIndex].method();
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="outlined"
        color="success"
        ref={anchorRef}
        aria-label="dropdown button"
        sx={sx}
        disabled={disabled}
      >
        <Tooltip title={tooltipMessage} placement="top" arrow>
          <span>
            <Button onClick={handleClick}>
              { options[selectedIndex].label }
            </Button>
          </span>
        </Tooltip>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      // disabled={index === 1}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      sx={{
                        fontSize: "14px",
                        color: "#2E7D32",
                        "&:hover": {
                          backgroundColor: "rgba(0, 128, 0, 0.1)",
                        },

                        "&.Mui-selected": {
                          backgroundColor: "#2E7D32",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#2E7D32",
                          },
                        },
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
