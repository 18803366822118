import { Editor } from "@tiptap/core";
import { generateRandomID } from "helper/shortIdGenerator";
import { FieldValues } from "react-hook-form";
import { WidgetType } from "tiptap/types/WidgetType";
import { WidgetTypeEnum } from "tiptap/types/enums/WidgetTypeEnum";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ShortTextIcon from "@mui/icons-material/ShortText";
import SubjectIcon from "@mui/icons-material/Subject";
import GestureIcon from "@mui/icons-material/Gesture";

type CustomInputsDict = {
  [key in WidgetType]: {
    btnLabel: string;
    addWidget: (data: FieldValues, editor: Editor) => void;
    icon: any;
  };
};

function getJSONStyle(style: { height: number; width: number }) {
  const JSONstyle = JSON.stringify({ style: style }).slice(1, -1);
  return JSONstyle;
}

const replaceIdsWithRandom = (string: string, id: string) => {
  let newString = string.replace(/document\.id/g, () => `document.${id}`);

  newString = newString.replace(/document\[id\]/g, () => `document[${id}]`);

  return newString;
};

export const setPhrases = (
  replacePhrase: {
    phrase: string;
    label: string;
    filterName?: string;
    config: string;
  },
  editor: Editor,
) => {
  const id = generateRandomID();
  const twigTemplate = {
    ...replacePhrase,
    phrase: replaceIdsWithRandom(replacePhrase.phrase, id),
    filterName: replacePhrase.filterName,
    config: replacePhrase.filterName
      ? replaceIdsWithRandom(replacePhrase.config, id)
      : undefined,
  };

  editor && editor.chain().focus().insertReplacePhrase(twigTemplate).run();
};

export const replacePhrases = [
  {
    phrase: "profile.firstName",
    label: "Profile: First Name",
    config: `{"style": {"width": 150}}`,
  },
  {
    phrase: "profile.lastName",
    label: "Profile: Last Name",
    config: `{"style": {"width": 150}}`,
  },
  {
    phrase: "profile.email",
    label: "Profile: Email",
    config: `{"style": {"width": 150}}`,
  },
  {
    phrase: "profile.phone",
    label: "Profile: Phone",
    config: `{"style": {"width": 150}}`,
  },
  {
    phrase: "profile.address",
    label: "Profile: Address",
    config: `{"style": {"width": 150}}`,
  },
  {
    phrase: "profile.city",
    label: "Profile: City",
    config: `{"style": {"width": 150}}`,
  },
  {
    phrase: "profile.region",
    label: "Profile: State",
    config: `{"style": {"width": 150}}`,
  },
  {
    phrase: "profile.postalCode",
    label: "Profile: Zip / Postal",
    config: `{"style": {"width": 150}}`,
  },
  {
    phrase: "profile.dob",
    filterName: "customDate",
    config: `{"dateFormat": "m/d/Y"}`,
    label: "Profile: Date of birth",
  },
  {
    phrase: "profile.country",
    label: "Profile: Country",
    config: `{"style": {"width": 150}}`,
  },
  {
    phrase: "meta",
    filterName: "serverDate",
    label: "Current Date",
    config: `{"dateFormat": "MM/DD/YYYY"}`,
  },
];

const addRadioWidget = (data: FieldValues, editor: Editor) => {
  if (!data.style) {
    data.style = {
      height: 25,
      width: 100,
    };
  }
  const style = getJSONStyle(data.style);

  const config = {
    phrase: `document.${data.name.trim().replace(/\s+/g, "_")}`,
    filterName: "field",
    config: `{ "fieldId": "document[id]", "type": "${WidgetTypeEnum.RADIO}", "required": ${data.required}, "name": "document[${data.name}]", "value": "${data.value}", ${style}}`,
    label: `Radio field ${data.value}`,
  };

  setPhrases(config, editor);
};

const addInputWidget = (data: FieldValues, editor: Editor) => {
  if (!data.style) {
    data.style = {
      height: 25,
      width: 300,
    };
  }
  const style = getJSONStyle(data.style);
  const config = {
    id: "",
    phrase: `document.id`,
    filterName: "field",
    config: `{ "fieldId": "document[id]", "type": "${WidgetTypeEnum.TEXT}","required": ${data.required}, "label": "${data.label}", ${style}}`,
    label: "input field",
  };

  setPhrases(config, editor);
};

const addTextAreaWidget = (data: FieldValues, editor: Editor) => {
  if (!data.style) {
    data.style = {
      height: 120,
      width: 300,
    };
  }
  const style = getJSONStyle(data.style);
  const config = {
    id: "",
    phrase: `document.id`,
    filterName: "field",
    config: `{ "fieldId": "document[id]", "type": "${WidgetTypeEnum.TEXTAREA}","required": ${data.required}, "label": "${data.label}", ${style}}`,
    label: "text area field",
  };

  setPhrases(config, editor);
};

const addCheckWidget = (data: FieldValues, editor: Editor) => {
  if (!data.style) {
    data.style = {
      height: 25,
      width: 44,
    };
  }
  const style = getJSONStyle(data.style);
  const config = {
    phrase: `document.id`,
    filterName: "field",
    config: `{ "fieldId": "document[id]", "type": "${WidgetTypeEnum.CHECKBOX}", "required": ${data.required},  ${style} }`,
    label: "Checkbox",
  };

  setPhrases(config, editor);
};

const addSignatureWidget = (data: FieldValues, editor: Editor) => {
  const id = generateRandomID();
  const label = data.label
    ? `${data.label}`
    : "Legal Signature of Adult Participant or Parent/Guardian if a minor participant(s)";

  if (!data.style) {
    data.style = {
      height: 120,
      width: 300,
    };
  }
  const style = getJSONStyle(data.style);
  const config = {
    phrase: `document.${id}`,
    filterName: "signature",
    config: `{"id": "${id}","fieldId": "${id}","type": "${WidgetTypeEnum.SIGNATURE}", "label": "${label}", "required": ${data.required}, ${style}, "config": {"type": "fingernail"}}`,
    label: "signature",
  };

  editor && editor.chain().focus().insertReplacePhrase(config).run();
};

const addDatePicker = (data: FieldValues, editor: Editor) => {
  const config = {
    id: "",
    phrase: `document.id`,
    filterName: "field",
    config: `{ "fieldId": "document[id]", "type": "${WidgetTypeEnum.DATEPICKER}", "required": ${data.required}, "label": "${data.label}", "style": {"width": 147, "height": 23}}`,
    label: "Date picker",
  };

  setPhrases(config, editor);
};

export const CUSTOM_INPUTS_DICT: CustomInputsDict = {
  radio: {
    btnLabel: "Radio input",
    addWidget: addRadioWidget,
    icon: <RadioButtonCheckedIcon />,
  },
  text: {
    btnLabel: "Input field",
    addWidget: addInputWidget,
    icon: <ShortTextIcon />,
  },
  textarea: {
    btnLabel: "Text area field",
    addWidget: addTextAreaWidget,
    icon: <SubjectIcon />,
  },
  datepicker: {
    btnLabel: "Date picker",
    addWidget: addDatePicker,
    icon: <CalendarTodayIcon />,
  },
  checkbox: {
    btnLabel: "Checkbox",
    addWidget: addCheckWidget,
    icon: <CheckBoxIcon />,
  },
  signature: {
    btnLabel: "Signature",
    addWidget: addSignatureWidget,
    icon: <GestureIcon />,
  },
};
