import { FC, useState } from "react";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { Editor } from "@tiptap/core";

import React from "react";
import { DocumentWidgetModalForm } from "components/Modal/DocumentWidgetModalForm/DocumentWidgetModalForm";
import { WidgetType } from "tiptap/types";
import "./PhrasesSidebarComponent.scss";
import {
  CUSTOM_INPUTS_DICT,
  replacePhrases,
  setPhrases,
} from "tiptap/customWidgetsConfig/customWidgetsConfig";

interface PhrasesSidebarProps {
  editor: Editor;
}

export const PhrasesSidebarComponent: FC<PhrasesSidebarProps> = ({
  editor,
}: PhrasesSidebarProps) => {
  const theme = useTheme();

  return (
    <Box className="sidebar-container">
      <Box className="sidebar--phrases">
        <Typography
          fontSize={"1.4em"}
          color={theme.palette.primary.dark}
          sx={{ letterSpacing: "2px" }}
        >
          Profile
        </Typography>
        <Box className="buttons-container">
          {replacePhrases.map((replacePhrase) => (
            <Button
              className="sidebar--phrases__button"
              sx={{ justifyContent: "center" }}
              key={replacePhrase.label}
              onClick={() => {
                setPhrases(replacePhrase, editor);
              }}
            >
              <span> {replacePhrase.label.slice(8)}</span>
            </Button>
          ))}
        </Box>
      </Box>
      <Box className="sidebar--phrases">
        <Typography
          fontSize={"1.4em"}
          color={theme.palette.primary.dark}
          sx={{ letterSpacing: "2px" }}
        >
          Widgets
        </Typography>

        <Box className="buttons-container">
          {Object.keys(CUSTOM_INPUTS_DICT).map((type) => {
            const [open, setOpen] = useState(false);
            const widgetType = type as WidgetType;
            const { btnLabel, addWidget, icon } =
              CUSTOM_INPUTS_DICT[widgetType];
            return (
              <React.Fragment key={btnLabel}>
                <DocumentWidgetModalForm
                  key={`${btnLabel}-modal`}
                  openState={[open, setOpen]}
                  widgetType={widgetType}
                  onClickSubmit={addWidget}
                  editor={editor}
                />
                <Button
                  className="sidebar--phrases__button"
                  key={btnLabel}
                  sx={{ justifyContent: "center" }}
                  onClick={() => {
                    setOpen(true);
                  }}
                  endIcon={icon}
                >
                  {btnLabel}
                </Button>
              </React.Fragment>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
