// MenuBoldButton.tsx
import React from "react";

import { Editor } from "@tiptap/core";

import { MenuButton } from "../MenuButton";
import { TipTapEditorContext } from "../../types";

interface MenuBoldButtonProps {
  editor: Editor;
  editorContext: TipTapEditorContext;
}

export const MenuBoldButton: React.FC<MenuBoldButtonProps> = ({
  editor,
  editorContext,
}) => {
  const isActive = editorContext.state.isActiveBold;

  const runCommand = () => {
    editor.chain().focus().toggleBold().run();
  };

  return (
    <MenuButton
      icon="bold"
      title="Bold"
      command={runCommand}
      isActive={isActive}
    />
  );
};
