import { FC, MouseEvent, useState } from "react";
import { Editor } from "@tiptap/core";
import { COLOR_SET } from "../../utils/color";
import { TipTapEditorContext } from "../../types";
import { MenuButton } from "../MenuButton";
import { Menu, TextField } from "@mui/material";

interface MenuColorButtonProps {
  editor: Editor;
  editorContext: TipTapEditorContext;
}

export const MenuColorButton: FC<MenuColorButtonProps> = ({
  editor,
  editorContext,
}) => {
  const [color, setColor] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const colorSet = COLOR_SET;

  const isActive = editorContext.state.isActiveTextStyle;

  const isColorSelected = (color: string) => {
    return editor.isActive("textStyle", { color });
  };

  const removeColor = () => {
    setPopoverVisible(false);
    editor.chain().setColor("black").run();
  };

  const confirmColor = (color: string) => {
    setPopoverVisible(false);
    editor.chain().focus().setColor(color).run();
  };

  // const popover = (
  //   <>

  //   </>
  // );

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setPopoverVisible(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverVisible(false);
  };

  return (
    <>
      <MenuButton
        title="Text color"
        icon="textColor"
        isActive={isActive}
        command={handleClick}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        // onChange={handleVisibleChange}
        open={popoverVisible}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div className={"color-set"}>
          {colorSet.map((color) => (
            <div key={color} className="color__wrapper">
              <div
                style={{ backgroundColor: color }}
                className={`color${
                  isColorSelected(color) ? " color--selected" : ""
                }`}
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => confirmColor(color)}
              />
            </div>
          ))}
        </div>

        <div className="color__wrapper">
          <div
            className="color color--remove"
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => removeColor()}
          />
        </div>
        <div className="color-hex">
          <TextField
            value={color}
            placeholder="HEX"
            autoFocus={true}
            // maxLength={7}
            size="small"
            className="color-hex__input"
            onChange={(value) => setColor(value.target.value)}
          />
          {/*<Button type="text" onClick={() => confirmColor(color)}>*/}
          {/*  OK*/}
          {/*</Button>*/}
        </div>
      </Menu>
    </>
  );
};
