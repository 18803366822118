import React, { FC, useState } from "react";

import { Box, Button, Modal, Typography } from "@mui/material";
import { OtpMethod } from "../../store/features/form/formSlice";
import { deleteAuth } from "../../store/features/api/clientApi/clientApi";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../store/features/notification/notificationSlice";
import { useAppDispatch } from "../../store";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "8px",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "40px 24px",
};

export type DisableVerificationProps = {
  isDisableVerificationModalOpen: boolean;
  setIsDisableVerificationModalOpen: (value: boolean) => void;
  type?: OtpMethod["type"];
  getTwoFAInfo: () => void;
};

export const DisableVerificationModal: FC<DisableVerificationProps> = ({
  isDisableVerificationModalOpen,
  setIsDisableVerificationModalOpen,
  type,
  getTwoFAInfo,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const handleModalClose = () => {
    setIsDisableVerificationModalOpen(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!type) return;

    try {
      const res = await dispatch(
        deleteAuth.initiate({
          verificationType: type,
        }),
      ).unwrap();
      dispatch(showSuccessNotification({ message: res.message }));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }

    setIsLoading(false);
    setIsDisableVerificationModalOpen(false);
    getTwoFAInfo();
  };

  return (
    <Modal
      open={isDisableVerificationModalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ width: "100%", marginBottom: "24px" }}>
            <Typography
              component={"span"}
              variant={"h6"}
              sx={{ fontWeight: "bold" }}
            >
              {type === "google_authenticator" &&
                "Disable Google Authenticator verification"}
              {type === "email" && "Disable Email verification"}
              {type === "phone_number" && "Disable Phone number verification"}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: "24px" }}>
            <Typography variant={"subtitle2"}>
              Are you sure you want to disable
              {type === "google_authenticator" && " google authenticator "}
              {type === "email" && " email "}
              {type === "phone_number" && " phone number "}
              verification method?
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "12px",
            }}
          >
            <Button
              variant={"outlined"}
              color={"inherit"}
              onClick={handleModalClose}
              sx={{ textTransform: "capitalize", padding: "7px 17px" }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant={"contained"}
              color={"success"}
              loading={isLoading}
              sx={{ textTransform: "capitalize", padding: "7px 34px" }}
              onClick={handleSubmit}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
