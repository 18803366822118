import * as sass from 'sass';

export function removeSassImports(input: string): string {
  // Regular expression to match @use and @import statements till the end of the line
  const pattern = /(@use|@import).*/g;
    
  // Replace matched lines with an empty string
  const result = input.replace(pattern, '');
  return result;
}

export function compileScssStringToCss(scss: string): string {
  try {
    const result = sass.compileString(scss, {
      style: "compressed",
    });
    return result.css;
  } catch (error) {
    console.error("Error compiling SCSS file:", error);
    return "";
  }
}
