import { Editor } from "@tiptap/core";
import { useState, useEffect } from "react";
import { Size } from "tiptap/types/Size/Size";
import { ResizeObserver } from "@juggle/resize-observer";

export function useMaxAllowedSizeAndObserver(
  editor: Editor,
  maxSize: number,
): [Size, ResizeObserver] {
  const [maxAllowedSize, setMaxAllowedSize] = useState<Size>({
    width: maxSize,
    height: maxSize,
  });

  const updateMaxAllowedSize = () => {
    setMaxAllowedSize((prevSize) => ({
      ...prevSize,
      width: parseInt(getComputedStyle(editor.view.dom).width, 10),
    }));
  };

  useEffect(() => {
    updateMaxAllowedSize();
  }, [editor.view.dom]);

  const resizeOb = new ResizeObserver(() => {
    updateMaxAllowedSize();
  });

  useEffect(() => {
    resizeOb.observe(editor.view.dom);
  }, []);

  return [maxAllowedSize, resizeOb];
}
