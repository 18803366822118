import { FC, useContext, useEffect, useState } from "react";

import { Box, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  AuthTypes,
  OtpMethod,
  setAuthMethod,
} from "../../store/features/form/formSlice";

import {
  InputWidget,
  InputWidgetProps,
} from "components/widgets/FormWidgets/InputWidget";
import {
  CheckboxWidget,
  CheckboxWidgetProps,
} from "../widgets/FormWidgets/CheckboxWidget";
import { LoaderContext } from "../../context/LoaderContext";
import { LoadingButton } from "@mui/lab";
import { OtpWidget } from "../OtpWidget/OtpWidget";
import { useAppSelector, useAppThunkDispatch } from "../../store";
import { signInThunk } from "../../store/features/thunk/thunkApi";

const signInFormData: InputWidgetProps[] = [
  {
    type: "email",
    fullWidth: true,
    // validator: "",//REG
    label: "Email",
    fieldId: "email",
    required: true, //boolean
    value: "",
    // validation_message: '',
    // custom_validator: {
    //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
    //   message: '',//string;
    //   flags: ''//string;
    // },
    // maxlength: 50
  },
  {
    type: "password",
    fullWidth: true,
    // validator: "",//REG
    label: "Password",
    fieldId: "password",
    required: true, //boolean
    value: "",
    // validation_message: '',
    // custom_validator: {
    //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
    //   message: '',//string;
    //   flags: ''//string;
    // },
    // maxlength: 50
  },
];
const checkBoxField: CheckboxWidgetProps = {
  type: "checkbox",
  fieldId: "rememberMe",
  label: "Remember me",
  value: false,
};
export const SigninComponent: FC = () => {
  const dispatch = useDispatch();
  const dispatchThunk = useAppThunkDispatch();
  const navigate = useNavigate();
  const { authMethod, otpMethods } = useAppSelector(({ form }) => form);
  const { isLoading } = useContext(LoaderContext);
  const [otpMethodsArray, setOtpMethodsArray] = useState<OtpMethod[]>([]);

  useEffect(() => {
    setOtpMethodsArray((prevState) => {
      const updatedState = [...prevState];
      otpMethods.forEach((otpMethod) => {
        const index = updatedState.findIndex(
          (item) => item.type === otpMethod.type,
        );
        if (index !== -1) {
          updatedState[index] = otpMethod;
        } else {
          updatedState.push(otpMethod);
        }
      });
      return updatedState;
    });
  }, [otpMethods]);

  const handleSendCode = (
    type: "email" | "phone_number" | "google_authenticator",
  ) => {
    dispatchThunk(
      signInThunk({
        data: null,
        verificationType: type,
        isNewUser: authMethod ? authMethod === AuthTypes.OTP_SIGN_UP : false,
      }),
    );
  };

  useEffect(() => {
    dispatch(setAuthMethod(AuthTypes.SIGN_IN));
    localStorage.clear();
  }, []);

  if (!authMethod) return null;

  if (authMethod === AuthTypes.OTP_SIGN_IN) {
    return (
      <div key={"OtpReset"}>
        <OtpWidget
          otpMethods={otpMethodsArray}
          onSendCode={handleSendCode}
          authMethod={authMethod}
        />
      </div>
    );
  }

  return (
    <Box sx={{ maxWidth: "400px", width: "100%", minWidth: "400px" }}>
      <Typography variant={"h4"} textAlign={"center"}>
        Sign in
      </Typography>
      <Box sx={{ marginTop: "5px" }}>
        <div>
          <InputWidget {...signInFormData[0]} />
        </div>
        <div>
          <InputWidget {...signInFormData[1]} />
        </div>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CheckboxWidget {...checkBoxField} />
        <Link
          sx={{ cursor: "pointer" }}
          onClick={() => {
            dispatch(setAuthMethod(AuthTypes.RESET));
            navigate("/reset_password");
          }}
        >
          Forgot your password?
        </Link>
      </Box>
      <LoadingButton
        sx={{ marginTop: "10px" }}
        variant={"contained"}
        type={"submit"}
        fullWidth
        color={"inherit"}
        loading={isLoading}
        loadingPosition="end"
        endIcon={<></>}
      >
        Sign In
      </LoadingButton>
      <Box
        display={"flex"}
        alignItems={"baseline"}
        gap={"5px"}
        paddingTop={"10px"}
      >
        <Typography variant={"subtitle1"}>No account?</Typography>
        <Link
          sx={{ cursor: "pointer" }}
          onClick={() => {
            dispatch(setAuthMethod(AuthTypes.SIGN_UP));
            navigate("/signup");
          }}
        >
          Sign up
        </Link>
      </Box>
      {/*<SocialAuthWidget />*/}
    </Box>
  );
};
