import { createApi } from "@reduxjs/toolkit/query/react";
import { baseCustomFetchBase } from "../service/customFetchBase";

import { FieldValues } from "react-hook-form";

const userApi = createApi({
  reducerPath: "form/locationApi",
  baseQuery: baseCustomFetchBase,
  endpoints: (builder) => ({
    getCompanyProfile: builder.mutation<any, any>({
      query: () => ({
        url: `company/profile`,
        method: "GET",
        credential: true,
      }),
    }),
    sendCompanyDetails: builder.mutation<any, FieldValues>({
      query: (data) => ({
        url: `company/profile`,
        method: "POST",
        body: data,
      }),
    }),
    sendUserProfile: builder.mutation<any, FieldValues>({
      query: (data) => ({
        url: `staff/profile`,
        method: "POST",
        body: data,
      }),
    }),
    getUserProfile: builder.mutation<any, any>({
      query: () => ({
        url: `staff/me`,
        method: "GET",
      }),
    }),
    updateUserProfile: builder.mutation<any, FieldValues>({
      query: (data) => ({
        url: `staff/me`,
        method: "PUT",
        body: data,
      }),
    }),
    refreshToken: builder.mutation<any, { refreshToken: string }>({
      query: (data) => ({
        url: `token`,
        method: "POST",
        body: data,
        credentials: "omit",
      }),
    }),
  }),
});

export const { useGetCompanyProfileMutation } = userApi;

export default userApi;

export const {
  sendUserProfile,
  sendCompanyDetails,
  updateUserProfile,
  getCompanyProfile,
  getUserProfile,
  refreshToken,
} = userApi.endpoints;
