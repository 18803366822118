import { Table as TiptapTable, createColGroup } from "@tiptap/extension-table";
import { DOMOutputSpec } from "@tiptap/pm/model";
import { mergeAttributes } from "@tiptap/core";
import tableStyles from "!raw-loader!../styles/Table.scss";
import editorStyles from "!raw-loader!../styles/Editor.scss";
import indexCss from "!raw-loader!../../index.css";
import variables from "!raw-loader!../styles/variables.scss";

import { default as jsonVariables } from "../styles/variables.json";
import { compileScssStringToCss, removeSassImports } from "helper/sassHelper";

const Table = TiptapTable.extend({
  // @ts-ignore
  addOptions() {
    return {
      // approximately 1em, redefining value from TipTap
      cellMinWidth: Number(jsonVariables.sizes["td-min-width-wo-px"]),
    };
  },
  parseHTML() {
    return [
      {
        tag: "table",
      },
    ];
  },
  renderHTML({ node, HTMLAttributes }) {
    const cleanedStyles = [
      removeSassImports(editorStyles),
      removeSassImports(tableStyles),
    ];
    const fullScss = variables + " " + cleanedStyles.join(" ");
    const fullCss = indexCss + compileScssStringToCss(fullScss);

    const { colgroup, tableWidth, tableMinWidth } = createColGroup(
      node,
      this.options.cellMinWidth,
    );
    const table: DOMOutputSpec = [
      "div",
      { class: "tableWrapper" },
      ["style", {}, fullCss],
      [
        "table",
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
          style: tableWidth
            ? `width: ${tableWidth}`
            : `min-width: ${tableMinWidth}`,
        }),
        colgroup ?? [],
        ["tbody", 0],
      ],
    ];
    return table;
  },
});
export default Table;
