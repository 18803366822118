import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import { Node as ProseMirrorNode } from "prosemirror-model";
import { ReplacePhrasesComponent } from "../views/ReplacePhrasesComponent/ReplacePhrasesComponent";
import { DEFAULT_IMAGE_DISPLAY } from "../constants";

export type ReplacePhraseOptions = {
  HTMLAttributes: Record<string, any>;
  renderReplacePhrase: (node: ProseMirrorNode) => string;
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    replacePhrases: {
      insertReplacePhrase: ({
        label,
        phrase,
        filterName,
        config,
      }: {
        label: string;
        phrase: string;
        filterName?: string;
        config?: string;
      }) => ReturnType;
    };
  }
}

const ReplacePhrase = Node.create<ReplacePhraseOptions>({
  name: "replacePhrases",

  group: "inline",

  inline: true,

  selectable: true,

  draggable: true,

  atom: true,

  addAttributes() {
    return {
      label: {
        default: "",
      },
      phrase: {
        default: "",
      },
      filterName: {
        default: "",
      },
      config: {
        default: "",
      },
      display: {
        default: DEFAULT_IMAGE_DISPLAY,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "span[data-replace-phrase]",
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    const filterParameter: string = node.attrs.config ? node.attrs.config : "";
    const filterName = node.attrs.filterName ? node.attrs.filterName : "";
    const filter = filterName ? `| ${filterName}('${filterParameter}')` : "";
    
    return [
      "span",
      mergeAttributes(
        {
          "data-replace-phrase": node.attrs.phrase,
          "data-replace-filterName": filterName,
          "data-replace-config": node.attrs.config ? node.attrs.config : "",
        },
        HTMLAttributes,
      ),
      `{{ ${node.attrs.phrase} ${filter} }}`,
    ];
  },

  addCommands() {
    return {
      insertReplacePhrase:
        (attributes: { label: string; phrase: string; filterName?: string, config?: string }) =>
          ({ commands }) => {
            return commands.insertContent({ type: this.name, attrs: attributes });
          },
    };
  },
  addNodeView() {
    return ReactNodeViewRenderer(ReplacePhrasesComponent);
  },
});

export default ReplacePhrase;
