import "./App.css";
import { AppWrapper } from "./components/AppWrapper/AppWrapper";
import { alpha, createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { Provider } from "react-redux";
import { store } from "./store";
import { GoIframe } from "components/GoIframe/GoIframe";
import { green } from "@mui/material/colors";

const darkGreen = "#476b3a";

export const theme = createTheme({
  palette: {
    secondary: {
      main: green[500],
    },
    primary: {
      main: darkGreen,
      light: alpha(darkGreen, 0.5),
      dark: "#626262",
    }
  },
});


function App() {
  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <div className="App">
            <AppWrapper />
            <GoIframe />
          </div>
        </ThemeProvider>
      </Provider>

    </>
  );
}

export default App;
