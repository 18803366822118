import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";

import { Mutex } from "async-mutex";
import { apiService } from "./apiService";
import { getApiBaseUrl } from "../../../../helper/urlHelper";
import { RootState } from "../../../index";
import { extractDomain, extractSubdomain } from "helper/domainHelper";

const mutex = new Mutex();

export const baseCustomFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  const { getState } = api;
  const state = getState() as RootState;

  const storeSubdomain = state.company.companyDetails?.subdomain;
  const subdomain = storeSubdomain || localStorage.getItem("SUBDOMAIN");

  const domain = extractDomain();

  const baseUrl = getApiBaseUrl(subdomain, domain);

  if (extractSubdomain()) {
    localStorage.setItem("BASE_URL_DOMAIN", `${subdomain}.${domain}`);
    localStorage.setItem("DOMAIN", `${domain}`);
    localStorage.setItem("SUBDOMAIN", `${subdomain}`);
  }

  const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { endpoint }) => {
      const token = apiService.getToken();

      // If we have a token set in state, let's assume that we should be passing it.
      if (token && endpoint !== "refreshToken") {
        headers.set("authorization", `Bearer ${token}`);
        headers.set("accept", "application/json");
      }

      return headers;
    },
  });

  let result = await baseQuery(args, api, extraOptions);
  if ((result.error?.data as any)?.message === "You are not logged in") {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshResult = await baseQuery(
          { credentials: "include", url: "/" },
          api,
          extraOptions,
        );

        if (refreshResult.data) {
          // Retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          //   api.dispatch(logout());
          // apiService.clear();
          // apiService.clearRefresh();
          // window.location.href = ';
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

// export default customFetchBase;

export const globalCustomFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  const domain = extractDomain();

  const globalUrl = getApiBaseUrl(undefined, domain);

  const globalQuery = fetchBaseQuery({
    baseUrl: globalUrl,
  });
  await mutex.waitForUnlock();
  let result = await globalQuery(args, api, extraOptions);
  if ((result.error?.data as any)?.message === "You are not logged in") {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshResult = await globalQuery(
          { credentials: "include", url: "/" },
          api,
          extraOptions,
        );

        if (refreshResult.data) {
          // Retry the initial query
          result = await globalQuery(args, api, extraOptions);
        }
        // else {
        //   api.dispatch(logout());
        //   window.location.href = '/login';
        // }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await globalQuery(args, api, extraOptions);
    }
  }

  return result;
};
