import { FieldValues } from "react-hook-form";
import { createApi } from "@reduxjs/toolkit/query/react";

import { globalCustomFetchBase } from "../service/customFetchBase";

const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: globalCustomFetchBase,
  endpoints: (builder) => ({
    signIn: builder.mutation<any, { email: string; password: string }>({
      query: (data) => ({
        url: `signin`,
        method: "POST",
        body: data,
      }),
    }),
    signUp: builder.mutation<
      any,
      {
        email: string;
        password: string;
        passwordConfirm: string;
        rememberMe: string;
        verificationCodes?: Record<any, any>;
      }
    >({
      query: (data) => ({
        url: `signup`,
        method: "POST",
        body: data,
      }),
    }),
    signUpConfirm: builder.mutation<any, FieldValues>({
      query: (data) => ({
        url: `signup/confirm`,
        method: "POST",
        body: data,
      }),
    }),
    resetPasswordVerify: builder.mutation<any, FieldValues>({
      query: (data) => ({
        url: `reset-password/verify`,
        method: "POST",
        body: data,
      }),
    }),
    resetPasswordConfirm: builder.mutation<any, FieldValues>({
      query: (data) => ({
        url: `reset-password/confirm`,
        method: "POST",
        body: data,
      }),
    }),
    resetPassword: builder.mutation<any, { email: string }>({
      query: (data) => ({
        url: `reset-password`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useSignInMutation,
  useSignUpMutation,
  useSignUpConfirmMutation,
  useResetPasswordMutation,
  useResetPasswordVerifyMutation,
  useResetPasswordConfirmMutation,
} = authApi;

export default authApi;

export const {
  signIn,
  signUp,
  signUpConfirm,
  resetPasswordVerify,
  resetPasswordConfirm,
  resetPassword,
} = authApi.endpoints;
