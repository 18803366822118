// MenuButton.tsx

import { FC } from "react";
import { Tooltip } from "@mui/material";
import "../styles/MenuButton.scss";

interface MenuButtonProps {
  icon: string;
  isActive?: boolean;
  isDisabled?: boolean;
  title: string;
  command?: (e: any) => void;
}

export const MenuButton: FC<MenuButtonProps> = ({
  icon,
  isDisabled = false,
  title,
  command = () => {},
}) => {
  // const commandButtonClass = `el-tiptap-editor__menu-button el-tiptap-editor__menu-button--active`;
  const commandButtonClass = `el-tiptap-editor__menu-button`;
  // const commandButtonClass = `
  //   'el-tiptap-editor__menu-button': true,
  //   'el-tiptap-editor__menu-button--active': ${isActive},
  //   'el-tiptap-editor__menu-button--readonly': ${isDisabled}
  // `;

  const onClick = (e: any) => {
    if (!isDisabled && command) command(e);
  };

  return (
    <Tooltip title={title} enterDelay={350} placement="top">
      {/*sx={commandButtonClass}*/}
      <div
        className={commandButtonClass}
        onMouseDown={(e: any) => e.preventDefault()}
        onClick={onClick}
      >
        <span className={icon}></span>
      </div>
    </Tooltip>
  );
};
