import { FC } from "react";
import { Editor } from "@tiptap/core";
import { TipTapEditorContext } from "tiptap/types/TipTapEditorContext";
import { MenuButton } from "../../MenuButton";

interface MenuTextAlignCenterButtonProps {
  editor: Editor;
  editorContext: TipTapEditorContext;
}

export const MenuTextAlignCenterButton: FC<MenuTextAlignCenterButtonProps> = ({
  editor,
  editorContext,
}) => {
  const isActive = editorContext.state.isActiveTextAlignCenter;

  const runCommand = () => {
    editor.chain().focus().setTextAlign("center").run();
  };

  return (
    <MenuButton
      icon="textAlignCenter"
      title="Align Center"
      command={runCommand}
      isActive={isActive}
    />
  );
};
