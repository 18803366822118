import { cloneElement, FC } from "react";

import Cookies from "js-cookie";

import { cleanStorageAndGoToLanding } from "helper/urlHelper";

import { StubRedirecting } from "../StubRedirecting/StubRedirecting";
import { apiService } from "../../store/features/api/service/apiService";

export const AuthGuard: FC<{ element: JSX.Element; rest?: any }> = ({
  element,
  ...rest
}) => {
  const isAuthenticatedUsingLocalStorage =
    determineIfAuthenticatedUsingLocalStorage();
  const isAuthCookiePresent = !!getAuthCookie();
  const isAuthenticated = isAuthenticatedUsingLocalStorage || isAuthCookiePresent;

  if (!isAuthenticatedUsingLocalStorage && isAuthCookiePresent) {
    try {
      const cookieData = getParsedDataFromCookies();
      fillLocalStorageWithDataFromCookies(cookieData);
    } catch (error: unknown) {
      console.error(error);
      return redirectToLanding();
    }
  }

  if (!isAuthenticated) {
    return redirectToLanding();
  }

  function redirectToLanding() {
    cleanStorageAndGoToLanding();
    return <StubRedirecting />;
  }

  return cloneElement(element, rest);
};

const determineIfAuthenticatedUsingLocalStorage = () => {
  const token = apiService.getToken();
  return !!token;
};

const getAuthCookie = () => Cookies.get("authWK");

const getParsedDataFromCookies = () => {
  const cookies = getAuthCookie();
  return cookies ? JSON.parse(cookies) : {};
};

const fillLocalStorageWithDataFromCookies = (cookieData: any) => {
  storeAuthenticationDataToLocalStorage(cookieData);
  markAsOpenedFromDomain();
  markIfNewUser(cookieData.is_new_user);
};

const storeAuthenticationDataToLocalStorage = (cookieData: any) => {
  localStorage.setItem("authWK", JSON.stringify(cookieData));
  localStorage.setItem("COMPANY_ACCESS_TOKEN", cookieData.access_token);
  localStorage.setItem("REFRESH_TOKEN", cookieData.refresh_token);
  localStorage.setItem("SUBDOMAIN", cookieData.subdomain);
};

const markAsOpenedFromDomain = () => {
  localStorage.setItem("IS_OPENED_FROM_DOMAIN", JSON.stringify(true));
};

const markIfNewUser = (isNewUser: boolean) => {
  localStorage.setItem("IS_NEW_USER", JSON.stringify(isNewUser));
};
