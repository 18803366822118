import React, { useEffect, useRef, useState, MouseEvent } from "react";
import { Editor } from "@tiptap/core";
import { NodeViewWrapper } from "@tiptap/react";
import { NodeSelection } from "prosemirror-state";
import { Node as ProsemirrorNode } from "prosemirror-model";
import { clamp } from "../../utils/shared";
import { resizeDirections } from "tiptap/constants";
import { ResizeDirection } from "tiptap/types/enums/ResizeDirection";
import { MAX_SIZE, MIN_SIZE } from "./ImageViewConsts";
import { Size } from "tiptap/types/Size/Size";
import {
  getNewFinalSize,
  getNewSizeWhileMoving,
} from "../helpers/resizeHelper";
import { useMaxAllowedSizeAndObserver } from "tiptap/hooks/useMaxAllowedSizeAndObserver";
import ImageWidgetModalForm from "components/Modal/ImageWidgetModalForm/ImageWidgetModalForm";
import { calculateAspectRatio } from "tiptap/utils/image";
import { ResizerState } from "tiptap/types/Size/ResizerState";

interface ImageViewProps {
  node: ProsemirrorNode;
  editor: Editor;
  getPos: Function;
  updateAttributes: Function;
  selected: boolean;
  onClose: Function;
}

const defaultResizerState: ResizerState = {
  x: 0,
  y: 0,
  w: 0,
  h: 0,
  dir: "",
};

export const ImageView: React.FC<ImageViewProps> = ({
  node,
  editor,
  getPos,
  updateAttributes,
  selected,
  onClose,
  ...rest
}) => {
  const [maxAllowedSize, resizeOb] = useMaxAllowedSizeAndObserver(
    editor,
    MAX_SIZE,
  );
  const imageViewRef = useRef<HTMLDivElement>(null);
  const [openModal, setOpenModal] = useState(false);
  const [resizing, setResizing] = useState(false);
  const [resizerState, setResizerState] =
    useState<ResizerState>(defaultResizerState);

  const src = node.attrs.src;
  const size: Size = {
    width: node.attrs.width,
    height: node.attrs.height,
  };

  const originalSize: Size = {
    width: node.attrs.originalWidth,
    height: node.attrs.originalHeight,
  };
  const display = node.attrs.display;

  const imageViewClass = ["image-view", `image-view--${display}`];

  useEffect(() => {
    if (resizing) {
      onEvents();
    } else {
      offEvents();
    }

    return () => {
      offEvents();
      resizeOb.disconnect();
    };
  }, [resizing]);

  function handleToggleKeepCurrentAspectRatio(keepAspectRatio: boolean) {
    updateAttributes({ keepAspectRatio: keepAspectRatio });
  }

  const selectImage = () => {
    const { state } = editor;
    const selection = NodeSelection.create(state.doc, getPos());
    const tr = state.tr.setSelection(selection);
    editor.view.dispatch(tr);
  };

  const onMouseDown = (
    e: MouseEvent<HTMLSpanElement>,
    dir: ResizeDirection,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    const { clientX, clientY } = e;
    const maxWidth = maxAllowedSize.width;

    const { width: newWidth, height: newHeight } = getNewFinalSize(
      size,
      MIN_SIZE,
      maxWidth,
    );

    setResizerState({
      x: clientX,
      y: clientY,
      w: newWidth,
      h: newHeight!,
      dir,
    });

    setResizing(true);
  };

  const onMouseMove = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!resizing) return;

    const { clientX, clientY } = e;

    const newSize = getNewSizeWhileMoving(
      { x: clientX, y: clientY },
      resizerState,
      MIN_SIZE,
      maxAllowedSize.width,
      node.attrs.keepAspectRatio,
      node.attrs.aspectRatio,
    );

    updateAttributes({
      width: newSize.width,
      height: newSize.height,
      aspectRatio: node.attrs.keepAspectRatio
        ? node.attrs.aspectRatio
        : calculateAspectRatio(newSize),
    });
  };

  const onMouseUp = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!resizing) return;

    setResizing(false);
    setResizerState(defaultResizerState);

    selectImage();
  };

  const onEvents = () => {
    addEventListener("mousemove", onMouseMove, true);
    addEventListener("mouseup", onMouseUp, true);
  };

  const offEvents = () => {
    removeEventListener("mousemove", onMouseMove, true);
    removeEventListener("mouseup", onMouseUp, true);
  };

  function returnImageToOriginalSize() {
    if (!originalSize) return;
    setResizing(false);
    updateAttributes({
      width: originalSize.width,
      height: originalSize.height,
      aspectRatio: calculateAspectRatio(originalSize),
      keepAspectRatio: false,
    });
    setResizerState({
      x: 0,
      y: 0,
      w: 0,
      h: 0,
      dir: "",
    });
  }

  return (
    <NodeViewWrapper
      {...rest}
      ref={imageViewRef}
      className="image-view__wrapper"
    >
      <NodeViewWrapper
        {...rest}
        as="span"
        className={`${imageViewClass.join(" ")}`}
      >
        <NodeViewWrapper
          {...rest}
          className={`image-view__body ${
            selected ? "image-view__body--focused" : ""
          } ${resizing ? "image-view__body--resizing" : ""}`}
          onDoubleClick={() => setOpenModal(true)}
          data-drag-handle
        >
          <img
            {...rest}
            src={src}
            title={node.attrs.title}
            alt={node.attrs.alt}
            style={{
              width: `${size.width}px`,
              height: `${size.height}px`,
            }}
            className="image-view__body__image"
            onClick={selectImage}
          />

          <ImageWidgetModalForm
            isOriginalSizeImage={
              size.width === originalSize.width &&
              size.height === originalSize.height
            }
            openState={[openModal, setOpenModal]}
            keepCurrentAspectRatioState={[
              node.attrs.keepAspectRatio,
              handleToggleKeepCurrentAspectRatio,
            ]}
            returnImageToOriginalSize={returnImageToOriginalSize}
          />

          {editor.isEditable && (selected || resizing) && (
            <div {...rest} className="image-resizer">
              {resizeDirections.map((direction) => (
                <span
                  {...rest}
                  key={direction}
                  className={`image-resizer__handler--${direction} image-resizer__handler`}
                  onMouseDown={(e) => onMouseDown(e, direction)}
                />
              ))}
            </div>
          )}
        </NodeViewWrapper>
      </NodeViewWrapper>
    </NodeViewWrapper>
  );
};
