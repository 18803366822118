import { createSlice } from "@reduxjs/toolkit/";
import { FieldValues } from "react-hook-form";
import { PayloadAction } from "@reduxjs/toolkit";

export type WorkflowStateType = {
  workflows: FieldValues[];
  editWorkflow: FieldValues | null;
  isEdit: boolean;
};

const initialState: WorkflowStateType = {
  workflows: [],
  editWorkflow: null,
  isEdit: false,
};

export const workflowSlice = createSlice({
  name: "workflow",
  initialState,
  reducers: {
    setWorkflows: (state, { payload }: PayloadAction<FieldValues[]>) => {
      state.workflows = payload.length
        ? payload.map((workflow) => {
            return {
              title: workflow.title,
              extensions: workflow.extensions,
              createdAt: workflow.createdAt,
              updatedAt: workflow.updatedAt,
              slug: workflow.slug,
              ...workflow,
            };
          })
        : [];
    },
    addWorkflow: (state, { payload }: PayloadAction<FieldValues>) => {
      state.workflows = [...state.workflows, payload];
    },
    putWorkflow: (state, { payload }: PayloadAction<FieldValues>) => {
      state.workflows = state.workflows.map((workflow) =>
        workflow.id === payload.id ? payload : workflow,
      );
    },
    removeWorkflow: (
      state,
      { payload }: PayloadAction<{ id: string | number }>,
    ) => {
      state.workflows = state.workflows.filter(
        (workflow) => workflow.id !== payload.id,
      );
    },
    setEditWorkflow: (
      state,
      { payload }: PayloadAction<{ id: string | number }>,
    ) => {
      state.editWorkflow =
        state.workflows.find((workflow) => workflow.id === payload.id) ?? {};
      state.isEdit = true;
    },
    setNewWorkflow: (state, { payload }: PayloadAction<FieldValues | null>) => {
      state.editWorkflow = payload;
      state.isEdit = true;
    },
    updateNewWorkflowGeneralInfo: (
      state,
      { payload }: PayloadAction<FieldValues>,
    ) => {
      state.editWorkflow = { ...state.editWorkflow, ...payload };
      state.isEdit = true;
    },
    clearEditWorkflow: (state) => {
      state.editWorkflow = null;
      state.isEdit = false;
    },
    updateEditWorkflowExtension: (
      state,
      { payload }: PayloadAction<{ key: string; value: FieldValues }>,
    ) => {
      const extensions = state.editWorkflow?.extensions ?? {};

      state.editWorkflow = {
        ...state?.editWorkflow,
        extensions: {
          ...extensions,
          [payload.key]: payload.value,
        },
      };
    },
  },
});

export const {
  setWorkflows,
  addWorkflow,
  putWorkflow,
  removeWorkflow,
  setNewWorkflow,
  setEditWorkflow,
  updateNewWorkflowGeneralInfo,
  clearEditWorkflow,
  updateEditWorkflowExtension,
} = workflowSlice.actions;
