import { Plugin, PluginKey, TextSelection } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { getMarkRange } from "@tiptap/core";

const SelectLinkPlugin = new Plugin({
  key: new PluginKey("SelectLink"),
  props: {
    handleClick(view: EditorView, pos: number) {
      const { schema, doc, tr } = view.state;
      const range = getMarkRange(doc.resolve(pos), schema.marks.link);
      if (!range) return false;

      const $start = doc.resolve(range.from);
      const $end = doc.resolve(range.to);

      const transaction = tr.setSelection(new TextSelection($start, $end));

      view.dispatch(transaction);
      return true;
    },
  },
});

export default SelectLinkPlugin;
