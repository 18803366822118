import { FC, useState, useEffect } from "react";

import {
  Box,
  Button,
  createTheme,
  Typography,
  Breadcrumbs,
  Stack,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { ThemeProvider } from "@emotion/react";
import { NavigateNext } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { useAppSelector, useAppThunkDispatch } from "../../../store";

import {
  deleteLocationThunk,
  getLocationsThunk,
} from "../../../store/features/thunk/locationThunk/locationThunk";
import { TableComponent } from "../../TableComponent/TableComponent";
import { Loader } from "../../Loader/Loader";

const headers: string[] = [
  "ID",
  "LOCATION NAME",
  "TIMEZONE",
  "CREATED AT",
  "UPDATED AT",
  "",
];

export const LocationsComponent: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { locations } = useAppSelector(({ location }) => location);

  const navigate = useNavigate();
  const dispatchThunk = useAppThunkDispatch();

  useEffect(() => {
    handleLocations();
  }, []);

  const handleLocations = async () => {
    setIsLoading(true);
    await dispatchThunk(getLocationsThunk());
    setIsLoading(false);
  };

  const handleDeleteLocation = async (id: string) => {
    setIsLoading(true);
    await dispatchThunk(deleteLocationThunk({ id }));
    setIsLoading(false);
  };

  const handleLocationEdit = (id: any): void => {
    navigate(`/location/${id}`);
  };

  const filterLocations = (locations: any[]): any[] => {
    return locations?.length
      ? locations?.map((location: any) => {
          return {
            id: location.id,
            name: location.name,
            timezone: location.timezone,
            createdAt: location.createdAt,
            updatedAt: location.updatedAt,
          };
        })
      : [];
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Box sx={{ padding: "0 50px 0 305px" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Stack spacing={2}>
            <Breadcrumbs
              separator={<NavigateNext fontSize="small" color={"success"} />}
              aria-label="breadcrumb"
            >
              <Typography
                key="1"
                color="text.primary"
                sx={{ padding: "14px 0" }}
              >
                Locations
              </Typography>
            </Breadcrumbs>
          </Stack>
        </Box>
        <Box
          sx={{
            width: "100%",
            padding: "24px 0 31px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div>
            <Button
              variant={"contained"}
              color={"success"}
              onClick={() => {
                navigate("/location/newLocation");
              }}
            >
              + Add new location
            </Button>
          </div>
        </Box>
        <TableComponent
          type={"locations"}
          rows={filterLocations(locations)}
          headers={headers}
          onDelete={handleDeleteLocation}
          onEdit={handleLocationEdit}
        />
      </Box>
    </>
  );
};
