import { createAsyncThunk } from "@reduxjs/toolkit";

import { FieldValues } from "react-hook-form";

import {
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
} from "../../notification/notificationSlice";

import {
  removeWorkflow,
  setNewWorkflow,
  setWorkflows,
  updateEditWorkflowExtension,
  updateNewWorkflowGeneralInfo,
  WorkflowStateType,
} from "../../workflow/workflowSlice";
import {
  createWorkflow,
  deleteWorkflow,
  deleteWorkflowExtension,
  getWorkflow,
  getWorkflows,
  updateWorkflow,
  updateWorkflowOptions,
  uploadFile,
} from "../../api/workflowApi/workflowApi";

import { wait } from "../../../../helper/wait";
import { serializeQuery } from "../../../../helper/urlHelper";

export const getWorkflowThunk = createAsyncThunk(
  "thunkSlice/getWorkflowThunk",
  async (id: string, { dispatch }) => {
    try {
      const workflow = await dispatch(
        getWorkflow.initiate({ id: id }),
      ).unwrap();
      dispatch(setNewWorkflow(workflow));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
      throw error;
    }
  },
);

export const getWorkflowsThunk = createAsyncThunk(
  "thunkSlice/getWorkflowsThunk",
  async (searchParams: Record<string, any> | null, { dispatch }) => {
    let searchParamsString = "";

    if (searchParams) {
      searchParamsString = serializeQuery(searchParams);
    }
    try {
      const workflows = await dispatch(
        getWorkflows.initiate(searchParamsString),
      ).unwrap();

      dispatch(setWorkflows(workflows));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const createWorkflowThunk = createAsyncThunk(
  "thunkSlice/createWorkflowThunk",
  async (data: FieldValues, { dispatch }) => {
    try {
      const workflow = await dispatch(createWorkflow.initiate(data)).unwrap();

      dispatch(
        showSuccessNotification({ message: "Workflow created successfully!" }),
      );

      return workflow.id;
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
      return error;
    }
  },
);

export const updateWorkflowThunk = createAsyncThunk(
  "thunkSlice/updateWorkflowThunk",
  async (
    {
      data,
      id,
      path,
      isExtraEmails = false,
    }: {
      data: FieldValues | Record<string, any>;
      id: string;
      path: string;
      isExtraEmails?: boolean;
    },
    { dispatch },
  ) => {
    try {
      await dispatch(
        showWarningNotification({ message: "Updating workflow..." }),
      );

      if (isExtraEmails) {
        await dispatch(
          updateWorkflowOptions.initiate({ data, id, path }),
        ).unwrap();
      } else {
        await dispatch(updateWorkflow.initiate({ data, id, path })).unwrap();
        if (!("optional" in data)) {
          dispatch(updateNewWorkflowGeneralInfo(data));
        }
      }
      await wait(100);

      await dispatch(showSuccessNotification({ message: "Workflow updated" }));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const deleteWorkflowExtensionThunk = createAsyncThunk(
  "thunkSlice/deleteWorkflowExtensionThunk",
  async ({ id, path }: { id: string; path: string }, { dispatch }) => {
    try {
      await dispatch(deleteWorkflowExtension.initiate({ id, path })).unwrap();

      // dispatch(putWorkflow(workflow));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const deleteWorkflowThunk = createAsyncThunk(
  "thunkSlice/updateWorkflowThunk",
  async (id: string, { dispatch }) => {
    try {
      await dispatch(deleteWorkflow.initiate({ id })).unwrap();

      dispatch(removeWorkflow({ id }));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const uploadFileThunk = createAsyncThunk(
  "thunkSlice/uploadFileThunk",
  async (
    {
      file,
      workflowId,
      fieldName,
    }: { file: string | ArrayBuffer; workflowId: string; fieldName: string },
    { dispatch, getState },
  ) => {
    try {
      const appearance =
        // @ts-ignore
        (getState().workflow as WorkflowStateType).editWorkflow?.extensions
          .appearance ?? {};
      const res = await dispatch(uploadFile.initiate({ file })).unwrap();

      await dispatch(
        updateWorkflow.initiate({
          data: { [fieldName]: res.imageUrl },
          path: "/extension/appearance",
          id: workflowId,
        }),
      ).unwrap();
      dispatch(
        updateEditWorkflowExtension({
          key: "appearance",
          value: { ...appearance, [fieldName]: res.imageUrl },
        }),
      );
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const removeImageThunk = createAsyncThunk(
  "thunkSlice/removeImageThunk",
  async (
    { workflowId, fieldName }: { workflowId: string; fieldName: string },
    { dispatch, getState },
  ) => {
    try {
      const appearance =
        // @ts-ignore
        (getState().workflow as WorkflowStateType).editWorkflow?.extensions
          .appearance ?? {};

      await dispatch(
        updateWorkflow.initiate({
          data: { [fieldName]: "" },
          path: "/extension/appearance",
          id: workflowId,
        }),
      ).unwrap();
      dispatch(
        updateEditWorkflowExtension({
          key: "appearance",
          value: { ...appearance, [fieldName]: "" },
        }),
      );
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);
