import { Country } from "country-state-city";

import { FC, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
  SelectFieldTypes,
  SelectOptionList,
  SelectWidgetProps,
} from "./SelectWidget.types";

import { ErrorMessage } from "@hookform/error-message";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { getValidationRule } from "../../../../helper/FormValidators/formHelper";
import { ControllerRenderProps } from "../../@types/Widget";
import {
  getSelectOptionListForCountry,
  getSelectOptionListForStates,
} from "helper/selectOptionListHelper";

const defaultProps = {
  type: SelectFieldTypes.SELECT,
  label: "FIRST",
  fieldId: "SELECT",
  required: false,
  value: "",
  option_list: [],
  country: "US",
  disabled: false,
};

function getOptionList(
  option_list: SelectOptionList[] | undefined,
  fieldId: string,
  country: string | undefined,
): SelectOptionList[] {
  const optionList =
    option_list || getSelectOptionListForStates(fieldId, country);
  return optionList;
}

export const SelectWidget: FC<SelectWidgetProps> = (field = defaultProps) => {
  const {
    label,
    fieldId,
    option_list,
    value,
    country,
    onOpen,
    onClose,
    disabled,
  } = field;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [optionList, setOptionList] = useState<SelectOptionList[]>(
    getOptionList(option_list, fieldId, country),
  );

  useEffect(() => {
    if (
      !option_list ||
      fieldId.includes("country") ||
      fieldId.includes("region")
    )
      return;
    setOptionList(option_list);
  }, [option_list]);

  useEffect(() => {
    const statesOptionList = getOptionList(option_list, fieldId, country);
    setOptionList(statesOptionList);
  }, [country]);

  useEffect(() => {
    if (!fieldId.includes("country")) return;
    if (disabled && value) {
      const country = Country.getCountryByCode(value);
      if (!country) return;
      const countryOptionList = getSelectOptionListForCountry(country);
      let countriesOptionList = [countryOptionList];
      setOptionList(countriesOptionList);
      return;
    }
    const countries = Country.getAllCountries();
    let countriesOptionList = countries.map(
      (country): SelectOptionList => getSelectOptionListForCountry(country),
    );

    const elementsToKeep = countriesOptionList.filter((element) =>
      ["US", "CA"].includes(element.value),
    );
    countriesOptionList = countriesOptionList.filter(
      (element) => !["US", "CA"].includes(element.value),
    );
    countriesOptionList.unshift({ value: "", label: "", disabled: true });
    countriesOptionList.unshift(elementsToKeep[1], elementsToKeep[0]);

    setOptionList(countriesOptionList);
  }, [fieldId]);

  const disabledColorStyle = {
    color: "rgba(0, 0, 0, 0.26)",
  };

  const disabledStyles = {
    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      ...disabledColorStyle,
      border: "1px solid rgba(0, 0, 0, 0.12)",
    },
    ".MuiSvgIcon-root.Mui-disabled": {
      fill: "rgba(0, 0, 0, 0.26)",
    },
  };

  return (
    <>
      <Controller
        render={({
          field: { value, onChange },
        }: {
          field: ControllerRenderProps<
            SelectOptionList | SelectOptionList[],
            SelectChangeEvent<any>
          >;
        }) => (
          <FormControl sx={{ width: "100%", mt: 2, textAlign: "initial" }}>
            <InputLabel
              style={disabled ? disabledColorStyle : {}}
              id={fieldId}
              size={"small"}
              required={field.required}
              error={!!errors[fieldId]}
            >
              {label}
            </InputLabel>
            <Select
              sx={disabledStyles}
              fullWidth
              disabled={disabled}
              displayEmpty={disabled && value ? true : undefined}
              native={disabled && value ? false : undefined}
              renderValue={
                disabled && value
                  ? (selected) => optionList[0]?.label
                  : undefined
              }
              onChange={onChange}
              size={"small"}
              value={value}
              onOpen={onOpen}
              onClose={onClose}
              id={fieldId}
              labelId={fieldId}
              label={label}
              inputProps={{ "aria-label": "Without label" }}
              error={!!errors[fieldId]}
            >
              {optionList?.map(({ value, label, disabled }) => (
                <MenuItem
                  key={value + label}
                  value={value}
                  disabled={disabled}
                  sx={
                    disabled && value === ""
                      ? { height: "1px", background: "black", padding: 0 }
                      : {}
                  }
                >
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        defaultValue={value || country ? "US" : ""}
        // onChange={onChangeHandle}
        control={control}
        rules={getValidationRule(field)}
        name={fieldId}
      />
      <ErrorMessage
        errors={errors}
        name={fieldId}
        render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
      />
    </>
  );
};
