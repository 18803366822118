import { createSlice } from "@reduxjs/toolkit/";
import { PayloadAction } from "@reduxjs/toolkit";

export type LocationType = {
  id: string;
  name: string;
  address: string;
  timezone: string;
  createdAt: string;
  updatedAt: string;
};
export type LocationSliceType = {
  locations: LocationType[] | [];
  editLocation: LocationType | null;
  isEdit: boolean;
};

const initialState: LocationSliceType = {
  locations: [],
  editLocation: null,
  isEdit: false,
};

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLocations: (state, { payload }: PayloadAction<LocationType[]>) => {
      state.locations = payload;
    },
    removeLocation: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.locations = state.locations.filter(
        (location) => location.id !== payload.id,
      );
    },
    updateLoc: (state, { payload }: PayloadAction<LocationType>) => {
      state.locations = state.locations.filter((location) =>
        location.id !== payload.id
          ? location
          : {
              id: payload.id,
              name: payload.name,
              address: payload?.address || "",
              timezone: payload?.timezone || "",
              createdAt: payload?.createdAt || "",
              updatedAt: payload?.updatedAt || "",
            },
      );
    },
    addLocation: (state, { payload }: PayloadAction<any>) => {
      const formattedPayload = {
        id: payload.id,
        name: payload.name,
        address: payload?.address || "",
        timezone: payload?.timezone || "",
        createdAt: payload?.createdAt || "",
        updatedAt: payload?.updatedAt || "",
      };
      state.locations = state.locations.length
        ? [...state.locations, formattedPayload]
        : [formattedPayload];
    },
    setEditLocation: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.editLocation =
        state.locations.find((locations) => locations.id === payload.id) ??
        null;
      state.isEdit = true;
    },
    setEditLocationFromResponse: (
      state,
      { payload }: PayloadAction<LocationType>,
    ) => {
      state.editLocation = payload;
      state.isEdit = true;
    },
    clearEditLocation: (state) => {
      state.editLocation = null;
      state.isEdit = false;
    },
  },
});

export const {
  setLocations,
  removeLocation,
  setEditLocation,
  setEditLocationFromResponse,
  clearEditLocation,
  updateLoc,
  addLocation,
} = locationSlice.actions;
