import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";

import {
  ContentWidget,
  FieldWidget,
  NavigationWidget,
} from "../../../components/widgets/@types/Widget";
import { ChildrenWidgetType } from "../../../components/widgets/ContentWidgets/ChildrenWidget";

export type StepBlockContent = {
  type: "content";
  widgets: ContentWidget[];
};

export type StepBlockNavigation = {
  type: "navigation";
  widgets: NavigationWidget[];
};

export type StepBlock = StepBlockContent | StepBlockNavigation;

export type StepType = {
  title: string;
  blocks: StepBlock[];
};

export type InitialChildrenWidgetsInfo = {
  step: number;

  childrenWidget: ChildrenWidgetType;
};

type InitialStateType = {
  steps: StepType[] | [];
  stepsTitles: string[] | [];
  activeStep: number;
  isLastStep: boolean;
  currentStep: StepType | null;
  childrenWidget: ChildrenWidgetType | null;
  currentContentWidgets: ContentWidget[] | [];
  initialChildrenWidgetsInfo: InitialChildrenWidgetsInfo[] | [];
  currentNavigationWidgets: NavigationWidget[] | [];
};

const initialState: InitialStateType = {
  steps: [],
  activeStep: 0,
  stepsTitles: [],
  isLastStep: false,
  currentStep: null,
  childrenWidget: null,
  initialChildrenWidgetsInfo: [],
  currentContentWidgets: [],
  currentNavigationWidgets: [],
};
export const stepsSlice = createSlice({
  name: "step",
  initialState,
  reducers: {
    setInitialStepsInfo: (state, { payload }: PayloadAction<StepType[]>) => {
      state.steps = payload;
      state.stepsTitles = state.steps
        .map(({ title }) => title)
        .filter((title) => !!title && title);
    },
    setCurrentStep: (
      state,
      { payload: { activeStep } }: PayloadAction<{ activeStep: number }>,
    ) => {
      const currentStep = state.steps[activeStep];

      state.activeStep = activeStep;
      state.isLastStep = state.steps.length <= activeStep + 1;
      state.currentStep = currentStep;

      state.currentContentWidgets =
        (currentStep?.blocks.find(({ type }) => type === "content")?.widgets as
          | ContentWidget[]
          | undefined) ?? [];

      state.currentNavigationWidgets =
        (currentStep?.blocks?.find(({ type }) => type === "navigation")
          ?.widgets as NavigationWidget[] | undefined) ?? [];
    },
    appendWidget: ({
      activeStep,
      currentContentWidgets,
      initialChildrenWidgetsInfo,
    }) => {
      //TODO: create correct duplication
      const currentChildrenWidget =
        (currentContentWidgets.find(({ type }) => type === "children") as
          | ChildrenWidgetType
          | undefined) ?? null;

      let childrenWidget: ChildrenWidgetType | null = null;

      const appendChildrenWidget = (
        widget: ChildrenWidgetType,
        count: string,
      ): void => {
        const widgets = widget.widgets.map((widget) => ({
          ...widget,
          config: {
            ...widget.config,
            props: {
              ...widget.config.props,
              // Todo add correct fieldId for duplicate
              fieldId: widget.config.props.fieldId.replace("[0]", `[${count}]`),
            },
          },
        })) as FieldWidget[] | [];

        currentContentWidgets = currentContentWidgets.map((widget) => {
          if (widget.type === "children") {
            widget.widgets.push(...widgets);
            widget.position.h = widget.position.h * 2;
          }

          return widget;
        });
      };

      if (!initialChildrenWidgetsInfo.length) {
        childrenWidget = currentChildrenWidget;

        if (!childrenWidget) {
          console.error("children widget is not found");
        }

        if (childrenWidget) {
          const childrenWidgetInfo: InitialChildrenWidgetsInfo = {
            step: activeStep,
            childrenWidget: childrenWidget,
          };
          appendChildrenWidget(childrenWidget, uuid());

          // @ts-ignore TODO fix @ts-ignore
          initialChildrenWidgetsInfo.push(childrenWidgetInfo);
        }
        return;
      }

      if (initialChildrenWidgetsInfo.length) {
        childrenWidget =
          initialChildrenWidgetsInfo.find(({ step }) => step === activeStep)
            ?.childrenWidget ?? null;
        if (childrenWidget && currentChildrenWidget) {
          appendChildrenWidget(childrenWidget, uuid());
        } else if (currentChildrenWidget) {
          const childrenWidgetInfo: InitialChildrenWidgetsInfo = {
            step: activeStep,
            childrenWidget: currentChildrenWidget,
          };
          appendChildrenWidget(currentChildrenWidget, uuid());

          // @ts-ignore TODO fix @ts-ignore
          initialChildrenWidgetsInfo.push(childrenWidgetInfo);
        }
        return;
      }
    },
  },
});

export const { setInitialStepsInfo, setCurrentStep, appendWidget } =
  stepsSlice.actions;
