import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Stack,
  Button,
  Typography,
  Breadcrumbs,
  Alert,
} from "@mui/material";
import { NavigateNext } from "@mui/icons-material";

import { TableComponent } from "../../TableComponent/TableComponent";
import {
  useAppDispatch,
  useAppSelector,
  useAppThunkDispatch,
} from "../../../store";
import {
  deleteDocumentThunk,
  getDocumentsThunk,
} from "../../../store/features/thunk/documentThunk/documentThunk";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { setDocuments } from "../../../store/features/document/documentSlice";
import { Loader } from "../../Loader/Loader";

const header = ["ID", "DOCUMENT NAME", "CREATED AT", "UPDATED AT", ""];

export const DocumentsComponent: FC<{ breadcrumbs?: boolean }> = ({
  breadcrumbs = true,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { documents, editDocument, isEdit } = useAppSelector(
    ({ document }) => document,
  );
  const dispatch = useAppDispatch();
  const dispatchThunk = useAppThunkDispatch();

  const navigate = useNavigate();

  const companyStatus = localStorage.getItem("COMPANY_STATUS");

  useEffect(() => {
    handleDocuments();
    return () => {
      dispatch(setDocuments([]));
    };
  }, []);

  const handleDocuments = async () => {
    setIsLoading(true);
    await dispatchThunk(getDocumentsThunk());
    setIsLoading(false);
  };

  const handleDocumentDelete = async (id: string): Promise<void> => {
    await dispatchThunk(deleteDocumentThunk({ id }));
  };
  // i need to sort documents by date

  const handleDocumentEdit = (id: any): void => {
    navigate("/document/" + id);
  };

  const filterDocuments = (documents: any[]): any[] => {
    return documents?.length
      ? documents?.map((document: any) => {
          return {
            id: document.id,
            name: document.name,
            createdAt: document.createdAt,
            updatedAt: document.updatedAt,
          };
        })
      : [];
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Box sx={{ padding: `0 50px 0 ${breadcrumbs ? "305px" : "0"}` }}>
        {breadcrumbs && (
          <Box
            sx={{
              display: "flex",
              borderBottom: 1,
              borderColor: "divider",
              justifyContent: "space-between",
            }}
          >
            <Stack spacing={2}>
              <Breadcrumbs
                separator={<NavigateNext fontSize="small" color={"success"} />}
                aria-label="breadcrumb"
              >
                <Typography
                  key="1"
                  color="text.primary"
                  sx={{ padding: "14px 0" }}
                >
                  Documents
                </Typography>
              </Breadcrumbs>
            </Stack>
          </Box>
        )}
        <Box
          sx={{
            width: "100%",
            paddingTop: "31px",
            paddingBottom: "30px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div></div>
          <div>
            <Button
              sx={{ marginRight: "15px" }}
              variant={"contained"}
              color={"success"}
              onClick={() => {
                navigate(
                  `/${!breadcrumbs ? "locations/newDocument" : "document/new"}`,
                );
              }}
            >
              + Add new document
            </Button>
            <Button
              sx={{ marginRight: "15px" }}
              variant={"outlined"}
              color={"inherit"}
              onClick={() => {
                navigate("/workflows");
              }}
            >
              Create workflow
            </Button>
          </div>
        </Box>
        {companyStatus &&
          (companyStatus === "registered" || companyStatus === "setup") && (
            <Alert
              sx={{ marginBottom: "10px" }}
              variant="outlined"
              icon={false}
              severity={
                companyStatus === "registered"
                  ? "error"
                  : companyStatus === "setup"
                  ? "warning"
                  : undefined
              }
            >
              {companyStatus === "registered" && (
                <>
                  For free trial, please email your form to{' '}
                  <span className='text-blue-700'>wkform@waiverking.com</span>. Document upload and editing is currently disabled.
                </>
              )}
              {companyStatus === "setup" &&
                "Your document is currently being processed. It will become available for use shortly."}
            </Alert>
          )}
        <TableComponent
          type={"documents"}
          headers={header}
          rows={filterDocuments(documents)}
          isEditDelete={true}
          onDelete={handleDocumentDelete}
          onEdit={handleDocumentEdit}
        />
      </Box>
    </>
  );
};
