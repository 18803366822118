import { Command, CommandProps } from "@tiptap/core";
import { Node } from "@tiptap/pm/model";
import { FontSize } from "tiptap-extension-font-size";
import { WONDER_NUM_DIFF_FOR_LIST_ITEM } from "./extensionsConsts";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    extendedFontSize: {
      setFontSize: (fontSize: number | string) => ReturnType;
      unsetFontSize: () => ReturnType;
    };
  }
}

type FontSizeOptions = {
  types: string[];
  getStyle: (fontSize: string) => string;
};

export const ExtendedFontSize = FontSize.extend<FontSizeOptions>({
  addOptions() {
    return {
      types: ["textStyle"],
      getStyle: (fontSize: string) => {
        return `font-size: ${fontSize}`;
      },
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: null,
            parseHTML: (element: any) => {
              const fontSize = element.style.fontSize;
              return fontSize;
            },
            renderHTML: (attributes: any) => {
              if (!attributes.fontSize) {
                return {};
              }
              return {
                style: this.options.getStyle(attributes.fontSize),
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setFontSize: (fontSize: number | string): Command => {
        const fontSizeWithUnit =
          typeof fontSize === "number" ? `${fontSize}pt` : fontSize;
        return ({ state, tr, chain }: CommandProps) => {
          if (state.selection.empty) {
            return chain()
              .setMark("textStyle", { fontSize: fontSizeWithUnit })
              .run();
          }
          const { from, to } = this.editor.state.selection;

          let referenceFrom = from;
          tr.doc.nodesBetween(from, to, (node: Node, pos: number) => {
            if (node.isText) {
              return chain()
                .setMark("textStyle", { fontSize: fontSizeWithUnit })
                .run();
            } else if (node.isBlock || node.isInline) {
              if (node.type.name === "listItem") {
                // when it is list, this is the wonderous number, that it is possible to rely on
                // to understand that whole listItem was selected
                if (referenceFrom - pos !== WONDER_NUM_DIFF_FOR_LIST_ITEM)
                  return;
                tr.setNodeMarkup(pos, null, {
                  ...node.attrs,
                  fontSize: fontSizeWithUnit,
                });
                // wonderous number again
                referenceFrom =
                  node.nodeSize + pos + WONDER_NUM_DIFF_FOR_LIST_ITEM;
              }
            }
          });
          return true;
        };
      },
      unsetFontSize:
        () =>
        ({ chain }: any) => {
          return chain()
            .setMark("textStyle", { fontSize: null })
            .removeEmptyTextStyle()
            .run();
        },
    };
  },
});
