import React, { FC, useEffect } from "react";

import { FieldValues, FormProvider, useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { InputWidget } from "../../widgets/FormWidgets/InputWidget";
import { CheckboxWidget } from "../../widgets/FormWidgets/CheckboxWidget";
import { Editor } from "@tiptap/core";
import { getTitleForDocumentWidgetModalForm } from "./titleDeterminator";
import "./DocumentWidgetModalForm.scss";
import { radioIdValidator, specialCharactersValidator } from "helper/FormValidators/characterValidators";
import { WidgetType } from "tiptap/types";

export const DocumentWidgetModalForm: FC<{
  openState: [
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  ];
  config?: FieldValues;
  widgetType: WidgetType;
  editor: Editor;

  onClickSubmit: (data: FieldValues, editor: Editor) => void;
}> = ({ openState, widgetType, editor, onClickSubmit, config }) => {
  const [open, setOpen] = openState;
  const formMethods = useForm();
  const isUpdate: boolean = config?.fieldId;

  const { handleSubmit, setValue, reset } = formMethods;

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data: FieldValues) => {
    const { ["fieldId"]: _, ...formData } = data;
    onClickSubmit(formData, editor);
    reset();
    handleClose();
  };

  useEffect(() => {
    if (!config) return;
    Object.keys(config).forEach((key) => {
      let value:any = config[key];
      if (key === "name") {
        const regex = /document\[(.*?)\]/;
        const match = value.replace(regex, "$1");
        value = match ? match : value;
      }
      setValue(`${key}`, value);
    });
  }, [config, setValue]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {getTitleForDocumentWidgetModalForm(widgetType)}
        </DialogTitle>
        <DialogContent>
          <div>
            <FormProvider {...formMethods}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                id="modal-form"
                noValidate
              >
                {widgetType === "radio" && (
                  <>
                    <div className="container-widget">
                      <InputWidget
                        fieldId="value"
                        type="text"
                        label="Value"
                        required
                        fullWidth={true}
                        rules={specialCharactersValidator}
                      />
                    </div>
                    <div className="container-widget">
                      <InputWidget
                        fieldId="name"
                        type="text"
                        label="Radio group"
                        required
                        fullWidth={true}
                        rules={{validate: radioIdValidator}}
                      />
                    </div>
                  </>
                )}
                {!["radio", "checkbox"].includes(widgetType) && (
                  <div className="container-widget">
                    <InputWidget
                      fieldId="label"
                      type="text"
                      label="Label"
                      fullWidth={true}
                      rules={{validate: specialCharactersValidator}}
                    />
                  </div>
                )}
                <div className="max-w-[20vmin]">
                  <CheckboxWidget
                    fieldId="required"
                    type="checkbox"
                    label="Required"
                    value={config?.required || false}
                  />
                </div>
              </form>
            </FormProvider>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit(onSubmit)}>
            {isUpdate ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
