import { Node, mergeAttributes } from "@tiptap/core";

export type IndentElementOptions = {
  HTMLAttributes: Record<string, any>;
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    indentElement: {
      insertIndentElement: () => ReturnType;
      removeIndentElement: () => ReturnType;
    };
  }
}

const IndentElement = Node.create<IndentElementOptions>({
  name: "indentElement",

  group: "inline",

  inline: true,

  selectable: false,

  atom: true,

  parseHTML() {
    return [
      {
        tag: "span[data-indent-element]",
      },
    ];
  },

  addAttributes() {
    return {
      indentElement: {
        default: true,
      },
    };
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(
        {
          style: "display: inline-block; margin-left: 30px;",
          "data-indent-element": node.attrs.indentElement ?? true,
        },
        HTMLAttributes,
      ),
    ];
  },

  addCommands() {
    return {
      insertIndentElement:
        () =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: {
              indentElement: true,
            },
          });
        },
      removeIndentElement:
        () =>
        ({ commands }) => {
          return commands.deleteNode(this.name);
        },
    };
  },
});

export default IndentElement;
