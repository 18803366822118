const LOCALHOST = "localhost";
const IPV4_ADDRESS_PATTERN = /^(\d{1,3}\.){3}\d{1,3}$/;
const MIN_PARTS_FOR_SUBDOMAIN = 3;
const MIN_PARTS_FOR_DOMAIN = 2;

export function extractSubdomain(): string {
  const hostname = window.location.hostname;

  if (isLocalhost(hostname) || isIPv4Address(hostname)) {
    return "";
  }

  const hostnameParts: string[] = splitHostname(hostname);

  if (hasTooFewPartsForSubdomain(hostnameParts)) {
    return "";
  }

  return getSubdomain(hostnameParts);
}

export function extractDomain(ifReroute: boolean = true): string {
  const hostname = window.location.hostname;

  if (!ifReroute && isLocalhost(hostname)) {
    return LOCALHOST;
  }

  if (ifReroute && (isLocalhost(hostname) || hostname.includes("wkf.loc"))) {
    return "wkf.loc";
  }

  const domainParts = splitHostname(hostname).reverse();

  if (hasTooFewPartsForDomain(domainParts)) {
    return hostname;
  }

  return getDomainFromParts(domainParts);
}

function isLocalhost(hostname: string): boolean {
  return hostname.includes(LOCALHOST);
}

function isIPv4Address(hostname: string): boolean {
  return IPV4_ADDRESS_PATTERN.test(hostname);
}

function splitHostname(hostname: string): string[] {
  return hostname.split(".");
}

function getDomainFromParts(parts: string[]): string {
  return `${parts[1]}.${parts[0]}`;
}

function hasTooFewPartsForSubdomain(parts: string[]): boolean {
  return parts.length < MIN_PARTS_FOR_SUBDOMAIN;
}

function hasTooFewPartsForDomain(parts: string[]): boolean {
  return parts.length < MIN_PARTS_FOR_DOMAIN;
}

function getSubdomain(parts: string[]): string {
  const subdomainParts = parts.slice(0, -2);
  return subdomainParts.join(".") || "";
}
