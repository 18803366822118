import { FC, useState } from "react";

import { TextField } from "@mui/material";
import { PatternFormat } from "react-number-format";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, useFormContext } from "react-hook-form";

import { getValidationRule } from "../../../../helper/FormValidators/formHelper";
import { isObjectByPath } from "../../../../helper/isObjectKeyByPath";

import { InputWidgetProps } from "../InputWidget";

export const PhoneWidget: FC<InputWidgetProps> = (props) => {
  const { fieldId, label, value, fullWidth = true } = props;

  const [keys] = useState(fieldId.split(/[[\]]+/).filter(Boolean));

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Controller
        render={({ field: { onChange, ...field } }) => (
          <PatternFormat
            format="(###) ###-####"
            error={isObjectByPath(errors, keys)}
            id={fieldId}
            size={"small"}
            margin="normal"
            label={label}
            fullWidth={fullWidth}
            customInput={TextField}
            {...field}
            onChange={(event) => onChange(formatValue(event.target.value))}
            type="tel"
          />
        )}
        control={control}
        defaultValue={value}
        name={fieldId}
        rules={getValidationRule(props)}
        {...props}
      />
      <ErrorMessage
        errors={errors}
        name={fieldId}
        render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
      />
    </>
  );
};

const formatValue = (value: string): string =>
  value
    .split("")
    .filter((char) => char.match(/[0-9]/g))
    .join("");
