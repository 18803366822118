import { FC } from "react";

import { Box, Typography } from "@mui/material";

import { Relationship } from "../../store/features/client/clientSlice";

import { RelationshipItem } from "./RelationshipItem";

import "./RelationshipsComponent.scss";

export type RelationshipsComponentProps = {
  clients: Relationship[];
};

export const RelationshipsComponent: FC<RelationshipsComponentProps> = ({
  clients,
}) => {
  return (
    <div id="relations">
      {clients.length ? (
        <Box className="relations-wrapper">
          <Typography variant={"h6"} className="title">
            Relationships
          </Typography>
          {clients.map((client) => (
            <RelationshipItem key={client.uuid} client={client} />
          ))}
        </Box>
      ) : (
        <>not found</>
      )}
    </div>
  );
};
