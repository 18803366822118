import React, { FC } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "8px",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "40px 24px",
};

export type DeleteAdminAproveProps = {
  isDeleteAdminApproveModalOpen: boolean;
  setIsDeleteAdminApproveModalOpen: (value: boolean) => void;
  handleSubmitModal: () => void;
  isLoading: boolean;
};

export const DeleteAdminApproveModal: FC<DeleteAdminAproveProps> = ({
  isDeleteAdminApproveModalOpen,
  setIsDeleteAdminApproveModalOpen,
  handleSubmitModal,
  isLoading,
}) => {
  const handleModalClose = () => {
    setIsDeleteAdminApproveModalOpen(false);
  };

  const handleSubmit = () => {
    handleSubmitModal();
  };

  return (
    <Modal
      open={isDeleteAdminApproveModalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ width: "100%", marginBottom: "24px" }}>
            <Typography
              component={"span"}
              variant={"h6"}
              sx={{ fontWeight: "bold" }}
            >
              Approve Account Deletion
            </Typography>
          </Box>
          <Box sx={{ marginBottom: "24px" }}>
            <Typography variant={"subtitle2"}>
              A user has submitted a request to delete their account. Please
              review the details carefully before approving. This action is
              irreversible and will permanently remove the user's account and
              associated data. Confirm the request only if it aligns with the
              user's intentions and complies with the platform's policies.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "12px",
            }}
          >
            <Button
              variant={"outlined"}
              color={"inherit"}
              onClick={handleModalClose}
              sx={{ textTransform: "capitalize", padding: "7px 17px" }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant={"contained"}
              color={"success"}
              loading={isLoading}
              sx={{ textTransform: "capitalize", padding: "7px 34px" }}
              onClick={handleSubmit}
            >
              Proceed
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
