import { createApi } from "@reduxjs/toolkit/query/react";
import { baseCustomFetchBase } from "../service/customFetchBase";
import { DocumentType } from "../../document/documentSlice";

const document = "document-template";
const documents = "document-templates";

const documentApi = createApi({
  reducerPath: "documentApi",
  baseQuery: baseCustomFetchBase,
  endpoints: (builder) => ({
    getDocument: builder.mutation<any, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `${document}/${uuid}`,
        method: "GET",
      }),
    }),
    getDocuments: builder.mutation<any, any>({
      query: () => ({
        url: `${documents}`,
        method: "GET",
      }),
    }),
    createDocument: builder.mutation<any, Omit<DocumentType, "id">>({
      query: (data) => ({
        url: `${document}`,
        method: "POST",
        body: data,
      }),
    }),
    deleteDocument: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `${document}/${id}`,
        method: "DELETE",
      }),
    }),
    updateDocument: builder.mutation<any, DocumentType>({
      query: (data) => ({
        url: `${document}/${data.id}`,
        method: "PUT",
        body: data,
      }),
    }),
    uploadFile: builder.mutation<any, { file: string | ArrayBuffer }>({
      query: ({ file }) => ({
        url: `/upload/image`,
        method: "POST",
        body: { image: file },
      }),
    }),
    getPreviewDocument: builder.mutation<any, { id: string , type: string}>({
      query: ({ id, type }) => ({
        url: `document-template/${id}/preview/${type}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useUploadFileMutation,
  useGetDocumentMutation,
  useGetPreviewDocumentMutation,
} = documentApi;

export default documentApi;

export const {
  getDocument,
  getDocuments,
  createDocument,
  deleteDocument,
  updateDocument,
} = documentApi.endpoints;
