import { FC } from "react";
import { Box, Button, Divider } from "@mui/material";
import { FacebookRounded, Google } from "@mui/icons-material";

export const SocialAuthWidget: FC = () => {
  return (
    <Box width={`400px`}>
      <Box paddingY={"40px"}>
        <Divider>Or continue with</Divider>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          size={"large"}
          startIcon={<Google />}
          type="button"
          color={"primary"}
          variant="outlined"
          sx={{ padding: "9px 22px" }}
        >
          Google
        </Button>
        <Button
          size={"large"}
          startIcon={<FacebookRounded />}
          type="button"
          color={"primary"}
          variant="outlined"
          sx={{ padding: "9px 22px" }}
        >
          Facebook
        </Button>
      </Box>
    </Box>
  );
};
