import { Alert, Box } from "@mui/material";
import { useEffect } from "react";
import { AlertTitle } from "@mui/lab";
import { Check } from "@mui/icons-material";

export const DropboxConnect = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      window.opener.postMessage({ code }, "*");
    }

    setTimeout(() => {
      window.close();
    }, 5000);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Alert severity="success" icon={false}>
        <AlertTitle
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginBottom: 0,
          }}
        >
          <Check /> Dropbox connected successfully.
        </AlertTitle>
      </Alert>
    </Box>
  );
};
