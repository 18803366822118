import React, { FC, useEffect, useState } from "react";

import { Box, Button, InputAdornment, Modal, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { QRCodeSVG } from "qrcode.react";
import { InputWidget } from "../widgets/FormWidgets/InputWidget";
import {
  sendCode,
  verifyCode,
} from "../../store/features/api/clientApi/clientApi";
import { useAppDispatch } from "../../store";
import { OtpMethod } from "../../store/features/form/formSlice";
import { LoadingButton } from "@mui/lab";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../store/features/notification/notificationSlice";

const DEFAULT_INTERVAL = 60;
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "8px",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "40px 24px",
};

export type AuthenticationModalProps = {
  isAuthenticationModalOpen: boolean;
  setIsAuthenticationModalOpen: (value: boolean) => void;
  type?: OtpMethod["type"];
  getTwoFAInfo: () => void;
  companyName?: string;
};

const generateRandomCode = (length: number) => {
  const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567";
  let code = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    code += charset.charAt(randomIndex);
  }
  return code;
};

export const TwoFactorAuthenticationModal: FC<AuthenticationModalProps> = ({
  isAuthenticationModalOpen,
  setIsAuthenticationModalOpen,
  type,
  getTwoFAInfo,
  companyName,
}) => {
  let interval: any = null;
  const [sec, setSec] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [googleAuthQRCode, setGoogleAuthQRCode] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const dispatch = useAppDispatch();
  const formMethods = useForm();
  const {
    formState: { errors },
    handleSubmit,
    watch,
    trigger,
    reset,
  } = formMethods;
  const typeValue = watch(type ?? "");
  const googleAuthQRLink = `otpauth://totp/WKform:${companyName}?secret=${googleAuthQRCode}&issuer=WKform`;

  const handleModalClose = () => {
    setIsAuthenticationModalOpen(false);
    setCodeSent(false);
    reset();
    setSec(0);
  };

  useEffect(() => {
    if (isAuthenticationModalOpen) {
      const code = generateRandomCode(16);
      setGoogleAuthQRCode(code);
    }
  }, [isAuthenticationModalOpen]);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    if (!type) return;

    try {
      const res = await dispatch(
        verifyCode.initiate({
          verificationCodes: { [type]: data.verificationCode },
          verificationType: type,
          [type]: data[type],
          ...(type === "google_authenticator" && {
            secret: googleAuthQRCode,
          }),
        }),
      ).unwrap();

      dispatch(showSuccessNotification({ message: res?.message }));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
    setIsLoading(false);
    handleModalClose();
    getTwoFAInfo();
  };

  useEffect(() => {
    if (sec <= 0) {
      clearTimeout(interval);
      return;
    }

    handleTick();

    return () => {
      clearInterval(interval);
    };
  }, [sec]);

  useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleTick = () => {
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(() => {
      setSec((prevState) => prevState - 1);
    }, 1000);
  };

  const sendVerificationCode = async () => {
    if (sec) return;

    await trigger(type);

    if (!typeValue || !type || errors?.[type!]) return;

    setSec(DEFAULT_INTERVAL);
    await dispatch(
      sendCode.initiate({
        [type as any]: typeValue,
        verificationType: type,
      }),
    ).unwrap();
    setCodeSent(true);
  };

  return (
    <>
      <Modal
        open={isAuthenticationModalOpen && !!type}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ width: "100%", marginBottom: "24px" }}>
                  <Box sx={{ textAlign: "start", marginBottom: "24px" }}>
                    <Typography
                      component={"span"}
                      variant={"h6"}
                      sx={{ fontWeight: "bold" }}
                    >
                      {type === "google_authenticator" &&
                        "Add Google Authenticator"}
                      {type === "email" && "Change Email"}
                      {type === "phone_number" &&
                        "Add phone number verification"}
                    </Typography>
                  </Box>
                  {type === "google_authenticator" && (
                    <Box>
                      <Typography
                        variant={"body2"}
                        sx={{ marginBottom: "16px" }}
                      >
                        Scan this QR code in the authenticator app
                      </Typography>
                      <Box
                        sx={{
                          marginBottom: "16px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <QRCodeSVG value={googleAuthQRLink} size={135} />
                      </Box>
                      <Typography
                        variant={"body2"}
                        sx={{ textAlign: "center", marginBottom: "16px" }}
                      >
                        {googleAuthQRCode}
                      </Typography>
                      <Typography
                        variant={"body2"}
                        sx={{ marginBottom: "16px" }}
                      >
                        If you are unable to scan the QR code, please enter this
                        code manually into the app.
                      </Typography>
                      <InputWidget
                        fieldId={"verificationCode"}
                        label={"Enter verification code"}
                        type={"text"}
                        value={""}
                      />
                      <Typography variant={"body2"}>
                        Enter the 6-digit code generated by the Authenticator
                        App.
                      </Typography>
                    </Box>
                  )}
                  {type === "email" && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "24px",
                      }}
                    >
                      <InputWidget
                        fieldId={"email"}
                        label={"New email address"}
                        type={"email"}
                        required={true}
                        value={""}
                      />
                      <InputWidget
                        fieldId={"verificationCode"}
                        label={"Enter verification code"}
                        type={"text"}
                        value={""}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Box
                                sx={{
                                  textDecoration: !sec ? "underline" : "none",
                                  lineHeight: "21px",
                                  fontSize: "14px",
                                  cursor: "pointer",
                                  color: !sec ? "#3874CB" : "#3FBA73",
                                }}
                                onClick={sendVerificationCode}
                              >
                                {!sec
                                  ? codeSent
                                    ? "Resend Code"
                                    : "Send code"
                                  : "Code sent"}
                              </Box>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  )}
                  {type === "phone_number" && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "24px",
                      }}
                    >
                      <InputWidget
                        fieldId={"phone_number"}
                        label={"Enter you number"}
                        type={"phone"}
                        value={""}
                      />
                      <InputWidget
                        fieldId={"verificationCode"}
                        label={"Enter verification code"}
                        type={"text"}
                        value={""}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Box
                                sx={{
                                  textDecoration: !sec ? "underline" : "none",
                                  lineHeight: "21px",
                                  fontSize: "14px",
                                  cursor: "pointer",
                                  color: !sec ? "#3874CB" : "#3FBA73",
                                }}
                                onClick={sendVerificationCode}
                              >
                                {!sec
                                  ? codeSent
                                    ? "Resend Code"
                                    : "Send code"
                                  : "Code sent"}
                              </Box>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  )}
                  {(type === "email" || type === "phone_number") &&
                    Boolean(sec) && (
                      <Box>
                        <Typography variant={"body2"}>
                          You can request a new code in {sec} seconds.
                        </Typography>
                        <Typography variant={"body2"}>
                          The code will expire in 5 minutes.
                        </Typography>
                      </Box>
                    )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "12px",
                  }}
                >
                  <Button
                    variant={"outlined"}
                    color={"inherit"}
                    onClick={handleModalClose}
                    sx={{ textTransform: "capitalize", padding: "7px 17px" }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    type={"submit"}
                    variant={"contained"}
                    color={"success"}
                    loading={isLoading}
                    disabled={
                      !Boolean(watch("verificationCode")) ||
                      watch("verificationCode").length !== 6
                    }
                    sx={{ textTransform: "capitalize", padding: "7px 34px" }}
                  >
                    Submit
                  </LoadingButton>
                </Box>
              </Box>
            </form>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
};
