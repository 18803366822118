import { ChangeEvent, FC, useState } from "react";

import { ErrorMessage } from "@hookform/error-message";
import { Radio, FormControlLabel } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

// @ts-ignore
import { RadioWidgetProps } from "./RadioWidget.types";
import { ControllerRenderProps } from "../../@types/Widget";

import { getValidationRule } from "../../../../helper/FormValidators/formHelper";
import { isObjectByPath } from "../../../../helper/isObjectKeyByPath";

export const RadioWidget: FC<RadioWidgetProps> = (field) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    label,
    name,
    fieldId,
    required,
    value,
    checked,
    isShowErrorMessage = true,
  } = field;

  const [keys] = useState(fieldId.split(/[[\]]+/).filter(Boolean));

  return (
    <>
      <Controller
        render={({
          field: { value, onChange },
        }: {
          field: ControllerRenderProps<boolean, ChangeEvent<HTMLInputElement>>;
        }) => (
          <FormControlLabel
            id={fieldId}
            control={
              <Radio
                value={value}
                name={name}
                required={required}
                checked={checked}
                onChange={onChange}
                sx={{
                  color: `${isObjectByPath(errors, keys) ? "red" : "black"}`,
                }}
              />
            }
            aria-required={false}
            label={label}
          />
        )}
        control={control}
        defaultValue={value}
        name={fieldId}
        rules={getValidationRule(field)}
      />

      {isShowErrorMessage && (
        <ErrorMessage
          errors={errors}
          name={fieldId}
          render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
        />
      )}
    </>
  );
};
