import { FC } from "react";
import { Editor } from "@tiptap/core";
import { MenuButton } from "../MenuButton";

interface MenuIndentButtonProps {
  editor: Editor;
}

export const MenuIndentButton: FC<MenuIndentButtonProps> = ({ editor }) => {
  const runCommand = () => {
    editor.chain().focus().indent().run();
  };

  return <MenuButton icon="indent" title="Indent" command={runCommand} />;
};
