import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import DownloadingIcon from "@mui/icons-material/Downloading";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import {
  emergencyBackupGenerate,
  emergencyClearAll,
} from "../../../../store/features/api/clientApi/clientApi";
import { useAppDispatch } from "../../../../store";
import { RequestType } from "./EmergencyActions";

type Action = {
  icon: JSX.Element;
  title: string;
  description: string;
  onClick: () => Promise<void>;
  disabled: boolean;
  button: string;
};

export type SuperAdminActionsProps = {
  emergencyStatus: any;
  setRequestType: (value: RequestType) => void;
  fechEmergencyStatus: () => void;
  setIsDeleteAdminApproveModalOpen: (value: boolean) => void;
};

export const SuperAdminActions: FC<SuperAdminActionsProps> = ({
  emergencyStatus,
  setRequestType,
  fechEmergencyStatus,
  setIsDeleteAdminApproveModalOpen,
}) => {
  const dispatch = useAppDispatch();

  const actionData: Action[] = [
    {
      icon: <VerifiedUserIcon />,
      title: "Account Deletion Request",
      description:
        "User requested account deletion. Confirm to permanently remove account and data.",
      onClick: async () => {
        setIsDeleteAdminApproveModalOpen(true);
        setRequestType(RequestType.DeleteApprove);
      },
      disabled:
        !emergencyStatus.delete?.requestTime ||
        emergencyStatus.delete?.approveTime,
      button: "Approve",
    },
    {
      icon: <DownloadingIcon />,
      title: "Generate Backup File",
      description:
        "You will generate backup account data file for user to download.",
      onClick: async () => {
        await dispatch(emergencyBackupGenerate.initiate({})).unwrap();
        await fechEmergencyStatus();
      },
      disabled:
        !emergencyStatus.backup?.requestTime ||
        emergencyStatus.backup?.generateTime,
      button: "Generate",
    },
    {
      icon: <ClearAllIcon />,
      title: "Test Clear All",
      description:
        "Pressing the 'Clear All' button will erase all emergency status response data. Only for testing purposes.",
      onClick: async () => {
        await dispatch(emergencyClearAll.initiate({})).unwrap();
        await fechEmergencyStatus();
      },
      disabled:
        emergencyStatus.freeze?.length === 0 &&
        emergencyStatus.backup?.length === 0 &&
        emergencyStatus.delete?.length === 0,
      button: "Clear All",
    },
  ];

  const executeAction = async (onClick: () => Promise<void>) => {
    await onClick();
  };

  return (
    <Box
      sx={{
        marginBottom: "80px",
        borderRadius: "4px",
        padding: "40px",
        backgroundColor: "#dedede",
        color: "#000",
      }}
    >
      <Box sx={{ textAlign: "start", marginBottom: "30px" }}>
        <Typography
          component={"span"}
          variant={"h5"}
          sx={{ fontWeight: "bold" }}
        >
          SuperAdmin Actions
        </Typography>
      </Box>

      <Box>
        {actionData.map((action, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              minHeight: "85px",
              padding: "20px",
              marginBottom: "30px",
              borderRadius: "4px",
              backgroundColor: "#dedede",
              boxShadow: "5px 5px 10px #c8c8c8, -5px -5px 10px #f4f4f4",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                width: "70%",
              }}
            >
              {action.icon}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography variant="subtitle1">{action.title}</Typography>
                <Box sx={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="body2"
                    sx={{ opacity: "0.5", textAlign: "start" }}
                  >
                    {action.description}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "20%",
              }}
            >
              <LoadingButton
                variant="outlined"
                color="error"
                sx={{ margin: "auto" }}
                onClick={() => {
                  executeAction(action.onClick);
                }}
                disabled={action.disabled}
              >
                {action.button}
              </LoadingButton>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
