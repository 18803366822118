import { FeatureType } from "../shared/types";

// @ts-ignore
import step from "../assets/step.svg";

const registerStepsData: Array<FeatureType> = [
  {
    icon: step,
    title: "Step 1",
    description: "Enter your company contact details",
  },
  {
    icon: step,
    title: "Step 2",
    description: "Sign our business agreement",
  },
  {
    icon: step,
    title: "Step 3",
    description: "Add your business’ primary location (city)",
  },
  {
    icon: step,
    title: "Step 4",
    description: `Do you have a document you’d like us to use? If so, email one PDF or Word document to <a style="color: #7EB250; text-decoration: underline" href="mailto:doc@waiverking.com">doc@waiverking.com</a>`,
  },
  {
    icon: step,
    title: "Step 5",
    description:
      "That’s it! Welcome to WaiverKing – we are happy you’re here! One of our team members will be in touch with you shortly to activate your first waiver!",
  },
];

export default registerStepsData;
