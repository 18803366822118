const COMPANY_ACCESS_TOKEN = "COMPANY_ACCESS_TOKEN";
const REFRESH_TOKEN = "REFRESH_TOKEN";

export class ApiService {
  clear() {
    localStorage.removeItem(COMPANY_ACCESS_TOKEN);
  }
  getToken(): string | null {
    return localStorage.getItem(COMPANY_ACCESS_TOKEN);
  }
  set(token: string): void {
    localStorage.setItem(COMPANY_ACCESS_TOKEN, token);
  }
  setRefresh(refreshToken: string): void {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }
  getRefresh(): string | null {
    return localStorage.getItem(REFRESH_TOKEN);
  }
  clearRefresh(): void {
    localStorage.removeItem(REFRESH_TOKEN);
  }
}

export const apiService = new ApiService();
