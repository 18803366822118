import { useEffect } from "react";

const DeletionPolicy = () => {
  const wrapper = document.querySelector(".landing-wrapper");

  useEffect(() => {
    wrapper?.scrollTo(0, 0);
  }, []);

  return (
    <div className="xs:px-[24px] xs:py-[70px] xs:pt-[64px] sm:px-[95px] sm:py-[95px] sm:pt-[130px] md:px-[139px] md:py-[120px] md:pt-[140px] lg:px-[317px] lg:py-[165px] lg:pt-[214px]">
      <section
        style={{
          marginBottom: "150px",
        }}
      >
        <div>
          <p
            style={{
              fontSize: "24px",
              lineHeight: "110%",
              marginBottom: "20px",
            }}
          >
            Effective Date: Dec 29, 2023
          </p>
          <p
            style={{
              fontSize: "54px",
              margin: "20px 0",
              lineHeight: "110%",
            }}
          >
            <strong>Account Deletion Instructions for WKForm</strong>
          </p>
          <p
            style={{
              fontSize: "40px",
              lineHeight: "110%",
              marginBottom: "20px",
            }}
          >
            To ensure the deletion of your account from WKForm, please follow
            these steps:
          </p>
          <ol
            style={{
              paddingLeft: "20px",
              listStyleType: "number",
              marginBottom: "20px",
            }}
          >
            <li>
              <p>
                <strong>Go To Your Account:</strong>
              </p>
              <ul
                style={{
                  paddingLeft: "20px",
                  listStyleType: "disc",
                  marginBottom: "10px",
                }}
              >
                <li>Go to Emergency Actions</li>
              </ul>
            </li>
            <li>
              <p>
                <strong>Freeze Your Account:</strong>
              </p>
              <ul
                style={{
                  paddingLeft: "20px",
                  listStyleType: "disc",
                  marginBottom: "10px",
                }}
              >
                <li>Locate the "Account Freeze" option.</li>
                <li>
                  Select the "Freeze Account" option to temporarily halt all
                  account activity while retaining data.
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>Request Deletion of Your Account:</strong>
              </p>
              <ul
                style={{
                  paddingLeft: "20px",
                  listStyleType: "disc",
                  marginBottom: "10px",
                }}
              >
                <li>Find the "Delete Account" option.</li>
                <li>Initiate the account deletion process.</li>
              </ul>
            </li>
            <li>
              <p>
                <strong>Choose Data Backup (Optional):</strong>
              </p>
              <ul
                style={{
                  paddingLeft: "20px",
                  listStyleType: "disc",
                  marginBottom: "10px",
                }}
              >
                <li>
                  If you wish to retain a copy of your data, select the "Request
                  Data Backup" checkbox during the deletion process.
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>Confirm Deletion Request:</strong>
              </p>
              <ul
                style={{
                  paddingLeft: "20px",
                  listStyleType: "disc",
                  marginBottom: "10px",
                }}
              >
                <li>Verify your intention to delete the account.</li>
              </ul>
            </li>
            <li>
              <p>
                <strong>Receive Verification Code:</strong>
              </p>
              <ul
                style={{
                  paddingLeft: "20px",
                  listStyleType: "disc",
                  marginBottom: "10px",
                }}
              >
                <li>
                  A verification code will be sent to your registered email for
                  confirmation.
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>Administrator Approval (if required):</strong>
              </p>
              <ul
                style={{
                  paddingLeft: "20px",
                  listStyleType: "disc",
                  marginBottom: "10px",
                }}
              >
                <li>
                  If administrator approval is necessary, your deletion request
                  will be pending until approved.
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>Final Confirmation:</strong>
              </p>
              <ul
                style={{
                  paddingLeft: "20px",
                  listStyleType: "disc",
                  marginBottom: "10px",
                }}
              >
                <li>
                  Upon approval, you will receive a confirmation email to
                  proceed with the deletion.
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>Visibility of 'Delete' Button:</strong>
              </p>
              <ul
                style={{
                  paddingLeft: "20px",
                  listStyleType: "disc",
                  marginBottom: "10px",
                }}
              >
                <li>
                  Upon approval, a 'delete' button will become visible within
                  your account settings.
                </li>
                <li>
                  Click on a 'delete' button and confirm deleting account data.
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>Enter Verification Code Again:</strong>
              </p>
              <ul
                style={{
                  paddingLeft: "20px",
                  listStyleType: "disc",
                  marginBottom: "10px",
                }}
              >
                <li>
                  Enter the verification code received in your email to proceed
                  with account deletion.
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>Final Confirmation:</strong>
              </p>
              <ul
                style={{
                  paddingLeft: "20px",
                  listStyleType: "disc",
                  marginBottom: "10px",
                }}
              >
                <li>
                  Confirm your intent to permanently delete the account by
                  submitting the verification code.
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>Waiting Period for Deletion:</strong>
              </p>
              <ul
                style={{
                  paddingLeft: "20px",
                  listStyleType: "disc",
                  marginBottom: "10px",
                }}
              >
                <li>
                  Please allow for a processing period, as account deletion may
                  take some time to be finalized.
                </li>
              </ul>
            </li>
          </ol>
          <p
            style={{
              fontSize: "24px",
              lineHeight: "110%",
              marginBottom: "20px",
            }}
          >
            Please note:
          </p>
          <ul
            style={{
              paddingLeft: "20px",
              listStyleType: "disc",
              marginBottom: "10px",
            }}
          >
            <li>
              Freezing your account suspends activities without deleting data.
            </li>
            <li>
              Account deletion involves a verification process for security
              purposes.
            </li>
            <li>The deletion process may require administrative approval.</li>
            <li>
              Your data will be permanently removed from our system after the
              deletion is completed.
            </li>
          </ul>
          <p
            style={{
              marginBottom: "20px",
            }}
          >
            Thank you for using WKForm. If you have any queries or need
            assistance, please contact our support team.
          </p>
          <p
            style={{
              marginBottom: "20px",
            }}
          >
            This structured set of steps aims to guide users through a clear and
            comprehensive account deletion process for your project, similar to
            the example provided for Facebook data deletion. Adjustments can be
            made based on your platform's specific features and requirements.
          </p>
        </div>
      </section>
      <section>
        <div style={{ gridColumnGap: "4px", display: "flex" }}>
          <div
            style={{
              fontSize: "24px",
              lineHeight: "110%",
            }}
          >
            Effective date:
          </div>
          <div
            style={{
              fontSize: "24px",
              lineHeight: "110%",
            }}
          >
            Feb 16, 2023
          </div>
        </div>
        <h1
          style={{
            fontSize: "54px",
            margin: "20px 0",
            lineHeight: "110%",
          }}
        >
          <strong>Facebook Data Deletion Instructions</strong>
        </h1>
        <div className="w-richtext">
          <h2
            style={{
              fontSize: "40px",
              lineHeight: "110%",
            }}
          >
            How to remove your data
          </h2>
          <p>
            <br />
            Musiversal uses a facebook login app to authenticate our users.
            Musiversal does not save your personal data in our server.
          </p>
          <p>‍</p>
          <p>
            According to Facebook policy, we have to provide User Data Deletion
            Callback URL or Data Deletion Instructions URL.
          </p>
          <p>‍</p>
          <p>
            If you want to delete your activities for Musiversal'S facebook
            login app, you can remove your information by following these steps:
          </p>
          <ul
            style={{
              paddingLeft: "20px",
              listStyleType: "disc",
            }}
          >
            <li>Go to your Facebook Account’s “Settings &amp; Privacy”.</li>
            <li>Click “Settings”</li>
            <li>Then click “Security and Login”.</li>
            <li>
              Look for “Apps and Websites” and you will see all of the apps and
              websites you have linked with your Facebook account.
            </li>
            <li>Search and Click “Musiversal” in the search bar.</li>
            <li>Scroll and click “Remove”.</li>
          </ul>
          <h2>‍</h2>
        </div>
      </section>
    </div>
  );
};
export default DeletionPolicy;
