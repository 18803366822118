import { FC, useEffect, useState } from "react";

import { Box, Button, Typography } from "@mui/material";

import { useAppThunkDispatch } from "../../../../../store";
import { signUpThunk } from "../../../../../store/features/thunk/thunkApi";

import {
  InputWidget,
  InputWidgetProps,
} from "../../../FormWidgets/InputWidget";

const singInFormData: InputWidgetProps[] = [
  {
    type: "text",
    // validator: "",//REG
    label: "Verification code",
    fieldId: "verificationCode",
    required: true, //boolean
    value: "",
    maxlength: 6,
  },
];

export const OTPWidget: FC = () => {
  const dispatchThunk = useAppThunkDispatch();

  const [sec, setSec] = useState(60);

  let interval: any = null;

  useEffect(() => {
    if (sec <= 0) {
      clearTimeout(interval);
      return;
    }

    handleTick();

    return () => {
      clearInterval(interval);
    };
  }, [sec]);

  const handleTick = () => {
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(() => {
      setSec((prevState) => prevState - 1);
    }, 1000);
  };

  return (
    <Box>
      <Typography variant={"h4"} textAlign={"center"}>
        Verification step
      </Typography>
      <Typography variant={"subtitle1"}>
        Please, enter the verification code that was sent to your email.
      </Typography>
      <div style={{ maxWidth: 300, margin: "0 auto", paddingTop: "30px" }}>
        <InputWidget {...singInFormData[0]} />
      </div>
      <Box display={"flex"} justifyContent={"center"}>
        <Button
          variant={"text"}
          sx={{ textTransform: "none" }}
          size={"small"}
          disabled={!!sec}
          onClick={() => {
            setSec(60);
            dispatchThunk(signUpThunk(null));
          }}
        >
          <Typography variant={"subtitle1"}>
            Send verification code again ?
          </Typography>{" "}
          {sec > 0 && (
            <Typography variant={"subtitle2"}>(left {sec} seconds)</Typography>
          )}
        </Button>
      </Box>
    </Box>
  );
};
