import { FC } from "react";
import { Editor } from "@tiptap/core";
import { MenuButton } from "../MenuButton";

interface MenuHorizontalRuleButtonProps {
  editor: Editor;
}

export const MenuHorizontalRuleButton: FC<MenuHorizontalRuleButtonProps> = ({
  editor,
}) => {
  const runCommand = () => {
    editor.chain().focus().setHorizontalRule().run();
  };

  return (
    <MenuButton
      icon="minus"
      title="Horizontal rule"
      command={runCommand}
      isActive={false}
    />
  );
};
