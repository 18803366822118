import { useEffect } from "react";
import { TermsOfServiceHtml } from "./termsOfServiceHtml";

const TermsOfService = () => {
  const wrapper = document.querySelector(".landing-wrapper");

  useEffect(() => {
    wrapper?.scrollTo(0, 0);
  }, []);

  return (
    <div className="xs:px-[24px] xs:py-[70px] xs:pt-[64px] sm:px-[95px] sm:py-[95px] sm:pt-[130px] md:px-[139px] md:py-[120px] md:pt-[140px] lg:px-[317px] lg:py-[165px] lg:pt-[214px]">
      <div dangerouslySetInnerHTML={{ __html: TermsOfServiceHtml }}></div>
    </div>
  );
};
export default TermsOfService;
