import { createSlice, PayloadAction } from "@reduxjs/toolkit/";

// import { BgImagePosition } from '../../../@types/WorkFlow';

export type Appearance = {
  buttons_color: string;
  background_color: string;
  background_url: string;
  logo_url?: string;
  background_image_position?: "cover" | "right";
  show_logo_in_header?: boolean;
};

const initialState: Appearance = {
  buttons_color: "",
  background_color: "",
  background_url: "",
  logo_url: "",
  show_logo_in_header: undefined,
};

export const appearanceSlice = createSlice({
  name: "appearance",
  initialState,
  reducers: {
    setAppearance: (state, { payload }: PayloadAction<Appearance>) => {
      return { ...state, ...payload };
    },
  },
});

export const { setAppearance } = appearanceSlice.actions;
