import { useEffect, useState } from "react";

export const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState<"down" | "up" | null>(
    null,
  );
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const container = document.querySelector(".landing-wrapper");

    const updateScrollDirection = () => {
      const scrollY = container?.scrollTop || 0;

      if (scrollY !== lastScrollY) {
        const direction = scrollY > lastScrollY ? "down" : "up";

        if (
          direction !== scrollDirection &&
          (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
        ) {
          setScrollDirection(direction);
        }
        setLastScrollY(scrollY > 0 ? scrollY : 0);
      }
    };

    container?.addEventListener("scroll", updateScrollDirection);

    return () => {
      container?.removeEventListener("scroll", updateScrollDirection);
    };
  }, [lastScrollY, scrollDirection]);

  return scrollDirection;
};
