import { FC, useEffect, useState } from "react";
import { Menu } from "@mui/material";

const INIT_GRID_SIZE = 5;
const MAX_GRID_SIZE = 10;
const DEFAULT_SELECTED_GRID_SIZE = 2;

interface GridSize {
  row: number;
  col: number;
}

interface CreateTablePopoverProps {
  onCreateTable: (gridSize: GridSize) => void;
  closeMainPopover: () => void;
}

export const CreateTablePopover: FC<CreateTablePopoverProps> = ({
  onCreateTable,
  closeMainPopover,
}) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [id, setId] = useState(popoverVisible ? "simple-popover" : undefined);

  const [tableGridSize, setTableGridSize] = useState<GridSize>({
    row: INIT_GRID_SIZE,
    col: INIT_GRID_SIZE,
  });
  const [selectedTableGridSize, setSelectedTableGridSize] = useState<GridSize>({
    row: DEFAULT_SELECTED_GRID_SIZE,
    col: DEFAULT_SELECTED_GRID_SIZE,
  });

  useEffect(() => {
    setId(popoverVisible ? "simple-popover" : undefined);
    popoverVisible && closeMainPopover();
  }, [popoverVisible]);

  const selectTableGridSize = (row: number, col: number) => {
    if (row + 1 === tableGridSize.row) {
      setTableGridSize({
        ...tableGridSize,
        row: Math.min(row + 1, MAX_GRID_SIZE),
      });
    }

    if (col + 1 === tableGridSize.col) {
      setTableGridSize({
        ...tableGridSize,
        col: Math.min(col + 1, MAX_GRID_SIZE),
      });
    }

    setSelectedTableGridSize({ row, col });
  };

  const onMouseDown = (row: number, col: number) => {
    setPopoverVisible(false);
    onCreateTable({ row: row + 1, col: col + 1 });
  };

  const resetTableGridSize = () => {
    setTableGridSize({ row: INIT_GRID_SIZE, col: INIT_GRID_SIZE });
    setSelectedTableGridSize({
      row: DEFAULT_SELECTED_GRID_SIZE,
      col: DEFAULT_SELECTED_GRID_SIZE,
    });
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setPopoverVisible(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverVisible(false);
  };

  return (
    <>
      <div
        className={`el-tiptap-popper__menu__item$`}
        // onMouseDown={handleClose}
        onClick={handleClick}
      >
        <span>Insert table</span>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        // onChange={handleVisibleChange}
        open={popoverVisible}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div className="table-grid-size-editor">
          <div className="table-grid-size-editor__body">
            {Array.from({ length: tableGridSize.row }).map((_, row) => (
              <div key={`r${row}`} className="table-grid-size-editor__row">
                {Array.from({ length: tableGridSize.col }).map((_, col) => (
                  <div
                    key={`c${col}`}
                    className={`table-grid-size-editor__cell ${
                      col <= selectedTableGridSize.col &&
                      row <= selectedTableGridSize.row
                        ? "table-grid-size-editor__cell--selected"
                        : ""
                    }`}
                    onMouseOver={() => selectTableGridSize(row, col)}
                    onMouseDown={() => onMouseDown(row, col)}
                  >
                    <div className="table-grid-size-editor__cell__inner" />
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div className="table-grid-size-editor__footer">
            {selectedTableGridSize.row + 1} X {selectedTableGridSize.col + 1}
          </div>
        </div>
      </Menu>
    </>
  );
};
