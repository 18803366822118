import { FC, useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormProvider, useForm } from "react-hook-form";

import {
  useAppDispatch,
  useAppSelector,
  useAppThunkDispatch,
} from "../../store";
import {
  getAgreementThunk,
  getSignedAgreementsThunk,
  getSignedAgreementThunk,
  signAgreementThunk,
} from "../../store/features/thunk/serviceAgreementThunk/serviceAgreementThunk";

import { getContentWidgets } from "../widgets/services/widgetsFactory";
import useElementSize from "../../hooks/useElementSize";
import { DocumentWidgetType } from "../widgets/ContentWidgets/DocumentWidget";
import { TableComponent } from "../TableComponent/TableComponent";
import {
  setAgreement,
  setPreview,
} from "../../store/features/serviceAgreement.ts/serviceAgreementSlice";
import { getCompanyDetailsThunk } from "../../store/features/thunk/thunkApi";
import { useNavigate } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
import { WarningModal } from "../Modal/WarningModal";

import "./BusinessArgeement.css";

//@ts-ignore
pdfjs.GlobalWorkerOptions.workerSrc = window.pdfjsWorkerPath;

const header: string[] = ["DOCUMENTS NAME", "SIGNED AT"];

export type BusinessAgreementProps = {
  cb: () => void;
};

export const BusinessAgreement: FC<BusinessAgreementProps> = () => {
  const [svgSignature, setSvgSignature] = useState<Record<string, any> | null>(
    null,
  );
  const [pages, setPages] = useState<number[]>([]);
  const [isPdfLoading, setIsPdfLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dispatchThunk = useAppThunkDispatch();

  const formMethods = useForm();
  const { handleSubmit, watch } = formMethods;

  const [container, { width }] = useElementSize();
  const {
    agreement,
    signedAgreements,
    signedAgreementPreview,
    companyDetails,
    isNewUser,
  } = useAppSelector(({ serviceAgreement, company }) => {
    return { ...serviceAgreement, ...company };
  });

  // @ts-ignore
  const signature = svgSignature?.[agreement?.widgets[5].id]?.png;
  const agreeCheckbox = watch("agree_checkbox");
  const typeSignature = watch("typeSignature");

  const calculateSvgSize = (base64String: any) => {
    const padding = base64String.endsWith("==")
      ? 2
      : base64String.endsWith("=")
        ? 1
        : 0;
    const fileSizeInBytes = (base64String.length / 4) * 3 - padding;
    return fileSizeInBytes / 1024;
  };

  const isValidSignature = () => {
    const fileSize = calculateSvgSize(signature);
    const minFileSize = 1;
    return fileSize > minFileSize;
  };

  useEffect(() => {
    dispatchThunk(getAgreementThunk());
    dispatchThunk(getSignedAgreementsThunk());
    return () => {
      dispatch(setAgreement(null));
    };
  }, [dispatchThunk, dispatch]);

  useEffect(() => {
    const ele = document.getElementById("signature-wrapper");

    if (ele) {
      if (svgSignature && isValidSignature()) {
        ele.style.borderColor = "black";
      } else {
        ele.style.borderColor = "red";
      }
    }
  }, [svgSignature]);

  useEffect(() => {
    if (agreeCheckbox && svgSignature && isValidSignature()) {
      setIsSubmit(true);
    } else if (agreeCheckbox && typeSignature?.length) {
      setIsSubmit(true);
    } else {
      setIsSubmit(false);
    }
  }, [agreeCheckbox, svgSignature, typeSignature]);

  const onPdfLoadSuccess = ({ numPages }: any) => {
    for (let i = 1; i <= numPages; i++) {
      setPages((prev) => [...prev, i]);
    }
    setIsPdfLoading(false);
  };

  const onSubmit = async (formData: any) => {
    let data: any;
    setIsLoading(true);
    data = {
      agreementId: (agreement?.widgets[0] as DocumentWidgetType).config
        ?.agreementId,
      get_a_copy_of_signed_agreement: formData.get_a_copy_of_signed_agreement,
      widgets: {
        ...formData.widgets,
        //@ts-ignore
        [agreement?.widgets[3].id]: {
          agree_checkbox: formData.agree_checkbox,
        },
      },
    };
    if (svgSignature && isValidSignature()) {
      data = {
        ...data,
        widgets: {
          ...svgSignature,
          ...data.widgets,
        },
      };
    } else if (formData.typeSignature && !svgSignature) {
      data = {
        ...data,
        widgets: {
          //@ts-ignore
          [agreement?.widgets[5].id]: {
            text: formData.typeSignature,
            type: "text",
          },
          ...data.widgets,
        },
      };
    } else {
      const ele = document.getElementById("signature-wrapper");
      ele!.style.borderColor = "red";
      setIsLoading(false);
      return;
    }

    const res = await dispatchThunk(signAgreementThunk({ data }));

    if (!signedAgreements.length && res.payload.status === "success") {
      isNewUser
        ? navigate("/location/newLocation")
        : await dispatchThunk(getSignedAgreementsThunk());
    }

    await dispatchThunk(getCompanyDetailsThunk());
    setIsLoading(false);
  };

  const getPage = useCallback(
    function PageRenderer() {
      return pages.length && !isPdfLoading ? (
        pages.map((pageNumber) => (
          <Page
            key={`page_${pageNumber}`}
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            renderInteractiveForms={false}
            scale={1.45}
          />
        ))
      ) : (
        <></>
      );
    },
    [agreement, isPdfLoading, pages],
  );

  return (
    <Box paddingTop={"30px"}>
      {signedAgreementPreview && !companyDetails?.unsignedDocuments && (
        <div
          onClick={() => dispatch(setPreview(null))}
          style={{
            position: "absolute",
            width: "100%",
            backgroundColor: "rgba(95, 83, 83, 0.5)",
            height: "100%",
            zIndex: "9999",
            top: "0",
            left: "0",
          }}
        >
          <iframe
            title={"preview"}
            style={{
              position: "absolute",
              width: "100%",
              maxWidth: "1200px",
              boxSizing: "border-box",
              backgroundColor: "transparent",
              padding: "20px 0",
              border: "none",
              height: "100%",
              zIndex: "9999",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            src={`data:${signedAgreementPreview.mimetype};base64,${signedAgreementPreview.binary}`}
          />
        </div>
      )}
      {!companyDetails?.unsignedDocuments && (
        <>
          <Typography
            variant={"h5"}
            sx={{ fontWeight: "bold" }}
            textAlign={"start"}
            paddingBottom={2}
          >
            Signed agreements
          </Typography>
          <TableComponent
            type={"signedAgreements"}
            headers={header}
            isEditDelete={false}
            rows={signedAgreements}
            onRowClick={(id) => {
              dispatchThunk(
                getSignedAgreementThunk({ id: `${id}`, isPreview: true }),
              );
            }}
          />
        </>
      )}
      {agreement && companyDetails?.unsignedDocuments ? (
        <Box sx={{ textAlign: "start", paddingTop: "40px", maxHeight: 800 }}>
          <Box>
            <Box sx={{ textAlign: "start", overflow: "scroll" }}>
              <Typography
                component={"span"}
                variant={"h5"}
                sx={{ fontWeight: "bold" }}
              >
                {
                  //@ts-ignore
                  (agreement.widgets[0] as DocumentWidgetType).config.title
                }
              </Typography>
              <Document
                renderMode={"canvas"}
                onLoadProgress={() => {
                  setIsPdfLoading(true);
                }}
                file={`data:application/pdf;base64,${
                  //@ts-ignore
                  (agreement.widgets[0] as DocumentWidgetType).config.binary
                }`}
                onLoadSuccess={onPdfLoadSuccess}
                onLoadError={(e) => console.log("onLoadError", e)}
              >
                {getPage()}
              </Document>
            </Box>
            <Box
              display={"flex"}
              paddingBottom={"60px"}
              maxWidth={"1200px"}
              justifyContent={"space-between"}
              alignItems={"flex-end"}
            >
              <Box
                ref={container}
                width={"100%"}
                maxWidth={"900px"}
                textAlign={"start"}
                paddingTop={"50px"}
                position={"relative"}
              >
                <FormProvider {...formMethods}>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    className={"aggrForm"}
                  >
                    {!isPdfLoading && (
                      <>
                        <div className="pdfForm">
                          {getContentWidgets(
                            [
                              agreement.widgets[2],
                              agreement.widgets[4],
                              agreement.widgets[3],
                              agreement.widgets[5],
                            ],
                            false,
                            false,
                            width,
                            setSvgSignature,
                          )}
                        </div>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          maxWidth={"900px"}
                        >
                          <WarningModal
                            openBtnType={"cancel"}
                            submitBtnTitle={"Submit"}
                            closeBtnTitle={"Close"}
                            message={
                              "Can you verify that you wish to retract your decision to sign the agreement?"
                            }
                            onSubmit={() => navigate(0)}
                          />
                          <LoadingButton
                            type={"submit"}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSubmit(onSubmit);
                            }}
                            disabled={!isSubmit}
                            variant={"contained"}
                            fullWidth={false}
                            color={"success"}
                            size={"large"}
                            loading={isLoading}
                          >
                            Submit
                          </LoadingButton>
                        </Box>
                      </>
                    )}
                  </form>
                </FormProvider>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};
