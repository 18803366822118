import { FC } from "react";

import { Box, Button, Modal, Typography } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "8px",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "40px 24px",
};

export type DropboxWarningModalProps = {
  isDropboxModalOpen: boolean;
  setIsDropboxModalOpen: (value: boolean) => void;
  setIsManageConnectionModalOpen: (value: boolean) => void;
};

export const DropboxWarningModal: FC<DropboxWarningModalProps> = ({
  isDropboxModalOpen,
  setIsDropboxModalOpen,
  setIsManageConnectionModalOpen,
}) => {
  const handleModalClose = () => {
    setIsDropboxModalOpen(false);
  };

  const handleSubmit = () => {
    handleModalClose();
    setIsManageConnectionModalOpen(true);
  };

  return (
    <Modal
      open={isDropboxModalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ width: "100%", marginBottom: "24px" }}>
            <Typography
              component={"span"}
              variant={"h6"}
              sx={{ fontWeight: "bold" }}
            >
              Dropbox Integration Warning
            </Typography>
          </Box>
          <Box sx={{ marginBottom: "24px" }}>
            <Typography variant={"subtitle2"}>
              You have attempted to perform an action that requires Dropbox
              integration, but Dropbox integration is currently not enabled in
              the workflow. <br /> To enable Dropbox integration, please follow
              these steps:
              <ol style={{ marginLeft: "20px", listStyleType: "decimal" }}>
                <li>Click the 'Manage' button.</li>
                <li>
                  In the 'Dropbox Connection Management' modal, click 'Connect'
                  to establish a connection.
                </li>
                <li>
                  Once connected, you can enable Dropbox integration for the
                  desired workflow actions.
                </li>
              </ol>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "12px",
            }}
          >
            <Button
              variant={"outlined"}
              color={"inherit"}
              onClick={handleModalClose}
              sx={{ textTransform: "capitalize", padding: "7px 17px" }}
            >
              Cancel
            </Button>
            <Button
              variant={"contained"}
              color={"error"}
              onClick={handleSubmit}
            >
              Manage
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
