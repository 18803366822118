import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AlertColor } from "@mui/material/Alert";

// export type AlertColor = 'success' | 'info' | 'warning' | 'error';

type NotificationStateType = {
  isOpen: boolean;
  severity: AlertColor | undefined;
  message: string | null | undefined;
};

const initialState: NotificationStateType = {
  isOpen: false,
  severity: undefined,
  message: "",
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    showSuccessNotification: (
      state,
      { payload }: PayloadAction<{ message: string | null | undefined }>,
    ) => {
      state.isOpen = true;
      state.severity = "success";
      state.message = payload.message;
    },
    showErrorNotification: (
      state,
      { payload }: PayloadAction<{ message: string | null | undefined }>,
    ) => {
      state.isOpen = true;
      state.severity = "error";
      state.message = payload.message;
    },
    showWarningNotification: (
      state,
      { payload }: PayloadAction<{ message: string | null | undefined }>,
    ) => {
      state.isOpen = true;
      state.severity = "warning";
      state.message = payload.message;
    },
    showInfoNotification: (
      state,
      { payload }: PayloadAction<{ message: string | null | undefined }>,
    ) => {
      state.isOpen = true;
      state.severity = "info";
      state.message = payload.message;
    },
    closeNotification: (state) => {
      state.isOpen = false;
      state.severity = undefined;
      state.message = "";
    },
  },
});

export const {
  showSuccessNotification,
  showErrorNotification,
  showWarningNotification,
  showInfoNotification,
  closeNotification,
} = notificationSlice.actions;
