type Props = {
  tier: string;
  description: string;
  price: string;
};
const PriceTier = ({ tier, description, price }: Props) => {
  return (
    <div className="xs:items-center flex w-full border-t border-seconary-400 text-center">
      <p className="xs:text-sm xs:py-3.5 basis-1/4 bg-seconary-100 font-poppins font-semibold text-primary-700 sm:py-5 sm:text-xl">
        {tier}
      </p>
      <p
        className="xs:text-sm sm:text-md xs:py-2 xs:pl-5 al xs:leading-4 basis-1/2 text-start sm:py-5 sm:pl-10 sm:leading-none"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <p className="xs:text-sm xs:py-3.5 basis-1/4 bg-seconary-100 font-poppins font-semibold text-primary-700 sm:py-5 sm:text-xl">
        {price}
      </p>
    </div>
  );
};
export default PriceTier;
