import { SelectOptionList } from "components/widgets/FormWidgets/SelectWidget";
import { DocumentType } from "../store/features/document/documentSlice";

function fromDocumentTypesToSelectOptionList(
  documents: DocumentType[] | [],
): SelectOptionList[] {
  const selectOptionList =
    documents.map((document) => ({
      label: document.name,
      value: document.id,
    })) ?? [];
  return selectOptionList;
}

export function getDocumentOptionsListAndSelectedDocuments(
  documents: DocumentType[] | [],
  workflowDocuments: any | undefined,
): [SelectOptionList[], DocumentType[]] {
  const isDocuments = documents?.length;
  const isWorkflowDocuments =
    typeof workflowDocuments !== "undefined" && workflowDocuments;

  let documentsOptionList: SelectOptionList[] = [];
  let selectedDocuments: DocumentType[] = [];

  if (isDocuments && isWorkflowDocuments) {
    selectedDocuments =
      documents.filter(({ id }) =>
        Object.keys(workflowDocuments).includes(`${id}`),
      ) ?? [];
    documentsOptionList = fromDocumentTypesToSelectOptionList(
      documents.filter(
        ({ id }) => !Object.keys(workflowDocuments).includes(`${id}`),
      ),
    );
  }

  if (!isWorkflowDocuments && isDocuments) {
    documentsOptionList = fromDocumentTypesToSelectOptionList(documents);
  }

  return [documentsOptionList, selectedDocuments];
}
