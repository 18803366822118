import { ChangeEvent, FC, useEffect, useRef, useState } from "react";

import SignatureCanvas from "react-signature-canvas";

import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";

import { InputWidget } from "../../FormWidgets/InputWidget";
import { SignatureWidgetProps } from "./SignatureWidget.types";
import { useFormContext } from "react-hook-form";
import useElementSize from "../../../../hooks/useElementSize";

export const SignatureWidget: FC<SignatureWidgetProps> = ({
  setSvgSignature,
  id,
}) => {
  const { unregister } = useFormContext();
  const [isFingerNail, setIsFingerNail] = useState(true);

  const [container, { width, height }] = useElementSize();

  const canvasRef = useRef<SignatureCanvas | null>(null);

  useEffect(() => {
    if (!isFingerNail && canvasRef.current) {
      canvasRef.current?.clear();
      setSvgSignature && setSvgSignature(null);
    }
  }, [isFingerNail]);

  const clear = () => {
    if (canvasRef.current && setSvgSignature) {
      canvasRef.current.clear();
      setSvgSignature && setSvgSignature(null);
    }
  };

  const save = () => {
    const canvas = canvasRef;
    if (canvas && !canvas?.current?.isEmpty()) {
      setSvgSignature &&
        setSvgSignature({
          [id]: {
            png: canvasRef.current
              ?.getTrimmedCanvas()
              ?.toDataURL("image/svg+xml", 1),
            type: "fingernail",
          },
        });

      const imageUrl = canvasRef.current
        ?.getTrimmedCanvas()
        ?.toDataURL("image/svg+xml", 1);
    } else {
      alert("Please provide a signature first.");
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsFingerNail(event.target.checked);
    if (!isFingerNail) {
      unregister("typeSignature");
    }
  };

  return (
    <Box>
      <FormControlLabel
        control={
          <Box marginLeft={"10px"} display={"flex"} alignItems={"baseline"}>
            <Typography variant="body1">Type signature</Typography>
            <Switch checked={isFingerNail} onChange={handleChange} />
            <Typography variant="body1">Draw signature</Typography>
          </Box>
        }
        label={""}
      />

      {!isFingerNail && (
        <Box>
          <InputWidget
            type={"text"}
            fieldId={"typeSignature"}
            required={true}
          />
        </Box>
      )}
      <Box
        id={"signature-wrapper"}
        border={`1px solid black`}
        visibility={`${!isFingerNail ? "hidden" : "visible"}`}
        borderRadius={"5px"}
        width={"600px"}
        height={"165px"}
        overflow={"hidden"}
        ref={container}
      >
        <SignatureCanvas
          ref={canvasRef}
          canvasProps={{
            width,
            height,
          }}
          onEnd={save}
        />
      </Box>

      {isFingerNail && (
        <Button type={"button"} onClick={clear}>
          Clear
        </Button>
      )}
    </Box>
  );
};
