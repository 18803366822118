import { FC } from "react";
import { Editor } from "@tiptap/core";
import { MenuUndoButton } from "./MenuUndoButton";
import { MenuRedoButton } from "./MenuRedoButton";

interface MenuHistoryButtonsProps {
  editor: Editor;
}

export const MenuHistoryButtons: FC<MenuHistoryButtonsProps> = ({ editor }) => {
  return (
    <div style={{ display: "flex" }}>
      <MenuUndoButton editor={editor} />
      <MenuRedoButton editor={editor} />
    </div>
  );
};
