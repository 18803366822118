import { createSlice } from "@reduxjs/toolkit/";
import { PayloadAction } from "@reduxjs/toolkit";

export type PaymentMethod = {
  id: number;
  name: string;
  clientData: ClientData;
  isDefault: boolean; // 0 or 1 (boolean)
  createdAt: string | null;
  updatedAt: string | null;
  deletedAt?: string | null;
};

export type ClientData = {
  city: string;
  address: string;
  country: string;
  fullName: string;
  postCode: string;
  cardNumberLastFour: string;
};

export type PaymentMethodStateType = {
  paymentMethods: PaymentMethod[];
};

const initialState: PaymentMethodStateType = {
  paymentMethods: [],
};

export const paymentSlice = createSlice({
  name: "paymentSlice",
  initialState,
  reducers: {
    setPaymentMethods: (state, { payload }: PayloadAction<PaymentMethod[]>) => {
      state.paymentMethods = payload;
    },
  },
});

export const { setPaymentMethods } = paymentSlice.actions;
