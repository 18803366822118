import { createAsyncThunk } from "@reduxjs/toolkit";

import { showErrorNotification } from "../../notification/notificationSlice";

import { FieldValues } from "react-hook-form";

import {
  getAgreement,
  getSignedAgreement,
  getSignedAgreements,
  signAgreement,
} from "../../api/serviceAgreementApi/serviceAgreementApi";
import {
  setAgreement,
  setPreview,
  setSignedAgreement,
  setSignedAgreements,
} from "../../serviceAgreement.ts/serviceAgreementSlice";

export const getAgreementThunk = createAsyncThunk(
  "thunkSlice/getAgreementThunk",
  async (_, { dispatch }) => {
    try {
      const agreement = await dispatch(getAgreement.initiate({})).unwrap();
      dispatch(setAgreement(agreement));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const getSignedAgreementThunk = createAsyncThunk(
  "thunkSlice/getSignedAgreementThunk",
  async (
    { id, isPreview = false }: { id: string; isPreview?: boolean },
    { dispatch },
  ) => {
    try {
      const signedAgreement = await dispatch(
        getSignedAgreement.initiate({ id }),
      ).unwrap();
      !isPreview
        ? dispatch(setSignedAgreement(signedAgreement))
        : dispatch(setPreview(signedAgreement));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const getSignedAgreementsThunk = createAsyncThunk(
  "thunkSlice/getSignedAgreementsThunk",
  async (_, { dispatch }) => {
    try {
      const signedAgreements = await dispatch(
        getSignedAgreements.initiate({}),
      ).unwrap();
      dispatch(setSignedAgreements(signedAgreements));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const signAgreementThunk = createAsyncThunk(
  "thunkSlice/signAgreementThunk",
  async (data: FieldValues, { dispatch }) => {
    try {
      return await dispatch(signAgreement.initiate(data)).unwrap();
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);
