import { nanoid } from "nanoid";
// @ts-ignore
import { Editor } from "@tiptap/react";

export class TipTapEditorService {
  protected editorsMap: { [key: string]: Editor } = {};

  registerEditor = (key: string, editor: Editor) => {
    console.log(`Editor registered #${key}`);
    this.editorsMap[key] = editor;
    return editor;
  };

  generateKey = () => nanoid(10);

  destroyEditor = (editorKey: string) => {
    this.editorsMap[editorKey].destroy();
    console.log(`editor destroyed #${editorKey}`);
  };

  get = (key: string): Editor => this.editorsMap[key];
}

const tipTapEditorService = new TipTapEditorService();
export default tipTapEditorService;
