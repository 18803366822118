import { mergeAttributes } from "@tiptap/core";
import { TaskList as TipTapTaskList, TaskListOptions } from "@tiptap/extension-task-list";
import todoListStyles from "!raw-loader!../styles/TodoList.scss";
import editorStyles from "!raw-loader!../styles/Editor.scss";
import indexCss from "!raw-loader!../../index.css";
import variables from "!raw-loader!../styles/variables.scss";

import { compileScssStringToCss, removeSassImports } from "helper/sassHelper";

export const TaskList = TipTapTaskList.extend<TaskListOptions>({
  name: "taskList",

  renderHTML({ HTMLAttributes }) {
    const cleanedStyles = [
      removeSassImports(editorStyles),
      removeSassImports(todoListStyles),
    ];
    const fullScss = variables + " " + cleanedStyles.join(" ");
    const fullCss = indexCss + compileScssStringToCss(fullScss);

    return [
      "ul",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        "data-type": this.name
      }),
      ["style", {}, fullCss],
      ["div", 0]
    ];
  }
});
