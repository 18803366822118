import { FC } from "react";
import { Editor } from "@tiptap/core";
import { TipTapEditorContext } from "../../../types";
import { MenuButton } from "../../MenuButton";

interface MenuTextAlignJustifyButtonProps {
  editor: Editor;
  editorContext: TipTapEditorContext;
}

export const MenuTextAlignJustifyButton: FC<
  MenuTextAlignJustifyButtonProps
> = ({ editor, editorContext }) => {
  const isActive = editorContext.state.isActiveTextAlignJustify;

  const runCommand = () => {
    editor.chain().focus().setTextAlign("justify").run();
  };

  return (
    <MenuButton
      icon="textAlignJustify"
      title="Align Justify"
      command={runCommand}
      isActive={isActive}
    />
  );
};
