import React, { createContext, useEffect, useState } from "react";

type LoaderContextType = {
  isLoading: boolean;
};

type LoaderContextProviderProps = {
  isLoading: boolean;
  children: React.ReactNode;
};

export const LoaderContext = createContext<LoaderContextType>({
  isLoading: false,
});

export const LoaderContextProvider = ({
  children,
  isLoading,
}: LoaderContextProviderProps) => {
  // const [isLoader, setIsLoader] = useState(false);
  //
  //   useEffect(() => {
  //     setIsLoader(value)
  //   }, [value]);

  return (
    <LoaderContext.Provider value={{ isLoading }}>
      {children}
    </LoaderContext.Provider>
  );
};
