import {
  DefaultFormWidgetProps,
  FieldValue,
  FormWidgetProps,
} from "../../components/widgets/@types/Widget";
import { InputFieldTypes } from "../../components/widgets/FormWidgets/InputWidget";

export const getCustomValidator = ({
  field,
}: {
  field: DefaultFormWidgetProps;
}) => {
  return (value: FieldValue): string | undefined => {
    const validationMessage = field.custom_validator?.message;

    if (!value) {
      return field.required
        ? validationMessage || "The field is required"
        : undefined;
    }

    if (typeof value === "string") {
      let regex: RegExp | undefined;
      if (typeof field.custom_validator?.value === "string") {
        regex = new RegExp(
          field.custom_validator?.value,
          field.custom_validator?.flags || "i",
        );
      } else if (field.custom_validator?.value instanceof RegExp) {
        regex = field.custom_validator?.value;
      } else {
        regex = undefined;
      }

      if (regex) {
        return !regex.test(value) ? validationMessage : undefined;
      }
    }

    if (typeof field.custom_validator?.value === "function") {
      return !field.custom_validator?.value(value)
        ? validationMessage
        : undefined;
    }

    return undefined;
  };
};

export const getValidationRule = (field: FormWidgetProps) => {
  let defaultRule = {
    required:
      field.required && (field.validation_message || "The field is required"),
    maxLength: {
      value: field.maxlength || 50,
      message: `This field should not contain more than ${
        field.maxlength || 50
      } characters`,
    },
    // minLength: {
    //   value: 4,
    //   message: `This field should not contain mor`
    // }
  };

  const emailRule = {
    pattern: {
      value: /^[a-zA-Z0-9+]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9.]+\.[a-zA-Z]{2,4}$/,
      message: "Please, enter a valid email",
    },
  };
  
  const validatePassword = (value: string): string | boolean => {
    if (!/(?=.*[a-z])/.test(value)) {
      return "Password must contain at least one lowercase letter.";
    }
    if (!/(?=.*[A-Z])/.test(value)) {
      return "Password must contain at least one uppercase letter.";
    }
    if (!/(?=.*\d)/.test(value)) {
      return "Password must contain at least one number.";
    }
    if (!/(?=.*[@$!%*?&])/.test(value)) {
      return "Password must contain at least one special character.";
    }
    if (!/^.{8,}$/.test(value)) {
      return "Password must be at least 8 characters long.";
    }
    return true;
  };

  if (field.type === InputFieldTypes.EMAIL) {
    defaultRule = { ...defaultRule, ...emailRule };
  }

  if (field.type === InputFieldTypes.PASSWORD) {
    // @ts-ignore
    defaultRule = { validate: validatePassword, ...defaultRule };
  }

  if (field.type === InputFieldTypes.PHONE) {
    // @ts-ignore
    defaultRule = { validate: validatePhone, ...defaultRule };
  }

  if (field.custom_validator) {
    const customPattern = {
      validate: getCustomValidator({ field }),
    };

    defaultRule = { ...defaultRule, ...customPattern };
  }

  return defaultRule;
};

const validatePhone = (value: string): string | true => {
  if (value.length !== 10) return "10-digit number required.";
  return true;
};
