import Twig, { extendFilter } from "twig";
import { FieldValues } from "react-hook-form";
import { FormWidgetProps } from "../../@types/Widget";

export const twigParser = (
  html: string,
  formData: FieldValues,
): { html: string; widgetsProps: Record<string, FormWidgetProps> | null } => {
  const widgetsProps: Record<string, FormWidgetProps> = {};

  Twig.extend(function () {
    extendFilter("field", (value, config) => {
      let widgetProps = null;

      if (config && config.length) {
        widgetProps = config[0] as FormWidgetProps;
        widgetsProps[widgetProps.fieldId] = widgetProps;
      }

      return value
        ? value
        : widgetProps && `<div id="${widgetProps.fieldId}"></div>`;
    });
  });

  const template = Twig.twig({
    data: html,
  });

  return { html: template.render({ ...formData }), widgetsProps };
};
