import { createSlice } from "@reduxjs/toolkit/";
import { PayloadAction } from "@reduxjs/toolkit";

export type Relationship = Client & {
  relationshipName: string;
};

export interface RecentDocument {
  documentTemplate: string;
  crmDocumentId: string;
  createdAt: string;
  locationId: number;
}

export type Client = {
  uuid: string;
  firstName: string;
  lastName: string;
  documentsCount: string;
  recentLocation: string;
  email: string;
  gender?: any;
  dob?: any;
  phone: string;
  address: string;
  city: string;
  region: string;
  country?: any;
  postalCode: string;
  referredBy: string;
  homePhone?: any;
  workPhone?: any;
  emergencyName?: any;
  status: number;
  createdAt: Date;
  updatedAt: Date;
  relationships?: Relationship[];
  recentDocuments?: RecentDocument[];
};

export type ClientStateType = {
  clients: Client[];
  client: Client | null;
  clientDocuments: any | [];
  clientDocument: any | null;
};

const initialState: ClientStateType = {
  clients: [],
  client: null,
  clientDocuments: [],
  clientDocument: null,
};

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setClients: (
      state,
      { payload }: PayloadAction<Client[] | { success: boolean }>,
    ) => {
      if (!Array.isArray(payload) && payload?.success) return;
      state.clients = Array.isArray(payload)
        ? payload.map((client: any) => {
            return {
              firstName: client.firstName,
              lastName: client.lastName,
              createdAt: client.createdAt,
              recentLocation: client.recentLocation || "-",
              documentsCount: client.documentsCount.toString(),
              uuid: client.uuid,
              email: client.email,
              gender: client.gender,
              dob: client.dob,
              phone: client.phone,
              address: client.address,
              city: client.city,
              region: client.region,
              country: client.country,
              postalCode: client.postalCode,
              referredBy: client.referredBy,
              homePhone: client.homePhone,
              workPhone: client.workPhone,
              emergencyName: client.emergencyName,
              status: client.status,
              updatedAt: client.updatedAt,
              relationships: client.relationships ? client.relationships : [],
              recentDocuments: client.recentDocuments
                ? client.recentDocuments
                : [],
            };
          })
        : [];
    },
    setClient: (state, { payload }: PayloadAction<Client | null>) => {
      state.client = payload;
    },
    setClientDocument: (state, { payload }: PayloadAction<any>) => {
      state.clientDocument = payload;
    },
    setClientDocuments: (state, { payload }: PayloadAction<any>) => {
      state.clientDocuments = payload
        ? Array.isArray(payload)
          ? payload.map((document: any) => {
              if (document.length) return {};
              return {
                name: document.name,
                createdAt: document.createdAt,
                location: "sdfgsdfsdf",
                uuid: document.uuid,
              };
            })
          : [payload].map((document: any) => {
              if (document.length) return {};
              return {
                name: document.name,
                createdAt: document.createdAt,
                location: "sdfgsdfsdf",
                uuid: document.uuid,
              };
            })
        : [];
    },
  },
});

export const { setClients, setClient, setClientDocuments, setClientDocument } =
  clientSlice.actions;
