// MenuUnderlineButton.tsx
import React from "react";
import { MenuButton } from "../MenuButton";
import { Editor } from "@tiptap/core";
import { TipTapEditorContext } from "../../types";

interface MenuUnderlineButtonProps {
  editor: Editor;
  editorContext: TipTapEditorContext;
}

export const MenuUnderlineButton: React.FC<MenuUnderlineButtonProps> = ({
  editor,
  editorContext,
}) => {
  const isActive = editorContext.state.isActiveUnderline;

  const runCommand = () => {
    editor.chain().focus().toggleUnderline().run();
  };

  return (
    <MenuButton
      icon="underline"
      title="Underline"
      command={runCommand}
      isActive={isActive}
    />
  );
};
