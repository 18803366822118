import {
  CheckboxElement,
  TextareaAutosizeElement,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import { FC, RefObject, useEffect, useMemo, useRef, useState } from "react";
import { clsx } from "clsx";
import { useLocation } from "react-router-dom";
import Heading from "../../shared/Heading";
import useMediaQuery from "../../hooks/useMediaQuery";

const inputStyle = {
  "& label.Mui-focused": {
    color: "#ED9645",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ED9645",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: "#ED9645",
  },
  "& .MuiOutlinedInput-root.Mui-focused fieldset": {
    borderColor: "#ED9645",
  },
  "&:hover .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottomColor: "#A29B9C",
  },
};

const inputLabelStyle = {
  style: { fontSize: "16px", lineHeight: "20px" },
};

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

interface ContactUsProps {
  contactUsRef: RefObject<HTMLDivElement>;
}

const ContactUs: FC<ContactUsProps> = ({ contactUsRef }) => {
  const [showBlock, setShowBlock] = useState(false);
  const [hideForm, setHideForm] = useState(false);
  let interval: ReturnType<typeof setInterval> | undefined = undefined;

  useEffect(() => {
    // @ts-ignore
    if (typeof Cookiebot !== "undefined") {
      interval = setInterval(() => {
        //@ts-ignore
        setShowBlock(!Cookiebot.consent.marketing);
        //@ts-ignore
        setHideForm(Cookiebot.consent.marketing);
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  const [isReloaded, setIsReloaded] = useState(true);

  const form = useForm({
    defaultValues: {
      xnQsjsdp:
        "7379f0835aee7a3e7539045fc17b74c068b9141e412a4a096d2dd076ede2f12e",
      zc_gad: "",
      xmIwtLD:
        "ad354598009e7998a241e418612bc1c54d5d4e8bb1e0dd77ba858ca2259a2c8b",
      actionType: "TGVhZHM=",
      returnURL: "https://www.waiverking.com/?contact-us=success",
      first_name: "",
      lastName: "",
      email: "",
      company: "",
      industry: "",
      website: "",
      Phone: "",
      country: "",
      enterdigest: "",
      emailOptOut: "",
      leadSource: "",
      yourMessage: "",
    },
  });

  const query = useQuery();
  const myRef = useRef<HTMLDivElement | null>(null);
  const executeScroll = () => {
    myRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  useEffect(() => {
    if (query.get("contact-us") === "success") {
      executeScroll();
    }
  }, [query]);

  const { control } = form;
  const isUnderSmallScreen = useMediaQuery("(min-width: 744px");

  const reloadImg = () => {
    setIsReloaded(false);
    setTimeout(() => {
      setIsReloaded(true);
    }, 100);
  };

  return (
    <section id="contactus" ref={myRef}>
      <div
        ref={contactUsRef}
        className="bg-seconary-100 xs:px-[24px] xs:pb-[70px] xs:pt-[10px] sm:px-[66px] sm:pb-[100px] sm:pt-[30px] md:px-[139px] lg:px-[153px] lg:py-[100px]"
      >
        <Heading>Contact us</Heading>
        <div className="flex gap-7 xs:flex-col lg:flex-row">
          <div className="xs:mt-[-30px] xs:px-0 sm:h-full sm:rounded-[10px] sm:border sm:border-primary-300 sm:bg-seconary-050 sm:px-[30px] sm:py-[40px] md:px-[97px] lg:w-[746px]">
            <h3 className="text-center font-poppins font-semibold text-primary-700 xs:text-base sm:text-xl">
              Chat to our team
            </h3>
            {showBlock && (
              <div className="allow-cookie-box">
                <div style={{ width: "250px", height: "250px" }}></div>
                <div className="allow-cookie-box-text-block">
                  <div style={{ textAlign: "center" }}>
                    <a
                      style={{ textDecoration: "underline" }}
                      href="javascript: Cookiebot.renew()"
                    >
                      To contact us, kindly adjust your cookie consent here.
                    </a>
                  </div>
                </div>
                <div style={{ width: "250px", height: "250px" }}></div>
              </div>
            )}
            {query.get("contact-us") === "success" && (
              <div className="mt-4 rounded-[10px] border bg-primary-200 p-2 text-center text-primary-010">
                <h4>
                  Thank you for contacting us. We will get back to you shortly.
                </h4>
              </div>
            )}
            {hideForm && (
              <form
                className="contact-form"
                action="https://crm.zoho.com/crm/WebToLeadForm"
                name="WebToLeads979544000001317011"
                method="POST"
                acceptCharset="UTF-8"
              >
                {/*<FormContainer {...form} FormProps={{action: 'https://crm.zoho.com/crm/WebToLeadForm', name: 'WebToLeads979544000001317011', method:'POST', acceptCharset: 'UTF-8'}}  onSuccess={checkMandatory}>*/}
                <TextFieldElement
                  control={control}
                  className="hidden"
                  type="hidden"
                  variant="standard"
                  name="xnQsjsdp"
                />
                <TextFieldElement
                  control={control}
                  className="hidden"
                  type="hidden"
                  variant="standard"
                  name="returnURL"
                />
                {/*<input type='text' style={{'display':'none'}} name='returnURL' value='https&#x3a;&#x2f;&#x2f;www.waiverking.com&#x2f;contact-us&#x3f;success'/>*/}
                <TextFieldElement
                  control={control}
                  className="hidden"
                  type="hidden"
                  name="zc_gad"
                  id="zc_gad"
                  variant="standard"
                />
                <TextFieldElement
                  control={control}
                  className="hidden"
                  type="hidden"
                  variant="standard"
                  name="xmIwtLD"
                  // value='ad354598009e7998a241e418612bc1c54d5d4e8bb1e0dd77ba858ca2259a2c8b'
                />
                <TextFieldElement
                  control={control}
                  className="hidden"
                  type="hidden"
                  variant="standard"
                  name="actionType"
                  // value='TGVhZHM='
                />
                <div
                  className={clsx("flex flex-wrap justify-between gap-5 pt-7")}
                >
                  <TextFieldElement
                    control={control}
                    className="xs:w-full sm:w-[48%]"
                    InputLabelProps={inputLabelStyle}
                    sx={inputStyle}
                    type="text"
                    variant="standard"
                    name="first_name"
                    label="First Name"
                    required
                  />
                  <TextFieldElement
                    control={control}
                    className="xs:w-full sm:w-[48%]"
                    InputLabelProps={inputLabelStyle}
                    sx={inputStyle}
                    type="text"
                    variant="standard"
                    name="lastName"
                    label="Last Name"
                    required
                  />
                  <TextFieldElement
                    control={control}
                    className="xs:w-full sm:w-[48%]"
                    InputLabelProps={inputLabelStyle}
                    sx={inputStyle}
                    type="email"
                    variant="standard"
                    name="email"
                    label="Email"
                    required
                  />
                  <TextFieldElement
                    control={control}
                    className="xs:w-full sm:w-[48%]"
                    InputLabelProps={inputLabelStyle}
                    sx={inputStyle}
                    type="text"
                    variant="standard"
                    name="company"
                    label="Company"
                    required
                  />
                  <CheckboxElement
                    control={control}
                    name="emailOptOut"
                    label={
                      <span style={{ fontSize: "14px" }}>Email Opt Out</span>
                    }
                    required
                    size="small"
                    sx={{
                      "&.Mui-checked .MuiSvgIcon-root": {
                        color: "#ED9645",
                      },
                    }}
                  />
                  <TextareaAutosizeElement
                    control={control}
                    InputLabelProps={inputLabelStyle}
                    sx={inputStyle}
                    fullWidth
                    variant="standard"
                    name="yourMessage"
                    label="Your Message"
                    required
                  />
                  <TextFieldElement
                    control={control}
                    id="captcha"
                    type="text"
                    name="enterdigest"
                    className="xs:w-full sm:w-[48%]"
                    InputLabelProps={inputLabelStyle}
                    sx={inputStyle}
                    variant="standard"
                    label="Captcha"
                    required
                  />
                  <div className="captcha-container flex w-2/5 flex-col items-center">
                    <div className="h-[48px] w-[140px] overflow-hidden rounded-lg border border-primary-300">
                      {isReloaded && (
                        <img
                          id="imgid"
                          src="https://crm.zoho.com/crm/CaptchaServlet?formId=ad354598009e7998a241e418612bc1c54d5d4e8bb1e0dd77ba858ca2259a2c8b&grpid=7379f0835aee7a3e7539045fc17b74c068b9141e412a4a096d2dd076ede2f12e"
                          alt="captcha"
                        ></img>
                      )}
                    </div>
                    <a href="javascript:" onClick={reloadImg}>
                      Reload
                    </a>
                  </div>
                  <button
                    type="submit"
                    className="mt-10 flex h-[42px] w-[180px] cursor-pointer items-center justify-center rounded-md bg-primary-400 font-roboto text-base text-primary-010 sm:px-5 sm:py-2"
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
          </div>
          <div className="xs:pt-[30px] sm:px-[20px] sm:py-[40px]">
            <h3 className="font-poppins font-semibold text-primary-700 xs:mb-4 xs:text-base sm:mb-8 sm:text-xl">
              Any questions?
            </h3>
            <div className="flex sm:gap-7">
              {isUnderSmallScreen && (
                <div className="text-md flex flex-col text-primary-700">
                  <p>Phone:</p>
                  <p>Email:</p>
                </div>
              )}
              <div className="flex flex-col text-primary-700 xs:text-sm sm:text-base">
                <p>+1 (888) 211-6693</p>
                <p className="flex flex-col">
                  <a
                    className="text-primary-200 underline"
                    href="mailto:contact_us@waiverking.com"
                  >
                    contact_us@waiverking.com
                  </a>
                  <a
                    className="text-primary-200 underline"
                    href="mailto:support@waiverking.com"
                  >
                    support@waiverking.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ContactUs;
