type Props = {
  icon: string;
  title: string;
  description: string;
};
const Feature = ({ icon, title, description }: Props) => {
  return (
    <div className="w-[261px]">
      <img className="mx-auto mb-7" src={icon} alt={title} />
      <h3 className="mb-6 px-1 text-center font-poppins text-lg font-semibold leading-relaxed text-primary-700">
        {title}
      </h3>
      <p
        className="text-sm"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
};
export default Feature;
