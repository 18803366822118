import { FC, MouseEvent, useState } from "react";

import { Editor } from "@tiptap/core";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { determineOriginalImgSizeAsync } from "./imageSizeDeterminator";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: "20px",
  borderRadius: "10px",
};

interface MenuUrlPromptImageButtonProps {
  editor: Editor;
}

export const MenuUrlPromptImageButton: FC<MenuUrlPromptImageButtonProps> = ({
  editor,
}) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [inputValue, setInputValue] = useState<string | undefined>("");

  const extension = editor.extensionManager.extensions.find(
    ({ name }) => name === "image",
  );
  const inputPattern = extension ? extension.options.urlPattern : /.*/;

  const handleClick = () => {
    setPopoverVisible(true);
  };

  const handleClose = () => {
    setPopoverVisible(false);
  };

  const handleInsertUrlImage = () => {
    if (inputValue) {
      openUrlPrompt(inputValue);
      setPopoverVisible(false);
    }
  };

  const openUrlPrompt = async (urlImg: string | undefined) => {
    if (urlImg && urlImg.match(inputPattern)) {
      const size = await determineOriginalImgSizeAsync(urlImg)
      editor.chain().focus().setImage({ src: urlImg, width: size.width, height: size.height } as any).run();
    }
  };
  
  return (
    <div>
      <div className="el-tiptap-popper__menu__item" onClick={handleClick}>
        <span>Insert Image by URL</span>
      </div>

      <Modal
        open={popoverVisible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="subtitle1"
            marginBottom={"10px"}
          >
            Insert image
          </Typography>
          <Box marginBottom={"30px"}>
            <TextField
              inputProps={{ pattern: inputPattern }}
              size={"small"}
              type={"text"}
              placeholder={"Url of image"}
              fullWidth
              onChange={(e: any) => setInputValue(e.target.value)}
            />
          </Box>

          <Box display={"flex"} justifyContent={"flex-end"} gap={"10px"}>
            <Button
              size={"small"}
              variant={"outlined"}
              color={"inherit"}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              size={"small"}
              variant={"contained"}
              color={"success"}
              onClick={handleInsertUrlImage}
            >
              Insert
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
