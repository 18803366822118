import { TextSelection, AllSelection, Transaction } from "prosemirror-state";
import { Node } from "prosemirror-model";
import { ChainedCommands, CommandProps } from "@tiptap/core";
import { clamp } from "./shared";

export enum IndentProps {
  min = 0,
  max = 210,

  more = 30,
  less = -30,
}

export function isBulletListNode(node: Node): boolean {
  return node.type.name === "bullet_list";
}

export function isOrderedListNode(node: Node): boolean {
  return node.type.name === "order_list";
}

export function isTodoListNode(node: Node): boolean {
  return node.type.name === "todo_list";
}

export function isListNode(node: Node): boolean {
  return (
    isBulletListNode(node) || isOrderedListNode(node) || isTodoListNode(node)
  );
}

function setNodeIndentMarkup(
  tr: Transaction,
  pos: number,
  delta: number,
): Transaction {
  if (!tr.doc) return tr;

  const node = tr.doc.nodeAt(pos);
  if (!node) return tr;

  const minIndent = IndentProps.min;
  const maxIndent = IndentProps.max;

  const indent = clamp((node.attrs.indent || 0) + delta, minIndent, maxIndent);

  if (indent === node.attrs.indent) return tr;

  const nodeAttrs = {
    ...node.attrs,
    indent,
  };

  return tr.setNodeMarkup(pos, node.type, nodeAttrs, node.marks);
}

function updateIndentLevel(
  tr: Transaction,
  delta: number,
  chain: () => ChainedCommands,
): Transaction {
  const { doc, selection } = tr;

  if (!doc || !selection) return tr;

  if (
    !(selection instanceof TextSelection || selection instanceof AllSelection)
  ) {
    return tr;
  }

  const { from, to } = selection;

  doc.nodesBetween(from, to, (node: Node, pos: number) => {
    const nodeType = node.type;

    if (nodeType.name === "paragraph" || nodeType.name === "heading") {
      if (!node.firstChild?.attrs.indentElement && delta === IndentProps.more) {
        chain().focus(from).insertIndentElement().run();

        return true;
      }

      tr = setNodeIndentMarkup(tr, pos, delta);
      return false;
    }
    return !isListNode(node);
  });

  return tr;
}

export const createIndentCommand = (delta: number) => {
  return ({ state, tr, dispatch, chain }: CommandProps) => {
    const { selection } = state;
    tr = tr.setSelection(selection);
    tr = updateIndentLevel(tr, delta, chain);

    if (tr.docChanged) {
      dispatch && dispatch(tr);
      return true;
    }

    return false;
  };
};
