import { FC, useEffect } from "react";
import parse, { HTMLReactParserOptions } from "html-react-parser";

import { DocumentWidgetProps } from "./DocumentWidget.types";
import { useAppDispatch, useAppSelector } from "../../../../store";

import { twigParser } from "./DocumentWidget.helper";
import { getFieldWidget } from "../../services/widgetsFactory";
import { setDocumentId } from "../../../../store/features/form/formSlice";

export const DocumentWidget: FC<DocumentWidgetProps> = ({
  config: { htmlSource, documentId },
}) => {
  const dispatch = useAppDispatch();

  const formData = useAppSelector(({ form }) => form.formData || {});
  const { html, widgetsProps } = twigParser(htmlSource, formData);

  useEffect(() => {
    dispatch(setDocumentId(documentId));

    return () => {
      dispatch(setDocumentId(null));
    };
  }, [documentId]);

  const options: HTMLReactParserOptions = {
    replace: (domNode: any) => {
      if (
        !!widgetsProps &&
        !!domNode &&
        domNode.attribs?.id &&
        widgetsProps[domNode.attribs.id] &&
        widgetsProps[domNode.attribs.id].fieldId === domNode.attribs.id
      ) {
        const props = { ...widgetsProps[domNode.attribs.id], fullWidth: false };
        return (
          //@ts-ignore
          <div style={{ display: "inline-block" }}>{getFieldWidget(props)}</div>
        );
      }
    },
  };

  return <div>{parse(html, options)}</div>;
};
