import { Node as ProseMirrorNode, NodeType } from "prosemirror-model";
import { Plugin, PluginKey } from "prosemirror-state";
import { nanoid } from "nanoid";

const isTargetNodeOfType = (node: ProseMirrorNode, type: any) =>
  node.type === type;
const isNodeHasAttribute = (node: ProseMirrorNode, attrName: string) =>
  Boolean(node.attrs && node.attrs[attrName]);

const attrName = "key";

export const UniqParagraphKeyPlugin = (isEditable: boolean) => {
  return new Plugin({
    key: new PluginKey("uniq-paragraph-key"),
    appendTransaction: (transactions, prevState, nextState) => {
      const tr = nextState.tr;
      if (!isEditable) return tr;

      let modified = false;
      if (transactions.some((transaction) => transaction.docChanged)) {
        // Adds a unique id to a node
        nextState.doc.descendants((node: ProseMirrorNode, pos: number) => {
          const { paragraph, heading, codeBlock } = nextState.schema.nodes as {
            [key: string]: NodeType;
          };
          if (
            (isTargetNodeOfType(node, paragraph) ||
              isTargetNodeOfType(node, heading) ||
              isTargetNodeOfType(node, codeBlock)) &&
            !isNodeHasAttribute(node, attrName)
          ) {
            const attrs = node.attrs;
            tr.setNodeMarkup(pos, undefined, {
              ...attrs,
              [attrName]: nanoid(),
            });
            modified = true;
          }
        });
      }

      return modified ? tr : null;
    },
  });
};
