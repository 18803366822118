import { Editor } from "@tiptap/core";
import { MenuButton } from "../../MenuButton";

interface MenuOpenLinkButtonProps {
  editor: Editor;
}

export const MenuOpenLinkButton = ({ editor }: MenuOpenLinkButtonProps) => {
  const openLink = () => {
    const { href } = editor.getAttributes("link");

    if (href) {
      // prevent attack
      const newTab = window.open();
      if (newTab) {
        newTab.opener = null;
        newTab.location.href = href;
      }
    }
  };

  return (
    <MenuButton command={openLink} title="Open link" icon="linkExternal" />
  );
};
