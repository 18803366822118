import React, { ChangeEvent, FC } from "react";

import { useFormContext, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { TextField } from "@mui/material";

import { TextareaFieldTypes, TextareaWidgetProps } from "./TextareaWidget.type";

import { getValidationRule } from "../../../../helper/FormValidators/formHelper";
import { ControllerRenderProps } from "../../@types/Widget";

const initialState = {
  type: TextareaFieldTypes.TEXTAREA,
  label: "FIRST",
  fieldId: "FIRST",
  required: false,
  value: "",
  rows: 4,
};

export const TextareaWidget: FC<TextareaWidgetProps> = (
  field = initialState,
) => {
  const { type, label, fieldId, required, value, rows } = field;

  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <Controller
        render={({
          field: { value, onChange },
        }: {
          field: ControllerRenderProps<string, ChangeEvent<HTMLInputElement>>;
        }) => (
          <>
            <TextField
              id={fieldId}
              rows={rows}
              type={type}
              value={value}
              error={!!errors?.[fieldId]}
              label={label}
              margin="normal"
              required={required}
              onChange={onChange}
              multiline
              fullWidth
            />
          </>
        )}
        control={control}
        defaultValue={value}
        name={fieldId}
        rules={getValidationRule(field)}
      />
      <ErrorMessage
        errors={errors}
        name={fieldId}
        render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
      />
    </>
  );
};
