import { createApi } from "@reduxjs/toolkit/query/react";
import { baseCustomFetchBase } from "../service/customFetchBase";
import { FieldValues } from "react-hook-form";

const workflows = "workflows";
const workflow = "workflow";

const workflowApi = createApi({
  reducerPath: "workflowApi",
  baseQuery: baseCustomFetchBase,
  endpoints: (builder) => ({
    getWorkflows: builder.mutation<any, string>({
      query: (searchParamsString) => ({
        url: `${workflows}${
          searchParamsString ? `?${searchParamsString}` : ""
        }`,
        method: "GET",
      }),
    }),
    getWorkflow: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `${workflow}/${id}`,
        method: "GET",
      }),
    }),
    createWorkflow: builder.mutation<any, FieldValues>({
      query: (data) => ({
        url: `${workflow}`,
        method: "POST",
        body: data,
      }),
    }),
    updateWorkflow: builder.mutation<
      any,
      { data: FieldValues; id: string; path: string }
    >({
      query: ({ data, id, path }) => ({
        url: `${workflow}/${id}${path}`,
        method: "PUT",
        body: data,
      }),
    }),
    updateWorkflowOptions: builder.mutation<
      any,
      { data: FieldValues; id: string; path: string }
    >({
      query: ({ data, id, path }) => ({
        url: `${workflow}/${id}${path}`,
        method: "PUT",
        body: data,
      }),
    }),
    deleteWorkflowExtension: builder.mutation<
      any,
      { id: string; path: string }
    >({
      query: ({ id, path }) => ({
        url: `${workflow}/${id}${path}`,
        method: "DELETE",
      }),
    }),
    deleteWorkflow: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `${workflow}/${id}`,
        method: "DELETE",
      }),
    }),
    uploadFile: builder.mutation<any, { file: string | ArrayBuffer }>({
      query: ({ file }) => ({
        url: `/upload/image`,
        method: "POST",
        body: { image: file },
      }),
    }),
    dropboxConnect: builder.mutation<any, any>({
      query: () => ({
        url: `/settings/dropbox/connect`,
        method: "GET",
      }),
    }),
    dropboxConnectConfirm: builder.mutation<any, { code?: string }>({
      query: (data) => ({
        url: `/settings/dropbox/connect`,
        method: "POST",
        body: data,
      }),
    }),
    dropboxDisconnect: builder.mutation<any, any>({
      query: () => ({
        url: `/settings/dropbox/disconnect`,
        method: "GET",
      }),
    }),
    dropboxConnectionStatus: builder.mutation<any, any>({
      query: () => ({
        url: `/settings/dropbox/status`,
        method: "GET",
      }),
    }),
    templateWorkflow: builder.mutation<any, any>({
      query: () => ({
        url: `/${workflow}-templates`,
        method: "GET",
      }),
    }),
  }),
});

export default workflowApi;
export const { useGetWorkflowMutation, useTemplateWorkflowMutation } =
  workflowApi;

export const {
  uploadFile,
  getWorkflows,
  getWorkflow,
  createWorkflow,
  updateWorkflow,
  updateWorkflowOptions,
  deleteWorkflow,
  deleteWorkflowExtension,
  dropboxConnect,
  dropboxConnectConfirm,
  dropboxDisconnect,
  dropboxConnectionStatus,
} = workflowApi.endpoints;
