import { FC, useEffect, useState } from "react";

import moment from "moment-timezone";
import { Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";

import { TimestampWidgetProps } from "./TimestampWidget.types";
import { convertServerToMomentFormat } from "helper/convertServerToMomentFormat";

export const TimestampWidget: FC<TimestampWidgetProps> = (props) => {
  const {
    id,
    config: { format },
  } = props;
  const { setValue } = useFormContext();

  const [currentTime, setCurrentTime] = useState(
    moment().format(convertServerToMomentFormat(format)),
  );

  const [UTC, setUTC] = useState(
    moment().utc().format(convertServerToMomentFormat(format)),
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format(convertServerToMomentFormat(format)));
      setUTC(moment().utc().format(convertServerToMomentFormat(format)));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [format]);

  useEffect(() => {
    setValue(`widgets[${id}][timezone]`, moment.tz.guess());
  }, []);

  return (
    <>
      <Typography
        variant="body1"
        component="div"
        style={{ fontFamily: "Times New Roman", fontSize: "18px" }}
      >
        Date and Time signed: {currentTime}
      </Typography>
      <Typography
        variant="body1"
        component="div"
        style={{ fontFamily: "Times New Roman", fontSize: "18px" }}
      >
        System Time Stamp: {UTC}
      </Typography>
    </>
  );
};
