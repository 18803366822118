import { FC, useContext, useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { FieldValues, useFormContext } from "react-hook-form";

import {
  resetPasswordThunk,
  signUpThunk,
} from "../../store/features/thunk/thunkApi";
import { AuthTypes, setAuthMethod } from "../../store/features/form/formSlice";
import {
  useAppDispatch,
  useAppSelector,
  useAppThunkDispatch,
} from "../../store";

import {
  InputWidget,
  InputWidgetProps,
} from "../widgets/FormWidgets/InputWidget";
import { LoaderContext } from "../../context/LoaderContext";
import { LoadingButton } from "@mui/lab";
import { OtpWidget } from "../OtpWidget/OtpWidget";

const signInFormData: InputWidgetProps[] = [
  {
    type: "email",
    // validator: "",//REG
    label: "Email",
    fieldId: "email",
    required: true, //boolean
    value: "",
    // validation_message: '',
    // custom_validator: {
    //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
    //   message: '',//string;
    //   flags: ''//string;
    // },
    // maxlength: 50
  },
  {
    type: "password",
    // validator: "",//REG
    label: "Password",
    fieldId: "password",
    required: true, //boolean
    value: "",
    // validation_message: '',
    // custom_validator: {
    //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
    //   message: '',//string;
    //   flags: ''//string;
    // },
    // maxlength: 50
  },
  {
    type: "password",
    // validator: "",//REG
    label: "Confirm Password",
    fieldId: "passwordConfirm",
    required: true, //boolean
    value: "",
    // validation_message: '',
    custom_validator: {
      value: (value) => !value, //RegExp | string | ((value: FieldValue) => boolean);
      message: "", //string;
    },
    // maxlength: 50
  },
  {
    type: "text",
    // validator: "",//REG
    label: "Verification code",
    fieldId: "verificationCodes.email",
    required: true, //boolean
    value: "",
    // validation_message: '',
    // custom_validator: {
    //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
    //   message: '',//string;
    //   flags: ''//string;
    // },
    // maxlength: 50
  },
];

export const Reset: FC = () => {
  const navigate = useNavigate();
  const { isLoading } = useContext(LoaderContext);
  const { reset } = useFormContext()

  return (
    <Box sx={{ maxWidth: "400px" }}>
      <Button
        onClick={() => {
          reset()
          navigate("/signin")
        }}
        variant={"text"}
        color={"inherit"}
        sx={{ display: "flex", marginBottom: "40px" }}
      >
        {"< Back"}
      </Button>
      <Typography variant={"h4"} textAlign={"center"}>
        Reset Password
      </Typography>
      <Typography variant={"subtitle1"} sx={{ paddingTop: "20px" }}>
        Enter the email associated with your account and we’ll send an email
        with instructions to reset your password
      </Typography>
      <div style={{ width: "100%", margin: "0 auto", paddingTop: "30px" }}>
        <InputWidget {...signInFormData[0]} />
      </div>
      <LoadingButton
        fullWidth
        variant={"contained"}
        type={"submit"}
        color={"inherit"}
        sx={{ marginTop: "20px" }}
        loading={isLoading}
        loadingPosition="end"
        endIcon={<></>}
      >
        Send verification code
      </LoadingButton>
    </Box>
  );
};

export const NewPassword: FC = () => {
  const dispatch = useAppDispatch();
  const { watch } = useFormContext();
  const { isLoading } = useContext(LoaderContext);

  const matchPasswords = (confPass: FieldValues) =>
    watch("password") === confPass;
  signInFormData[2].custom_validator = {
    // @ts-ignore
    value: matchPasswords,
    message: "Your passwords do no match",
  };

  return (
    <Box sx={{ maxWidth: "400px" }}>
      <Button
        onClick={() => dispatch(setAuthMethod(AuthTypes.OTP_RESET))}
        variant={"text"}
        color={"inherit"}
        sx={{ display: "flex", marginBottom: "40px" }}
      >
        {"< Back"}
      </Button>
      <Typography variant={"h4"} textAlign={"center"}>
        Create new Password
      </Typography>
      <Typography variant={"subtitle1"} sx={{ paddingTop: "20px" }}>
        Your new password should be different from previous used password
      </Typography>
      <Box sx={{ width: "100%" }}>
        <InputWidget {...signInFormData[1]} />
        <InputWidget {...signInFormData[2]} />
      </Box>
      <LoadingButton
        fullWidth
        variant={"contained"}
        type={"submit"}
        color={"inherit"}
        sx={{ marginTop: "20px" }}
        loading={isLoading}
        loadingPosition="end"
        endIcon={<></>}
      >
        Set a new Password
      </LoadingButton>
    </Box>
  );
};

export const OtpReset: FC = () => {
  const dispatch = useAppDispatch();
  const dispatchThunk = useAppThunkDispatch();

  const [sec, setSec] = useState(60);

  let interval: any = null;

  useEffect(() => {
    if (sec <= 0) {
      clearTimeout(interval);
      return;
    }

    handleTick();

    return () => {
      clearInterval(interval);
    };
  }, [sec]);

  const handleTick = () => {
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(() => {
      setSec((prevState) => prevState - 1);
    }, 1000);
  };

  return (
    <Box sx={{ maxWidth: "400px" }}>
      <Button
        onClick={() => dispatch(setAuthMethod(AuthTypes.RESET))}
        variant={"text"}
        color={"inherit"}
        sx={{ display: "flex", marginBottom: "40px" }}
      >
        {"< Back"}
      </Button>
      <Typography variant={"h4"} textAlign={"center"}>
        Verification step
      </Typography>
      <Typography variant={"subtitle1"}>
        Please, enter the verification code that was sent to your email.
      </Typography>
      <div style={{ width: "100%", paddingTop: "30px" }}>
        <InputWidget {...signInFormData[3]} />
      </div>
      <Box display={"flex"} justifyContent={"center"}>
        <Button
          variant={"text"}
          sx={{ textTransform: "none" }}
          size={"small"}
          disabled={!!sec}
          onClick={() => {
            setSec(60);
            dispatchThunk(signUpThunk(null));
          }}
        >
          <Typography variant={"subtitle1"}>
            Send verification code again ?
          </Typography>{" "}
          {sec > 0 && (
            <Typography variant={"subtitle2"}>(left {sec} seconds)</Typography>
          )}
        </Button>
      </Box>
      <Button
        fullWidth
        variant={"contained"}
        type={"submit"}
        color={"inherit"}
        sx={{ marginTop: "20px" }}
      >
        Confirm
      </Button>
    </Box>
  );
};

export const ResetPasswordComponent: FC = () => {
  const { authMethod, otpMethods } = useAppSelector(({ form }) => form);

  const dispatch = useDispatch();
  const dispatchThunk = useAppThunkDispatch();

  useEffect(() => {
    if (!authMethod) {
      dispatch(setAuthMethod(AuthTypes.RESET));
    }
  }, []);

  const handleSendCode = () => {
    dispatchThunk(resetPasswordThunk(null));
  };

  const getForm = () => {
    if (authMethod) {
      switch (authMethod) {
        case AuthTypes.OTP_RESET:
          return (
            <div key={"OtpReset"}>
              <OtpWidget
                otpMethods={otpMethods}
                onSendCode={handleSendCode}
                authMethod={authMethod}
              />
            </div>
          );
        case AuthTypes.NEW_PASSWORD:
          return (
            <div key={"NewPassword"}>
              <NewPassword />
            </div>
          );
        case AuthTypes.RESET:
        default:
          return (
            <div key={"Reset"}>
              <Reset />
            </div>
          );
      }
    }
  };

  return <>{getForm()}</>;
};
