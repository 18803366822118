import { ReactNodeViewRenderer } from "@tiptap/react";
import { Image as TiptapImage } from "@tiptap/extension-image";

import { ImageView } from "../views/ImageView/ImageView";
import {
  DEFAULT_IMAGE_WIDTH,
  DEFAULT_IMAGE_DISPLAY,
  DEFAULT_IMAGE_URL_REGEX,
  DEFAULT_ASPECT_RATIO,
} from "../constants";
import { Command, mergeAttributes } from "@tiptap/core";
import { calculateAspectRatio } from "tiptap/utils/image";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    image: {
      setImage: (options: {
        src: string;
        alt?: string;
        title?: string;
        width?: number;
        height?: number;
        display?: string;
      }) => ReturnType;
    };
  }
}

export interface AdditionalImageOptions {
  inline: boolean | string;
  allowBase64: boolean;
  HTMLAttributes: Record<string, any>;
  defaultWidth: number;
  defaultDisplay: string;
  urlPattern: RegExp;
}

const ImageWidget = TiptapImage.extend<AdditionalImageOptions>({
  draggable: true,

  addOptions() {
    return {
      inline: false,
      allowBase64: false,
      HTMLAttributes: {},
      defaultWidth: DEFAULT_IMAGE_WIDTH,
      defaultDisplay: DEFAULT_IMAGE_DISPLAY,
      urlPattern: DEFAULT_IMAGE_URL_REGEX,
    };
  },

  parseHTML() {
    return [
      {
        tag: this.options.allowBase64
          ? "img[src]"
          : 'img[src]:not([src^="data:"])',
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    HTMLAttributes = {
      ...HTMLAttributes,
      style: `height: ${node.attrs.height}px; width: ${node.attrs.width}px;`,
    };

    return [
      "img",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },

  addAttributes() {
    return {
      src: {
        default: "",
        parseHTML(element: HTMLElement) {
          return element.getAttribute("src");
        },
      },
      alt: { default: "", parseHTML: (element) => element.getAttribute("alt") },
      title: {
        default: "",
        parseHTML: (element) => element.getAttribute("title"),
      },
      width: {
        default:
          this.options.defaultWidth > 0
            ? this.options.defaultWidth
            : DEFAULT_IMAGE_WIDTH,
        renderHTML: (attributes) =>
          // so that it does not conflict with existing attr width
          attributes.width ? { "data-width": attributes.width } : {},
        parseHTML(element: HTMLElement) {
          const widthAttr = element.getAttribute("data-width");
          return widthAttr ? parseFloat(widthAttr) : this.default;
        },
      },
      height: {
        default: null,
        parseHTML(element: HTMLElement) {
          const heightAttr = element.getAttribute("data-height");
          return heightAttr ? parseFloat(heightAttr) : this.default;
        },
        renderHTML: (attributes) =>
          attributes.height ? { "data-height": attributes.height } : {},
      },
      originalWidth: {
        default:
          this.options.defaultWidth > 0
            ? this.options.defaultWidth
            : DEFAULT_IMAGE_WIDTH,
        parseHTML: (element) => {
          const originalWidthAttr = element.getAttribute("data-original-width");
          return originalWidthAttr ? parseFloat(originalWidthAttr) : null;
        },
        renderHTML: (attributes) =>
          attributes.originalWidth
            ? { "data-original-width": attributes.originalWidth }
            : {},
      },
      originalHeight: {
        default: DEFAULT_IMAGE_WIDTH / DEFAULT_ASPECT_RATIO,
        parseHTML: (element) => {
          const originalHeightAttr = element.getAttribute(
            "data-original-height",
          );
          // when null is returned, default value for this attr is taken
          return originalHeightAttr ? parseFloat(originalHeightAttr) : null;
        },
        renderHTML: (attributes) =>
          attributes.originalHeight
            ? { "data-original-height": attributes.originalHeight }
            : {},
      },
      display: {
        default: /(inline|block|left|right)/.test(this.options.defaultDisplay)
          ? this.options.defaultDisplay
          : DEFAULT_IMAGE_DISPLAY,
      },
      keepAspectRatio: {
        default: false,
        parseHTML: (element) => {
          return element.getAttribute("data-keep-aspect-ratio") === "true";
        },
        renderHTML: (attributes) =>
          attributes.keepAspectRatio
            ? { "data-keep-aspect-ratio": attributes.keepAspectRatio }
            : {},
      },
      aspectRatio: {
        default: DEFAULT_ASPECT_RATIO,
        parseHTML: (element) => {
          const aspectRatio = element.getAttribute("data-aspect-ratio");
          return aspectRatio ? parseFloat(aspectRatio) : null;
        },
        renderHTML: (attributes) =>
          attributes.aspectRatio
            ? { "data-aspect-ratio": attributes.aspectRatio }
            : {},
      },
    };
  },

  inline() {
    return true;
  },
  group() {
    return "inline";
  },
  addNodeView() {
    return ReactNodeViewRenderer(ImageView);
  },

  addCommands() {
    return {
      setImage: (options: any): Command => {
        return ({ commands }: any) => {
          return commands.insertContent({
            type: this.name,
            attrs: {
              ...options,
              originalWidth: options.width,
              originalHeight: options.height,
              keepAspectRatio: false,
              aspectRatio: calculateAspectRatio(options.width, options.height),
            },
          });
        };
      },
    };
  },
});

export default ImageWidget;
