import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  showErrorNotification,
  showSuccessNotification,
} from "../../notification/notificationSlice";
import {
  createDocument,
  deleteDocument,
  updateDocument,
  getDocument,
  getDocuments,
} from "../../api/documentApi/documentApi";
import {
  addDocument,
  DocumentType,
  removeDocument,
  setDocuments,
  updateDoc,
} from "../../document/documentSlice";

export const getDocumentsThunk = createAsyncThunk(
  "thunkSlice/getDocumentsThunk",
  async (_, { dispatch }) => {
    try {
      const documents = await dispatch(getDocuments.initiate({})).unwrap();

      dispatch(setDocuments(documents));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const createDocumentThunk = createAsyncThunk(
  "thunkSlice/createDocumentThunk",
  async (
    { name, content }: { name: string; content: string },
    { dispatch },
  ) => {
    try {
      let res = await dispatch(
        createDocument.initiate({ name, content }),
      ).unwrap();

      dispatch(addDocument(res));
      dispatch(
        showSuccessNotification({
          message: "The new document successfully created",
        }),
      );
      return res;
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const getDocumentThunk = createAsyncThunk(
  "thunkSlice/getDocumentThunk",
  async ({ uuid }: { uuid: string }, { dispatch }) => {
    try {
      let document = await dispatch(getDocument.initiate({ uuid })).unwrap();

      dispatch(showSuccessNotification({ message: document?.message }));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const deleteDocumentThunk = createAsyncThunk(
  "thunkSlice/deleteDocumentThunk",
  async ({ id }: { id: string }, { dispatch }) => {
    try {
      let res = await dispatch(deleteDocument.initiate({ id })).unwrap();

      dispatch(removeDocument({ id }));
      dispatch(showSuccessNotification({ message: res?.message }));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const updateDocumentThunk = createAsyncThunk(
  "thunkSlice/updateDocumentThunk",
  async (document: DocumentType, { dispatch }) => {
    try {
      let res = await dispatch(updateDocument.initiate(document)).unwrap();

      dispatch(updateDoc(res));
      dispatch(
        showSuccessNotification({
          message: "Your changes have been saved successfully",
        }),
      );
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);
//@ts-ignore
