import React, { useState } from "react";
import { MenuButton } from "../../MenuButton";
import { Editor } from "@tiptap/core";
import { TipTapEditorContext } from "../../../types";

interface AddLinkCommandButtonProps {
  editor: Editor;
  editorContext: TipTapEditorContext;
}

interface LinkData {
  href: string;
  openInNewTab?: boolean;
}

export const MenuAddLinkButton: React.FC<AddLinkCommandButtonProps> = ({
  editor,
  editorContext,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const isActive = editorContext.state.isActiveLink;

  const addLink = (data: LinkData) => {
    const { href, openInNewTab } = data;
    const target = openInNewTab ? "_blank" : null;

    editor.chain().focus().setLink({ href, target }).run();
    closeAddLinkDialog();
  };

  const openAddLinkDialog = () => {
    setIsVisible(true);
  };

  const closeAddLinkDialog = () => {
    setIsVisible(false);
  };

  return (
    <div>
      <MenuButton
        isActive={isActive}
        command={openAddLinkDialog}
        title="Add link"
        icon="link"
      />

      {/*<LinkDialog*/}
      {/*  title="Apply link"*/}
      {/*  onClose={closeAddLinkDialog}*/}
      {/*  onClick={addLink}*/}
      {/*  isVisible={isVisible}*/}
      {/*/>*/}
    </div>
  );
};
