import { FieldValues } from "react-hook-form";

import { apiService } from "../api/service/apiService";

import {
  AuthTypes,
  FormStateType,
  OtpMethod,
  setAuthMethod,
  setOtpMethods,
} from "../form/formSlice";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../notification/notificationSlice";

import {
  refreshToken,
  getUserProfile,
  getCompanyProfile,
  sendCompanyDetails,
  updateUserProfile,
} from "../api/userApi/userApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading } from "../thunkSlice/thunkSlice";
import {
  signIn,
  signUp,
  signUpConfirm,
  resetPassword,
  resetPasswordConfirm,
  resetPasswordVerify,
} from "../api/authApi/authApi";
import { RootState } from "../../index";
import {
  CompanyDetails,
  setCompanyDetails,
  setIsNewUser,
  setIsSuperAdmin,
  setSubdomain,
  setUserProfile,
} from "../company/companySlice";
import { wait } from "../../../helper/wait";
import { signInWithSubdomain } from "../api/clientApi/clientApi";
import { getBaseUrl } from "helper/urlHelper";
import { NavigateFunction } from "react-router-dom";
import Cookies from 'js-cookie'
import { extractSubdomain } from "helper/domainHelper";

export const refreshTokenThunk = createAsyncThunk(
  "thunkSlice/refreshTokenThunk",
  async (cb: (() => void) | undefined, { dispatch }) => {
    const refToken = apiService.getRefresh();

    try {
      if (!refToken || refToken === "undefined") {
        throw new Error("Token was not found");
      }
      const res = await dispatch(
        refreshToken.initiate({ refreshToken: refToken }),
      ).unwrap();

      localStorage.setItem("COMPANY_STATUS", res?.status);
      apiService.set(res?.accessToken);
      apiService.setRefresh(res?.refreshToken);
      dispatch(setIsSuperAdmin(res?.isSuperAdmin));
    } catch (error: any) {
      cb?.();
      console.error(error);
      throw new Error("Token was not found", error);
    }
  },
);

export const sendCompanyDetailsThunk = createAsyncThunk(
  "thunkSlice/sendCompanyDetailsThunk",
  async (data: CompanyDetails, { dispatch }): Promise<void> => {
    try {
      await dispatch(sendCompanyDetails.initiate(data)).unwrap();

      dispatch(setCompanyDetails(data));
      dispatch(showSuccessNotification({ message: "Company details saved" }));
    } catch (e) {
      console.error(e);
    }
  },
);

export const sendPersonalDetailsThunk = createAsyncThunk(
  "thunkSlice/sendCompanyDetailsThunk",
  async (data: FieldValues, { dispatch }): Promise<void> => {
    try {
      await dispatch(updateUserProfile.initiate(data)).unwrap();
      dispatch(setUserProfile(data));
    } catch (e) {
      console.error(e);
    }
  },
);

export const getCompanyDetailsThunk = createAsyncThunk(
  "thunkSlice/getCompanyDetailsThunk",
  async (
    data:
      | {
          isNewUser: boolean;
          companyDetailsNavigate?: (
            unsginedDocsCount: number,
            isNewUser: boolean,
          ) => void;
        }
      | undefined,
    { dispatch },
  ): Promise<any> => {
    try {
      let companyDetails = await dispatch(
        getCompanyProfile.initiate({}),
      ).unwrap();
      data?.companyDetailsNavigate &&
        data.companyDetailsNavigate(
          companyDetails?.unsignedDocuments ?? 0,
          data.isNewUser,
        );
      // temporary solution for setting country to US only
      companyDetails = {
        ...companyDetails,
        country: "US",
      };
      dispatch(setCompanyDetails(companyDetails));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    } finally {
      await dispatch(setLoading({ status: "idle" }));
    }
  },
);

export const getPersonalDetailsThunk = createAsyncThunk(
  "thunkSlice/getPersonalDetailsThunk",
  async (_, { dispatch }): Promise<any> => {
    try {
      const personalDetails = await dispatch(
        getUserProfile.initiate({}),
      ).unwrap();
      dispatch(setUserProfile(personalDetails));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    } finally {
      await dispatch(setLoading({ status: "idle" }));
    }
  },
);

export const processAuthenticationMethodsThunk = createAsyncThunk(
  "thunkSlice/processAuthenticationMethodsThunk",
  async (methods: any, { dispatch }) => {
    dispatch(setOtpMethods(methods));
    dispatch(setAuthMethod(AuthTypes.OTP_SIGN_IN));

    for (const item of methods) {
      if (item.message) {
        dispatch(
          showSuccessNotification({
            message: `${item.message} ${item.destination ?? ""}`,
          }),
        );
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    }
  },
);

function sendPostMessageToIframe(
  accessToken: string,
  refreshToken: string,
  isNewUser: boolean,
  baseUrl: string,
  isSuperAdmin: boolean,
) {
  const iframe = document.getElementById("goPage") as HTMLIFrameElement;

  if (iframe) {
    iframe.contentWindow?.postMessage(
      {
        message: "login",
        payload: {
          access_token: accessToken,
          refresh_token: refreshToken,
          is_new_user: isNewUser,
          is_super_admin: isSuperAdmin,
        },
      },
      `${baseUrl}/go/`,
    );
  }
}

export const setCompanyDataThunk = createAsyncThunk(
  "thunkSlice/setCompanyDataThunk",
  async (
    {
      isNewUser,
      cb,
      companyDetailsNavigate,
    }: {
      isNewUser: boolean;
      cb?: () => void;
      companyDetailsNavigate?: (
        unsginedDocsCount: number,
        isNewUser: boolean,
      ) => void;
    },
    { dispatch },
  ): Promise<any> => {
    await wait(100);
    await dispatch(refreshTokenThunk(cb));
    await wait(100);

    dispatch(setIsNewUser(isNewUser));
    await dispatch(
      getCompanyDetailsThunk({ isNewUser, companyDetailsNavigate }),
    );
  },
);

export const signInThunk = createAsyncThunk(
  "thunkSlice/signInThunk",
  async (
    {
      data,
      cb,
      companyDetailsNavigate,
      isNewUser,
      verificationType,
    }: {
      data: FieldValues | null;
      cb?: () => void;
      isNewUser: boolean;
      verificationType?: OtpMethod["type"];
      companyDetailsNavigate?: (
        unsginedDocsCount: number,
        isNewUser: boolean,
      ) => void;
    },
    { dispatch, getState },
  ): Promise<any> => {
    const state = getState() as RootState;
    const formData = state.form.formData;

    const email = data && data.email ? data.email : formData?.email;
    const password = data && data.password ? data.password : formData?.password;
    const verificationCodes =
      data && data.verificationCodes ? data.verificationCodes : undefined;

    if (email && password) {
      let subdomain = localStorage.getItem("SUBDOMAIN");
      const windowSubdomain = extractSubdomain();
      try {
        if ((!subdomain && windowSubdomain) || !windowSubdomain) {
          const res = await dispatch(
            signIn.initiate({ email, password, ...formData }),
          ).unwrap();
          if (res.subdomain) {
            subdomain = res.subdomain;
          }
        }

        dispatch(setSubdomain(subdomain));
        const res = await dispatch(
          signInWithSubdomain.initiate({
            email,
            password,
            verificationType,
            verificationCodes: verificationCodes ?? {},
          }),
        ).unwrap();

        if (res?.methods && res.methods.length && !verificationCodes) {
          dispatch(processAuthenticationMethodsThunk(res.methods));
        }

        //if verificationType exist then return to prevent sign in flow
        if (verificationType) return;

        // @TODO: check after refactoring
        // if (res?.methods && res.methods.length && !verificationCodes) {
        //   dispatch(processAuthenticationMethodsThunk(res.methods));
        // }

        if (
          res.accessToken &&
          res.tokenType &&
          res.refreshToken &&
          companyDetailsNavigate
        ) {
          if (windowSubdomain) {
            apiService.set(res.accessToken);
            apiService.setRefresh(res.refreshToken);

            localStorage.setItem("IS_NEW_USER", JSON.stringify(isNewUser));

            dispatch(
              setCompanyDataThunk({ isNewUser, cb, companyDetailsNavigate }),
            );
            return;
          }

          const baseUrl = getBaseUrl(subdomain, window.location.hostname);

          Cookies.set(
              'authWK',
              JSON.stringify({
                access_token: res.accessToken,
                refresh_token: res.refreshToken,
                is_new_user: isNewUser,
                is_super_admin: res.isSuperAdmin,
                subdomain: subdomain
              }),
              {domain: `\.${window.location.hostname}`, path: '/'}
          );

          window.location.href = `${baseUrl}/account`;
        }
      } catch (error: any) {
        dispatch(
          showErrorNotification({
            message: error?.data?.error || error?.data?.message,
          }),
        );
        console.error(error);
        throw new Error(error);
      }
    }
  },
);

export const signUpThunk = createAsyncThunk(
  "thunk/signUpThunk",
  async (data: FieldValues | null, { dispatch, getState }) => {
    const state = getState() as RootState;
    const formData = state.form.formData;

    const email = data && data.email ? data.email : formData?.email;
    const password = data && data.password ? data.password : formData?.password;
    const rememberMe =
      data && data.rememberMe ? data.rememberMe : formData?.rememberMe;
    const passwordConfirm =
      data && data.passwordConfirm
        ? data.passwordConfirm
        : formData?.passwordConfirm;
    // @TODO: check after refactoring
    // const verificationCodes =
    //   data && data.verificationCodes
    //     ? data.verificationCodes
    //     : formData?.verificationCodes;

    if (email && password && passwordConfirm) {
      try {
        const res = await dispatch(
          signUp.initiate({
            email,
            password,
            passwordConfirm,
            rememberMe,
          }),
        ).unwrap();

        if (res?.methods && res.methods.length) {
          res.methods.forEach((item: any) => {
            item.message &&
              dispatch(
                showSuccessNotification({
                  message: `${item.message} ${item.destination ?? ""}`,
                }),
              );
          });
        } else {
          dispatch(showSuccessNotification({ message: res.message }));
        }

        if (res?.methods.length) {
          dispatch(setOtpMethods(res.methods));
          dispatch(setAuthMethod(AuthTypes.OTP_SIGN_UP));
        } else {
          dispatch(showErrorNotification({ message: undefined }));
        }
      } catch (error: any) {
        dispatch(
          showErrorNotification({
            message: error?.data?.message ?? error?.data?.error,
          }),
        );
        console.error(error);
      }
    }
  },
);

export const resetPasswordThunk = createAsyncThunk(
  "thunkSlice/resetPasswordThunk",
  async (data: FieldValues | null, { dispatch, getState }) => {
    const state = getState() as RootState;
    const formData = state.form.formData;

    const email = data && data.email ? data.email : formData?.email;

    if (email) {
      try {
        const res = await dispatch(
          resetPassword.initiate({ email: email }),
        ).unwrap();

        if (res?.methods && res.methods.length) {
          res.methods.forEach((item: any) => {
            item.message &&
              dispatch(
                showSuccessNotification({
                  message: `${item.message} ${item.destination ?? ""}`,
                }),
              );
          });
        } else {
          dispatch(showSuccessNotification({ message: res.message }));
        }

        dispatch(setOtpMethods(res.methods));
        dispatch(setAuthMethod(AuthTypes.OTP_RESET));
      } catch (error: any) {
        dispatch(
          showErrorNotification({
            message: error?.data?.message ?? error?.data?.error,
          }),
        );
        console.error(error);
      }
    }
  },
);
// {
//   "methods": [
//   {
//     "type": "email",
//     "message": "We have sent you a verification code to your email:",
//     "destination": "lobym@mailinator.com",
//     "timeout": 60,
//     "expiresIn": 300
//   }
// ]
// }

export const signUpConfirmThunk = createAsyncThunk(
  "thunkSlice/signUpConfirmThunk",
  async (
    {
      data,
      companyDetailsNavigate,
    }: {
      data: FieldValues;
      companyDetailsNavigate?: (
        unsginedDocsCount: number,
        isNewUser: boolean,
      ) => void;
    },
    { dispatch, getState },
  ) => {
    const {
      form: { formData },
    } = getState() as { form: FormStateType };

    const email = data && data.email ? data.email : formData?.email;
    const password = data && data.password ? data.password : formData?.password;
    const rememberMe =
      data && data.rememberMe ? data.rememberMe : formData?.rememberMe;
    const passwordConfirm =
      data && data.passwordConfirm
        ? data.passwordConfirm
        : formData?.passwordConfirm;
    const verificationCodes =
      data && data.verificationCodes
        ? data.verificationCodes
        : formData?.verificationCodes;

    if (email && password && passwordConfirm) {
      try {
        const res = await dispatch(
          signUpConfirm.initiate({
            email,
            password,
            passwordConfirm,
            rememberMe,
            verificationCodes,
          }),
        ).unwrap();

        await dispatch(showSuccessNotification({ message: res.message }));
        await dispatch(
          signInThunk({
            data: { verificationCodes },
            companyDetailsNavigate,
            isNewUser: true,
          }),
        );
        await dispatch(setAuthMethod(null));
        // next();
      } catch (error: any) {
        dispatch(
          showErrorNotification({
            message: error?.data?.message ?? error?.data?.error,
          }),
        );
        console.error(error);
        throw new Error(error);
      }
    }
  },
);

export const resetPasswordVerifyThunk = createAsyncThunk(
  "thunkSlice/resetPasswordVerifyThunk",
  async (data: FieldValues, { dispatch, getState }) => {
    const {
      form: { formData },
    } = getState() as { form: FormStateType };
    if (data.verificationCodes && formData?.email) {
      try {
        let res = await dispatch(
          resetPasswordVerify.initiate({ email: formData.email, ...data }),
        ).unwrap();

        dispatch(showSuccessNotification({ message: res?.message }));
        dispatch(setAuthMethod(AuthTypes.NEW_PASSWORD));
      } catch (error: any) {
        dispatch(
          showErrorNotification({
            message: error?.data?.message ?? error?.data?.error,
          }),
        );
        console.error(error);
      }
    }
  },
);

export const resetPasswordConfirmThunk = createAsyncThunk(
  "thunkSlice/resetPasswordConfirmThunk",
  async (
    {
      data,
      cb,
      companyDetailsNavigate,
      navigate,
    }: {
      data: FieldValues;
      cb: (() => void) | undefined;
      companyDetailsNavigate?: (
        unsginedDocsCount: number,
        isNewUser: boolean,
      ) => void;
      navigate: NavigateFunction;
    },
    { dispatch, getState },
  ) => {
    const {
      form: { formData },
    } = getState() as { form: FormStateType };
    if (data.password && data.passwordConfirm) {
      try {
        const res = await dispatch(
          resetPasswordConfirm.initiate({ ...formData, ...data }),
        ).unwrap();

        await dispatch(showSuccessNotification({ message: res?.message }));
        navigate("/signin");
      } catch (error: any) {
        dispatch(
          showErrorNotification({
            message: error?.data?.message ?? error?.data?.error,
          }),
        );
        console.error(error);

        throw new Error(error);
      }
    }
  },
);
