import { PropsCommentItem } from "../shared/types";

// @ts-ignore
import clubmenagos from "../assets/comments/clubmenagos.jpeg";
// @ts-ignore
import fitclub from "../assets/comments/fitclub.png";
// @ts-ignore
import kbfitness from "../assets/comments/kbfitness.png";
// @ts-ignore
import moda from "../assets/comments/moda.jpeg";
// @ts-ignore
import strikezone from "../assets/comments/strikezone.png";

export const comments: PropsCommentItem[] = [
  {
    commentText:
      "WaiverKing been an invaluable tool that has given us an edge. It has been completely trouble free and the WaiverKing team has been a pleasure to work with!",
    name: "Steven Tsunekawa",
    position: "Spa and Fitness Director",
    profileImg: clubmenagos,
  },
  {
    commentText:
      "We have been using WaiverKing for 2 months now and we can't believe how much more convenient our systemsare. If only we had of found WaiverKing sooner, we could have saved so many hours of filing and not to mentiontrying to find clients paperwork. Now, SO SIMPLE. WaiverKing has improved our business and I would not hesitatein endorsing it to any business that asks.",
    name: "Fit Club Boxing Studio",
    position: "Harley GM at FitClub Boxing Studios",
    profileImg: fitclub,
  },
  {
    commentText:
      "We have tried using other electronic waiver systems in the past and none are as convenient as Waiver King. They take care of the extra step of putting the waivers into our mind body system, which saves us a lot of time!",
    name: "KB Fitness",
    position: "Owner KB Fitness",
    profileImg: kbfitness,
  },
  {
    commentText:
      "WaiverKing has been an amazing company to work with. Their paperless waiver system has allowed us to create aseamless experience for our students and to scale our business even as our student body grows and grows eachyear. They are responsive, flexible, and they provide an incredible product that integrates perfectly witMindbdy. We would recommend WaiverKing to anyone who is committed to helping the environment by greening theirbusiness practices.",
    name: "Charis Anton",
    position: "Studio Director Moda Yoga",
    profileImg: moda,
  },
  {
    commentText:
      "After trying a number of electronic waiver solutions, there was only one choice, WAIVERKING. We choseWAIVERKING because of its integration with MindBody. As the customer completes the online waiver, WAIVERKINGcreates the client profile in MindBody and uploads the waiver to the documents section of the client profile.There`s no need to re-enter information or manually upload documents, WAIVERKING does it all for you.",
    name: "Ken Stewart",
    position: "Owner Strike Zone",
    profileImg: strikezone,
  },
];
