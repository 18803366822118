import { FC } from "react";

import {
  Box,
  Breadcrumbs,
  Stack,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { NavigateNext } from "@mui/icons-material";

import { Workflows } from "./Workflows";

export const WorkflowsComponent: FC<{ breadcrumbs?: boolean }> = () => {
  return (
    <>
      <Box sx={{ padding: "0 50px 0 305px" }}>
        <Box
          sx={{
            display: "flex",
            borderBottom: 1,
            borderColor: "divider",
            justifyContent: "space-between",
          }}
        >
          <Stack spacing={2}>
            <Breadcrumbs
              separator={<NavigateNext fontSize="small" color={"success"} />}
              aria-label="breadcrumb"
            >
              <Typography
                key="1"
                color="text.primary"
                sx={{ padding: "14px 0" }}
              >
                Workflows
              </Typography>
            </Breadcrumbs>
          </Stack>
        </Box>
        <Workflows />
      </Box>
    </>
  );
};
