export function getWidgets(country: string): any {
  return [
    //TITLE WIDGET: Personal Information
    {
      type: "text",
      config: {
        value: "Personal Information",
        font: {
          size: "20px",
          weight: "bold",
        },
      },
      position: {
        x: 0,
        y: 0,
        w: 2,
        h: 1,
      },
    },

    {
      position: {
        x: 0,
        y: 1,
        w: 3,
        h: 1,
      },
      type: "field",
      // ref: 'profile.firstName',
      config: {
        props: {
          type: "text",
          // validator: "",//REG
          label: "ID",
          fieldId: "uuid",
          required: false, //boolean
          value: "",
          // validation_message: '',
          // custom_validator: {
          //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
          //   message: '',//string;
          //   flags: ''//string;
          // },
          // maxlength: 50
        },
      },
    },
    {
      position: {
        x: 3,
        y: 1,
        w: 3,
        h: 1,
      },
      type: "field",
      // ref: 'profile.firstName',
      config: {
        props: {
          type: "text",
          // validator: "",//REG
          label: "First Name",
          fieldId: "firstName",
          required: false, //boolean
          value: "",
          // validation_message: '',
          // custom_validator: {
          //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
          //   message: '',//string;
          //   flags: ''//string;
          // },
          // maxlength: 50
        },
      },
    },

    {
      position: {
        x: 0,
        y: 1.5,
        w: 3,
        h: 1,
      },
      type: "field",
      // ref: 'profile.firstName',
      config: {
        props: {
          type: "text",
          // validator: "",//REG
          label: "Last Name",
          fieldId: "lastName",
          required: false, //boolean
          value: "",
          // validation_message: '',
          // custom_validator: {
          //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
          //   message: '',//string;
          //   flags: ''//string;
          // },
          // maxlength: 50
        },
      },
    },
    {
      position: {
        x: 3,
        y: 1.5,
        w: 3,
        h: 1,
      },
      type: "field",
      // ref: 'profile.firstName',
      config: {
        props: {
          type: "select",
          // validator: "",//REG
          label: "Gender",
          fieldId: "gender",
          required: true, //boolean
          value: "",
          option_list: [
            { value: "male", label: "Male" },
            { value: "female", label: "Female" },
            { value: "other", label: "Other" },
          ],
          // validation_message: '',
          // custom_validator: {
          //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
          //   message: '',//string;
          //   flags: ''//string;
          // },
          // maxlength: 50
        },
      },
    },

    {
      position: {
        x: 0,
        y: 2.5,
        w: 3,
        h: 1,
      },
      type: "field",
      config: {
        props: {
          type: "datepicker",
          // validator: "",//REG
          label: "Day of birth",
          fieldId: "dob",
          required: true, //boolean
          value: "",
          // validation_message: '',
          // custom_validator: {
          //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
          //   message: '',//string;
          //   flags: ''//string;
          // },
          // maxlength: 50
        },
      },
    },
    {
      position: {
        x: 3,
        y: 2.5,
        w: 3,
        h: 1,
      },
      type: "field",
      // ref: 'profile.firstName',
      config: {
        props: {
          type: "text",
          // validator: "",//REG
          label: "Age",
          fieldId: "age",
          required: false, //boolean
          value: "",
          // validation_message: '',
          // custom_validator: {
          //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
          //   message: '',//string;
          //   flags: ''//string;
          // },
          // maxlength: 50
        },
      },
    },

    //TITLE WIDGET: Contact info
    {
      type: "text",
      config: {
        value: "Contact info",
        font: {
          size: "20px",
        },
      },
      position: {
        x: 0,
        y: 3.5,
        w: 1.5,
        h: 0.5,
      },
    },

    {
      position: {
        x: 0,
        y: 5,
        w: 3,
        h: 1,
      },
      type: "field",
      // ref: 'profile.firstName',
      config: {
        props: {
          type: "email",
          // validator: "",//REG
          label: "E-mail",
          fieldId: "email",
          required: false, //boolean
          value: "",
          // validation_message: '',
          // custom_validator: {
          //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
          //   message: '',//string;
          //   flags: ''//string;
          // },
          // maxlength: 50
        },
      },
    },
    {
      position: {
        x: 3,
        y: 5,
        w: 3,
        h: 1,
      },
      type: "field",
      // ref: 'profile.firstName',
      config: {
        props: {
          type: "text",
          // validator: "",//REG
          label: "Phone Number",
          fieldId: "phone",
          required: false, //boolean
          value: "",
          // validation_message: '',
          // custom_validator: {
          //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
          //   message: '',//string;
          //   flags: ''//string;
          // },
          // maxlength: 50
        },
      },
    },

    //TITLE WIDGET: Address
    {
      type: "text",
      config: {
        value: "Address",
        font: {
          size: "20px",
        },
      },
      position: {
        x: 0,
        y: 5.5,
        w: 6,
        h: 1,
      },
    },

    {
      position: {
        x: 0,
        y: 6,
        w: 3,
        h: 1,
      },
      type: "field",
      // ref: 'profile.firstName',
      config: {
        props: {
          type: "text",
          // validator: "",//REG
          label: "Street address",
          fieldId: "address",
          required: false, //boolean
          value: "",
          // validation_message: '',
          // custom_validator: {
          //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
          //   message: '',//string;
          //   flags: ''//string;
          // },
          // maxlength: 50
        },
      },
    },
    {
      position: {
        x: 3,
        y: 6,
        w: 3,
        h: 1,
      },
      type: "field",
      config: {
        props: {
          type: "text",
          // validator: "",//REG
          label: "City",
          fieldId: "city",
          required: false, //boolean
          value: "",
          // validation_message: '',
          // custom_validator: {
          //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
          //   message: '',//string;
          //   flags: ''//string;
          // },
          // maxlength: 50
        },
      },
    },

    {
      position: {
        x: 0,
        y: 7,
        w: 3,
        h: 1,
      },
      type: "field",
      config: {
        props: {
          type: "select",
          // validator: "",//REG
          label: "State / Province",
          fieldId: "region",
          required: true, //boolean
          value: "",
          country: country,
          // validation_message: '',
          // custom_validator: {
          //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
          //   message: '',//string;
          //   flags: ''//string;
          // },
          // maxlength: 50
        },
      },
    },
    {
      position: {
        x: 3,
        y: 7,
        w: 3,
        h: 1,
      },
      type: "field",
      config: {
        props: {
          type: "text",
          // validator: "",//REG
          label: "Zip / Postal",
          fieldId: "postalCode",
          required: false, //boolean
          value: "",
          // validation_message: '',
          // custom_validator: {
          //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
          //   message: '',//string;
          //   flags: ''//string;
          // },
          // maxlength: 50
        },
      },
    },

    //TITLE WIDGET: Custom
    {
      type: "text",
      config: {
        value: "Custom fields",
        font: {
          size: "20px",
        },
      },
      position: {
        x: 0,
        y: 7.5,
        w: 6,
        h: 1,
      },
    },

    {
      position: {
        x: 0,
        y: 8,
        w: 3,
        h: 1,
      },
      type: "field",
      config: {
        props: {
          type: "text",
          // validator: "",//REG
          label: "Custom Field 1",
          fieldId: "customField1",
          required: false, //boolean
          value: "",
          // validation_message: '',
          // custom_validator: {
          //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
          //   message: '',//string;
          //   flags: ''//string;
          // },
          // maxlength: 50
        },
      },
    },
    {
      position: {
        x: 3,
        y: 8,
        w: 3,
        h: 1,
      },
      type: "field",
      config: {
        props: {
          type: "text",
          // validator: "",//REG
          label: "Custom Field 2",
          fieldId: "customField2",
          required: false, //boolean
          value: "",
          // validation_message: '',
          // custom_validator: {
          //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
          //   message: '',//string;
          //   flags: ''//string;
          // },
          // maxlength: 50
        },
      },
    },
  ];
}
