import React from "react";

import { Editor } from "@tiptap/core";

import { MenuRemoveLinkButton } from "../MenuItems/Link/MenuRemoveLinkButton";
import { MenuOpenLinkButton } from "../MenuItems/Link/MenuOpenLinkButton";
import { MenuEditLinkButton } from "../MenuItems/Link/MenuEditLinkButton";

import "../MenuBubble/MenuBubble.scss";

interface Props {
  editor: Editor;
}

export const LinkBubbleMenu: React.FC<Props> = ({ editor }) => {
  const linkAttrs = editor.getAttributes("link") as {
    href: string;
    target: string;
  };

  return (
    <div className="link-bubble-menu">
      <slot name="prepend" />

      <MenuOpenLinkButton editor={editor} />
      <MenuEditLinkButton editor={editor} />
      <MenuRemoveLinkButton editor={editor} />
    </div>
  );
};
