import { useEffect } from "react";

const CookiesPolicy = () => {
  const wrapper = document.querySelector(".landing-wrapper");

  useEffect(() => {
    wrapper?.scrollTo(0, 0);
  }, []);

  return (
    <div className="xs:px-[24px] xs:py-[70px] xs:pt-[64px] sm:px-[95px] sm:py-[95px] sm:pt-[130px] md:px-[139px] md:py-[120px] md:pt-[140px] lg:px-[317px] lg:py-[165px] lg:pt-[214px]">
      <h1
        className="mx-auto mb-[72px] font-poppins font-bold text-primary-700 xs:mt-[60px]
      xs:text-2xl sm:mt-[80px] sm:text-4xl sm:leading-normal md:mt-[50px] lg:mt-0"
      >
        Cookies policy
      </h1>
      <p>Last Updated on : 10th April 2020</p>
      <h3>What is a cookie?</h3>
      <p>
        A cookie is a small text file that is stored on your computer or other
        internet connected device in order to identify your browser, provide
        analytics, remember information about you such as your language
        preference or login information. They're completely safe and can't be
        used to run programs or deliver viruses to your device.You can learn
        more about cookies by clicking here (external link).
      </p>
      <h3>What type of cookies does WaiverKing use?</h3>
      <p>
        Cookies can either be session cookies or persistent cookies. A session
        cookie expires automatically when you close your browser. A persistent
        cookie will remain until it expires or you delete your cookies.
        Expiration dates are set in the cookies themselves; some may expire
        after a few minutes while others may expire after multiple years.
        Cookies placed by the website you’re visiting are called “first party
        cookies".
      </p>
      <p>
        Below is a detailed list of the cookies we use on our website. Our
        website is scanned with our cookie scanning tool regularly to maintain a
        list as accurate as possible. We classify cookies in the following
        categories: Strictly Necessary Statistics Marketing
      </p>
      <p>
        Strictly Necessary cookies are necessary for our website to function and
        cannot be switched off in our systems. They are essential in order to
        enable you to navigate around the website and use its features. If you
        remove or disable these cookies, we cannot guarantee that you will be
        able to use our websites.
      </p>
      <p>
        Statistic cookies help website owners to understand how visitors
        interact with websites by collecting and reporting information
        anonymously.
      </p>
      <p>
        Marketing cookies are used to track visitors across websites. The
        intention is to display ads that are relevant and engaging for the
        individual user and thereby more valuable for publishers and third party
        advertisers.
      </p>
      <table>
        <thead>
          <tr>
            <th>Cookie Name</th>
            <th>Purpose</th>
            <th>Validity</th>
            <th>Category</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>6e4b8efee4</td>
            <td>Contact Us form security</td>
            <td>Session</td>
            <td>Necessary</td>
          </tr>
          <tr>
            <td>crmcsr</td>
            <td>
              Collects information on user preferences and/or interaction with
              web-campaign content - This is used on CRM-campaign-platform used
              by website owners for promoting events or products.
            </td>
            <td>Session</td>
            <td>Necessary</td>
          </tr>
          <tr>
            <td>JSESSIONID</td>
            <td>Preserves users states across page requests.</td>
            <td>Session</td>
            <td>Necessary</td>
          </tr>
          <tr>
            <td>test_cookie</td>
            <td>Used to check if the user's browser supports cookies.</td>
            <td>1 day</td>
            <td>Necessary</td>
          </tr>
          <tr>
            <td>rc::c</td>
            <td>This cookie is used to distinguish between humans and bots.</td>
            <td>Session</td>
            <td>Necessary</td>
          </tr>
          <tr>
            <td>CookieConsent</td>
            <td>
              Stores the user's cookie consent state for the current domain
            </td>
            <td>1 year</td>
            <td>Necessary</td>
          </tr>
          <tr>
            <td>__cfduid</td>
            <td>
              Used by the content network, Cloudflare, to identify trusted web
              traffic.
            </td>
            <td>29 days</td>
            <td>Necessary</td>
          </tr>
          <tr>
            <td>PHPSESSID</td>
            <td>CPreserves user session state across page requests.</td>
            <td>Session</td>
            <td>Necessary</td>
          </tr>
          <tr>
            <td>_csrf</td>
            <td>
              Ensures visitor browsing-security by preventing cross-site request
              forgery. This cookie is essential for the security of the website
              and visitor.
            </td>
            <td>Session</td>
            <td>Necessary</td>
          </tr>
          <tr>
            <td>zip</td>
            <td>
              CStore IP locale country value for proper website operations
            </td>
            <td>1 day</td>
            <td>Necessary</td>
          </tr>
          <tr>
            <td>IDE</td>
            <td>
              Used by Google DoubleClick to register and report the website
              user's actions after viewing or clicking one of the advertiser's
              ads with the purpose of measuring the efficacy of an ad and to
              present targeted ads to the user.
            </td>
            <td>2 years</td>
            <td>Statistics</td>
          </tr>
          <tr>
            <td>yt-remote-device-id</td>
            <td>
              Stores the user's video player preferences using embedded YouTube
              video
            </td>
            <td>Persistent</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>yt-remote-connected-devices</td>
            <td>
              Stores the user's video player preferences using embedded YouTube
              video
            </td>
            <td>Persistent</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>yt-remote-session-app</td>
            <td>
              Stores the user's video player preferences using embedded YouTube
              video
            </td>
            <td>Session</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>yt-remote-cast-installed</td>
            <td>
              Stores the user's video player preferences using embedded YouTube
              video
            </td>
            <td>1 month</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>yt-remote-session-name</td>
            <td>
              Stores the user's video player preferences using embedded YouTube
              video
            </td>
            <td>1 day</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>yt-remote-fast-check-period</td>
            <td>
              Stores the user's video player preferences using embedded YouTube
              video
            </td>
            <td>1 hour</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>VISITOR_INFO1_LIVE</td>
            <td>
              Tries to estimate the users' bandwidth on pages with integrated
              YouTube videos.
            </td>
            <td>179 days</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>YSC</td>
            <td>
              Registers a unique ID to keep statistics of what videos from
              YouTube the user has seen.
            </td>
            <td>Session</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>GPS</td>
            <td>
              Registers a unique ID on mobile devices to enable tracking based
              on geographical GPS location.
            </td>
            <td>1 day</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>_csrf_hash</td>
            <td>Web page protection mechanism</td>
            <td>1 day</td>
            <td>Necessary</td>
          </tr>
        </tbody>
      </table>
      <h3>How you can manage cookies?</h3>
      <p>
        Most browsers allow you to control cookies through their 'settings'
        preferences. However, if you limit the ability of websites to set
        cookies, you may worsen your overall user experience, since it will no
        longer be personalized to you. It may also stop you from saving
        customized settings like login information. Browser manufacturers
        provide help pages relating to cookie management in their products.
        Please see below for more information.
      </p>
      <p>
        Browser manufacturers provide help pages relating to cookie management
        in their products. Please see below for more information. Google
        ChromeInternet ExplorerMozilla FirefoxSafari (Desktop)Safari
        (Mobile)Android BrowserOperaOpera Mobile
      </p>
      <h3>Disclaimer</h3>
      <p>
        We may update this Cookie Statement from time to time in order to
        reflect, for example, changes to the cookies we use or for other
        operational, legal or regulatory reasons. Please therefore re-visit this
        Cookie Statement regularly to stay informed about our use of cookies and
        related technologies. For more information relating to cookies, you may
        contact support@waiverking.com.
      </p>
    </div>
  );
};
export default CookiesPolicy;
