import { FC } from "react";

import ReactGridLayout from "react-grid-layout";
import { Box, Button } from "@mui/material";

import { appendWidget } from "../../../../store/features/steps/stepSlice";
import { getFieldWidget } from "../../services/widgetsFactory";
import { ChildrenWidgetProps } from "./ChildrenWidget.types";
import { FieldWidget } from "../../@types/Widget";
import { useAppDispatch } from "../../../../store";

export const ChildrenWidget: FC<ChildrenWidgetProps> = (props) => {
  const { widgets } = props;

  const dispatch = useAppDispatch();

  const getChildrenWidgets = (widgets: FieldWidget[]) => {
    return widgets.map(({ position, config: { props } }, index) => (
      <div key={props.fieldId + index} data-grid={position}>
        {getFieldWidget(props)}
      </div>
    ));
  };

  const appendWidgets = () => {
    dispatch(appendWidget());
  };

  return (
    <div>
      <ReactGridLayout
        width={props.width}
        transformScale={0.1}
        margin={[30, 30]}
        containerPadding={[0, 0]}
        isResizable={false}
        isDraggable={false}
        rowHeight={80}
        compactType={null}
        cols={6}
      >
        {getChildrenWidgets(widgets)}
      </ReactGridLayout>
      <Box sx={{ width: `100%`, display: "flex", justifyContent: "flex-end" }}>
        <Button
          sx={{ marginTop: "30px" }}
          variant={"outlined"}
          onClick={appendWidgets}
        >
          + Add a Child
        </Button>
      </Box>
    </div>
  );
};
