import { PricingType } from "../shared/types";

const pricingData: Array<PricingType> = [
  {
    tier: "Tier 1",
    description: `<span style="color: #111827">1 to 100</span> Monthly Transactions`,
    price: "$ 15.00",
  },
  {
    tier: "Tier 2",
    description: `<span style="color: #111827">101 to 200</span> Monthly Transactions`,
    price: "$ 30.00",
  },
  {
    tier: "Tier 3",
    description: `<span style="color: #111827">201 to 300</span> Monthly Transactions`,
    price: "$ 45.00",
  },
  {
    tier: "Tier 4",
    description: `<span style="color: #111827">301 to 400</span> Monthly Transactions`,
    price: "$ 60.00",
  },
  {
    tier: "Tier 5",
    description: `<span style="color: #111827">401 to 500</span> Monthly Transactions`,
    price: "$ 75.00",
  },
  {
    tier: "Tier 6",
    description: `<span style="color: #111827">501 to 600</span> Monthly Transactions`,
    price: "$ 90.00",
  },
  {
    tier: "Tier 7",
    description: `<span style="color: #111827">Over 600</span> @ 0.10c per transaction`,
    price: "$ x.xx",
  },
];

export default pricingData;
