import { createSlice } from "@reduxjs/toolkit/";
import { PayloadAction } from "@reduxjs/toolkit";
import { ContentWidget } from "components/widgets/@types/Widget";

export type AgreementType = {
  agreementId: string;
  content: string;
  title: string;
  widgets: ContentWidget[];
};

export type SignedAgreementPreviewType = SignedAgreementType & {
  mimetype: string;
  binary: string;
};

export type SignedAgreementType = {
  uuid: number;
  userUuid: string;
  agreementId: number;
  name: string;
  route: string;
  hash: string;
  size: string;
  createdAt: string;
  updatedAt: string;
};

export type ServiceAgreementStateType = {
  agreement: AgreementType | null;
  signedAgreement: SignedAgreementType | null;
  signedAgreementPreview: SignedAgreementPreviewType | null;
  signedAgreements: SignedAgreementType[];
};

const initialState: ServiceAgreementStateType = {
  agreement: null,
  signedAgreement: null,
  signedAgreementPreview: null,
  signedAgreements: [],
};

export const serviceAgreementSlice = createSlice({
  name: "serviceAgreementSlice",
  initialState,
  reducers: {
    setAgreement: (state, { payload }: PayloadAction<AgreementType | null>) => {
      state.agreement = payload;
    },
    setPreview: (
      state,
      { payload }: PayloadAction<SignedAgreementPreviewType | null>,
    ) => {
      state.signedAgreementPreview = payload;
    },
    setSignedAgreement: (
      state,
      { payload }: PayloadAction<SignedAgreementType>,
    ) => {
      state.signedAgreement = payload;
    },
    setSignedAgreements: (
      state,
      { payload }: PayloadAction<SignedAgreementType[]>,
    ) => {
      state.signedAgreements = payload.length
        ? payload.map((agreement) => {
            return {
              name: agreement.name,
              createdAt: agreement.createdAt,
              updatedAt: agreement.updatedAt,
              uuid: agreement.uuid,
              userUuid: agreement.userUuid,
              agreementId: agreement.agreementId,
              route: agreement.route,
              hash: agreement.hash,
              size: agreement.size,
            };
          })
        : [];
    },
  },
});

export const {
  setAgreement,
  setPreview,
  setSignedAgreement,
  setSignedAgreements,
} = serviceAgreementSlice.actions;
