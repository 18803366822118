import React, { FC, useState } from "react";

import { Box, Button, Modal, Typography } from "@mui/material";
import {
  freezeAccount,
  unfreezeAccount,
} from "../../store/features/api/clientApi/clientApi";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../store/features/notification/notificationSlice";
import { useAppDispatch } from "../../store";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "8px",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "40px 24px",
};

export type FreezeAccountProps = {
  isFreezeAccountModalOpen: boolean;
  setIsFreezeAccountModalOpen: (value: boolean) => void;
  fechEmergencyStatus: () => void;
  accountStatus: string;
};

export const FreezeAccountModal: FC<FreezeAccountProps> = ({
  isFreezeAccountModalOpen,
  setIsFreezeAccountModalOpen,
  fechEmergencyStatus,
  accountStatus,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const handleModalClose = () => {
    setIsFreezeAccountModalOpen(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const res = await dispatch(
        accountStatus?.length === 0
          ? freezeAccount.initiate({})
          : unfreezeAccount.initiate({}),
      ).unwrap();

      dispatch(showSuccessNotification({ message: res.message }));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }

    setIsLoading(false);
    setIsFreezeAccountModalOpen(false);
    fechEmergencyStatus();
  };

  return (
    <Modal
      open={isFreezeAccountModalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ width: "100%", marginBottom: "24px" }}>
            <Typography
              component={"span"}
              variant={"h6"}
              sx={{ fontWeight: "bold" }}
            >
              {accountStatus?.length === 0
                ? "Freeze account"
                : "Unfreeze account"}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: "24px" }}>
            {accountStatus?.length === 0 ? (
              <Typography variant={"subtitle2"}>
                Activate the 'Freeze Account' feature to temporarily suspend
                your document signing capabilities.
                <br />
                Are you sure you want to freeze your account temporarily? This
                action will prevent you from signing any documents until you
                choose to unfreeze your account.
              </Typography>
            ) : (
              <Typography>
                Unfreeze your account to resume document signing. This action
                restores your full access to document signing capabilities,
                allowing you to continue seamlessly with your signing tasks.
                <br />
                Are you sure you want to unfreeze your account? Once confirmed,
                you will regain access to document signing features and resume
                normal account activity.
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "12px",
            }}
          >
            <Button
              variant={"outlined"}
              color={"inherit"}
              onClick={handleModalClose}
              sx={{ textTransform: "capitalize", padding: "7px 17px" }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant={"contained"}
              color={"success"}
              loading={isLoading}
              sx={{ textTransform: "capitalize", padding: "7px 34px" }}
              onClick={handleSubmit}
            >
              Confirm
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
