import { useState, useEffect, MouseEvent } from "react";

import { Editor } from "@tiptap/core";
import { Button, Menu, MenuItem } from "@mui/material";

import { TipTapEditorContext } from "tiptap/types";
import { FontFamily, FONTS_LIST } from "../../constants";

interface MenuFontFamilyDropdownProps {
  editor: Editor;
  editorContext: TipTapEditorContext;
}

export const MenuFontFamilyDropdown = (props: MenuFontFamilyDropdownProps) => {
  const { editor, editorContext } = props;
  const [fontFamily, setFontFamily] = useState<string>(
    getFontTitle(FontFamily.OpenSans),
  );
  const [isVisible, setIsVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    updateFontFamilyFromState();
  }, [editorContext.state]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsVisible(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsVisible(false);
  };

  function getFontTitle(font: FontFamily): string {
    const item = FONTS_LIST.find(({ value }: any) => value === font);

    return item ? item.title : FONTS_LIST[0].title;
  }

  function isActive(font?: string): boolean {
    if (font) {
      return font === editorContext.state.activeFontFamily;
    }

    return editorContext.state.isActiveFontFamily;
  }

  function updateFontFamily(font: any) {
    editor.chain().focus().setFontFamily(font.value).run();
    setFontFamily(font.title);
    handleClose();
  }

  function updateFontFamilyFromState() {
    const state = editorContext.state;

    if (state.isActiveFontFamily) {
      const fontFamily = state.activeFontFamily;

      setFontFamily(getFontTitle(fontFamily as FontFamily));
    }
  }

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={isVisible ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isVisible ? "true" : undefined}
        style={{ fontFamily: fontFamily }}
        onClick={handleClick}
      >
        {fontFamily}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        // onChange={handleVisibleChange}
        open={isVisible}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {FONTS_LIST.map((item: any) => (
          <MenuItem
            key={item.value}
            // command={item}
            onClick={() => updateFontFamily(item)}
            className={
              isActive(item.value)
                ? "el-tiptap-dropdown-menu__item--active el-tiptap-dropdown-menu__item"
                : "el-tiptap-dropdown-menu__item"
            }
          >
            <span style={{ fontFamily: item.value }}>{item.title}</span>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
