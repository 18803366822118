import React from "react";
import { Editor } from "@tiptap/core";
import { TipTapEditorContext } from "../../../types";
import { MenuTextAlignLeftButton } from "./MenuTextAlignLeftButton";
import { MenuTextAlignCenterButton } from "./MenuTextAlignCenterButton";
import { MenuTextAlignRightButton } from "./MenuTextAlignRightButton";
import { MenuTextAlignJustifyButton } from "./MenuTextAlignJustifyButton";

interface MenuTextAlignButtonsProps {
  editor: Editor;
  editorContext: TipTapEditorContext;
}

export const MenuTextAlignButtons: React.FC<MenuTextAlignButtonsProps> = ({
  editor,
  editorContext,
}) => {
  return (
    <div style={{ display: "flex" }}>
      <MenuTextAlignLeftButton editor={editor} editorContext={editorContext} />
      <MenuTextAlignCenterButton
        editor={editor}
        editorContext={editorContext}
      />
      <MenuTextAlignRightButton editor={editor} editorContext={editorContext} />
      <MenuTextAlignJustifyButton
        editor={editor}
        editorContext={editorContext}
      />
    </div>
  );
};
