import { FC, ReactNode, SyntheticEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonProps,
  styled,
  Tab,
  TabProps,
  Tabs,
  TabsProps,
  Tooltip,
  Typography,
} from "@mui/material";
// @ts-ignore
import {
  useAppDispatch,
  useAppSelector,
  useAppThunkDispatch,
} from "../../store";
import {
  createDocumentThunk,
  updateDocumentThunk,
} from "../../store/features/thunk/documentThunk/documentThunk";
import { InputWidget } from "../widgets/FormWidgets/InputWidget";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import {
  clearEditDocument,
  DocumentType,
} from "../../store/features/document/documentSlice";
import { setIsNewUser } from "../../store/features/company/companySlice";
import { TipTap } from "../../tiptap/TipTap";
import { Editor } from "@tiptap/react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetDocumentMutation,
  useGetPreviewDocumentMutation,
} from "../../store/features/api/documentApi/documentApi";
import { LoadingButton } from "@mui/lab";
import CircularProgress from "@mui/material/CircularProgress";
import { Loader } from "../Loader/Loader";
import { showErrorNotification } from "../../store/features/notification/notificationSlice";
import { PhrasesSidebarComponent } from "components/PhrasesSidebarComponent/PhrasesSidebarComponent";
import { DropdownButton } from "../DropdownButton/DropDownButton";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}
const StyledTabs = styled((props: TabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  borderRadius: "38px",
  padding: "5px",
  backgroundColor: "#CCCCCC",
  width: "100%",
  maxWidth: "938px",
  minHeight: "28px",
  "& .MuiTabs-flexContainer": {
    justifyContent: "center",
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& .MuiTabs-indicatorSpan": {
    display: "none",
  },
});

const StyledTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    height: "28px",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    color: "#000000",
    minHeight: "28px",
    "&.MuiButtonBase-root": {
      width: "100%",
      maxWidth: "466px",
      padding: "10px 0",
    },
    "&.Mui-selected": {
      borderRadius: "20px",
      color: "black",
      backgroundColor: "white",
    },
  }),
);

const ColorButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: "#ed9645",
  "&:hover": {
    backgroundColor: "#e87c17",
  },
}));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export const NewDocumentsComponent: FC = () => {
  const [value, setValue] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [editor, setEditor] = useState<Editor | null>(null);
  const [isSaveBtnHit, setIsSaveBtnHit] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [documentTitle, setDocumentTitle] = useState<any>();
  const [documentContent, setDocumentContent] = useState<any>();

  const [wasDocumentChanged, setWasDocumentChanged] = useState(true);

  const navigate = useNavigate();

  const { documentId } = useParams();
  const [getDocument, { data, isLoading }] = useGetDocumentMutation();
  const [
    getPreviewDocument,
    { data: previewData, isLoading: isPreviewLoading, error: previewError },
  ] = useGetPreviewDocumentMutation();

  const [isContent, setIsContent] = useState(Boolean(data?.content));

  const { isNewUser } = useAppSelector(({ company }) => company);

  const formMethods = useForm();
  const { handleSubmit, setValue: setFormValue, watch } = formMethods;

  const titleChanges = watch("name");

  const dispatchThunk = useAppThunkDispatch();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (documentId && documentId !== "new") {
      getDocument({ uuid: documentId });
      setIsEdit(true);
    } else {
      setIsEdit(false);
      // setEditorState(EditorState.createEmpty());
    }
    return () => clearEditor();
  }, [documentId]);

  useEffect(() => {
    if (data && data.name) {
      setFormValue("name", data.name);

      setDocumentTitle(data.name);
      setDocumentContent(data.content);
    }
  }, [data]);

  useEffect(() => {
    if (previewError) {
      setShowPreview(false);

      dispatch(
        showErrorNotification({
          message:
            // previewError?.data?.error ? previewError?.data?.error : "PDF preview is unavailable" TODO need to fix this error
            "Preview is unavailable",
        }),
      );
    }
  }, [previewError]);

  useEffect(() => {
    setWasDocumentChanged(
      documentTitle !== titleChanges,
    );
  }, [titleChanges]);

  const clearEditor = () => {
    handleEditorContent();
    setFormValue("name", "");
    dispatch(clearEditDocument());

    // onFinish && onFinish();
  };

  const handleEditorContent = () => {};

  const handleTabsValue = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleCreate = async (data: FieldValues, content: string) => {
    return dispatchThunk(
      createDocumentThunk({
        name: data.name,
        content,
      }),
    );
  };

  const handleUpdate = async (data: FieldValues, content: string) => {
    return dispatchThunk(
      updateDocumentThunk({
        ...data,
        id: documentId,
        name: data.name,
        content,
      } as DocumentType),
    );
  };

  const handleSuccessSave = (id: number | undefined) => {
    if (isNewUser || id) {
      navigate(`/${isNewUser ? "locations/newLocation" : `document/${id}`}`);
    }
    dispatch(setIsNewUser(false));
    localStorage.setItem("IS_NEW_USER", JSON.stringify(false));
  };

  const options = [
    {
      label: "CHECK RESULT IN PDF",
      method: () => handlePDFDocumentPreview(),
    },
    {
      label: "CHECK RESULT IN HTML",
      method: () => handleHTMLDocumentPreview(),
    },
  ];

  const handlePDFDocumentPreview = () => {
    if (documentId && documentId !== "new") {
      getPreviewDocument({ id: documentId, type: "pdf" });
    }
    setShowPreview(true);
  };

  const handleHTMLDocumentPreview = () => {
    if (documentId && documentId !== "new") {
      getPreviewDocument({ id: documentId, type: "html" });
    }
    setShowPreview(true);
  };

  const onSubmit = async (data: FieldValues) => {
    setButtonLoader(true);
    setIsSaveBtnHit(true);

    if (editor && editor.getHTML().length && data.name && isContent) {
      const handler = !isEdit ? handleCreate : handleUpdate;
      const res = await handler(data, editor.getHTML());

      if (!res.type.includes("/rejected")) {
        handleSuccessSave(res?.payload?.id || undefined);
      }

      setWasDocumentChanged(false);
      setDocumentTitle(data.name);
      setDocumentContent(editor.getHTML());
    }
    setButtonLoader(false);
  };

  return (
    <>
      {showPreview && (
        <>
          {isPreviewLoading ? (
            <Loader isLoading={isPreviewLoading} />
          ) : (
            <div
              onClick={() => setShowPreview(false)}
              style={{
                position: "absolute",
                width: "100%",
                backgroundColor: "rgba(95, 83, 83, 0.5)",
                height: "100%",
                zIndex: "9999",
                top: "0",
                left: "0",
              }}
            >
              {previewData?.mimetype &&
              previewData.mimetype.includes("html") ? (
                <div
                  style={{
                    width: "100%",
                    maxWidth: "1200px",
                    backgroundColor: "#525659",
                    margin: "20px auto",
                    paddingBottom: "20px",
                    height: "-webkit-fill-available",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      width: "100%",
                      backgroundColor: "#323639",
                      height: "60px",
                    }}
                  ></span>
                  <iframe
                    title="document preview"
                    style={{
                      width: "100%",
                      maxWidth: "21cm",
                      boxSizing: "border-box",
                      height: "100%",
                      margin: "auto",
                      boxShadow:
                        "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                    }}
                    src={`data:${previewData?.mimetype};base64,${previewData?.binary}`}
                  />
                </div>
              ) : (
                <iframe
                  title="document preview"
                  style={{
                    position: "absolute",
                    width: "100%",
                    maxWidth: "1200px",
                    boxSizing: "border-box",
                    backgroundColor: "transparent",
                    padding: "20px 0",
                    border: "none",
                    height: "100%",
                    zIndex: "9999",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                  src={`data:${previewData?.mimetype};base64,${previewData?.binary}`}
                />
              )}
            </div>
          )}
        </>
      )}

      <Box sx={{ padding: `0 50px 0 305px` }}>
        {/*{breadcrumbs && (*/}
        {/*  <Typography variant={"h5"} textAlign={"start"} marginTop={"30px"}>*/}
        {/*    Add Document*/}
        {/*  </Typography>*/}
        {/*)}*/}
        <Box display={"flex"} justifyContent={"center"} paddingTop={"39px"}>
          <StyledTabs value={value} onChange={handleTabsValue}>
            <StyledTab iconPosition={"start"} label="Create the Document" />
            {/*<StyledTab
              iconPosition={"start"}
              label="Upload the ready-made Document"
            />*/}
          </StyledTabs>
        </Box>
        <TabPanel index={0} value={value}>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Box>
                <InputWidget
                  fieldId={"name"}
                  label={"Document title"}
                  required={true}
                  type={"text"}
                  value={"New document"}
                />
              </Box>
              <Box display={"flex"} gap={"2vw"} marginTop={"30px"}>
                <Box width={"100%"}>
                  {isLoading ? (
                    <CircularProgress color="success" />
                  ) : (
                    <Box height={"100%"}>
                      <TipTap
                        setEditor={setEditor}
                        content={data?.content || ""}
                        setIsContent={setIsContent}
                        isContent={isContent}
                        isSaveBtnHit={isSaveBtnHit}
                        setWasDocumentChanged={setWasDocumentChanged}
                      />
                      <Box
                        sx={{
                          padding: "20px 0",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <DropdownButton
                            options={options}
                            disabled={!data || buttonLoader}
                            tooltipMessage={"Preview shows last saved changes"}
                          />
                        </div>
                        <Box sx={{ display: "flex", gap: "10px" }}>
                          <LoadingButton
                            type={"submit"}
                            variant={"contained"}
                            color={"success"}
                            loading={buttonLoader}
                          >
                            Save Changes
                          </LoadingButton>
                          <Tooltip
                            title="Proceed to Workflows"
                            placement="top"
                            arrow
                          >
                            <span>
                              <ColorButton
                                variant="contained"
                                disabled={wasDocumentChanged || buttonLoader}
                                onClick={() => {
                                  navigate("/workflows");
                                }}
                              >
                                Next Step
                              </ColorButton>
                            </span>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
                {editor && <PhrasesSidebarComponent editor={editor} />}
              </Box>
            </form>
          </FormProvider>
        </TabPanel>
        <TabPanel index={1} value={value}>
          <Box
            sx={{
              width: "890px",
              height: "88px",
              margin: "52px auto 0",
              border: "1px dashed green",
              borderRadius: "10px",
            }}
          >
            <Button sx={{ textTransform: "none" }} color={"success"}>
              Upload a file
            </Button>{" "}
            <Typography variant={"subtitle2"}>
              {" "}
              or drag and drop PDF, Word up to 10MB
            </Typography>
          </Box>
        </TabPanel>
      </Box>
    </>
  );
};
