import { twMerge } from "tailwind-merge";

type Props = {
  text: string;
  style: string;
  icon?: string;
  href?: string;
  additionalContent?: string;
};
const Button = ({ text, style, icon, href, additionalContent }: Props) => {
  return (
    <a
      href={href}
      className={twMerge(
        "flex cursor-pointer items-center justify-center rounded-md sm:px-5 sm:py-2",
        style,
      )}
    >
      {text}
      {additionalContent && (
        <span className="text-center font-roboto font-normal text-primary-300 xs:text-xs sm:text-sm">
          {additionalContent}
        </span>
      )}
      {icon && <img src={icon} alt={text} />}
    </a>
  );
};
export default Button;
