export function radioIdValidator(
  value: string,
) {
  const digitRegex = /^\d/;
  const hasDigitAtFirstPosition = digitRegex.test(value);
  if (hasDigitAtFirstPosition) return "Digits at first position are not allowed";
  // Regular expression to match any character that is not alphanumeric
  // or a space
  return specialCharactersValidator(value)
}

export function specialCharactersValidator(
    value: string,
  ) {
    // Regular expression to match any character that is not alphanumeric
    // or a space
    const specialCharregex = /[^A-Za-z0-9\s]/;
    const doesContainSpecialChars = specialCharregex.test(value);
  
    return doesContainSpecialChars ? "You cannot use special characters" : true;
  }
  