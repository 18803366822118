import { Paragraph as TiptapParagraph } from "@tiptap/extension-paragraph";
import uniqKeyAttributeParse from "../extensions/UniqKey/uniqKeyAttributeParse";

const Paragraph = TiptapParagraph.extend({
  addAttributes() {
    return {
      ...(this.parent ? this.parent() : {}),
      key: {
        default: null,
        parseHTML: uniqKeyAttributeParse,
        renderHTML: (attributes) => ({ key: attributes.key }),
        keepOnSplit: false,
      },
      style: {
        default:
          'font-size: 14px; font-family: "Open Sans"; line-height: 1.5; min-height: 21px; word-break: break-word;',
      },
    };
  },
});

export default Paragraph;
