import { FC, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { FreezeAccountModal } from "../../../Modal/FreezeAccountModal";
import { DeleteAccountDataModal } from "../../../Modal/DeleteAccountDataModal";
import { DownloadAccountDataModal } from "../../../Modal/DownloadAccountDataModal";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../store/features/notification/notificationSlice";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  emergencyBackupDownload,
  emergencyBackupRequest,
  emergencyDeleteApprove,
  emergencyDeleteConfirm,
  emergencyDeleteRequest,
  getEmergencyStatus,
} from "../../../../store/features/api/clientApi/clientApi";
import { SecurityVerificationModal } from "../../../Modal/SecurityVerificationModal";
import { OtpMethod } from "../../../../store/features/form/formSlice";
import { DeleteAdminApproveModal } from "../../../Modal/DeleteAdminApproveModal";
import { SuperAdminActions } from "./SuperAdminActions";
import { CustomSwitch } from "../../../CustomSwitch/CustomSwitch";

export type DateOptions = {
  year?: "numeric" | "2-digit";
  month?: "numeric" | "2-digit" | "long" | "short" | "narrow";
  day?: "numeric" | "2-digit";
  hour?: "numeric" | "2-digit";
  minute?: "numeric" | "2-digit";
  hour12?: boolean;
};

export enum RequestType {
  BackupRequest = "backup-request",
  DeleteRequest = "delete-request",
  DeleteApprove = "delete-approve",
  DeleteConfirm = "delete-confirm",
  BackupDownload = "backup-download",
}

export type EmergencyActionsProps = {
  value: number;
  setIsLoadingAuthInfo: (value: boolean) => void;
};

export const EmergencyActions: FC<EmergencyActionsProps> = ({
  value,
  setIsLoadingAuthInfo,
}) => {
  const [isFreezeAccountModalOpen, setIsFreezeAccountModalOpen] =
    useState(false);
  const [isDeleteAccountDataModalOpen, setIsDeleteAccountDataModalOpen] =
    useState(false);
  const [isDownloadAccountDataModalOpen, setIsDownloadAccountDataModalOpen] =
    useState(false);
  const [isSecurityVerificationModalOpen, setIsSecurityVerificationModalOpen] =
    useState(false);
  const [isDeleteAdminApproveModalOpen, setIsDeleteAdminApproveModalOpen] =
    useState(false);

  const [emergencyStatus, setEmergencyStatus] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteAgree, setIsDeleteAgree] = useState(false);
  const [otpMethods, setOtpMethods] = useState<OtpMethod[]>([]);
  const [requestType, setRequestType] = useState<RequestType | undefined>();

  const { isSuperAdmin } = useAppSelector(({ company }) => company);

  const dispatch = useAppDispatch();

  const fechEmergencyStatus = async () => {
    setIsLoadingAuthInfo(true);
    try {
      const res = await dispatch(getEmergencyStatus.initiate({})).unwrap();
      setEmergencyStatus(res);
    } catch (error) {
      dispatch(
        showErrorNotification({
          message: (error as any)?.data?.message ?? (error as any)?.data?.error,
        }),
      );
      console.error(error);
    }
    setIsLoadingAuthInfo(false);
  };

  const handleSubmitModal = async (
    verificationCodes?: Record<any, any> | undefined,
  ) => {
    setIsLoading(true);

    try {
      let res;

      switch (requestType) {
        case RequestType.BackupRequest:
          res = await dispatch(
            emergencyBackupRequest.initiate({
              verificationCodes: verificationCodes?.email
                ? verificationCodes
                : undefined,
            }),
          ).unwrap();
          break;
        case RequestType.DeleteRequest:
          res = await dispatch(
            emergencyDeleteRequest.initiate({
              withoutBackup: isDeleteAgree,
              verificationCodes: verificationCodes?.email
                ? verificationCodes
                : undefined,
            }),
          ).unwrap();
          break;
        case RequestType.DeleteApprove:
          res = await dispatch(
            emergencyDeleteApprove.initiate({
              verificationCodes: verificationCodes?.email
                ? verificationCodes
                : undefined,
            }),
          ).unwrap();
          break;
        case RequestType.BackupDownload:
          res = await dispatch(
            emergencyBackupDownload.initiate({
              verificationCodes: verificationCodes?.email
                ? verificationCodes
                : undefined,
            }),
          ).unwrap();
          break;
        case RequestType.DeleteConfirm:
          res = await dispatch(
            emergencyDeleteConfirm.initiate({
              withoutBackup: isDeleteAgree,
              verificationCodes: verificationCodes?.email
                ? verificationCodes
                : undefined,
            }),
          ).unwrap();
          break;
        default:
          break;
      }

      setOtpMethods(res.methods);

      if (res?.methods && res.methods.length) {
        res.methods.forEach((item: any) => {
          item.message &&
            dispatch(
              showSuccessNotification({
                message: `${item.message} ${item.destination ?? ""}`,
              }),
            );
        });
      } else {
        dispatch(showSuccessNotification({ message: res.message }));
      }

      const handleDownload = async (fileUrl: string) => {
        try {
          const response = await fetch(fileUrl);
          const htmlContent = await response.text();

          const blob = new Blob([htmlContent], { type: "text/html" });
          const blobUrl = window.URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = blobUrl;
          link.setAttribute("download", "data.html");

          link.click();

          window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
          console.error("Download failed:", error);
        }
      };

      if (res?.url) {
        // const link = document.createElement("a");
        // link.href = res?.url;
        // link.setAttribute("download", "");
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link); //TODO: we might need it later!
        handleDownload(res?.url);
      }

      if (res?.methods) {
        setIsDeleteAccountDataModalOpen(false);
        setIsDownloadAccountDataModalOpen(false);
        setIsDeleteAdminApproveModalOpen(false);
        setIsSecurityVerificationModalOpen(true);
      } else {
        setIsSecurityVerificationModalOpen(false);
        await fechEmergencyStatus();
      }
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
    setIsLoading(false);
  };

  const formatDate = (unformattedDate: string, hoursToAdd?: number) => {
    const date = new Date(unformattedDate);

    if (hoursToAdd && hoursToAdd !== 0) {
      date.setHours(date.getHours() + hoursToAdd);
    }

    const options: DateOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = date
      .toLocaleDateString("en-US", options)
      .replace("at ", "");
    if (hoursToAdd && hoursToAdd !== 0) {
      return `Link active till ${formattedDate}`;
    } else {
      return `Request time: ${formattedDate}`;
    }
  };

  useEffect(() => {
    if (value === 5) {
      fechEmergencyStatus();
    }
  }, [value]);

  return (
    <Box sx={{ paddingTop: "40px" }}>
      {isSuperAdmin && (
        <SuperAdminActions
          emergencyStatus={emergencyStatus}
          setRequestType={setRequestType}
          fechEmergencyStatus={fechEmergencyStatus}
          setIsDeleteAdminApproveModalOpen={setIsDeleteAdminApproveModalOpen}
        />
      )}
      <Box sx={{ marginBottom: "40px" }}>
        <Box sx={{ textAlign: "start", marginBottom: "30px" }}>
          <Typography
            component={"span"}
            variant={"h5"}
            sx={{ fontWeight: "bold" }}
          >
            Freeze account
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: "85px",
            padding: "20px",
            marginBottom: "1px",
            borderRadius: "4px",
            backgroundColor: "#fff",
            boxShadow: "5px 5px 15px #d9d9d9, -5px -5px 15px #ffffff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              width: "70%",
            }}
          >
            <AcUnitIcon />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography variant={"subtitle1"}>
                Pause Signatures, Amplify Security
              </Typography>
              <Box sx={{ display: "flex", gap: "5px" }}>
                <Typography
                  variant={"body2"}
                  sx={{ opacity: "0.5", textAlign: "start" }}
                >
                  The 'Freeze Account' toggle lets you suspend signature
                  privileges temporarily, reinforcing the security measures for
                  your valuable documents.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "20%",
            }}
          >
            <CustomSwitch
              checked={emergencyStatus?.freeze?.length !== 0}
              sx={{ margin: "auto" }}
              onClick={() => setIsFreezeAccountModalOpen(true)}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "40px" }}>
        <Box sx={{ textAlign: "start", marginBottom: "30px" }}>
          <Typography
            component={"span"}
            variant={"h5"}
            sx={{ fontWeight: "bold" }}
          >
            Backup account data
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: "85px",
            padding: "20px",
            marginBottom: "1px",
            borderRadius: "4px",
            backgroundColor: "#fff",
            boxShadow: "5px 5px 15px #d9d9d9, -5px -5px 15px #ffffff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              width: "70%",
            }}
          >
            <SimCardDownloadIcon />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography variant={"subtitle1"}>
                Save Your Account Story
              </Typography>
              <Box sx={{ display: "flex", gap: "5px" }}>
                <Typography
                  variant={"body2"}
                  sx={{ opacity: "0.5", textAlign: "start" }}
                >
                  Backup Account Data,' you're in control. Request a download of
                  all your account details—documents, preferences, and more.
                  It's an easy way to download all your account data.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "20%",
            }}
          >
            {emergencyStatus?.backup &&
              emergencyStatus?.backup?.requestTime &&
              !emergencyStatus?.backup?.generateTime && (
                <Box sx={{ margin: "auto" }}>
                  <Typography
                    variant={"body2"}
                    sx={{ opacity: "0.5", textAlign: "start" }}
                  >
                    {formatDate(emergencyStatus?.backup?.requestTime)}
                  </Typography>
                  <Typography
                    variant={"body2"}
                    sx={{ opacity: "0.5", textAlign: "start" }}
                  >
                    Backup generation may take up to 48 hours
                  </Typography>
                </Box>
              )}
            {(!emergencyStatus?.backup ||
              Object.keys(emergencyStatus?.backup).length === 0) && (
              <LoadingButton
                variant={"contained"}
                color={"success"}
                sx={{ margin: "auto" }}
                onClick={() => {
                  setIsDownloadAccountDataModalOpen(true);
                  setRequestType(RequestType.BackupRequest);
                }}
              >
                Request
              </LoadingButton>
            )}
            {emergencyStatus?.backup?.generateTime && (
              <Box sx={{ margin: "auto" }}>
                <LoadingButton
                  variant={"contained"}
                  color={"success"}
                  onClick={() => {
                    setIsDownloadAccountDataModalOpen(true);
                    setRequestType(RequestType.BackupDownload);
                  }}
                >
                  Download
                </LoadingButton>
                {emergencyStatus?.backup?.downloadTime && (
                  <Typography
                    variant={"body2"}
                    sx={{
                      opacity: "0.5",
                      marginTop: "5px",
                    }}
                  >
                    {formatDate(emergencyStatus?.backup?.generateTime, 48)}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "40px" }}>
        <Box sx={{ textAlign: "start", marginBottom: "30px" }}>
          <Typography
            component={"span"}
            variant={"h5"}
            sx={{ fontWeight: "bold" }}
          >
            Delete account
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: "85px",
            padding: "20px",
            marginBottom: "1px",
            borderRadius: "4px",
            backgroundColor: "#fff",
            boxShadow: "5px 5px 15px #d9d9d9, -5px -5px 15px #ffffff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              width: "70%",
            }}
          >
            <CancelIcon />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography variant={"subtitle1"}>Close Your Account</Typography>
              <Box sx={{ display: "flex", gap: "5px" }}>
                <Typography
                  variant={"body2"}
                  sx={{ opacity: "0.5", textAlign: "start" }}
                >
                  Short Description: "Click 'Delete Account' to close your
                  account. We'll remove your info securely. It's as simple as
                  that.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "20%",
            }}
          >
            {emergencyStatus?.delete &&
              emergencyStatus?.delete?.requestTime &&
              !emergencyStatus?.delete?.approveTime && (
                <Box sx={{ margin: "auto" }}>
                  <Typography
                    variant={"body2"}
                    sx={{ opacity: "0.5", textAlign: "start" }}
                  >
                    {formatDate(emergencyStatus.delete.requestTime)}
                  </Typography>
                  <Typography
                    variant={"body2"}
                    sx={{ opacity: "0.5", textAlign: "start" }}
                  >
                    Please wait while we approve your request
                  </Typography>
                </Box>
              )}
            {(!emergencyStatus?.delete ||
              Object.keys(emergencyStatus?.delete).length === 0) && (
              <LoadingButton
                variant={"contained"}
                color={"error"}
                sx={{ margin: "auto" }}
                onClick={() => {
                  setIsDeleteAccountDataModalOpen(true);
                  setRequestType(RequestType.DeleteRequest);
                }}
              >
                Request
              </LoadingButton>
            )}
            {emergencyStatus?.delete?.approveTime &&
              !emergencyStatus?.delete?.confirmTime && (
                <LoadingButton
                  variant={"contained"}
                  color={"error"}
                  sx={{ margin: "auto" }}
                  onClick={() => {
                    setIsDeleteAccountDataModalOpen(true);
                    setRequestType(RequestType.DeleteConfirm);
                  }}
                >
                  Delete
                </LoadingButton>
              )}
            {emergencyStatus?.delete?.confirmTime && (
              <Typography
                variant={"body2"}
                sx={{
                  opacity: "0.5",
                  margin: "auto",
                }}
              >
                Your account will be deleted in 24-48 hours
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      <FreezeAccountModal
        isFreezeAccountModalOpen={isFreezeAccountModalOpen}
        setIsFreezeAccountModalOpen={setIsFreezeAccountModalOpen}
        fechEmergencyStatus={fechEmergencyStatus}
        accountStatus={emergencyStatus?.freeze}
      />
      <DeleteAccountDataModal
        isDeleteAccountDataModalOpen={isDeleteAccountDataModalOpen}
        setIsDeleteAccountDataModalOpen={setIsDeleteAccountDataModalOpen}
        accountStatus={emergencyStatus}
        setIsDownloadAccountDataModalOpen={setIsDownloadAccountDataModalOpen}
        setIsDeleteAgree={setIsDeleteAgree}
        handleSubmitModal={handleSubmitModal}
        isLoading={isLoading}
        setRequestType={setRequestType}
        requestType={requestType}
      />
      <DownloadAccountDataModal
        isDownloadAccountDataModalOpen={isDownloadAccountDataModalOpen}
        setIsDownloadAccountDataModalOpen={setIsDownloadAccountDataModalOpen}
        handleSubmitModal={handleSubmitModal}
        isLoading={isLoading}
        requestType={requestType}
      />
      <SecurityVerificationModal
        isSecurityVerificationModalOpen={isSecurityVerificationModalOpen}
        setIsSecurityVerificationModalOpen={setIsSecurityVerificationModalOpen}
        handleSubmitModal={handleSubmitModal}
        otpMethods={otpMethods}
        isLoading={isLoading}
      />
      <DeleteAdminApproveModal
        isDeleteAdminApproveModalOpen={isDeleteAdminApproveModalOpen}
        setIsDeleteAdminApproveModalOpen={setIsDeleteAdminApproveModalOpen}
        handleSubmitModal={handleSubmitModal}
        isLoading={isLoading}
      />
    </Box>
  );
};
