import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { FC } from "react";

export type LoaderProps = {
  isLoading: boolean;
};

export const Loader: FC<LoaderProps> = ({ isLoading }) => (
  <div>
    <Backdrop
      sx={{
        color: "#008000",
        zIndex: "100",
        padding: "0 50px 0 305px",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
      }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  </div>
);
