import { ICountry, State } from "country-state-city";
import { SelectOptionList } from "../components/widgets/FormWidgets/SelectWidget/SelectWidget.types";

export function getSelectOptionListForStates(
  fieldId: string,
  country: string | undefined,
): SelectOptionList[] {
  const isState = fieldId.includes("region");

  if (!isState || !country) return [];
  const statesArr = State.getStatesOfCountry(country);

  if (!statesArr) return [];
  let statesOptionList = statesArr.map(
    (state): SelectOptionList => ({
      value: state.isoCode,
      label: state.name,
    }),
  );
  return statesOptionList;
}

export function getSelectOptionListForCountry(country: ICountry) {
  const countryOptionList = {
    value: country.isoCode,
    label: `${country.flag} ${country.name}`,
  };
  return countryOptionList;
}
