import { Coordinates, DefaultFormWidgetProps } from "../../@types/Widget";

export type TextareaWidgetType = {
  type: "field";
  position: Coordinates;
  config: TextareaWidgetConfig;
};

export type TextareaWidgetProps = {
  type: TextareaFieldTypes.TEXTAREA;
  rows?: number;
  value?: TextareaFieldValues;
} & DefaultFormWidgetProps;

export type TextareaWidgetConfig = {
  props: TextareaWidgetProps;
};

export enum TextareaFieldTypes {
  TEXTAREA = "textarea",
}
export type TextareaFieldType = "textarea";

export type TextareaFieldValues = string | number;
