import { NavLink } from "react-router-dom";
import Button from "../../shared/Button";
import { NavProps } from "../../shared/types";

// @ts-ignore
import mainlogo from "../../assets/mainlogo.svg";
// @ts-ignore
import facebook from "../../assets/facebook.png";
// @ts-ignore
import linkedin from "../../assets/linkedin.png";

const Footer = ({
  scrollToFAQ,
  scrollToContactUs,
  scrollToFeatures,
  scrollToHowItWorks,
  scrollToPricing,
  scrollToAboutUs,
}: NavProps) => {
  const navMenuItems = [
    {
      label: "FEATURES",
      onClick: scrollToFeatures,
    },
    {
      label: "HOW IT WORKS",
      onClick: scrollToHowItWorks,
    },
    {
      label: "PRICING",
      onClick: scrollToPricing,
    },
    {
      label: "ABOUT US",
      onClick: scrollToAboutUs,
    },
  ];

  return (
    <section>
      <div className="flex justify-between bg-seconary-100 shadow-[0px_-4px_20px_0px_rgba(0,0,0,0.15)_inset] xs:flex-col xs:px-[24px] xs:py-[65px] sm:flex-row sm:px-[96px] sm:py-[100px] md:px-[139px] md:py-[84px] lg:px-[153px]">
        <div className="flex gap-x-[179px] xs:mb-[49px] xs:flex-col sm:mb-0 lg:flex-row">
          <div className="xs:mb-[40px]">
            <img className="lg:mt-[-30px]" src={mainlogo} alt="mainlogowhite" />
          </div>
          <div className="flex gap-x-[116px] font-poppins text-xs font-medium text-primary-700">
            <div className="flex gap-x-[104px] xs:flex-col xs:gap-y-4 md:flex-row">
              <ul className="flex flex-col gap-y-4">
                {navMenuItems.map((item, index) => (
                  <li key={index}>
                    <NavLink
                      to="/"
                      className="cursor-pointer transition duration-500 hover:text-primary-400"
                      onClick={() => {
                        setTimeout(() => item.onClick(), 100);
                      }}
                    >
                      {item.label}
                    </NavLink>
                  </li>
                ))}
              </ul>
              <ul className="flex flex-col gap-y-4">
                <li>
                  <NavLink
                    to="/"
                    className="cursor-pointer transition duration-500 hover:text-primary-400"
                    onClick={() => {
                      setTimeout(() => scrollToFAQ(), 100);
                    }}
                  >
                    FAQ
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/"
                    className="cursor-pointer transition duration-500 hover:text-primary-400"
                    onClick={() => {
                      setTimeout(() => scrollToContactUs(), 100);
                    }}
                  >
                    CONTACT US
                  </NavLink>
                </li>
                {/*<li>*/}
                {/*  <a*/}
                {/*    className="flex items-center gap-2"*/}
                {/*    onClick={() => {*/}
                {/*      setIsSupportModalOpen(true);*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    SUPPORT*/}
                {/*  </a>*/}
                {/*</li>*/}
                {/*TODO: we will need this later*/}
              </ul>
            </div>
            <div className="flex flex-col gap-y-4">
              <NavLink to="/privacy-policy">PRIVACY POLICY</NavLink>
              <NavLink to="/cookies-policy">COOKIES POLICY</NavLink>
              <NavLink to="/deletion-policy">DELETION POLICY</NavLink>
              <NavLink to="/terms-of-service">TERMS OF SERVICE</NavLink>
              <a href={`javascript: Cookiebot.renew()`}>MANAGE COOKIES</a>
            </div>
          </div>
        </div>
        <div>
          <div className="w-fit xs:mb-10 md:mb-16">
            <Button
              text={"Start free trial up"}
              style={"text-primary-010 bg-primary-400 xs:w-[164px] xs:h-[42px]"}
              href={"/signup"}
            />
            <p className="text-center font-roboto font-normal text-primary-300 xs:text-xs sm:text-sm">
              No credit card required
            </p>
          </div>
          <div className="flex flex-col text-xs text-primary-700">
            <p>+1 (888) 211-6693</p>
            <a
              className="text-primary-200 underline"
              href="mailto:contact_us@waiverking.com"
            >
              contact_us@waiverking.com
            </a>
            <a
              className="text-primary-200 underline"
              href="mailto:support@waiverking.com"
            >
              support@waiverking.com
            </a>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between bg-seconary-100 text-primary-700 xs:px-[24px] xs:py-[22px] xs:text-xs sm:px-[96px] md:px-[139px] lg:px-[153px]">
        <p>WaverKing © Copyright 2023</p>
        <div className="flex gap-5 xs:mr-0 sm:mr-[75px]">
          <a
            href="https://www.facebook.com/waiverking"
            target="_blank"
            title="Facebook"
          >
            <img src={facebook} alt="facebook" />
          </a>
          <a
            href="https://www.linkedin.com/company/waiverking-inc-"
            target="_blank"
            title="Linkedin"
          >
            <img src={linkedin} alt="linkedin" />
          </a>
        </div>
      </div>
    </section>
  );
};
export default Footer;
