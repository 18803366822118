import { createApi } from "@reduxjs/toolkit/query/react";
import { baseCustomFetchBase } from "../service/customFetchBase";

import { FieldValues } from "react-hook-form";

const serviceAgreement = "service-agreement";
const serviceAgreements = "service-agreements";

const serviceAgreementApi = createApi({
  reducerPath: "form/serviceAgreementApi",
  baseQuery: baseCustomFetchBase,
  endpoints: (builder) => ({
    getAgreement: builder.mutation<any, any>({
      query: () => ({
        url: `${serviceAgreement}`,
        method: "GET",
      }),
    }),
    getSignedAgreement: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `${serviceAgreement}/${id}`,
        method: "GET",
      }),
    }),
    getSignedAgreements: builder.mutation<any, {}>({
      query: () => ({
        url: `${serviceAgreements}`,
        method: "GET",
      }),
    }),
    signAgreement: builder.mutation<any, FieldValues>({
      query: (data) => ({
        url: `${serviceAgreement}`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export default serviceAgreementApi;

export const {
  getAgreement,
  getSignedAgreement,
  getSignedAgreements,
  signAgreement,
} = serviceAgreementApi.endpoints;
