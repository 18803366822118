import useMediaQuery from "../hooks/useMediaQuery";

type Props = {
  title?: string;
  description: string;
};
const Overview = ({ title, description }: Props) => {
  const isAboveExtraSmallScreen = useMediaQuery("(min-width: 375px");
  const isUnderSmallScreen = useMediaQuery("(max-width: 480px");

  return (
    <div className="flex">
      <div className="h-224 w-px flex-shrink-0 bg-gradient-to-b from-gray-900 opacity-30 xs:mr-3 lg:mx-3"></div>
      <div className="flex flex-col xs:w-full sm:w-[261px] md:w-[350px] lg:w-[261px]">
        {title && (
          <h3 className="mb-6 font-poppins text-xl font-semibold text-primary-400">
            {title}
          </h3>
        )}
        <p
          className="text-primary-300"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      {isAboveExtraSmallScreen && isUnderSmallScreen && (
        <div className="h-224 w-px flex-shrink-0 bg-gradient-to-b from-white opacity-30 xs:ml-3"></div>
      )}
    </div>
  );
};
export default Overview;
