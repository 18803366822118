// TablePopover.tsx
import { useState, FC, useEffect, MouseEvent } from "react";
import { Editor } from "@tiptap/core";
import { MenuButton } from "../../MenuButton";
import { TipTapEditorContext } from "../../../types";
import { Menu } from "@mui/material";
// @ts-ignore
import { CreateTablePopover } from "./CreateTablePopover";

interface TablePopoverProps {
  editor: Editor;
  editorContext: TipTapEditorContext;
}

export const TablePopover: FC<TablePopoverProps> = ({
  editor,
  editorContext,
}) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [id, setId] = useState(popoverVisible ? "simple-popover" : undefined);

  useEffect(() => {
    setId(popoverVisible ? "simple-popover" : undefined);
  }, [popoverVisible]);

  const isTableActive = (): boolean => {
    return editorContext.state.isActiveTable;
  };

  const enableMergeCells = (): boolean => {
    return editorContext.state.isCanMergeCells;
  };

  const enableSplitCell = (): boolean => {
    return editorContext.state.isCanSplitCell;
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setPopoverVisible(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverVisible(false);
  };

  const createTable = ({ row, col }: { row: number; col: number }) => {
    editor
      .chain()
      .focus()
      .insertTable({ rows: row, cols: col, withHeaderRow: false })
      .run();

    handleClose();
  };

  const changeBorder = () => {
    editor.chain().focus().setBorderStyleToAllTableCells().run();

    handleClose();
  };

  const addColumnBefore = () => {
    editor.chain().focus().addColumnBefore().run();
  };
  const toggleHeaderRow = () => {
    editor.chain().focus().toggleHeaderRow().run();
  };
  const fixTables = () => {
    editor.chain().focus().fixTables().run();
  };
  const addColumnAfter = () => {
    editor.chain().focus().addColumnAfter().run();
  };

  const addRowBefore = () => {
    editor.chain().focus().addRowBefore().run();
  };

  const addRowAfter = () => {
    editor.chain().focus().addRowAfter().run();
  };

  const mergeCells = () => {
    editor.chain().focus().mergeCells().run();
  };

  const splitCell = () => {
    editor.chain().focus().splitCell().run();
  };

  const deleteColumn = () => {
    editor.chain().focus().deleteColumn().run();
  };

  const deleteRow = () => {
    editor.chain().focus().deleteRow().run();
  };

  const deleteTable = () => {
    editor.chain().focus().deleteTable().run();
  };
  return (
    <>
      <MenuButton
        title="Table"
        icon="table-icon"
        isActive={false}
        command={handleClick}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        // onChange={handleVisibleChange}
        open={popoverVisible}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div className="el-tiptap-popper__menu">
          <div className="el-tiptap-popper__menu__item">
            <CreateTablePopover
              onCreateTable={createTable}
              closeMainPopover={() => {}}
            />
          </div>

          {/*<div className="el-tiptap-popper__menu__item__separator" />*/}

          {/*<div className="el-tiptap-popper__menu__item">*/}
          {/*  <CreateTablePopover*/}
          {/*    onCreateTable={createTable}*/}
          {/*    closeMainPopover={() => {}}*/}
          {/*  />*/}
          {/*</div>*/}

          <div className="el-tiptap-popper__menu__item__separator" />

          <div
            className={`el-tiptap-popper__menu__item${
              !isTableActive ? " el-tiptap-popper__menu__item--disabled" : ""
            }`}
            onMouseDown={handleClose}
            onClick={addColumnBefore}
          >
            <span>Add Column Before</span>
          </div>

          <div className="el-tiptap-popper__menu__item__separator" />

          <div
            className={`el-tiptap-popper__menu__item${
              !isTableActive ? " el-tiptap-popper__menu__item--disabled" : ""
            }`}
            onMouseDown={handleClose}
            onClick={changeBorder}
          >
            <span>Toggle transparent border</span>
          </div>

          <div
            className={`el-tiptap-popper__menu__item${
              !isTableActive ? " el-tiptap-popper__menu__item--disabled" : ""
            }`}
            onMouseDown={handleClose}
            onClick={addColumnAfter}
          >
            <span>Add Column After</span>
          </div>

          <div
            className={`el-tiptap-popper__menu__item${
              !isTableActive ? " el-tiptap-popper__menu__item--disabled" : ""
            }`}
            onMouseDown={handleClose}
            onClick={deleteColumn}
          >
            <span>Delete column</span>
          </div>

          <div className="el-tiptap-popper__menu__item__separator" />

          <div
            className={`el-tiptap-popper__menu__item${
              !isTableActive ? " el-tiptap-popper__menu__item--disabled" : ""
            }`}
            onMouseDown={handleClose}
            onClick={addRowBefore}
          >
            <span>Add Row Before</span>
          </div>

          <div
            className={`el-tiptap-popper__menu__item${
              !isTableActive ? " el-tiptap-popper__menu__item--disabled" : ""
            }`}
            onMouseDown={handleClose}
            onClick={addRowAfter}
          >
            <span>Add Row After</span>
          </div>

          <div
            className={`el-tiptap-popper__menu__item${
              !isTableActive ? " el-tiptap-popper__menu__item--disabled" : ""
            }`}
            onMouseDown={handleClose}
            onClick={deleteRow}
          >
            <span>Delete Row</span>
          </div>

          <div className="el-tiptap-popper__menu__item__separator" />

          <div
            className={`el-tiptap-popper__menu__item${
              !enableMergeCells ? " el-tiptap-popper__menu__item--disabled" : ""
            }`}
            onMouseDown={handleClose}
            onClick={mergeCells}
          >
            <span>Merge Cells</span>
          </div>

          <div
            className={`el-tiptap-popper__menu__item${
              !enableSplitCell ? " el-tiptap-popper__menu__item--disabled" : ""
            }`}
            onMouseDown={handleClose}
            onClick={splitCell}
          >
            <span>Split Cell</span>
          </div>

          <div className="el-tiptap-popper__menu__item__separator" />

          <div
            className={`el-tiptap-popper__menu__item${
              !isTableActive ? " el-tiptap-popper__menu__item--disabled" : ""
            }`}
            onMouseDown={handleClose}
            onClick={deleteTable}
          >
            <span>Delete Table</span>
          </div>

          <div className="el-tiptap-popper__menu__item__separator" />

          <div
            className={`el-tiptap-popper__menu__item${
              !isTableActive ? " el-tiptap-popper__menu__item--disabled" : ""
            }`}
            onMouseDown={handleClose}
            onClick={fixTables}
          >
            <span>Fix Tables</span>
          </div>

          <div className="el-tiptap-popper__menu__item__separator" />

          <div
            className={`el-tiptap-popper__menu__item${
              !isTableActive ? " el-tiptap-popper__menu__item--disabled" : ""
            }`}
            onMouseDown={handleClose}
            onClick={toggleHeaderRow}
          >
            <span>Toggle header row</span>
          </div>
        </div>
      </Menu>
    </>
  );
};
