import { ResizeDirection } from "./types/enums/ResizeDirection";

export const DEFAULT_IMAGE_URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
export const DEFAULT_IMAGE_WIDTH = 200;
export const DEFAULT_ASPECT_RATIO = 0.5;
export const DEFAULT_IMAGE_DISPLAY = "inline-block";

export enum FontFamily {
  OpenSans = "Open Sans",
  Lato = "Lato",
  Roboto = "Roboto",
  RobotoCondensed = "Roboto Condensed",
  PTSans = "PT Sans",
  Arial = "Arial",
  Verdana = "Verdana",
  TimesNewRoman = "Times New Roman",
  Montserrat = "Montserrat",
  Oswald = "Oswald",
  Raleway = "Raleway",
}

export const FONTS_LIST = [
  { title: "Open Sans", value: FontFamily.OpenSans },
  { title: "Lato", value: FontFamily.Lato },
  { title: "Roboto", value: FontFamily.Roboto },
  { title: "Roboto Condensed", value: FontFamily.RobotoCondensed },
  { title: "PT Sans", value: FontFamily.PTSans },
  { title: "Arial", value: FontFamily.Arial },
  { title: "Verdana", value: FontFamily.Verdana },
  { title: "Times New Roman", value: FontFamily.TimesNewRoman },
  { title: "Montserrat", value: FontFamily.Montserrat },
  { title: "Oswald", value: FontFamily.Oswald },
  { title: "Raleway", value: FontFamily.Raleway },
];

export const FONT_SIZES = [10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];
export const DEFAULT_FONT_SIZE = 14;

export const resizeDirections = [
  ResizeDirection.TOP_LEFT,
  ResizeDirection.TOP_RIGHT,
  ResizeDirection.BOTTOM_LEFT,
  ResizeDirection.BOTTOM_RIGHT,
];
