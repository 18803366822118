import { FC, RefObject } from "react";
import { FeatureType } from "../../shared/types";
import Heading from "../../shared/Heading";
import registerStepsData from "../../data/registerStepsData";
import Step from "./Step";

interface HowItWorksProps {
  howItWorksRef: RefObject<HTMLDivElement>;
}

const HowItWorks: FC<HowItWorksProps> = ({ howItWorksRef }) => {
  return (
    <section id="howitworks" ref={howItWorksRef}>
      <div className="xs:px-[23px] sm:px-[96px] md:px-[139px] lg:px-[153px] lg:pb-0 lg:pt-[100px]">
        <Heading>How it works</Heading>
        <div className="mb-[100px] flex gap-8 xs:mt-[-35px] xs:flex-col sm:mt-[-25px] md:mt-0 md:flex-row">
          <div className="flex items-center justify-center xs:h-[185px] sm:h-[312px] md:h-[217px] md:w-1/2 lg:h-auto">
            <video controls>
              <source src="/wkform-how-it-works.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="xs:text-sm sm:text-base md:w-1/2">
            <p className="mb-8">
              WaiverKing is packed full of features that will benefit every
              aspect of your business. Furthermore, our products and services
              are backed by our expert and compassionate support team –
              distinguishing themselves as the best in the industry, one happy
              client at a time.
            </p>
            <p className="mb-8">
              Don’t risk your business on a lesser product that creates more
              problems than it solves! WaiverKing transitions your customers
              from registration to enjoying services in a snap. Plus they’re
              automatically integrated into the MindBodyOnline system so you can
              deliver a superior customer experience every time.
            </p>
            <p>
              WaiverKing has drastically improved countless influential
              businesses across multiple industries just like yours and it will
              absolutely transform your business process for the better!
            </p>
          </div>
        </div>
      </div>
      <div>
        <h2 className="text-center font-poppins text-xl font-semibold text-primary-700 xs:m-auto xs:w-3/4 sm:w-full">
          Register with these{" "}
          <span className="text-primary-200">Quick & Easy</span> Steps
        </h2>
        <div className="flex flex-wrap gap-7 pt-16 xs:px-[23px] xs:py-[40px] sm:gap-y-20 sm:px-[96px] sm:py-[65px] md:px-[139px] md:py-[60px] lg:px-[153px] lg:py-[70px]">
          {registerStepsData.map((step: FeatureType) => (
            <Step
              key={step.title}
              icon={step.icon}
              title={step.title}
              description={step.description}
            />
          ))}
        </div>
      </div>
      <div className="text-primary-700 xs:px-[23px] sm:px-[96px] md:px-[139px] lg:px-[153px]">
        <p>Any Questions?</p>
        <p>Phone: 1-888-211-6693</p>
        <p>
          Click here to{" "}
          <a
            href="mailto:contact_us@waiverking.com"
            className="text-primary-200 underline"
          >
            Email Us!
          </a>
        </p>
      </div>
    </section>
  );
};
export default HowItWorks;
