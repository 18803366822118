// Import React, necessary libraries, and components
import React, { useState, useEffect } from "react";
import { Editor } from "@tiptap/core";
import { BubbleMenu } from "@tiptap/react";
import { TipTapEditorContext } from "../../types";
import { LinkBubbleMenu } from "./LinkBubbleMenu";
import { AllSelection, TextSelection } from "prosemirror-state";
import { UpdateBubbleMenuPositionPlugin } from "../../plugins/UpdateBubbleMenuPositionPlugin";

import { MenuBoldButton } from "../MenuItems/MenuBoldButton";
import { MenuItalicButton } from "../MenuItems/MenuItalicButton";
import { MenuUnderlineButton } from "../MenuItems/MenuUnderlineButton";
import { MenuStrikeButton } from "../MenuItems/MenuStrikeButton";
import { MenuButton } from "../MenuButton";
import { MenuAddLinkButton } from "../MenuItems/Link/MenuAddLinkButton";
import { MenuRemoveLinkButton } from "../MenuItems/Link/MenuRemoveLinkButton";

// Define the component prop types
interface MenuBubbleProps {
  isEditable: boolean;
  editor: Editor;
  editorContext: TipTapEditorContext;
}

// Define the menu type enum
enum MenuType {
  NONE = "none",
  DEFAULT = "default",
  LINK = "link",
}

// Define the functional component
export const MenuBubble: React.FC<MenuBubbleProps> = ({
  isEditable,
  editor,
  editorContext,
}) => {
  const [activeMenu, setActiveMenu] = useState<MenuType | null>(MenuType.NONE);
  const [isLinkBack, setIsLinkBack] = useState(false);
  const [isActive, setIsActive] = useState(!!activeMenu);

  const tippyOptions = {
    maxWidth: 500,
    hideOnClick: true,
    plugins: [UpdateBubbleMenuPositionPlugin],
  };

  const isDefaultMenuActive = activeMenu === MenuType.DEFAULT;
  const isLinkMenuActive = activeMenu === MenuType.LINK;
  const isLinkSelection = editorContext.state.isActiveLink;

  const shouldShow = () => {
    setActiveMenu(null);
    const isEmpty = editor.state.selection.empty;
    return !editorContext.state.isActiveImage && !isEmpty;
  };

  useEffect(() => {
    setIsActive(!!activeMenu);
  }, [activeMenu]);

  useEffect(() => {
    if (isLinkSelection) {
      if (!isLinkBack) {
        setActiveMenu(MenuType.LINK);
      }
    } else {
      setActiveMenu(getCurrentMenuType());
      setIsLinkBack(false);
    }
  }, [editorContext.selection]);

  const setMenuType = (type: MenuType) => {
    setActiveMenu(type);
  };

  const getCurrentMenuType = (): MenuType => {
    if (isLinkSelection) return MenuType.LINK;
    if (
      editor &&
      (editor.state.selection instanceof TextSelection ||
        editor.state.selection instanceof AllSelection)
    ) {
      return MenuType.DEFAULT;
    }
    return MenuType.NONE;
  };

  return (
    <BubbleMenu
      editor={editor}
      shouldShow={shouldShow}
      tippyOptions={tippyOptions}
    >
      <div
        className={`el-tiptap-editor__menu-bubble${
          !isEditable ? " el-tiptap-editor__menu-bubble-emoji" : ""
        }`}
        style={{ display: isActive ? "block" : "none" }}
      >
        {isDefaultMenuActive && (
          <div className="el-tiptap-editor__menu-bubble__default">
            <MenuBoldButton editor={editor} editorContext={editorContext} />
            <MenuItalicButton editor={editor} editorContext={editorContext} />
            <MenuUnderlineButton
              editor={editor}
              editorContext={editorContext}
            />
            <MenuStrikeButton editor={editor} editorContext={editorContext} />
            <MenuAddLinkButton editor={editor} editorContext={editorContext} />
            <MenuRemoveLinkButton editor={editor} />
            <MenuButton
              icon="comment"
              title="Add comment"
              command={() => {
                setMenuType(MenuType.NONE);
                // editorContext.state.setIsTextCommentOpen(true);
              }}
            />
          </div>
        )}
        {isLinkMenuActive && (
          <LinkBubbleMenu
            editor={editor}
            // editorContext={editorContext}
            // linkBack={linkBack}
            // setMenuType={setMenuType}
          />
        )}
        {/*{editorContext.state.isTextCommentOpen && (*/}
        {/*  <TextCommentBubbleMenu*/}
        {/*    editor={editor}*/}
        {/*    editorContext={editorContext}*/}
        {/*    setMenuType={setMenuType}*/}
        {/*  />*/}
        {/*)}*/}
      </div>
    </BubbleMenu>
  );
};
