import { FC, useEffect, useState } from "react";
import { Alert, Box, Button, Skeleton, Typography } from "@mui/material";
// @ts-ignore
import dropbox from "../../assets/img/dropbox.svg";
import { CustomSwitch } from "../CustomSwitch/CustomSwitch";
import { DropboxWarningModal } from "../Modal/DropboxWarningModal";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useAppDispatch, useAppThunkDispatch } from "../../store";
import { updateWorkflowThunk } from "../../store/features/thunk/workflowThunk/workflowThunk";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../store/features/notification/notificationSlice";
import {
  dropboxConnectConfirm,
  dropboxConnectionStatus,
  useGetWorkflowMutation,
} from "../../store/features/api/workflowApi/workflowApi";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import { DropboxManageConnectionModal } from "../Modal/DropboxManageConnectionModal";

export type DropboxExtensionProps = {
  workflowId: string;
};

export const DropboxExtension: FC<DropboxExtensionProps> = ({ workflowId }) => {
  const [dropboxSwitch, setDropboxSwitch] = useState(false);
  const [dropboxStatusRes, setDropboxStatusRes] = useState<any>(null);
  const [isDropboxModalOpen, setIsDropboxModalOpen] = useState(false);
  const [dropboxCode, setDropboxCode] = useState<string | undefined>();
  const [isManageConnectionModalOpen, setIsManageConnectionModalOpen] =
    useState(false);
  const [isDropboxIntegrationAvailable, setIsDropboxIntegrationAvailable] =
    useState(false);

  const formMethods = useForm();

  const { handleSubmit, setValue } = formMethods;
  const dispatchThunk = useAppThunkDispatch();
  const dispatch = useAppDispatch();

  const [getWorkflow, { data, isLoading }] = useGetWorkflowMutation();
  const dropboxIntegration = data?.extensions?.dropbox?.enabled;

  useEffect(() => {
    getWorkflow({ id: workflowId });
  }, []);

  useEffect(() => {
    if (!data) return;
    setDropboxSwitch(dropboxIntegration);
    setIsDropboxIntegrationAvailable(dropboxIntegration);
  }, [data]);

  useEffect(() => {
    getDropboxStatus();
  }, []);

  useEffect(() => {
    if (dropboxCode) {
      const fetchData = async () => {
        try {
          const res = await dispatch(
            dropboxConnectConfirm.initiate({ code: dropboxCode }),
          ).unwrap();
          if (res) {
            dispatch(showSuccessNotification({ message: res.message }));
            getDropboxStatus();
          }
        } catch (error) {
          dispatch(
            showErrorNotification({
              message:
                (error as any)?.data?.message ?? (error as any)?.data?.error,
            }),
          );
          console.error(error);
        }
      };

      fetchData();
    }
  }, [dropboxCode]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const receivedCode = event.data.code;

      setDropboxCode(receivedCode);
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const getDropboxStatus = async () => {
    try {
      const res = await dispatch(dropboxConnectionStatus.initiate({})).unwrap();
      setDropboxStatusRes(res);
    } catch (error) {
      dispatch(
        showErrorNotification({
          message: (error as any)?.data?.message ?? (error as any)?.data?.error,
        }),
      );
      console.error(error);
    }
  };

  const onSubmit = async (data: FieldValues) => {
    setIsDropboxIntegrationAvailable(data?.enabled);

    if (
      dropboxStatusRes?.dropboxStatus !== "connected" &&
      !isDropboxIntegrationAvailable
    )
      return;

    await dispatchThunk(
      updateWorkflowThunk({
        data,
        id: workflowId,
        path: "/extension/dropbox",
      }),
    );
  };

  const handleSwitchChange = (checked: boolean) => {
    if (dropboxStatusRes?.dropboxStatus !== "connected" && checked) {
      setIsDropboxModalOpen(true);
    } else {
      setValue("enabled", checked);
      setDropboxSwitch(checked);
    }
  };

  return (
    <>
      {dropboxStatusRes?.dropboxStatus !== "connected" &&
        !!dropboxStatusRes?.dropboxStatus && (
          <Alert
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "600px",
              margin: " 0 auto 20px",
              border: "1px solid red",
            }}
            severity="error"
          >
            {dropboxStatusRes?.dropboxStatusMessage}
          </Alert>
        )}
      <Box sx={{ marginTop: "20px" }}>
        <FormProvider {...formMethods}>
          <form onChange={handleSubmit(onSubmit)} noValidate>
            {isLoading ? (
              <Skeleton variant="rounded" width="100%" height={90} />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  minHeight: "85px",
                  padding: "20px",
                  marginBottom: "1px",
                  borderRadius: "4px",
                  backgroundColor: "#fff",
                  boxShadow: "5px 5px 15px #d9d9d9, -5px -5px 15px #ffffff",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    width: "70%",
                  }}
                >
                  <img src={dropbox} alt={"dropbox"} />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography variant={"subtitle1"}>
                      Seamlessly integrate Dropbox into your workflow
                    </Typography>
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <Typography
                        variant={"body2"}
                        sx={{ opacity: "0.5", textAlign: "start" }}
                      >
                        Enable this switch to incorporate Dropbox functionality
                        into your project. Once enabled, you'll be able to
                        access and manage your Dropbox files directly from
                        within your workflow.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "20%",
                  }}
                >
                  <CustomSwitch
                    name="enabled"
                    onChange={(e) => handleSwitchChange(e.target.checked)}
                    sx={{ margin: "auto" }}
                    checked={dropboxSwitch}
                  />
                </Box>
              </Box>
            )}
            <Box sx={{ marginTop: "20px" }}>
              {isLoading ? (
                <Skeleton variant="rounded" width="100%" height={90} />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    minHeight: "85px",
                    padding: "20px",
                    marginBottom: "1px",
                    borderRadius: "4px",
                    backgroundColor: "#fff",
                    boxShadow: "5px 5px 15px #d9d9d9, -5px -5px 15px #ffffff",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                      width: "70%",
                    }}
                  >
                    <SettingsInputComponentIcon />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography variant={"subtitle1"}>
                        Manage Your Dropbox Integration
                      </Typography>
                      <Box sx={{ display: "flex", gap: "5px" }}>
                        <Typography
                          variant={"body2"}
                          sx={{ opacity: "0.5", textAlign: "start" }}
                        >
                          This tab allows you to seamlessly manage your
                          connection to Dropbox within the workflow. Whether you
                          need to establish a new connection, update settings,
                          or temporarily disable integration.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "20%",
                    }}
                  >
                    <Button
                      variant={"contained"}
                      color={
                        dropboxStatusRes?.dropboxStatus !== "connected"
                          ? "error"
                          : "success"
                      }
                      onClick={() => {
                        setIsManageConnectionModalOpen(true);
                      }}
                      sx={{ margin: "auto" }}
                    >
                      Manage
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>

            <DropboxWarningModal
              isDropboxModalOpen={isDropboxModalOpen}
              setIsDropboxModalOpen={setIsDropboxModalOpen}
              setIsManageConnectionModalOpen={setIsManageConnectionModalOpen}
            />
            <DropboxManageConnectionModal
              isManageConnectionModalOpen={isManageConnectionModalOpen}
              setIsManageConnectionModalOpen={setIsManageConnectionModalOpen}
              dropboxStatus={dropboxStatusRes?.dropboxStatus}
            />
          </form>
        </FormProvider>
      </Box>
    </>
  );
};
