import TipTapDocument from "@tiptap/extension-document";
import TipTapText from "@tiptap/extension-text";
// @ts-ignore
import Placeholder from "@tiptap/extension-placeholder";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
// @ts-ignore
import Highlight from "@tiptap/extension-highlight";
import History from "@tiptap/extension-history";
import Italic from "@tiptap/extension-italic";
import Indent from "../extensions/Indent";
import Bold from "@tiptap/extension-bold";
import BlockQuote from "@tiptap/extension-blockquote";
import Strike from "@tiptap/extension-strike";
// @ts-ignore
import Underline from "@tiptap/extension-underline";
// @ts-ignore
import Link from "@tiptap/extension-link";
// @ts-ignore
import FontFamily from "@tiptap/extension-font-family";
import { ExtendedFontSize } from "tiptap/extensions/FontSize";
import OrderedList from "@tiptap/extension-ordered-list";
// @ts-ignore
import TextAlign from "@tiptap/extension-text-align";
// @ts-ignore
import TextStyle from "@tiptap/extension-text-style";
// @ts-ignore
import TaskItem from "@tiptap/extension-task-item";
// @ts-ignore
import CharacterCount from "@tiptap/extension-character-count";
import { HardBreak } from "@tiptap/extension-hard-break";
import { Extensions } from "@tiptap/core";
import { ExtendedColor } from "tiptap/extensions/ExtendedColor";
import { ExtendedListItem } from "tiptap/extensions/ExtendedListItem";
import BulletList from "@tiptap/extension-bullet-list";
import { TaskList } from "tiptap/extensions/TaskList";

export const extensions: Extensions = [
  TipTapDocument,
  TipTapText,
  ExtendedColor.configure({ types: ["textStyle"] }),
  Placeholder.configure({
    emptyEditorClass: "el-tiptap-editor--empty",
    emptyNodeClass: "el-tiptap-editor__placeholder",
    showOnlyCurrent: false,
    placeholder: "Write something ..."
  }),
  HorizontalRule,
  Highlight.configure({ multicolor: true }),
  History,
  Italic,
  Indent,
  Bold,
  FontFamily.configure({ types: ["textStyle"] }),
  ExtendedFontSize,
  BlockQuote,
  Strike,
  Underline,
  Link.configure({ openOnClick: false }),
  ExtendedListItem.configure({
    HTMLAttributes: {
      style: `
        font-size: 14px;
        font-family: 'Open Sans';
        margin-bottom: 0;
        margin-left: 24px;
        margin-top: 0;
        padding-bottom: 5px;
        padding-left: 0;
        padding-top: 5px;`
    }
  }),
  BulletList.configure({
    HTMLAttributes: {
      style: `
        display: block;
        list-style-type: disc;`
    }
  }),
  OrderedList.configure({
    HTMLAttributes: {
      style: `
      display: block;
      list-style-type: decimal;`
    }
  }),
  TextAlign.configure({ types: ["heading", "paragraph"] }),
  TextStyle,
  TaskList.configure({
    HTMLAttributes: {
      style: `list-style: none; padding: 0; margin-left: 4px;`
    }
  }),
  TaskItem.configure({
    HTMLAttributes: {
      class: "todo-item"
    }
  }),
  HardBreak,
  CharacterCount.configure({})
];
