import { FC, cloneElement } from "react";
import { StubRedirecting } from "../StubRedirecting/StubRedirecting";
import { redirectToWithoutSubdomain } from "helper/urlHelper";
import { extractSubdomain } from "helper/domainHelper";

export const RedirectToMainDomainGuard: FC<{
  element: JSX.Element;
  rest?: any;
}> = ({ element, ...rest }) => {
  const subdomain = extractSubdomain();
  if (subdomain) {
    redirectToWithoutSubdomain(subdomain);
    return <StubRedirecting/>
  }

  return cloneElement(element, rest);
};
