import { FC, RefObject } from "react";
import { PricingType } from "../../shared/types";
import Heading from "../../shared/Heading";
import PriceTier from "./PriceTier";
import useMediaQuery from "../../hooks/useMediaQuery";
import pricingData from "../../data/pricingData";

// @ts-ignore
import pricingbg from "../../assets/pricingbg.png";

interface PricingProps {
  pricingRef: RefObject<HTMLDivElement>;
}

const Pricing: FC<PricingProps> = ({ pricingRef }) => {
  const isUnderLargeScreen = useMediaQuery("(min-width: 1440px");

  return (
    <section id="pricing" ref={pricingRef}>
      <div className="xs:px-[24px] xs:pb-[70px] sm:px-[96px] sm:pb-[100px] md:px-[139px] lg:px-[153px] lg:py-[100px]">
        <Heading>Pricing</Heading>
        <div className="flex xs:mt-[-35px] sm:mt-[-20px]">
          <div className="xs:w-full lg:w-2/3">
            <div className="text-sm">
              <ul className="mb-8 list-disc pl-6">
                <li>
                  A low number of transactions = A Lower bill for that month.
                </li>
                <li>
                  A high number of transactions = A Higher bill for that month.
                  With…a lower per-transaction cost of as little as $0.28.
                </li>
              </ul>
            </div>
            <div className="mb-8 overflow-hidden rounded-[10px] border border-primary-700">
              <div className="flex h-[22px] items-center justify-end bg-primary-300 px-2">
                <span className="mx-1 h-2 w-2 rounded-full bg-primary-010"></span>
                <span className="mx-1 h-2 w-2 rounded-full bg-primary-010"></span>
                <span className="mx-1 h-2 w-2 rounded-full bg-primary-010"></span>
              </div>
              {pricingData.map((price: PricingType) => (
                <PriceTier
                  key={price.tier}
                  tier={price.tier}
                  description={price.description}
                  price={price.price}
                />
              ))}
            </div>
            <div className="text-sm">
              <p className="mb-8">
                WaiverKing provides the flexibility and reliability that your
                business demands. Pay for ONLY what you use and NEVER run out of
                Waivers and Forms.{" "}
              </p>
              <p>It’s super simple …</p>
              <ul className="list-disc pl-6">
                <li>
                  One (1) transaction is automatically registered each time a
                  customer signs one of your documents in WKForm, it approves.
                </li>
                <li>
                  Your transaction count automatically resets at the beginning
                  of each month.
                </li>
                <li>You pay for only what your business needs to prosper.</li>
              </ul>
            </div>
          </div>
          {isUnderLargeScreen && (
            <div className="ml-[-55px] mt-[84px]">
              <img src={pricingbg} alt="pricingbg" />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
export default Pricing;
