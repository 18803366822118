import { DEFAULT_IMAGE_WIDTH } from "tiptap/constants";
import { Size } from "tiptap/types/Size/Size";

export async function determineOriginalImgSizeAsync(url: string) {
  const response = await fetch(url);
  const blob = await response.blob();
  const imageUrl = URL.createObjectURL(blob);

  // Create a temporary <img> element
  const tempImg = new Image();
  tempImg.src = imageUrl;

  // Wait for the image to load
  await new Promise((resolve) => {
    tempImg.onload = resolve;
    tempImg.onerror = resolve;
  });

  const width = Math.min(DEFAULT_IMAGE_WIDTH, tempImg.width);
  const aspectRatio = tempImg.width / tempImg.height;

  const originalSize: Size = {
    width: width,
    height: width/ aspectRatio,
  };
  return originalSize;
}
