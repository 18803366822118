import React from "react";
import { Editor } from "@tiptap/core";
import { TipTapEditorContext } from "../../types";
import { MenuButton } from "../MenuButton";

interface MenuOrderedListButtonProps {
  editor: Editor;
  editorContext: TipTapEditorContext;
}

export const MenuOrderedListButton: React.FC<MenuOrderedListButtonProps> = ({
  editor,
  editorContext,
}) => {
  const isActive = editorContext.state.isActiveOrderedList;

  const runCommand = () => {
    editor.chain().focus().toggleOrderedList().run();
  };

  return (
    <MenuButton
      icon="list-ol"
      title="Ordered list"
      command={runCommand}
      isActive={isActive}
    />
  );
};
