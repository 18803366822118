import { ChangeEvent, FC, useEffect, useState } from "react";

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { useAppThunkDispatch } from "../../store";
import { updateWorkflowThunk } from "../../store/features/thunk/workflowThunk/workflowThunk";
import { useGetWorkflowMutation } from "../../store/features/api/workflowApi/workflowApi";

export type NotificationExtensionProps = {
  workflowId: string;
};

export type FormModel = {
  companyNotify: string;
  userNotify: string;
  extraNotify: string;
  extraEmails: string;
};

export const NotificationExtension: FC<NotificationExtensionProps> = ({
  workflowId,
}) => {
  const [notifications, setNotifications] = useState({
    companyNotify: "disabled",
    userNotify: "disabled",
    extraNotify: "disabled",
    extraEmails: [],
  });
  const [emailError, setEmailError] = useState(false);
  const formMethods = useForm<FormModel>({
    defaultValues: {
      companyNotify: "disabled",
      userNotify: "disabled",
      extraNotify: "disabled",
      extraEmails: "disabled",
    },
  });
  const { setValue, handleSubmit, register, watch } = formMethods;

  const dispatchThunk = useAppThunkDispatch();

  const [getWorkflow, { data }] = useGetWorkflowMutation();

  useEffect(() => {
    getWorkflow({ id: workflowId });
  }, []);

  useEffect(() => {
    if (!data) return;
    setNotifications(data.extensions.notifications);
  }, [data]);

  useEffect(() => {
    if (notifications) {
      setValue("companyNotify", notifications.companyNotify);
      setValue("userNotify", notifications.userNotify);
      setValue("extraNotify", notifications.extraNotify);
      setValue("extraEmails", notifications.extraEmails?.join(", ") || "");
    }
  }, [notifications]);

  const getEmailAddresses = (value: string) => {
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
    return value.match(emailRegex) || [];
  };

  const onSubmit = (data: FieldValues) => {
    dispatchThunk(
      updateWorkflowThunk({
        data: {
          ...data,
          extraEmails: getEmailAddresses(data.extraEmails),
        },
        id: workflowId || "",
        path: "/extension/notifications",
      }),
    );
  };

  const onSubmitOptions = (data: FieldValues) => {
    dispatchThunk(
      updateWorkflowThunk({
        data: getEmailAddresses(data.extraEmails),
        id: workflowId || "",
        path: "/extension/notifications/extra_emails",
      }),
    );
  };

  const handleCompany = (event: ChangeEvent<HTMLInputElement>) => {
    setValue("companyNotify", event.target.value);
  };
  const handleUser = (event: ChangeEvent<HTMLInputElement>) => {
    setValue("userNotify", event.target.value);
  };
  const handleExtra = (event: ChangeEvent<HTMLInputElement>) => {
    setValue("extraNotify", event.target.value);
  };

  const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const textareaValue = event.target.value;
    if (getEmailAddresses(textareaValue).length > 0) {
      setEmailError(false);
    } else if (!textareaValue.length) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  return (
    <Box paddingTop={"20px"} display={"flex"} gap={"30px"}>
      <FormProvider {...formMethods}>
        <form onChange={handleSubmit(onSubmit)} noValidate>
          <Box display={"flex"} gap={"30px"}>
            <Box>
              <FormControl>
                <FormLabel>
                  Do you want to receive a copy of the signed document?
                </FormLabel>
                <RadioGroup
                  name="companyNotify"
                  sx={{ my: 1, textAlign: "start" }}
                  onChange={handleCompany}
                >
                  <div>
                    <FormControlLabel
                      label={"No"}
                      value="disabled"
                      checked={watch("companyNotify") === "disabled"}
                      control={<Radio />}
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      label={"Yes, attach a PDF"}
                      value="pdf"
                      checked={watch("companyNotify") === "pdf"}
                      control={<Radio />}
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      label={"Yes"}
                      value="info"
                      checked={watch("companyNotify") === "info"}
                      control={<Radio />}
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </Box>
            <Box>
              <FormControl>
                <FormLabel>
                  {" "}
                  Do you want to send the signed document to the user?
                </FormLabel>
                <RadioGroup
                  name="userNotify"
                  sx={{ my: 1, textAlign: "start" }}
                  onChange={handleUser}
                >
                  <div>
                    <FormControlLabel
                      label={"No"}
                      value="disabled"
                      checked={watch("userNotify") === "disabled"}
                      control={<Radio />}
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      label={"Yes, attach a PDF"}
                      value="pdf"
                      checked={watch("userNotify") === "pdf"}
                      control={<Radio />}
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      label={"Yes"}
                      value="info"
                      checked={watch("userNotify") === "info"}
                      control={<Radio />}
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </Box>
            <Box>
              <FormControl>
                <FormLabel>Extra email</FormLabel>
                <RadioGroup
                  name="extraNotify"
                  sx={{ my: 1, textAlign: "start" }}
                  onChange={handleExtra}
                >
                  <div>
                    <FormControlLabel
                      label={"No"}
                      value="disabled"
                      checked={watch("extraNotify") === "disabled"}
                      control={<Radio />}
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      label={"Yes, attach a PDF"}
                      value="pdf"
                      checked={watch("extraNotify") === "pdf"}
                      control={<Radio />}
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      label={"Yes"}
                      value="info"
                      checked={watch("extraNotify") === "info"}
                      control={<Radio />}
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </form>
        <form onSubmit={handleSubmit(onSubmitOptions)} noValidate>
          <Box display={"flex"} flexDirection={"column"}>
            <textarea
              {...register("extraEmails")}
              placeholder="Type emails in here…"
              style={{
                padding: "10px",
                height: "150px",
                border: emailError ? "1px solid red" : "1px solid black",
                outline: "none",
              }}
              onChange={handleTextareaChange}
            />
            <Button
              type={"submit"}
              variant={"contained"}
              color={"success"}
              sx={{ marginTop: "30px" }}
            >
              Save
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};
