import { FC, useEffect, useState } from "react";

import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import {
  Alert,
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  deleteWorkflowExtensionThunk,
  updateWorkflowThunk,
} from "../../store/features/thunk/workflowThunk/workflowThunk";
import { useAppDispatch, useAppThunkDispatch } from "../../store";
import { updateEditWorkflowExtension } from "../../store/features/workflow/workflowSlice";
import {
  DocumentType,
  setEditDocument,
} from "../../store/features/document/documentSlice";

import { WarningModal } from "../Modal/WarningModal";
import {
  SelectOptionList,
  SelectWidget,
} from "../widgets/FormWidgets/SelectWidget";
import { getDocumentOptionsListAndSelectedDocuments } from "helper/documentTypeListHelper";

export const WorkflowDocumentsSelector: FC<{
  workflowDocuments: any | undefined;
  documents: DocumentType[] | [];
  workflowId: string;
}> = ({ workflowDocuments, documents, workflowId }) => {
  const [docsOptionList, selectedDocs] =
    getDocumentOptionsListAndSelectedDocuments(documents, workflowDocuments);
  const [selectedDocuments, setSelectedDocuments] =
    useState<DocumentType[]>(selectedDocs);
  const [documentsOptionList, setDocumentsOptionList] =
    useState<SelectOptionList[]>(docsOptionList);
  const [isDisabled, setIsDisabled] = useState<boolean>(
    selectedDocs.length != 0,
  );
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const formMethods = useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dispatchThunk = useAppThunkDispatch();

  const { getValues, setValue, handleSubmit } = formMethods;

  useEffect(() => {
    const [docsOptionList, selectedDocs] =
      getDocumentOptionsListAndSelectedDocuments(documents, workflowDocuments);
    setSelectedDocuments(selectedDocs);
    setDocumentsOptionList(docsOptionList);
  }, [documents, workflowDocuments]);

  useEffect(() => {
    setIsDisabled(selectedDocuments.length != 0);
  }, [selectedDocuments]);

  const onSubmit = async () => {
    const documentId = getValues("documentId");
    const doc = documents.length
      ? documents.find(({ id }) => id === documentId) ?? null
      : null;

    const isDocAdded =
      doc && workflowDocuments
        ? Object.keys(workflowDocuments).includes(`${doc.id}`)
        : false;

    if (!isDocAdded && doc) {
      try {
        await dispatchThunk(
          updateWorkflowThunk({
            data: { optional: false },
            id: workflowId,
            path: `/extension/documents/${doc.id}`,
          }),
        );

        setSelectedDocuments((prevState) =>
          prevState.length ? [...prevState, doc] : [doc],
        );

        setValue("documentId", "");

        const wfD = workflowDocuments ?? {};
        const obj = { ...wfD, [doc.id]: { optional: false } };
        dispatch(updateEditWorkflowExtension({ key: "documents", value: obj }));
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleDocumentEdit = (id: any): void => {
    dispatch(setEditDocument({ id }));
    navigate(`/document/${id}`);
  };

  const handleDeleteDocumentFromWorkflow = async (docId: string | number) => {
    if (!workflowId) return;

    try {
      await dispatchThunk(
        deleteWorkflowExtensionThunk({
          id: workflowId,
          path: `/extension/documents/${docId}`,
        }),
      );

      setSelectedDocuments((prevState) =>
        prevState.filter(({ id }) => docId !== id),
      );

      const obj = { ...workflowDocuments };

      delete obj[`${docId}`];

      dispatch(updateEditWorkflowExtension({ key: "documents", value: obj }));
    } catch (e) {
      console.error(e);
    }
  };

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isDisabled) {
      setIsTooltipOpen(true);
    }
  };

  const handleMouseLeave = () => {
    setIsTooltipOpen(false);
  };

  return (
    <FormProvider {...formMethods}>
      <form onBlur={handleSubmit(() => {})} noValidate>
        <Tooltip
          title="Currently, you can add
          only one document per workflow"
          open={isTooltipOpen}
          followCursor
          arrow
        >
          <Box
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            display={"flex"}
            width={"100%"}
            gap={"25px"}
            marginTop={"20px"}
          >
            <div style={{ width: "100%" }}>
              <SelectWidget
                disabled={isDisabled}
                fieldId={"documentId"}
                label={"Documents"}
                type={"select"}
                option_list={documentsOptionList}
                onClose={onSubmit}
                value={""}
              />
            </div>
            <Button
              disabled={isDisabled}
              variant={"outlined"}
              color={"inherit"}
              sx={{ minWidth: "230px", marginTop: "14px" }}
              onClick={() => {
                navigate("/document/new");
              }}
            >
              + Create document
            </Button>
          </Box>
        </Tooltip>
        {selectedDocuments.length ? (
          selectedDocuments.map((doc) => (
            <Box
              key={doc.id}
              sx={{
                backgroundColor: "rgba(63, 186, 115, 0.1)",
                display: "flex",
                textAlign: "start",
                justifyContent: "space-between",
                marginTop: "15px",
                padding: "15px",
              }}
            >
              <Box>
                <Typography variant={"subtitle1"}>{doc.name || ""}</Typography>
              </Box>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Tooltip title={"Edit document"} placement={"top"} arrow>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => handleDocumentEdit(doc.id)}
                  >
                    <Edit fontSize="inherit" />
                  </IconButton>
                </Tooltip>
                <WarningModal
                  openBtnType={"deleteIcon"}
                  openBtnTooltip={"Delete document from workflow"}
                  submitBtnTitle={"Delete"}
                  message={
                    "Are you sure you want to delete document form this workflow?"
                  }
                  onSubmit={() => handleDeleteDocumentFromWorkflow(doc.id)}
                />
              </Stack>
            </Box>
          ))
        ) : (
          <Alert
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "600px",
              margin: " 20px auto",
              border: "1px solid red",
            }}
            severity="error"
          >
            You haven&apos;t selected any documents.
          </Alert>
        )}
      </form>
    </FormProvider>
  );
};
