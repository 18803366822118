import { Node as ProsemirrorNode } from "prosemirror-model";

export function isProfilePhrase(phrase: string): boolean {
  return phrase.startsWith("profile");
}

export function isResizeableInput(input: string | undefined): boolean {
  if (!input) return false;
  return input === "signature" || input.replace(/\s/g, "").includes("textarea");
}

export function getFilterConfig(
  node: ProsemirrorNode,
): Record<string, any> | null | undefined {
  if (!node.attrs.config) return null;

  const decodedString = node.attrs.config
    .replace(/\n/g, "")
    .replace(/\s+/g, "");
  try {
    const parsedObject = JSON.parse(decodedString);
    return parsedObject;
  } catch (error) {
    console.log("error getFilterConfig", error);
  }
}
