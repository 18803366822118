export type TipTapState = {
  isActiveStrike: boolean;
  isActiveBold: boolean;
  isActiveBlockquote: boolean;
  isActiveItalic: boolean;
  isActiveImage: boolean;
  isActiveUnderline: boolean;
  isActiveHeading: boolean;
  isActiveHeading1: boolean;
  isActiveHeading2: boolean;
  isActiveHeading3: boolean;
  isActiveHeading4: boolean;
  isActiveHeading5: boolean;
  isActiveFontFamily: boolean;
  activeFontFamily: string;
  isActiveLink: boolean;
  isActiveTextAlignLeft: boolean;
  isActiveTextAlignRight: boolean;
  isActiveTextAlignCenter: boolean;
  isActiveTextAlignJustify: boolean;
  isActiveOrderedList: boolean;
  isActiveBulletList: boolean;
  isActiveTodoList: boolean;
  isActiveCodeBlock: boolean;
  isActiveHighlight: boolean;
  isActiveTextStyle: boolean;
  isActiveTable: boolean;
  isCanMergeCells: boolean;
  isCanSplitCell: boolean;
};

export const tipTapEmptyState = (): TipTapState => ({
  isActiveBold: false,
  isActiveBlockquote: false,
  isActiveItalic: false,
  isActiveImage: false,
  isActiveStrike: false,
  isActiveUnderline: false,
  isActiveHeading: false,
  isActiveHeading1: false,
  isActiveHeading2: false,
  isActiveHeading3: false,
  isActiveHeading4: false,
  isActiveHeading5: false,
  isActiveFontFamily: false,
  activeFontFamily: "",
  isActiveLink: false,
  isActiveTextAlignLeft: false,
  isActiveTextAlignRight: false,
  isActiveTextAlignCenter: false,
  isActiveTextAlignJustify: false,
  isActiveOrderedList: false,
  isActiveBulletList: false,
  isActiveTodoList: false,
  isActiveCodeBlock: false,
  isActiveHighlight: false,
  isActiveTextStyle: false,
  isActiveTable: false,
  isCanMergeCells: false,
  isCanSplitCell: false,
});
