import { FC, MouseEvent, useEffect, useRef, useState } from "react";
import { NodeViewContent, NodeViewWrapper } from "@tiptap/react";
import { Editor } from "@tiptap/core";
import { Node as ProsemirrorNode } from "prosemirror-model";
import { NodeSelection } from "prosemirror-state";
import { clamp } from "../../utils/shared";
import { DocumentWidgetModalForm } from "components/Modal/DocumentWidgetModalForm/DocumentWidgetModalForm";
import { WidgetInEditorRepresentation } from "tiptap/components/WidgetInEditorRepresentation";
import { WidgetType } from "tiptap/types/WidgetType";
import { resizeDirections } from "tiptap/constants";
import {
  MAX_MIN_SIZE,
  MAX_SIZE,
  MIN_SIZE,
} from "./ReplacePhrasesComponentConsts";
import { ResizeDirection } from "tiptap/types";
import { getNewFinalSize } from "../helpers/resizeHelper";
import {
  getFilterConfig,
  isProfilePhrase,
  isResizeableInput,
} from "./ReplacePhraseHelper";
import { useMaxAllowedSizeAndObserver } from "tiptap/hooks/useMaxAllowedSizeAndObserver";
import { CUSTOM_INPUTS_DICT } from "tiptap/customWidgetsConfig/customWidgetsConfig";

type ReplacePhrasesComponentProps = {
  node: ProsemirrorNode;
  deleteNode: any;
  selected: boolean;
  getPos: any;
  updateAttributes: any;
  editor: Editor;
};

export const ReplacePhrasesComponent: FC<ReplacePhrasesComponentProps> = (
  props,
) => {
  const {
    node,
    selected,
    deleteNode,
    editor,
    updateAttributes,
    getPos,
    ...rest
  } = props;
  const [maxAllowedSize, resizeOb] = useMaxAllowedSizeAndObserver(
    editor,
    MAX_SIZE,
  );
  const imageViewRef = useRef<HTMLDivElement>(null);
  const [resizing, setResizing] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [currentConfig, setCurrentConfig] = useState<
    Record<string, any> | null | undefined
  >(getFilterConfig(node));

  const [resizerState, setResizerState] = useState({
    x: 0,
    y: 0,
    w: 0,
    h: 0,
    dir: "",
  });
  const filterType = currentConfig?.type ?? "";
  const isResizableInHeight = isResizeableInput(filterType);
  const width = currentConfig?.style?.width;
  const height = currentConfig?.style?.height;
  const label = node.attrs.label;

  useEffect(() => {
    setCurrentConfig((prev: Record<string, any> | null | undefined) => {
      if (prev) {
        return getFilterConfig(node);
      }
    });
  }, [node.attrs.config]);

  useEffect(() => {
    if (resizing) {
      onEvents();
    } else {
      offEvents();
    }

    return () => {
      offEvents();
      resizeOb.disconnect();
    };
  }, [resizing]);

  const selectPhrase = () => {
    const { state } = editor;
    const selection = NodeSelection.create(state.doc, getPos());
    const tr = state.tr.setSelection(selection);
    editor.view.dispatch(tr);
  };

  const onMouseDown = (
    e: MouseEvent<HTMLSpanElement>,
    dir: ResizeDirection,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    const { clientX, clientY } = e;
    const maxWidth = maxAllowedSize.width;

    const newSize = getNewFinalSize({ width, height }, MIN_SIZE, maxWidth);

    setResizerState({
      x: clientX,
      y: isResizableInHeight ? clientY : MAX_MIN_SIZE,
      w: newSize.width,
      h: isResizableInHeight ? newSize.height! : MAX_MIN_SIZE,
      dir,
    });

    setResizing(true);
  };

  const onMouseMove = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!resizing) return;

    const { clientX, clientY } = e;
    const dx =
      (clientX - resizerState.x) * (/l/.test(resizerState.dir) ? -1 : 1);
    const dy =
      (clientY - resizerState.y) * (/t/.test(resizerState.dir) ? -1 : 1);

    const calcWidth = clamp(
      resizerState.w + dx,
      MIN_SIZE,
      maxAllowedSize.width,
    );
    const calcHeight = isResizableInHeight
      ? Math.max(resizerState.h + dy, MIN_SIZE)
      : MIN_SIZE;

    const style = currentConfig?.style ? currentConfig.style : {};

    const attrs = JSON.stringify({
      ...currentConfig,
      style: {
        ...style,
        width: calcWidth,
        height: calcHeight,
      },
    });

    updateAttributes({
      config: attrs,
    });
  };

  const onMouseUp = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!resizing) return;

    setResizing(false);
    setResizerState({
      x: 0,
      y: 0,
      w: 0,
      h: 0,
      dir: "",
    });

    selectPhrase();
  };

  const onEvents = () => {
    addEventListener("mousemove", onMouseMove, true);
    addEventListener("mouseup", onMouseUp, true);
  };

  const offEvents = () => {
    removeEventListener("mousemove", onMouseMove, true);
    removeEventListener("mouseup", onMouseUp, true);
  };

  const onDoubleClick = () => {
    if (isProfilePhrase(node.attrs.phrase)) return;
    setOpenModal(true);
  };

  return (
    <NodeViewWrapper
      {...rest}
      ref={imageViewRef}
      className="image-view__wrapper"
      onClick={selectPhrase}
    >
      <NodeViewWrapper
        {...rest}
        style={{
          display: isResizeableInput(filterType) ? "flex" : "inline-block",
          backgroundColor: "#f1f1f1",
          textTransform: "uppercase",
          border: "1px solid #74af26",
          borderRadius: "3px",
          margin: isResizeableInput(filterType) ? "0 auto" : "1px",
          padding: "1px",
          width: `${width}px`,
          height: `${height}px`,
        }}
        getpos={getPos()}
        className={`image-view__body ${
          selected ? "image-view__body--focused" : ""
        } ${resizing ? "image-view__body--resizing" : ""}`}
        draggable={true}
        data-drag-handle
        onDoubleClick={onDoubleClick}
      >
        <NodeViewContent
          as="span"
          draggable="true"
          className={`image-view__body__image text-widget-green flex items-center ${isResizeableInput(filterType) ? "w-full justify-center" : ""}`}
          data-drag-handle=""
        >
          <WidgetInEditorRepresentation
            filterType={filterType}
            label={label}
            value={currentConfig?.value ?? currentConfig?.label ?? label}
          />
        </NodeViewContent>
        {editor.isEditable && (selected || resizing) && (
          <div {...rest} className="image-resizer">
            {resizeDirections.map((direction) => (
              <span
                {...rest}
                key={direction}
                className={`image-resizer__handler--${direction} image-resizer__handler`}
                onMouseDown={(e) => onMouseDown(e, direction)}
              />
            ))}
          </div>
        )}
        {openModal && currentConfig && (
          <>
            {CUSTOM_INPUTS_DICT[filterType as WidgetType] && (
              <DocumentWidgetModalForm
                openState={[openModal, setOpenModal]}
                widgetType={filterType}
                onClickSubmit={
                  CUSTOM_INPUTS_DICT[filterType as WidgetType].addWidget
                }
                editor={editor}
                config={currentConfig}
              />
            )}
          </>
        )}
      </NodeViewWrapper>
    </NodeViewWrapper>
  );
};
