import { FC, useEffect, useState } from "react";

import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useAppSelector, useAppThunkDispatch } from "../../../store";
import { getClientsThunk } from "../../../store/features/thunk/clientThunk/clientThunk";

import { TableComponent } from "../../TableComponent/TableComponent";
import { Loader } from "../../Loader/Loader";

const headers: string[] = [
  "FIRST NAME",
  "LAST NAME",
  "CREATION DATE",
  "RECENT LOCATION",
  "DOCUMENTS COUNT",
  "RECENT DOCUMENTS",
];

export const Clients: FC<{ breadcrumbs?: boolean }> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);

  const navigate = useNavigate();
  const dispatchThunk = useAppThunkDispatch();

  const { clients } = useAppSelector(({ client }) => client);

  useEffect(() => {
    handleClients();
  }, []);

  const handleClients = async () => {
    setIsLoading(true);
    await dispatchThunk(getClientsThunk({ page, perPage }));
    setIsLoading(false);
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Box
        sx={{
          width: "100%",
          paddingBottom: "31px",
          paddingTop: "31px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div></div>
      </Box>
      <TableComponent
        type={"clients"}
        headers={headers}
        isEditDelete={false}
        rows={clients}
        onRowClick={(uuid) => {
          navigate(`/client/${uuid}/info`, { replace: true });
        }}
      />
    </>
  );
};
