import { createSlice, PayloadAction } from "@reduxjs/toolkit/";

export type ThunkSliceType = {
  status: "idle" | "loading";
  error: any;
  interval: any;
};

const initialState: ThunkSliceType = {
  status: "idle",
  error: null,
  interval: null,
};

export const thunkSlice = createSlice({
  name: "thunk",
  initialState,
  reducers: {
    setInterval: (state, { payload }: PayloadAction<{ interval: any }>) => {
      state.interval = payload.interval;
    },
    setLoading: (
      state,
      { payload }: PayloadAction<{ status: "idle" | "loading" }>,
    ) => {
      return {
        ...state,
        status: payload.status,
      };
    },
    setFinished: (state) => {
      return {
        ...state,
        status: "idle",
      };
    },
  },
  // extraReducers: (builder) => {
  // builder.addCase(resetPasswordConfirmThunk.pending, setLoading);
  // builder.addCase(resetPasswordConfirmThunk.fulfilled, setFinished);
  // builder.addCase(resetPasswordConfirmThunk.rejected, setError);
  //
  // builder.addCase(getProfileThunk.pending, setLoading);
  // builder.addCase(getProfileThunk.fulfilled, setFinished);
  // builder.addCase(getProfileThunk.rejected, setError);
  //
  // builder.addCase(resetPasswordVerifyThunk.pending, setLoading);
  // builder.addCase(resetPasswordVerifyThunk.fulfilled, setFinished);
  // builder.addCase(resetPasswordVerifyThunk.rejected, setError);
  //
  // builder.addCase(signUpConfirmThunk.pending, setLoading);
  // builder.addCase(signUpConfirmThunk.fulfilled, setFinished);
  // builder.addCase(signUpConfirmThunk.rejected, setError);
  //
  // builder.addCase(resetPasswordThunk.pending, setLoading);
  // builder.addCase(resetPasswordThunk.fulfilled, setFinished);
  // builder.addCase(resetPasswordThunk.rejected, setError);
  //
  // builder.addCase(singUpThunk.pending, setLoading);
  // builder.addCase(singUpThunk.fulfilled, setFinished);
  // builder.addCase(singUpThunk.rejected, setError);
  //
  // builder.addCase(singInThunk.pending, setLoading);
  // builder.addCase(singInThunk.fulfilled, setFinished);
  // builder.addCase(singInThunk.rejected, setError);
  // }
});

export const { setInterval, setLoading, setFinished } = thunkSlice.actions;
