type Props = {
  icon: string;
  title: string;
  description: string;
};
const Step = ({ icon, title, description }: Props) => {
  return (
    <div className="xs:w-[358px] relative sm:w-[261px] md:w-[358px]">
      <img className="mb-6" src={icon} alt={title} />
      <h3 className="absolute top-0 ml-10 mt-4 font-poppins text-xl font-semibold leading-relaxed text-primary-700">
        {title}
      </h3>
      <p
        className="xs:text-sm sm:text-base"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
};
export default Step;
