import uniqKeyService from "../UniqKey/UniqKeyService";
import { nanoid } from "nanoid";

function uniqKeyAttributeParse(element: Element) {
  let paragraphKey = element.getAttribute("key");

  if (
    !paragraphKey ||
    uniqKeyService.isExists(paragraphKey) ||
    document.querySelector(`.ProseMirror [key="${paragraphKey}"]`)
  ) {
    paragraphKey = nanoid();
    uniqKeyService.add(paragraphKey);
  }

  return paragraphKey;
}

export default uniqKeyAttributeParse;
