import { FC, ReactElement, useEffect, useState } from "react";
import useElementSize from "../../hooks/useElementSize";

export type CommentsSliderProps = {
  children: ReactElement[];
  itemsPerPage?: number;
};

export const CommentsSlider: FC<CommentsSliderProps> = ({
  children,
  itemsPerPage = 1,
}) => {
  const [items, setItems] = useState<ReactElement[] | ReactElement[][]>(
    children,
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [container, { width }] = useElementSize();

  useEffect(() => {
    const interval = setInterval(() => {
      autoPlay();
    }, 10000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  useEffect(() => {
    getArrayWithItemsPerPage();
  }, [children, itemsPerPage]);

  const autoPlay = () => {
    if (currentIndex === items.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const getArrayWithItemsPerPage = () => {
    const array = [];
    for (let i = 0; i < children.length; i += itemsPerPage) {
      array.push(children.slice(i, i + itemsPerPage));
    }
    setItems(array);
  };

  return (
    <div
      ref={container}
      className="relative overflow-hidden xs:mt-[-35px] sm:mt-[-20px]"
    >
      <div
        className="flex w-fit transition-transform duration-1000 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * width}px)` }}
      >
        {items.map((child, index) => (
          <div
            className={"flex justify-center gap-[10px]"}
            key={index}
            style={{ width: `${width}px` }}
          >
            {child}
          </div>
        ))}
      </div>
      <div className="flex items-center justify-center gap-0.5">
        {items.map((_, commentIndex) => (
          <div
            key={commentIndex}
            onClick={() => setCurrentIndex(commentIndex)}
            className={`mx-1 h-2 w-2 cursor-pointer rounded-full ${
              currentIndex === commentIndex
                ? "h-3 w-3 bg-primary-200"
                : "bg-seconary-300"
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};
