import { FC } from "react";
import { LogoWidgetProps } from "./LogoWidget.types";

export const LogoWidget: FC<LogoWidgetProps> = ({ config }) => {
  return (
    <img
      src={config.logo_ul}
      style={{
        maxWidth: "100%",
        maxHeight: "140px",
        borderRight: "0.5px solid grey",
      }}
      alt={"Company logo"}
    />
  );
};
