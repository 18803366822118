import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  showErrorNotification,
  showSuccessNotification,
} from "../../notification/notificationSlice";
import {
  deleteLocation,
  getLocation,
  getLocations,
  createLocation,
  updateLocation,
} from "../../api/locationApi/locationApi";
import {
  addLocation,
  clearEditLocation,
  LocationType,
  removeLocation,
  setEditLocation,
  setEditLocationFromResponse,
  setLocations,
  updateLoc,
} from "../../location/locationSlice";

export const getLocationsThunk = createAsyncThunk(
  "thunkSlice/getLocationsThunk",
  async (_, { dispatch }) => {
    try {
      let locations = await dispatch(getLocations.initiate({})).unwrap();

      dispatch(setLocations(locations));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const createLocationThunk = createAsyncThunk(
  "thunkSlice/createLocationThunk",
  async (
    data: Pick<LocationType, "address" | "name" | "timezone">,
    { dispatch },
  ) => {
    try {
      let res = await dispatch(createLocation.initiate(data)).unwrap();

      dispatch(addLocation(res));
      dispatch(
        showSuccessNotification({
          message: "The new location successfully created",
        }),
      );
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const getLocationThunk = createAsyncThunk(
  "thunkSlice/getLocationThunk",
  async ({ id }: { id: string }, { dispatch }) => {
    try {
      let res = await dispatch(getLocation.initiate({ id })).unwrap();
      dispatch(setEditLocationFromResponse(res));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const deleteLocationThunk = createAsyncThunk(
  "thunkSlice/deleteLocationThunk",
  async ({ id }: { id: string }, { dispatch }) => {
    try {
      await dispatch(deleteLocation.initiate({ id })).unwrap();

      dispatch(removeLocation({ id }));
      dispatch(
        showSuccessNotification({ message: "Location successfully deleted" }),
      );
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const updateLocationThunk = createAsyncThunk(
  "thunkSlice/updateLocationThunk",
  async (data: any, { dispatch }) => {
    try {
      let res = await dispatch(updateLocation.initiate(data)).unwrap();

      dispatch(updateLoc(res));
      dispatch(clearEditLocation());
      dispatch(showSuccessNotification({ message: "Saved" }));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);
