import { FC, useEffect, useState } from "react";

import { Box, Container } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { FieldValues, useForm, FormProvider } from "react-hook-form";

import {
  resetPasswordConfirmThunk,
  resetPasswordThunk,
  resetPasswordVerifyThunk,
  signUpConfirmThunk,
  signInThunk,
  signUpThunk,
} from "../../../store/features/thunk/thunkApi";
import { AuthTypes, setFormData } from "../../../store/features/form/formSlice";
import {
  useAppDispatch,
  useAppSelector,
  useAppThunkDispatch,
} from "../../../store";

//@ts-ignore
import wkLogo from "../../../assets/img/waiverking-logo.svg";
import { LoaderContextProvider } from "../../../context/LoaderContext";
import {
  redirectToMainPage,
  getRouteBasedOnUnsignedDocumentsAndIsNewUser,
} from "helper/urlHelper";

export const AuthComponent: FC<{ children?: JSX.Element }> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const formMethods = useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const thunkDispatch = useAppThunkDispatch();

  const {
    form: { authMethod },
    company,
  } = useAppSelector(({ form, company }) => ({
    form,
    company,
  }));

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    async function clearFormData(){
      await dispatch(setFormData({ email: "", password: "", passwordConfirm: "", verificationCodes: {} }))
    }
    
    if (authMethod === "SIGN_IN" || authMethod === "SIGN_UP") {
      clearFormData()
    }

    reset();
  }, [authMethod]);

  const handleSignIn = async (data: FieldValues) => {
    try {
      await thunkDispatch(
        signInThunk({
          data,
          cb: redirectToMainPage,
          isNewUser: false,
          companyDetailsNavigate: (
            unsginedDocsCount: number,
            isNewUser: boolean,
          ) =>
            navigate(
              `/${getRouteBasedOnUnsignedDocumentsAndIsNewUser(
                unsginedDocsCount,
                isNewUser,
              )}`,
            ),
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };

  const handleSignUp = async (data: FieldValues) => {
    await thunkDispatch(signUpThunk(data));
  };

  const handleResetPassword = async (data: FieldValues) => {
    await thunkDispatch(resetPasswordThunk(data));
  };

  const handleSignUpConfirm = async (data: FieldValues) => {
    try {
      const res = await thunkDispatch(
        signUpConfirmThunk({
          data,
          companyDetailsNavigate: (
            unsginedDocsCount: number,
            isNewUser: boolean,
          ) =>
            navigate(
              `/${getRouteBasedOnUnsignedDocumentsAndIsNewUser(
                unsginedDocsCount,
                isNewUser,
              )}`,
            ),
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };

  const handleResetPasswordVerify = async (data: FieldValues) => {
    await thunkDispatch(resetPasswordVerifyThunk(data));
  };

  const handleRestPasswordConfirm = async (data: FieldValues) => {
    try {
      await thunkDispatch(
        resetPasswordConfirmThunk({
          data,
          cb: redirectToMainPage,
          companyDetailsNavigate: (
            unsginedDocsCount: number,
            isNewUser: boolean,
          ) =>
            navigate(
              `/${getRouteBasedOnUnsignedDocumentsAndIsNewUser(
                unsginedDocsCount,
                isNewUser,
              )}`,
            ),
          navigate,
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = async (formData: FieldValues) => {
    dispatch(setFormData(formData));

    if (authMethod) {
      setIsLoading(true);
      switch (authMethod) {
        case AuthTypes.SIGN_IN:
          await handleSignIn(formData);
          setIsLoading(false);
          return;
        case AuthTypes.SIGN_UP:
          await handleSignUp(formData);
          setIsLoading(false);
          return;
        case AuthTypes.RESET:
          await handleResetPassword(formData);
          setIsLoading(false);
          return;
        case AuthTypes.OTP_SIGN_UP:
          await handleSignUpConfirm(formData);
          setIsLoading(false);
          return;
        case AuthTypes.OTP_SIGN_IN:
          await handleSignIn(formData);
          setIsLoading(false);
          return;
        case AuthTypes.OTP_RESET:
          await handleResetPasswordVerify(formData);
          setIsLoading(false);
          return;
        case AuthTypes.NEW_PASSWORD:
          await handleRestPasswordConfirm(formData);
          setIsLoading(false);
          reset()
          return;
      }
    }
  };

  return (
    <Box height={"100%"} sx={{ display: "flex" }}>
      <Box height={"100%"} width={"100%"}>
        <img
          src={wkLogo}
          alt={"logo"}
          style={{
            position: "absolute",
            bottom: "20px",
            right: "50px",
            maxHeight: "50px",
          }}
        />
        <Box
          bgcolor="white"
          sx={{
            minWidth: "60%",
            width: "100%",
            margin: "89px 0 89px 0 ",
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "50px 24px",
            }}
            maxWidth={"md"}
          >
            <LoaderContextProvider isLoading={isLoading}>
              <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <Outlet />
                </form>
              </FormProvider>
            </LoaderContextProvider>
          </Container>
        </Box>
        {/*{background_image_position === BgImagePosition.RIGHT && (*/}
        {/*  <div*/}
        {/*    style={{*/}
        {/*      padding: '89px 0 89px 0 ',*/}
        {/*      backgroundImage: `url(${background_url})`,*/}
        {/*      width: '100%',*/}
        {/*      backgroundPosition: 'center',*/}
        {/*      backgroundRepeat: ' no-repeat',*/}
        {/*      backgroundSize: 'cover'*/}
        {/*    }}*/}
        {/*  />*/}
        {/*)}*/}
      </Box>
    </Box>
  );
};
