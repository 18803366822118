import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DatePickerWidgetProps } from "./DatePickerWidget.types";
import { getValidationRule } from "../../../../helper/FormValidators/formHelper";
import { ErrorMessage } from "@hookform/error-message";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";

const defaultProps: DatePickerWidgetProps = {
  type: "datepicker",
  label: "CHECKBOX",
  fieldId: "CHECKBOX",
  required: false,
  value: "",
};

export const DatePickerWidget: FC<DatePickerWidgetProps> = (
  field = defaultProps,
) => {
  const { label, fieldId, required, max_date, min_date, value } = field;
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const getValue = (value: any) => {
    const momentValue = moment(value);

    if (momentValue.isValid()) {
      return momentValue;
    }

    return null;
  };

  return (
    <>
      <Controller
        render={({ field: { ref, onChange, value } }) => (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              onChange={onChange}
              value={getValue(value)}
              maxDate={max_date}
              minDate={min_date}
              slotProps={{
                textField: {
                  name: fieldId,
                  error: !!errors?.[fieldId],
                  size: "small",
                  label: label,
                  margin: "normal",
                  required: required,
                  fullWidth: true,
                  inputRef: ref,
                },
              }}
            />
          </LocalizationProvider>
        )}
        control={control}
        defaultValue={getValue(value)}
        name={fieldId}
        rules={getValidationRule(field)}
      />

      <ErrorMessage
        errors={errors}
        name={fieldId}
        render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
      />
    </>
  );
};
