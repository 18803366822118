import {
  FormContainer,
  TextareaAutosizeElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { clsx } from "clsx";

// @ts-ignore
import closeiconblack from "../../assets/closeiconblack.svg";

const inputFocusStyle = {
  "& label.Mui-focused": {
    color: "#7EB250",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#7EB250",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: "#7EB250",
  },
  "& .MuiOutlinedInput-root.Mui-focused fieldset": {
    borderColor: "#7EB250",
  },
};

const inputLabelStyle = {
  style: { fontSize: "16px", lineHeight: "20px" },
};

type Props = {
  setIsSupportModalOpen: (value: boolean) => void;
};

const SupportModal = ({ setIsSupportModalOpen }: Props) => {
  return (
    <>
      <div
        className={`fixed bottom-0 left-0 right-0 top-0 z-[103] overscroll-none`}
      >
        <div className="absolute bottom-0 left-0 right-0 top-0 bg-[#000] opacity-40"></div>
        <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
          <div className="xs:border-seconary-300 relative h-fit bg-primary-010 xs:w-[346px] xs:rounded-[10px] xs:border xs:px-[24px] xs:py-[40px] sm:w-[632px] sm:px-[40px] md:w-[746px] md:px-[97px]">
            <button
              className="absolute right-0 top-0 xs:m-[16px] sm:m-[26px]"
              onClick={() => setIsSupportModalOpen(false)}
            >
              <img src={closeiconblack} alt="close" />
            </button>
            <h3 className="text-center font-poppins font-semibold text-primary-700 xs:text-base sm:text-xl">
              Chat to our team if you need our support
            </h3>
            <FormContainer>
              <div
                className={clsx("flex flex-wrap justify-between gap-5 pt-7")}
              >
                <TextFieldElement
                  className="xs:w-full sm:w-[47%]"
                  InputLabelProps={inputLabelStyle}
                  sx={inputFocusStyle}
                  type="text"
                  variant="standard"
                  name="clientName"
                  label="Client Name"
                  required
                />
                <TextFieldElement
                  className="xs:w-full sm:w-[47%]"
                  InputLabelProps={inputLabelStyle}
                  sx={inputFocusStyle}
                  type="text"
                  variant="standard"
                  name="company"
                  label="Company"
                  required
                />
                <TextFieldElement
                  className="xs:w-full sm:w-[47%]"
                  InputLabelProps={inputLabelStyle}
                  sx={inputFocusStyle}
                  type="text"
                  variant="standard"
                  name="country"
                  label="Country"
                  required
                />
                <TextFieldElement
                  className="xs:w-full sm:w-[47%]"
                  InputLabelProps={inputLabelStyle}
                  sx={inputFocusStyle}
                  type="text"
                  variant="standard"
                  name="website"
                  label="Website"
                  required
                />
                <TextFieldElement
                  className="xs:w-full sm:w-[47%]"
                  InputLabelProps={inputLabelStyle}
                  sx={inputFocusStyle}
                  type="email"
                  variant="standard"
                  name="email"
                  label="Email"
                  required
                />
                <TextFieldElement
                  className="xs:w-full sm:w-[47%]"
                  InputLabelProps={inputLabelStyle}
                  sx={inputFocusStyle}
                  type="text"
                  variant="standard"
                  name="subject"
                  label="Subject"
                  required
                />
                <TextareaAutosizeElement
                  InputLabelProps={inputLabelStyle}
                  sx={inputFocusStyle}
                  fullWidth
                  variant="standard"
                  name="description"
                  label="Description"
                  required
                />
                <button
                  type="submit"
                  className="mt-10 flex h-[42px] w-[180px] cursor-pointer items-center justify-center rounded-md bg-primary-200 font-roboto text-base text-primary-010 sm:px-5 sm:py-2"
                >
                  Submit
                </button>
              </div>
            </FormContainer>
          </div>
        </div>
      </div>
    </>
  );
};
export default SupportModal;
