import { FC, useState } from "react";
import { QuestionType } from "../../shared/types";

// @ts-ignore
import circleplus from "../../assets/circleplus.svg";
// @ts-ignore
import circleminus from "../../assets/circleminus.svg";

const Question: FC<QuestionType> = ({ answer, question }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleToogleActive = () => {
    setIsExpanded((prevState) => !prevState);
  };
  return (
    <div
      className={`border-seconary-300 group w-full border-t py-3
      ${isExpanded ? "is-active" : ""}
      `}
    >
      <div className="my-2 flex items-center">
        <div className="w-full font-poppins text-xl font-semibold text-primary-700">
          {question}
        </div>
        <div
          className="mx-4 cursor-pointer text-xl"
          onClick={handleToogleActive}
        >
          {isExpanded ? (
            <img src={circleminus} alt="circleminus" />
          ) : (
            <img src={circleplus} alt="circleplus" />
          )}
        </div>
      </div>
      <div
        className="max-h-0 w-[95%] overflow-hidden duration-700 group-[.is-active]:max-h-[100px]"
        dangerouslySetInnerHTML={{ __html: answer }}
      ></div>
    </div>
  );
};
export default Question;
