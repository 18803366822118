import React, { FC, useEffect } from "react";

import { Box, Button, Modal, Typography } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { FormProvider, useForm } from "react-hook-form";

import { CheckboxElement } from "react-hook-form-mui";
import { RequestType } from "../pages/AccountComponent/EmergencyActions/EmergencyActions";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "8px",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "40px 24px",
};

export type DeleteAccountDataProps = {
  isDeleteAccountDataModalOpen: boolean;
  setIsDeleteAccountDataModalOpen: (value: boolean) => void;
  accountStatus: any;
  setIsDownloadAccountDataModalOpen: (value: boolean) => void;
  setIsDeleteAgree: (value: boolean) => void;
  handleSubmitModal: () => void;
  isLoading: boolean;
  setRequestType: (value: RequestType) => void;
  requestType: RequestType | undefined;
};

export const DeleteAccountDataModal: FC<DeleteAccountDataProps> = ({
  isDeleteAccountDataModalOpen,
  setIsDeleteAccountDataModalOpen,
  accountStatus,
  setIsDownloadAccountDataModalOpen,
  setIsDeleteAgree,
  handleSubmitModal,
  isLoading,
  setRequestType,
  requestType,
}) => {
  const formMethods = useForm();
  const { handleSubmit, watch, reset } = formMethods;

  const handleModalClose = () => {
    setIsDeleteAccountDataModalOpen(false);
    reset();
  };

  const handleDownloadRequest = () => {
    setRequestType(RequestType.BackupRequest);
    setIsDeleteAccountDataModalOpen(false);
    setIsDownloadAccountDataModalOpen(true);
  };

  const onSubmit = () => {
    handleSubmitModal();
  };

  useEffect(() => {
    setIsDeleteAgree(watch("agree"));
  }, [watch("agree")]);

  useEffect(() => {
    if (isDeleteAccountDataModalOpen) {
      reset();
    }
  }, [isDeleteAccountDataModalOpen]);

  return (
    <Modal
      open={isDeleteAccountDataModalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ width: "100%", marginBottom: "24px" }}>
                <Typography
                  component={"span"}
                  variant={"h6"}
                  sx={{ fontWeight: "bold" }}
                >
                  Delete account data
                </Typography>
              </Box>

              <Box sx={{ marginBottom: "24px" }}>
                {requestType === RequestType.DeleteRequest && (
                  <Typography variant={"subtitle2"}>
                    {accountStatus?.freeze?.length === 0
                      ? "You must freeze your account to proceed!"
                      : !accountStatus?.backup ||
                        Object.keys(accountStatus?.backup).length === 0
                      ? "We have noticed that you requested to delete your account without requesting download all your data. Do you want to request to download your data now?"
                      : 'Are you sure you want to request to delete your account? Please be certain ofyour decision, as this process is irreversible and cannot be undone. If you wish to continue, click "Request Delete" to initiate the account data deletion. If not, click "Cancel" to retain your information.'}
                  </Typography>
                )}
                {requestType === RequestType.DeleteConfirm && (
                  <Typography variant={"subtitle2"}>
                    Are you sure you want to delete all your account data? This
                    action is irreversible and will permanently remove all your
                    information, including settings, preferences, and saved
                    data. You will lose access to your account and cannot
                    recover any deleted information.
                    <br /> Please ensure this action aligns with your
                    intentions, as it cannot be undone. Consider exporting or
                    saving any important data before proceeding.
                    <br /> Are you sure you want to proceed with deleting all
                    your account data?
                  </Typography>
                )}
              </Box>
              {accountStatus?.freeze?.length !== 0 && (
                <Box sx={{ marginBottom: "24px" }}>
                  <CheckboxElement
                    id="agree"
                    name="agree"
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {accountStatus?.backup?.length === 0
                          ? "I want request delete account without request my data"
                          : `I want ${
                              requestType === RequestType.DeleteRequest
                                ? "to request"
                                : ""
                            } delete account`}
                      </span>
                    }
                    required
                    sx={{
                      "&.Mui-checked .MuiSvgIcon-root": {
                        color: "#2E7D32",
                      },
                    }}
                  />
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "12px",
                }}
              >
                <Button
                  variant={"outlined"}
                  color={"inherit"}
                  onClick={handleModalClose}
                  sx={{ textTransform: "capitalize", padding: "7px 17px" }}
                >
                  Cancel
                </Button>

                {((!accountStatus?.backup &&
                  accountStatus?.freeze?.length !== 0) ||
                  (Object.keys(accountStatus?.backup).length === 0 &&
                    accountStatus?.freeze?.length !== 0)) && (
                  <Button
                    variant={"contained"}
                    color={"success"}
                    onClick={handleDownloadRequest}
                    sx={{ textTransform: "capitalize", padding: "7px 34px" }}
                  >
                    Request your data
                  </Button>
                )}

                <LoadingButton
                  type={"submit"}
                  variant={"contained"}
                  color={"error"}
                  loading={isLoading}
                  sx={{ textTransform: "capitalize", padding: "7px 34px" }}
                  disabled={
                    accountStatus?.frteeze?.length === 0 || !watch("agree")
                  }
                >
                  {requestType === RequestType.DeleteRequest &&
                    "Request delete"}
                  {requestType === RequestType.DeleteConfirm && "Confirm"}
                </LoadingButton>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
};
