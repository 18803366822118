import React, { useState } from "react";
import { JsonTree } from "react-editable-json-tree";
import { Button } from "@mui/material";
import _ from "lodash";
import "./WorkflowEditJsonData.css";
import { WarningModal } from "../Modal/WarningModal";
import moment from "moment";

const WorkflowEditJsonData = ({ data, update }: any) => {
  const [json, setJson] = useState(_.cloneDeep({ ...data }));
  const [dataJson, setDataJson] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [message, setMessage] = useState("");

  const handleUpdate = (updatedData: any) => {
    setDataJson(_.cloneDeep(updatedData));
  };

  const onDeltaUpdate = (deltaUpdate: any) => {
    setOpenConfirmation(true);
    setMessage(
      `Do you want to change "${deltaUpdate.key}"\nfrom "${deltaUpdate.oldValue}"\nto "${deltaUpdate.newValue}"`,
    );
  };

  const submitChanges = () => {
    setOpenConfirmation(false);
    update(dataJson);
  };

  const cancelChanges = () => {
    setJson(_.cloneDeep({ ...data }));
    setOpenConfirmation(false);
  };

  const downloadJson = () => {
    const fileData = JSON.stringify(json, null, 2);
    const blob = new Blob([fileData], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${localStorage.getItem("SUBDOMAIN")}_${data.slug}_${moment().format("YYYYMMDD_HHmmss")}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="json-tree">
        <Button
          sx={{ display: "flex", justifyContent: "center" }}
          onClick={downloadJson}
        >
          Download JSON
        </Button>
        <JsonTree
          data={json}
          isCollapsed={() => false}
          readOnly={false}
          onDeltaUpdate={onDeltaUpdate}
          onFullyUpdate={handleUpdate}
          editButtonElement={
            <Button
              sx={{ marginLeft: "7px" }}
              variant={"contained"}
              color={"success"}
            >
              Edit
            </Button>
          }
          cancelButtonElement={
            <Button variant={"contained"} color={"inherit"}>
              Cancel
            </Button>
          }
        />
      </div>
      {openConfirmation && (
        <WarningModal
          open={openConfirmation}
          message={message}
          onCancel={cancelChanges}
          onSubmit={submitChanges}
        />
      )}
    </>
  );
};

export default WorkflowEditJsonData;
