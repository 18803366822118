import { createApi } from "@reduxjs/toolkit/query/react";
import { LocationType } from "../../location/locationSlice";
import { baseCustomFetchBase } from "../service/customFetchBase";

const location = "location";
const locations = "locations";

// @ts-ignore
const locationApi = createApi({
  reducerPath: "locationApi",
  baseQuery: baseCustomFetchBase,
  endpoints: (builder) => ({
    getLocation: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `${location}/${id}`,
        method: "GET",
      }),
    }),
    createLocation: builder.mutation<
      any,
      Pick<LocationType, "address" | "name" | "timezone">
    >({
      query: (data) => ({
        url: `${location}`,
        method: "POST",
        body: data,
      }),
    }),
    getLocations: builder.mutation<LocationType[], any>({
      query: () => ({
        url: `${locations}`,
        method: "GET",
      }),
    }),
    deleteLocation: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `${location}/${id}`,
        method: "DELETE",
      }),
    }),
    updateLocation: builder.mutation<any, LocationType>({
      query: (data) => ({
        url: `${location}/${data.id}`,
        method: "PUT",
        body: data,
      }),
    }),
  }),
});

export default locationApi;

export const {
  getLocation,
  getLocations,
  createLocation,
  deleteLocation,
  updateLocation,
} = locationApi.endpoints;
