import React, { FC } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { RequestType } from "../pages/AccountComponent/EmergencyActions/EmergencyActions";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "8px",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "40px 24px",
};

export type DownloadAccountDataProps = {
  isDownloadAccountDataModalOpen: boolean;
  setIsDownloadAccountDataModalOpen: (value: boolean) => void;
  handleSubmitModal: () => void;
  isLoading: boolean;
  requestType: RequestType | undefined;
};

export const DownloadAccountDataModal: FC<DownloadAccountDataProps> = ({
  isDownloadAccountDataModalOpen,
  setIsDownloadAccountDataModalOpen,
  handleSubmitModal,
  isLoading,
  requestType,
}) => {
  const handleModalClose = () => {
    setIsDownloadAccountDataModalOpen(false);
  };

  const handleSubmit = () => {
    handleSubmitModal();
  };

  return (
    <Modal
      open={isDownloadAccountDataModalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ width: "100%", marginBottom: "24px" }}>
            <Typography
              component={"span"}
              variant={"h6"}
              sx={{ fontWeight: "bold" }}
            >
              Download account data
            </Typography>
          </Box>
          <Box sx={{ marginBottom: "24px" }}>
            {requestType === RequestType.BackupRequest && (
              <Typography variant={"subtitle2"}>
                Are you sure you want to request to download all your account
                data? If you wish to continue, click "Proceed" to initiate the
                account data downloading. If not, click "Cancel".
              </Typography>
            )}
            {requestType === RequestType.BackupDownload && (
              <Typography variant={"subtitle2"}>
                Downloading all your account data will compile and export all
                information associated with your account, including personal
                details, preferences, and usage data. This file may contain
                sensitive information.
                <br />
                Please ensure that you are accessing this feature for legitimate
                purposes and that the downloaded data will be handled securely.
                Be cautious while storing or sharing this exported file, as it
                may contain sensitive information about your account.
                <br />
                Are you sure you want to download all your account data?
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "12px",
            }}
          >
            <Button
              variant={"outlined"}
              color={"inherit"}
              onClick={handleModalClose}
              sx={{ textTransform: "capitalize", padding: "7px 17px" }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant={"contained"}
              color={"success"}
              loading={isLoading}
              sx={{ textTransform: "capitalize", padding: "7px 34px" }}
              onClick={handleSubmit}
            >
              {requestType === RequestType.BackupRequest && "Proceed"}
              {requestType === RequestType.BackupDownload && "Download"}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
