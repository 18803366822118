import { CartesianCoordinates } from "tiptap/types/Size/Coordinates";
import { ResizerState } from "tiptap/types/Size/ResizerState";
import { Size } from "tiptap/types/Size/Size";
import { clamp } from "tiptap/utils/shared";

export function getNewFinalSize(
  currentSize: Size,
  minAllowedSize: number,
  maxAllowedSize: number,
): Size {
  let newWidth = currentSize.width || minAllowedSize;
  let newHeight = currentSize.height || minAllowedSize;
  newWidth = Math.min(newWidth, maxAllowedSize);

  return { width: newWidth, height: newHeight };
}

export function getNewSizeWhileMoving(
  clientCoords: CartesianCoordinates,
  resizerState: ResizerState,
  minSize: number,
  maxWidth: number,
  keepAspectRatio: boolean,
  aspectRatio: number,
) {
  const dx =
    (clientCoords.x - resizerState.x) * (/l/.test(resizerState.dir) ? -1 : 1);
  const dy =
    (clientCoords.y - resizerState.y) * (/t/.test(resizerState.dir) ? -1 : 1);

  let newWidth = resizerState.w + dx;
  let newHeight = resizerState.h + dy;
  let newMinSize: Size = {
    width: minSize,
    height: minSize,
  };

  if (keepAspectRatio) {
    if (newWidth !== 0 && newHeight !== 0) {
      if (dx !== 0 || (dx !== 0 && dy !== 0)) {
        newHeight = newWidth / aspectRatio;
        newMinSize.height! /= aspectRatio;
      } else if (dy !== 0) {
        newWidth = newHeight * aspectRatio;
        newMinSize.width *= aspectRatio;
      }
    }
  }
  newWidth = clamp(newWidth, newMinSize.width, maxWidth);
  newHeight = Math.max(newHeight, newMinSize.height!);
  
  const newSize: Size = {
    width: newWidth,
    height: newHeight,
  };
  return newSize;
}
