import { Size } from "tiptap/types/Size/Size";

type ImageResult = {
  complete: boolean;
  width: number;
  height: number;
  src: string;
};

interface ImageCache {
  [key: string]: ImageResult;
}

const IMAGE_CACHE: ImageCache = {};

export function resolveImg(src: string): Promise<ImageResult> {
  return new Promise((resolve, reject) => {
    const result: ImageResult = {
      complete: false,
      width: 0,
      height: 0,
      src,
    };

    if (!src) {
      reject(result);
      return;
    }

    if (IMAGE_CACHE[src]) {
      resolve({ ...IMAGE_CACHE[src] });
      return;
    }

    const img = new Image();

    img.onload = () => {
      result.width = img.width;
      result.height = img.height;
      result.complete = true;

      IMAGE_CACHE[src] = { ...result };
      resolve(result);
    };

    img.onerror = () => {
      reject(result);
    };

    img.src = src;
  });
}

export function roundToThreeDigits(value: number): number {
  return Math.round(value * 1000) / 1000;
}

export function calculateAspectRatio(width: number, height: number): number;
export function calculateAspectRatio(size: Size): number;

export function calculateAspectRatio(
  widthOrSize: number | Size,
  height?: number,
): number {
  if (typeof widthOrSize === "number" && typeof height === "number") {
    return roundToThreeDigits(widthOrSize / height);
  } else if (typeof widthOrSize === "object") {
    const size = widthOrSize as Size;
    return roundToThreeDigits(size.width / size.height!);
  } else {
    throw new Error("Invalid arguments");
  }
}
