import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button } from "@mui/material";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import LockTwoToneIcon from "@mui/icons-material/LockTwoTone";

type ImageWidgetModalFormProps = {
  openState: [
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  ];
  keepCurrentAspectRatioState: [
    keepCurrentAspectRatio: boolean,
    handleToggleKeepAspectRatio: (keepAspectRatio: boolean) => void,
  ];
  isOriginalSizeImage: boolean;
  returnImageToOriginalSize: () => void;
};

export default function ImageWidgetModalForm(
  props: ImageWidgetModalFormProps,
): JSX.Element {
  const {
    isOriginalSizeImage,
    openState,
    keepCurrentAspectRatioState,
    returnImageToOriginalSize,
  } = props;
  const [open, setOpen] = openState;
  const [keepCurrentAspectRatio, handleToggleKeepAspectRatio] =
    keepCurrentAspectRatioState;

  const handleClose = () => {
    setOpen(false);
  };

  const toggle = () => {
    handleToggleKeepAspectRatio(!keepCurrentAspectRatio);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form"
        }}
      >
        <Box className="flex w-full justify-between">
          <DialogTitle>Image widget settings</DialogTitle>
          <DialogActions>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </Box>

        <DialogContent dividers>
          <Box className="flex max-h-[40vmin] flex-col gap-3">
            <Box>
              {!keepCurrentAspectRatio ? (
                <IconButton>
                  <LockOpenTwoToneIcon onClick={toggle} />
                </IconButton>
              ) : (
                <IconButton>
                  <LockTwoToneIcon color="primary" onClick={toggle} />
                </IconButton>
              )}
              <span className="tracking-[0.06em]">
                keep current aspect ratio?
              </span>
            </Box>
            <Box className="p-[8px]">
              {!isOriginalSizeImage && (
                <Button variant="outlined" onClick={returnImageToOriginalSize}>
                  Return image to original size
                </Button>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
