import { useState } from "react";

import { Editor } from "@tiptap/core";

import { MenuButton } from "../../MenuButton";
import { LinkDialog } from "./LinkDialog";

interface MenuEditLinkButtonProps {
  editor: Editor;
}

export const MenuEditLinkButton = ({ editor }: MenuEditLinkButtonProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [href, setHref] = useState("");
  const [target, setTarget] = useState("");

  const updateLinkAttrs = ({
    href,
    openInNewTab = true,
  }: {
    href: string;
    openInNewTab: boolean;
  }) => {
    editor.chain().focus().unsetLink();
    editor
      .chain()
      .focus()
      .setLink({ href, target: openInNewTab ? "_blank" : null })
      .run();
    closeEditLinkDialog();
  };

  const openEditLinkDialog = () => {
    const { href, target } = editor.getAttributes("link");
    setHref(href.toString());
    setTarget(target);
    setIsVisible(true);
  };

  const closeEditLinkDialog = () => {
    setIsVisible(false);
  };

  return (
    <div>
      <MenuButton
        command={openEditLinkDialog}
        title="Edit link"
        icon="linkEdit"
      />
      <LinkDialog
        title="Edit link"
        onClose={closeEditLinkDialog}
        onClick={updateLinkAttrs}
        isUpdateLink={true}
        isVisible={isVisible}
        href={href}
        target={target}
      />
    </div>
  );
};
