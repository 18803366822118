export const isObjectByPath = (
  object: Record<string, any>,
  keys: string[],
): boolean => {
  let currentObject = object;
  for (let key of keys) {
    if (Object.hasOwn(currentObject, key)) {
      currentObject = currentObject[key];
    } else {
      return false;
    }
  }

  return !!currentObject;
};
