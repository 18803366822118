// MenuItalicButton.tsx
import React from "react";
import { Editor } from "@tiptap/core";
import { MenuButton } from "../MenuButton";
import { TipTapEditorContext } from "../../types";

interface MenuItalicButtonProps {
  editor: Editor;
  editorContext: TipTapEditorContext;
}

export const MenuItalicButton: React.FC<MenuItalicButtonProps> = ({
  editor,
  editorContext,
}) => {
  const isActive = editorContext.state.isActiveItalic;

  const runCommand = () => {
    editor.chain().focus().toggleItalic().run();
  };

  return (
    <MenuButton
      icon="italic"
      title="Italic"
      command={runCommand}
      isActive={isActive}
    />
  );
};
