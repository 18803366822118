// MenuBar.tsx

import { FC } from "react";
import { Editor } from "@tiptap/react";

import { MenuItalicButton } from "./MenuItems/MenuItalicButton";
import { TablePopover } from "./MenuItems/TablePopover/TablePopover";
import { MenuBoldButton } from "./MenuItems/MenuBoldButton";
import { MenuUnderlineButton } from "./MenuItems/MenuUnderlineButton";
import { MenuStrikeButton } from "./MenuItems/MenuStrikeButton";

import { MenuHeadingDropdown } from "./MenuItems/MenuHeadingDropdown";
import { MenuFontFamilyDropdown } from "./MenuItems/MenuFontFamilyDropdown";
import { MenuFontSizeDropdown } from "./MenuItems/MenuFontSizeDropdown";
import { MenuColorButton } from "./MenuItems/MenuColorButton";
import { MenuHighlightButton } from "./MenuItems/MenuHighlightButton";
import { MenuBulletListButton } from "./MenuItems/MenuBulletListButton";
import { MenuOrderedListButton } from "./MenuItems/MenuOrderedListButton";
import { MenuTodoListButton } from "./MenuItems/MenuTodoListButton";
import { MenuHistoryButtons } from "./MenuItems/History/MenuHistoryButtons";
import { MenuRemoveFormatButton } from "./MenuItems/MenuRemoveFormatButton";
import { MenuTextAlignButtons } from "./MenuItems/TextAlign/MenuTextAlignButtons";
import { MenuIndentButton } from "./MenuItems/MenuIndentButton";
import { MenuOutdentButton } from "./MenuItems/MenuOutdentButton";
import { MenuHorizontalRuleButton } from "./MenuItems/MenuHorizontalRuleButton";
import { MenuAddImageButton } from "./MenuItems/Image/MenuAddImageButton";

import "../styles/MenuBar.scss";

type TipTapEditorContext = any; // Replace 'any' with the correct type for your application

interface MenuBarProps {
  editor: Editor;
  editorContext: TipTapEditorContext;
  className?: string;
}

export const MenuBar: FC<MenuBarProps> = ({
  editor,
  editorContext,
  className,
}) => {
  return (
    <div className={className}>
      <MenuHeadingDropdown editor={editor} editorContext={editorContext} />
      <span className="menu-button-devider" />
      <MenuFontFamilyDropdown editor={editor} editorContext={editorContext} />
      <span className="menu-button-devider" />
      <MenuFontSizeDropdown editor={editor} editorContext={editorContext} />

      <span className="menu-button-devider" />

      <MenuBoldButton editor={editor} editorContext={editorContext} />
      <MenuItalicButton editor={editor} editorContext={editorContext} />
      <MenuUnderlineButton editor={editor} editorContext={editorContext} />
      <MenuStrikeButton editor={editor} editorContext={editorContext} />

      <span className="menu-button-devider" />

      <MenuColorButton editor={editor} editorContext={editorContext} />
      <MenuHighlightButton editor={editor} editorContext={editorContext} />
      <MenuRemoveFormatButton editor={editor} />

      <span className="menu-button-devider" />

      <MenuTextAlignButtons editor={editor} editorContext={editorContext} />
      <MenuIndentButton editor={editor} />
      <MenuOutdentButton editor={editor} />

      <span className="menu-button-devider" />

      <MenuOrderedListButton editor={editor} editorContext={editorContext} />
      <MenuBulletListButton editor={editor} editorContext={editorContext} />
      <MenuTodoListButton editor={editor} editorContext={editorContext} />

      <MenuHistoryButtons editor={editor} />

      <span className="menu-button-devider" />

      <MenuAddImageButton editor={editor} editorContext={editorContext} />
      <TablePopover editor={editor} editorContext={editorContext} />
      <MenuHorizontalRuleButton editor={editor} />
      {/*<MenuAddLinkButton editor={editor} editorContext={editorContext} />*/}
      {/*<MenuAddAttachmentButton editor={editor} />*/}
      {/*<MenuBlockquoteButton editor={editor} editorContext={editorContext} />*/}
      {/*<MenuCodeBlockButton editor={editor} editorContext={editorContext} />*/}
    </div>
  );
};
