import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  showErrorNotification,
  showSuccessNotification,
} from "../../notification/notificationSlice";

import {
  deleteRelationship,
  getClientById,
  getClientDocumentById,
  getClientDocumentsById,
  getClients,
  updateClientProfile,
} from "../../api/clientApi/clientApi";
import {
  setClient,
  setClientDocument,
  setClientDocuments,
  setClients,
} from "../../client/clientSlice";

import { FieldValues } from "react-hook-form";
import { RootState } from "../../../index";

export const getClientsThunk = createAsyncThunk(
  "thunkSlice/getClientsThunk",
  async (
    {
      page,
      perPage,
    }: {
      page: number;
      perPage: number;
    },
    { dispatch },
  ) => {
    try {
      const response = await dispatch(
        getClients.initiate({ page, perPage }),
      ).unwrap();
      dispatch(setClients(response.data));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);
export const deleteRelationshipThunk = createAsyncThunk(
  "thunkSlice/deleteRelationshipThunk",
  async (relationshipUuid: string, { dispatch, getState }) => {
    const {
      client: { client },
    } = getState() as RootState;
    const uuid = client?.uuid as string;

    try {
      await dispatch(
        deleteRelationship.initiate({ relationshipUuid, uuid }),
      ).unwrap();

      await dispatch(getClientThunk(`${uuid}`));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const getClientThunk = createAsyncThunk(
  "thunkSlice/getClientThunk",
  async (uuid: string, { dispatch }) => {
    try {
      await dispatch(setClient(null));

      const client = await dispatch(getClientById.initiate({ uuid })).unwrap();

      await dispatch(setClient(client));
      await dispatch(getClientDocumentsThunk(uuid));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const getClientDocumentsThunk = createAsyncThunk(
  "thunkSlice/getClientDocumentsThunk",
  async (uuid: string, { dispatch }) => {
    try {
      await dispatch(setClientDocuments([]));

      const clientDocuments = await dispatch(
        getClientDocumentsById.initiate({ uuid }),
      ).unwrap();

      dispatch(setClientDocuments(clientDocuments));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const updateClientProfileThunk = createAsyncThunk(
  "thunkSlice/updateClientProfileThunk",
  async ({ uuid, data }: { uuid: string; data: FieldValues }, { dispatch }) => {
    try {
      const client = await dispatch(
        updateClientProfile.initiate({ uuid, data }),
      ).unwrap();

      dispatch(showSuccessNotification({ message: client?.message }));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const getClientDocumentThunk = createAsyncThunk(
  "thunkSlice/getClientDocumentThunk",
  async (
    { clientUuid, documentUuid }: { clientUuid: string; documentUuid: string },
    { dispatch },
  ) => {
    try {
      let document = await dispatch(
        getClientDocumentById.initiate({ clientUuid, documentUuid }),
      ).unwrap();

      dispatch(setClientDocument(document));
      dispatch(showSuccessNotification({ message: document?.message }));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);
