import { FC } from "react";

import { useAppSelector } from "../../store/index";
import { getBaseUrl } from "helper/urlHelper";
import { extractSubdomain } from "helper/domainHelper";

export const GoIframe: FC = () => {
  const storeSubdomain = useAppSelector(
    ({ company }) => company.companyDetails?.subdomain,
  );
  const baseUrl = getBaseUrl(storeSubdomain, window.location.hostname);

  return (
    <>
      {!extractSubdomain() && storeSubdomain && (
        <iframe
          id="goPage"
          src={`${baseUrl}/go/`}
          style={{ display: "none" }}
        />
      )}
    </>
  );
};
