import { FC, useContext, useEffect } from "react";
import { Box, Link, Typography } from "@mui/material";
import { FieldValues, useFormContext } from "react-hook-form";
import {
  InputWidget,
  InputWidgetProps,
} from "../widgets/FormWidgets/InputWidget";
import { useDispatch } from "react-redux";
import { AuthTypes, setAuthMethod } from "../../store/features/form/formSlice";
import {
  useAppDispatch,
  useAppSelector,
  useAppThunkDispatch,
} from "../../store";
import { signUpThunk } from "../../store/features/thunk/thunkApi";
import { useNavigate } from "react-router-dom";
import { LoaderContext } from "../../context/LoaderContext";
import { LoadingButton } from "@mui/lab";
import { OtpWidget } from "../OtpWidget/OtpWidget";

const signInFormData: InputWidgetProps[] = [
  {
    type: "email",
    label: "Email",
    fieldId: "email",
    required: true,
    value: "",
  },
  {
    type: "password",
    label: "Password",
    fieldId: "password",
    required: true,
    value: "",
  },
  {
    type: "password",
    label: "Confirm Password",
    fieldId: "passwordConfirm",
    required: true,
    value: "",
    custom_validator: {
      value: (value) => !value,
      message: "",
    },
  },
  {
    type: "text",
    label: "Verification code",
    fieldId: "verificationCode",
    required: true,
    value: "",
  },
];

const messages = [
  "Your account and company are in the works...",
  "Hang tight, we're crafting your account and company...",
  "Configuring settings to match your needs...",
  "Securing your data for peace of mind...",
  "Almost there—full access coming soon!",
  "Thanks for waiting, it won't be long!",
];

export const Signup: FC = () => {
  const { watch } = useFormContext();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoading } = useContext(LoaderContext);

  const matchPasswords = (confPass: FieldValues) =>
    watch("password") === confPass;

  signInFormData[2].custom_validator = {
    // @ts-ignore
    value: matchPasswords,
    message: "Your passwords do no match",
  };

  return (
    <Box sx={{ maxWidth: "400px", width: "100%" }}>
      <Typography variant={"h4"} textAlign={"center"}>
        Create an account
      </Typography>
      <InputWidget {...signInFormData[0]} />
      <InputWidget {...signInFormData[1]} />
      <InputWidget {...signInFormData[2]} />
      <LoadingButton
        sx={{ marginTop: "10px" }}
        variant={"contained"}
        type={"submit"}
        fullWidth
        color={"inherit"}
        loading={isLoading}
        loadingPosition="end"
        endIcon={<></>}
      >
        Sign Up
      </LoadingButton>
      <Box
        display={"flex"}
        alignItems={"baseline"}
        gap={"5px"}
        paddingTop={"10px"}
      >
        <Typography variant={"subtitle1"}>Have an account?</Typography>
        <Link
          sx={{ cursor: "pointer" }}
          onClick={() => {
            dispatch(setAuthMethod(AuthTypes.SIGN_IN));
            navigate("/signin");
          }}
        >
          Sign in
        </Link>
      </Box>
    </Box>
  );
};

export const SignupComponent: FC = () => {
  const { authMethod, otpMethods } = useAppSelector(({ form }) => form);
  const dispatch = useDispatch();
  const dispatchThunk = useAppThunkDispatch();

  const handleSendCode = () => {
    dispatchThunk(signUpThunk(null));
  };

  useEffect(() => {
    dispatch(setAuthMethod(AuthTypes.SIGN_UP));
    localStorage.clear();
  }, []);

  if (!authMethod) return null;

  if (authMethod === AuthTypes.OTP_SIGN_UP) {
    return (
      <div key={"OtpReset"}>
        <OtpWidget
          otpMethods={otpMethods}
          onSendCode={handleSendCode}
          authMethod={authMethod}
        />
      </div>
    );
  }

  return (
    <div key={"Reset"}>
      <Signup />
    </div>
  );
};
