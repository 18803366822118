import { FC, useEffect, useState } from "react";

import ReactGridLayout from "react-grid-layout";
import { Box, Button, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

import {
  getClientDocumentThunk,
  updateClientProfileThunk,
} from "../../store/features/thunk/clientThunk/clientThunk";
import useElementSize from "../../hooks/useElementSize";
import { useAppSelector, useAppThunkDispatch } from "../../store";

import { TableComponent } from "../TableComponent/TableComponent";
import { getContentWidgets } from "../widgets/services/widgetsFactory";
import { RelationshipsComponent } from "../RelationshipsComponent/RelationshipsComponent";

import { getWidgets } from "./widgets";

const headers: string[] = ["DOCUMENTS NAME", "CREATION DATE", "LOCATION"];

export const ClientInfo: FC = () => {
  const dispatchThunk = useAppThunkDispatch();
  const [container, { width }] = useElementSize();

  const formMethods = useForm();
  const { handleSubmit, setValue, watch, unregister } = formMethods;
  const dob = watch("dob");

  const [show, setShow] = useState(false);

  const { client, clientDocuments } = useAppSelector(({ client }) => client);

  useEffect(() => {
    setShow(false);
    if (client) {
      Object.entries(client).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
    setShow(true);
  }, [client, setValue]);

  useEffect(() => {
    if (dob) {
      const birthdate = new Date(dob);
      const ageDiffMs = Date.now() - birthdate.getTime();
      const ageDate = new Date(ageDiffMs);
      const age = Math.abs(ageDate.getUTCFullYear() - 1970);
      setValue("age", age);
    }
  }, [dob, setValue]);

  const onSubmit = (data: any) => {
    dispatchThunk(
      updateClientProfileThunk({ uuid: `${client?.uuid || ""}`, data }),
    );
  };

  return (
    <>
      <Typography
        variant={"h5"}
        fontWeight={"bold"}
        textAlign={"start"}
        paddingBottom={"20px"}
      >
        Documents, client signed
      </Typography>
      <TableComponent
        type={"clientDocuments"}
        headers={headers}
        rows={clientDocuments}
        onRowClick={(uuid) => {
          dispatchThunk(
            getClientDocumentThunk({
              clientUuid: `${client?.uuid || ""}`,
              documentUuid: `${uuid}`,
            }),
          );
        }}
      />

      <Box
        display={"flex"}
        maxWidth={"1200px"}
        justifyContent={"space-between"}
      >
        <Box
          ref={container}
          width={"100%"}
          maxWidth={"715px"}
          textAlign={"start"}
          paddingTop={"50px"}
          position={"relative"}
        >
          {show && (
            <FormProvider {...formMethods}>
              <form id="userProf" onSubmit={handleSubmit(onSubmit)} noValidate>
                <ReactGridLayout
                  width={width <= 900 ? width - 48 : width}
                  transformScale={0.1}
                  margin={[30, 30]}
                  containerPadding={[0, 0]}
                  isResizable={false}
                  isDraggable={false}
                  rowHeight={60}
                  compactType={null}
                  cols={6}
                >
                  {getContentWidgets(
                    getWidgets(client?.country),
                    false,
                    false,
                    width,
                    () => {},
                  )}
                </ReactGridLayout>
              </form>
            </FormProvider>
          )}
        </Box>
        <Box
          width={"100%"}
          display={"flex"}
          maxWidth={"300px"}
          paddingTop={"30px"}
          flexDirection="column"
          justifyContent={"space-between"}
          gap={"20px"}
        >
          {client && client.relationships?.length ? (
            <RelationshipsComponent clients={client.relationships} />
          ) : (
            <div></div>
          )}
          <Box display={"flex"} gap={"20px"}>
            <Button variant={"outlined"} color={"inherit"} fullWidth>
              Cancel
            </Button>
            <Button
              form={"userProf"}
              type={"submit"}
              variant={"contained"}
              color={"success"}
              fullWidth
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
