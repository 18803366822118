import { FC } from "react";
import { PropsCommentItem } from "../../shared/types";
import useMediaQuery from "../../hooks/useMediaQuery";

// @ts-ignore
import quoteslarge from "../../assets/quoteslarge.svg";
// @ts-ignore
import quotessmall from "../../assets/quotessmall.svg";
// @ts-ignore
import yourlogohere from "../../assets/yourlogohere.png";

const COMPANY_LOGO = yourlogohere;

export const CommentItem: FC<PropsCommentItem> = ({
  commentText,
  name,
  position,
  profileImg = COMPANY_LOGO,
}) => {
  const isUnderLargeScreen = useMediaQuery("(min-width: 1440px");

  return (
    <div className={"flex flex-col gap-[50px] px-[30px]"}>
      <div
        className="relative flex rounded-xl bg-primary-200 p-4 pb-[28px] pt-[38px] text-primary-010 before:absolute before:h-0 before:w-0 before:border-b-[28px]
      before:border-l-[28px] before:border-r-[22px] before:border-t-[22px] before:border-b-transparent before:border-l-primary-200 before:border-r-transparent before:border-t-primary-200 xs:px-[20px] xs:text-sm xs:before:bottom-[-30px] xs:before:left-[40px] sm:text-xl lg:px-[65px] lg:before:bottom-[-40px] lg:before:left-[70px]"
      >
        {isUnderLargeScreen ? (
          <img
            className="mr-[5px] mt-[-10px] self-start xs:w-[50px]"
            src={quoteslarge}
            alt={quoteslarge}
          />
        ) : (
          <img
            className="mr-[5px] mt-[-10px] self-start xs:w-[50px]"
            src={quotessmall}
            alt={quotessmall}
          />
        )}
        {commentText}
      </div>
      <div className="flex items-center gap-[30px]">
        <div className="flex h-[67px] w-[67px] items-center rounded-full">
          <img src={profileImg} alt={profileImg} />
        </div>
        <div>
          <p className="font-poppins text-xl font-semibold text-primary-700">
            {name}
          </p>
          <p>{position}</p>
        </div>
      </div>
    </div>
  );
};
