import { FeatureType } from "../shared/types";

// @ts-ignore
import feature1 from "../assets/feature1.svg";
// @ts-ignore
import feature2 from "../assets/feature2.svg";
// @ts-ignore
import feature3 from "../assets/feature3.svg";
// @ts-ignore
import feature4 from "../assets/feature4.svg";
// @ts-ignore
import feature5 from "../assets/feature5.svg";
// @ts-ignore
import feature6 from "../assets/feature6.svg";
// @ts-ignore
import feature7 from "../assets/feature7.svg";
// @ts-ignore
import feature8 from "../assets/feature8.svg";
// @ts-ignore
import feature9 from "../assets/feature9.svg";
// @ts-ignore
import feature10 from "../assets/feature10.svg";
// @ts-ignore
import feature11 from "../assets/feature11.svg";

const featuresData: Array<FeatureType> = [
  {
    icon: feature1,
    title: "Kiosk for the Front of House",
    description:
      "Elevate customer experience with our sleek kiosk solution. Transform your front desk into a seamless digital hub for waivers and forms, impressing clients while effortlessly capturing their information.",
  },
  {
    icon: feature2,
    title: "Internal Docs - Streamline Client Interaction",
    description:
      "Empower your staff to streamline client interactions with our Internal Docs feature. Say goodbye to paper forms as your team efficiently records essential details on-the-fly, leaving clients impressed by your professionalism and organization.",
  },
  {
    icon: feature3,
    title: "Unlimited Documents and Forms",
    description: `Break free from limitations with <a style="color: #7EB250; text-decoration: underline" href="https://www.wkform.com">WKForm.com</a> . Upload and manage as many documents and forms as you need, ensuring your business has the flexibility to adapt and thrive without constraints.`,
  },
  {
    icon: feature4,
    title: "Sign Waivers Anywhere, Anytime",
    description:
      "Embrace flexibility with our dual signing options. Clients can sign waivers conveniently from hero or on-site, enabling you to offer a seamless experience whether they're on-premises or miles away.",
  },
  {
    icon: feature5,
    title: "Finger Signing for Effortless Consent",
    description:
      "Redefine convenience with our finger signing feature. Clients can provide their consent with a simple touch, making the process smoother and modernizing the way agreements are sealed.",
  },
  {
    icon: feature6,
    title: "Mandatory Fields for Accurate Data",
    description:
      "Ensure accuracy with our mandatory fields feature. Make sure crucial information is never missed by customizing fields that require completion, guaranteeing your records are complete and dependable.",
  },
  {
    icon: feature7,
    title: "Family Support for Comprehensive Waivers",
    description:
      "Extend your reach with family-friendly features. Adults can effortlessly include their children in the waiver, catering to family-oriented experiences and ensuring comprehensive coverage.",
  },
  {
    icon: feature8,
    title: "Quick Onboarding with Lite Version",
    description: `Need speed? Our Lite Version offers a rapid onboarding experience, perfect for customers looking to get started swiftly while still enjoying the benefits of <a style="color: #7EB250; text-decoration: underline" href="https://www.wkform.com">WKForm.com</a>'s powerful platform.`,
  },
  {
    icon: feature9,
    title: "Self-Service Dashboard - Insights at a Glance",
    description:
      "Empower your clients with the Self-Service Dashboard. Access statistics, review bills, and effortlessly retrieve receipts all in one place, putting the control in their hands and simplifying their engagement with your business.",
  },
  {
    icon: feature10,
    title: "Instant Email Copies for Peace of Mind",
    description:
      "Offer peace of mind with our instant email copies. Clients receive a digital copy of the signed waiver or form, keeping them informed and assured about the agreements they've made.",
  },
  {
    icon: feature11,
    title: "Multi-Location Support and Flexible Billing",
    description: `Grow effortlessly with our multi-location support and adaptable billing options. Whether you have one location or a network of venues, <a style="color: #7EB250; text-decoration: underline" href="https://www.wkform.com">WKForm.com</a> seamlessly accommodates your business model and ensures billing is tailored to your needs.`,
  },
];

export default featuresData;
