import { FC } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { DatePickerWidgetProps } from "../DatePickerWidget";
import { getValidationRule } from "../../../../helper/FormValidators/formHelper";
import { ErrorMessage } from "@hookform/error-message";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { USTimezones } from "components/widgets/@types/UStimeZones";
import { getFullNameTZ } from "helper/timeZoneHelper";

const defaultProps: DatePickerWidgetProps = {
  type: "datepicker",
  label: "CHECKBOX",
  fieldId: "CHECKBOX",
  required: false,
  value: "",
};

export const TimeZonePickerWidget: FC<any> = (field = defaultProps) => {
  const { fieldId, value } = field;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Controller
        render={({ field: { onChange, value, name } }) => (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <FormControl
              sx={{ width: "100%", mt: 2 }}
              error={!!errors?.[fieldId]}
            >
              <InputLabel
                id={fieldId}
                size={"small"}
                sx={{ background: "#fff", padding: "0 5px" }}
              >
                Timezone *
              </InputLabel>
              <Select
                name={name}
                id={fieldId}
                required
                fullWidth
                onChange={onChange}
                value={value}
                inputProps={{ "aria-label": "Without label" }}
                size={"small"}
              >
                {USTimezones.map((name: string) => {
                  const fullFormat = getFullNameTZ(name);
                  return (
                    <MenuItem key={`${fullFormat}`} value={`${name}`}>
                      {fullFormat}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </LocalizationProvider>
        )}
        control={control}
        defaultValue={value}
        name={fieldId}
        rules={getValidationRule(field)}
      />
      <ErrorMessage
        errors={errors}
        name={fieldId}
        render={({ message }) => (
          <p style={{ color: "red", fontSize: "13px" }}>{message}</p>
        )}
      />
    </>
  );
};
