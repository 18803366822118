import { extractDomain } from "./domainHelper";

export function getBaseUrl(
  subdomain: string | undefined | null,
  domain: string,
): string {
  const currentProtocol = window.location.protocol;
  const portStr = window.location.port ? `:${window.location.port}` : "";
  const baseUrlDomain = getBaseUrlDomain(subdomain, domain);
  const baseUrl = `${currentProtocol}//${baseUrlDomain}${portStr}`;
  return baseUrl;
}
export function getStrippedSubdomainFromPath(
  path: string,
  subdomain: string,
): string {
  const strippedPath = path.replace(`${subdomain}.`, "");
  return strippedPath;
}

export function redirectToWithoutSubdomain(subdomain: string) {
  const currentPath = window.location.href;
  const strippedPath = getStrippedSubdomainFromPath(currentPath, subdomain);
  window.location.href = strippedPath;
}

export function getApiBaseUrl(
  subdomain: string | undefined | null,
  domain: string,
): string {
  const baseUrlDomain = getBaseUrlDomain(subdomain, domain);
  const baseUrl = `https://${baseUrlDomain}/api/v1/`;
  return baseUrl;
}

function getBaseUrlDomain(
  subdomain: string | undefined | null,
  domain: string,
) {
  const baseUrlDomain = subdomain ? `${subdomain}.${domain}` : domain;
  return baseUrlDomain;
}

export const openNewTab = (url: string) => {
  return window.open(url, "_blank");
};

export function redirectToMainPage() {
  window.location.href = "/";
}

export const cleanStorageAndGoToLanding = (isRedirect = false) => {
  localStorage.clear();
  if (isRedirect) {
    window.location.href = getBaseUrl(undefined, extractDomain(false));
  }
};

export function getRouteBasedOnUnsignedDocumentsAndIsNewUser(
  unsignedDocumentsCount: number,
  isNewUser: boolean,
) {
  return unsignedDocumentsCount || isNewUser ? "account" : "clients";
}

export const copyTextToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
};

export const serializeQuery = (params: Record<string, any>) => {
  const queryString = [];

  for (const key in params) {
    if (Object.hasOwn(params, key)) {
      const value = params[key];
      if (Array.isArray(value) && value.length > 0) {
        value.forEach((arrayValue) => {
          queryString.push(
            `${encodeURIComponent(key)}[]=${encodeURIComponent(arrayValue)}`,
          );
        });
      } else if (
        typeof value === "object" &&
        value !== null &&
        Object.keys(value).length > 0
      ) {
        for (const subKey in value) {
          if (Object.hasOwn(value, subKey) && value[subKey].length > 0) {
            queryString.push(
              `${encodeURIComponent(key)}[${encodeURIComponent(
                subKey,
              )}]=${encodeURIComponent(value[subKey])}`,
            );
          }
        }
      } else {
        queryString.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
        );
      }
    }
  }

  return queryString.join("&");
};
