import React, { FC } from "react";

import { Link } from "react-router-dom";
import { Box } from "@mui/material";

import { useAppThunkDispatch } from "../../store";
import { Relationship } from "store/features/client/clientSlice";
import { deleteRelationshipThunk } from "../../store/features/thunk/clientThunk/clientThunk";
import { WarningModal } from "../Modal/WarningModal";

export type RelationshipItemProps = {
  client: Relationship;
};

export const RelationshipItem: FC<RelationshipItemProps> = ({ client }) => {
  const dispatchThunk = useAppThunkDispatch();

  return (
    <Box
      sx={{ display: " flex", gap: "10px", alignItems: "center" }}
      className="relation"
    >
      <Link to={`/client/${client.uuid}/info`} className={"relation-name"}>
        {client.firstName} {client.lastName} ({client.relationshipName})
      </Link>
      <WarningModal
        openBtnType={"closeIcon"}
        openBtnTooltip={"Delete relationship"}
        submitBtnTitle={"Delete"}
        closeBtnTitle={"Close"}
        message={"Are you sure you want to delete this relationship?"}
        onSubmit={() => dispatchThunk(deleteRelationshipThunk(client.uuid))}
      />
    </Box>
  );
};
