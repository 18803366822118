import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Button, Stack, Breadcrumbs } from "@mui/material";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import {
  createLocationThunk,
  getLocationThunk,
  updateLocationThunk,
} from "../../store/features/thunk/locationThunk/locationThunk";
import { setIsNewUser } from "store/features/company/companySlice";
import {
  clearEditLocation,
  LocationType,
} from "../../store/features/location/locationSlice";
import {
  useAppDispatch,
  useAppSelector,
  useAppThunkDispatch,
} from "../../store";

import { InputWidget } from "../widgets/FormWidgets/InputWidget";
import { TimeZonePickerWidget } from "../widgets/FormWidgets/TimeZonePickerWidget/TimeZonePickerWidget";
import { NavigateNext } from "@mui/icons-material";
import { Loader } from "../Loader/Loader";

type FormModel = {
  name: string;
  timezone: string;
};

export const NewLocationComponent: FC = () => {
  const [isNewLocation, setIsNewLocation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dispatchThunk = useAppThunkDispatch();
  const { locationId } = useParams<{ locationId: string }>();

  const { isNewUser } = useAppSelector(({ company }) => company);
  let { editLocation, isEdit } = useAppSelector(({ location }) => location);

  const formMethods = useForm<FormModel>();
  const { handleSubmit, setValue: setFormValue, reset } = formMethods;

  useEffect(() => {
    if (isEdit && editLocation) {
      Object.entries(editLocation).forEach(([key, value]) => {
        setFormValue(key as keyof FormModel, value);
      });
    }
  }, [isEdit, editLocation, setFormValue]);

  useEffect(() => {
    if (locationId) {
      handleLocation(locationId);
    } else {
      setIsNewLocation(true);
      dispatch(clearEditLocation());
      reset();
    }
  }, [locationId]);

  const handleLocation = async (locationId: string) => {
    setIsLoading(true);
    await dispatchThunk(getLocationThunk({ id: locationId }));
    setIsLoading(false);
  };

  const handleCreateLocation = (data: FieldValues) => {
    dispatchThunk(createLocationThunk(data as LocationType));
  };

  const onSubmit = (data: FieldValues) => {
    if (isEdit) {
      dispatchThunk(
        updateLocationThunk({ ...editLocation, ...data } as LocationType),
      );
      navigate("/locations");
      return;
    }

    handleCreateLocation(data);
    if (isNewUser) {
      navigate("/documents");
      dispatch(setIsNewUser(false));
      return;
    }
    navigate("/locations");
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Box sx={{ padding: `0 50px 0 305px` }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Stack spacing={2}>
            <Breadcrumbs
              separator={<NavigateNext fontSize="small" color={"success"} />}
              aria-label="breadcrumb"
            >
              <Typography
                key="1"
                color="text.primary"
                sx={{ padding: "14px 0" }}
              >
                Locations
              </Typography>
              {isNewLocation ? (
                <Typography
                  key="2"
                  color="text.primary"
                  sx={{ padding: "14px 0" }}
                >
                  New Location
                </Typography>
              ) : (
                <Typography
                  key="2"
                  color="text.primary"
                  sx={{ padding: "14px 0" }}
                >
                  {editLocation?.name}
                </Typography>
              )}
            </Breadcrumbs>
          </Stack>
        </Box>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box sx={{ textAlign: "start", paddingTop: "24px" }}>
              <Typography variant={"h5"} sx={{ fontWeight: "bold" }}>
                Location info
              </Typography>
              <Typography variant={"subtitle1"}>
                Add information about your business location
              </Typography>
            </Box>
            <Box
              sx={{
                paddingTop: "50px",
              }}
            >
              <Box display={"flex"} maxWidth={"720px"} gap={"25px"}>
                <div style={{ width: "100%" }}>
                  <InputWidget
                    fieldId={"name"}
                    required={true}
                    label={"Location name"}
                    type={"text"}
                    value={""}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <TimeZonePickerWidget fieldId={"timezone"} required={true} />
                </div>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "10px",
              }}
            >
              <Typography variant={"subtitle2"} sx={{ textAlign: "start" }}>
                We have assigned some default name for your location <br />
                To continue, please change the location name
              </Typography>
              <Button
                type={"submit"}
                variant={"contained"}
                color={"success"}
                sx={{ height: "45px", width: "100px " }}
              >
                {isEdit ? "Edit" : "Save"}
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </>
  );
};
