import { FC } from "react";
import { useAppSelector, useAppThunkDispatch } from "../../store";
import { TableComponent } from "../TableComponent/TableComponent";
import { getClientDocumentThunk } from "../../store/features/thunk/clientThunk/clientThunk";
import { Typography } from "@mui/material";

const headers: string[] = ["DOCUMENTS NAME", "CREATION DATE", "LOCATION"];

export const ClientDocuments: FC = () => {
  const dispatchThunk = useAppThunkDispatch();
  const { client, clientDocuments } = useAppSelector(({ client }) => client);

  if (!client) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <>
      <TableComponent
        type={"clientDocuments"}
        headers={headers}
        rows={clientDocuments}
        onRowClick={(uuid) => {
          dispatchThunk(
            getClientDocumentThunk({
              clientUuid: `${client?.uuid || ""}`,
              documentUuid: `${uuid}`,
            }),
          );
        }}
      />
    </>
  );
};
