import { FC } from "react";

import { Editor } from "@tiptap/core";
import { TipTapEditorContext } from "../../../types";
import { MenuButton } from "../../MenuButton";

interface MenuTextAlignLeftButtonProps {
  editor: Editor;
  editorContext: TipTapEditorContext;
}

export const MenuTextAlignLeftButton: FC<MenuTextAlignLeftButtonProps> = ({
  editor,
  editorContext,
}) => {
  const isActive = editorContext.state.isActiveTextAlignLeft;

  const runCommand = () => {
    editor.chain().focus().setTextAlign("left").run();
  };

  return (
    <MenuButton
      icon="textAlignLeft"
      title="Align Left"
      command={runCommand}
      isActive={isActive}
    />
  );
};
