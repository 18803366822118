import { AuthWidgetProps } from "./AuthWidget.types";
import { FC, SyntheticEvent, useState } from "react";
import { SignUpWidget } from "./SignUpWidget/SignUpWidget";
import { SignInWidget } from "./SignInWidget";
import { Box, Tab, Tabs } from "@mui/material";
import { Email, Phone } from "@mui/icons-material";
import { ResetPasswordWidget } from "./ResetPasswordWidget";
import { OTPWidget } from "./OTPWidget";
import { NewPasswordWidget } from "./NewPasswordWidget";
import { useAppSelector } from "../../../../store";
import { AuthTypes } from "../../../../store/features/form/formSlice";
import { SocialAuthWidget } from "./SocialAuthWidget/SocialAuthWidget";

export const AuthWidget: FC<AuthWidgetProps> = () => {
  const authMethod = useAppSelector(({ form }) => form.authMethod);

  const getAuthWidget = (type: AuthTypes | null): JSX.Element | undefined => {
    if (type) {
      switch (type) {
        case AuthTypes.SIGN_IN:
          return <SignInWidget />;
        case AuthTypes.SIGN_UP:
          return <SignUpWidget />;
        case AuthTypes.RESET:
          return <ResetPasswordWidget />;
        case AuthTypes.OTP_RESET:
        case AuthTypes.OTP_SIGN_UP:
          return <OTPWidget />;
        case AuthTypes.NEW_PASSWORD:
          return <NewPasswordWidget />;
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      {getAuthWidget(authMethod)}
      {authMethod &&
        ![
          AuthTypes.RESET,
          AuthTypes.OTP_RESET,
          AuthTypes.OTP_SIGN_UP,
          AuthTypes.NEW_PASSWORD,
        ].includes(authMethod) && <SocialAuthWidget />}
    </Box>
  );
};

export const AuthTabsWidget: FC = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      sx={{
        height: "55px",
      }}
    >
      <Tab
        icon={<Email />}
        iconPosition={"start"}
        label="Email or social media"
      />
      <Tab icon={<Phone />} iconPosition={"start"} label="Phone number" />
    </Tabs>
  );
};
