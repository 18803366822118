import React from "react";
import { ContentWidget, FormWidgetProps } from "../@types/Widget";

import { AuthWidget } from "../ContentWidgets/AuthWidget/AuthWidget";
import { DatePickerWidget } from "../FormWidgets/DatePickerWidget";
import { ChildrenWidget } from "../ContentWidgets/ChildrenWidget";
import { DocumentWidget } from "../ContentWidgets/DocumentWidget";
import { CheckboxWidget } from "../FormWidgets/CheckboxWidget";
import { TextareaWidget } from "../FormWidgets/TextareaWidget";
import { TitleWidget, TitleWidgetType } from "../ContentWidgets/TitleWidget";
import { SelectWidget } from "../FormWidgets/SelectWidget";
import { LogoWidget } from "../ContentWidgets/LogoWidget";
import { InputWidget } from "../FormWidgets/InputWidget";
import { SignatureWidget } from "../ContentWidgets/SignatureWidget";
import { RadioWidget } from "../FormWidgets/RadioWidget/RadioWidget";
import { TimestampWidget } from "../FormWidgets/TimestampWidget/TimestampWidget";

export const getFieldWidget = (field: FormWidgetProps): JSX.Element => {
  let widgetElement: JSX.Element = <></>;

  if (field) {
    const { type } = field;
    switch (type) {
      case "datepicker":
        widgetElement = <DatePickerWidget {...field} />;
        break;
      case "checkbox":
        widgetElement = <CheckboxWidget {...field} />;
        break;
      case "textarea":
        widgetElement = <TextareaWidget {...field} />;
        break;
      case "select":
        widgetElement = <SelectWidget {...field} />;
        break;
      case "radio":
        widgetElement = <RadioWidget {...field} />;
        break;
      case "text":
      case "email":
      case "password":
        widgetElement = <InputWidget {...field} />;
        break;
      default:
        widgetElement = <></>;
        break;
    }
  }
  return widgetElement;
};

export const getContentWidget = (
  widget: ContentWidget,
  width: number,
  setSvgSignature: (svg: Record<string, any> | null) => void,
): JSX.Element => {
  let widgetElement: JSX.Element = <></>;

  if (widget) {
    const { type } = widget;
    switch (type) {
      case "text":
        widgetElement = <TitleWidget {...widget} />;
        break;
      case "logo":
        widgetElement = <LogoWidget {...widget} />;
        break;
      case "authorization_methods":
        widgetElement = <AuthWidget {...widget} />;
        break;
      case "field":
        widgetElement = getFieldWidget(
          widget.config.props ? widget.config.props : (widget.config as any),
        );
        break;
      case "children":
        widgetElement = <ChildrenWidget {...widget} width={width} />;
        break;
      case "signature":
        widgetElement = (
          <SignatureWidget {...widget} setSvgSignature={setSvgSignature} />
        );
        break;
      case "document":
        widgetElement = <DocumentWidget {...widget} />;
        break;
      case "timestamp":
        widgetElement = <TimestampWidget {...widget} />;
        break;
      default:
        widgetElement = <></>;
        break;
    }
  }
  return widgetElement;
};

export const getContentWidgets = (
  widgets: ContentWidget[],
  isLoading = false,
  isSuccess = false,
  width: number,
  setSvgSignature: (svg: Record<string, any> | null) => void,
): JSX.Element[] | JSX.Element => {
  const loading: TitleWidgetType = {
    type: "text",
    config: {
      value: "Signing...",
      font: {
        size: "20px",
      },
    },
    position: {
      x: 2.3,
      y: 0,
      w: 2,
      h: 1,
    },
  };
  const success: TitleWidgetType = {
    type: "text",
    config: {
      value: "Document signed",
      font: {
        size: "20px",
      },
    },
    position: {
      x: 2.3,
      y: 0,
      w: 2,
      h: 1,
    },
  };
  if (isLoading) {
    //TODO: add correct individual key
    const key = "klasjdlkasjd-" + 3;
    return (
      <div key={key} data-grid={{ ...loading.position }}>
        {getContentWidget(loading, width, setSvgSignature)}
      </div>
    );
  }

  //TODO: add loader handler
  if (isSuccess) {
    const key = "klasjdlkasjd-" + 1;
    setTimeout(() => {
      document.location.reload();
    }, 1000);
    return (
      <div key={key} data-grid={{ ...success.position }}>
        {getContentWidget(success, width, setSvgSignature)}
      </div>
    );
  }
  return widgets.map((widget, index) => {
    //TODO: add correct individual key
    const key = "klasjdlkasjd-" + index + 2;
    return (
      <div
        key={key}
        {...(widget.position ? { "data-grid": { ...widget.position } } : {})}
      >
        {getContentWidget(widget, width, setSvgSignature)}
      </div>
    );
  });
};
