import { FC } from "react";
import { Editor } from "@tiptap/core";
import { MenuButton } from "../MenuButton";

interface MenuOutdentButtonProps {
  editor: Editor;
}

export const MenuOutdentButton: FC<MenuOutdentButtonProps> = ({ editor }) => {
  const runCommand = () => {
    editor.chain().focus().outdent().run();
  };

  return <MenuButton icon="outdent" title="Outdent" command={runCommand} />;
};
