// LinkDialog.tsx
import React, { useState, useEffect } from "react";

import { Button, Dialog } from "@mui/material";

interface LinkDialogProps {
  title: string;
  isUpdateLink?: boolean;
  href?: string;
  target?: string;
  isVisible: boolean;
  onClose: () => void;
  onClick: (data: { href: string; openInNewTab: boolean }) => void;
}

export const LinkDialog: React.FC<LinkDialogProps> = ({
  title,
  isUpdateLink = false,
  href = "",
  target = "",
  isVisible,
  onClose,
  onClick,
}) => {
  const [linkAttrs, setLinkAttrs] = useState({ href, openInNewTab: true });

  useEffect(() => {
    if (isVisible) {
      setLinkAttrs({
        href,
        openInNewTab: isUpdateLink ? target === "_blank" : true,
      });
      window.addEventListener("keypress", handleKeyboardEvent);
    } else {
      window.removeEventListener("keypress", handleKeyboardEvent);
    }
    return () => {
      window.removeEventListener("keypress", handleKeyboardEvent);
    };
  }, [isVisible, href, isUpdateLink, target]);

  const handleKeyboardEvent = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      onClick(linkAttrs);
    }
  };

  const handleClick = () => {
    onClick(linkAttrs);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog title={title} open={isVisible} onClose={handleClose}>
      {/*<Form*/}
      {/*  model={linkAttrs}*/}
      {/*  labelPosition="right"*/}
      {/*  size="small"*/}
      {/*  onSubmit={(e) => e.preventDefault()}>*/}
      {/*  <FormItem label="Href" prop="href">*/}
      {/*    <Input*/}
      {/*      value={linkAttrs.href}*/}
      {/*      onChange={(value: string) => setLinkAttrs({ ...linkAttrs, href: value })}*/}
      {/*      autoComplete="off"*/}
      {/*    />*/}
      {/*  </FormItem>*/}

      {/*  <FormItem prop="openInNewTab">*/}
      {/*    <Checkbox*/}
      {/*      checked={linkAttrs.openInNewTab}*/}
      {/*      onChange={(value: boolean) => setLinkAttrs({ ...linkAttrs, openInNewTab: value })}>*/}
      {/*      Open in new tab*/}
      {/*    </Checkbox>*/}
      {/*  </FormItem>*/}
      {/*</Form>*/}

      <div slot="footer">
        <Button size="small" onClick={handleClose}>
          Cancel
        </Button>

        <Button
          size="small"
          onMouseDown={(e) => e.preventDefault()}
          onClick={handleClick}
        >
          {isUpdateLink ? "Save" : "Apply"}
        </Button>
      </div>
    </Dialog>
  );
};
