import { Coordinates, DefaultFormWidgetProps } from "../../@types/Widget";

export enum InputFieldTypes {
  EMAIL = "email",
  PASSWORD = "password",
  TEXT = "text",
  PHONE = "phone",
}

export type InputWidgetType = {
  type: "field";
  position: Coordinates;
  config: InputWidgetConfig;
};

export type InputWidgetProps = {
  type: InputFieldType;
  value?: InputFieldValues;
  fullWidth?: boolean;
} & DefaultFormWidgetProps;

export type InputWidgetConfig = {
  props: InputWidgetProps;
};

export type InputFieldType = "email" | "password" | "text" | "phone";

export type InputFieldValues = string | number;
