import React, { FC, useState } from "react";

import { Close, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
//@ts-ignore
import Illustration from "../../assets/img/Illustration.svg";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "8px",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "24px",
};

export type WarningModalProps = {
  message: string;
  title?: string;
  openBtnTitle?: string;
  openBtnTooltip?: string;
  closeBtnTitle?: string;
  submitBtnTitle?: string;
  openBtnType?: OpenModalButtonType;
  onSubmit: () => void;
  onCancel?: () => void;
  onOpen?: () => void;
  open?: boolean;
};

export const WarningModal: FC<WarningModalProps> = ({
  message,
  title,
  openBtnTitle,
  openBtnTooltip,
  closeBtnTitle,
  submitBtnTitle,
  openBtnType,
  onOpen,
  onCancel,
  onSubmit,
  open = false,
}) => {
  const [isVisible, setIsVisible] = useState(open);
  const handleOpen = (e: any) => {
    e.stopPropagation();

    onOpen && onOpen();

    setIsVisible(true);
  };
  const handleCancel = (e: any) => {
    e.stopPropagation();

    onCancel && onCancel();
    setIsVisible(false);
  };
  const handleSubmit = (e: any) => {
    e.stopPropagation();
    onSubmit && onSubmit();
    setIsVisible(false);
  };

  return (
    <>
      <Modal
        open={isVisible}
        onClose={handleCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            display={"flex"}
            gap={"20px"}
            alignItems={"center"}
            marginTop={"30px"}
          >
            <img src={Illustration} width={"40px"} height={"40px"} alt="" />
            <Box>
              {title && (
                <Typography id="modal-modal-description">{title}</Typography>
              )}
              <Typography id="modal-modal-description">{message}</Typography>
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            gap={"12px"}
            marginTop={"16px"}
          >
            <Button
              variant={"outlined"}
              color="inherit"
              onClick={(e: any) => handleCancel(e)}
            >
              {closeBtnTitle ? closeBtnTitle : "Cancel"}
            </Button>
            <Button
              variant={"contained"}
              color="error"
              onClick={(e: any) => handleSubmit(e)}
            >
              {submitBtnTitle ? submitBtnTitle : "Submit"}
            </Button>
          </Box>
        </Box>
      </Modal>
      {!open &&  <OpenModalButton
        title={openBtnTitle}
        type={openBtnType}
        onOpen={handleOpen}
        tooltip={openBtnTooltip}
      /> }
    </>
  );
};

export type OpenModalButtonType =
  | "cancel"
  | "delete"
  | "deleteIcon"
  | "closeIcon";

export type OpenModalButtonProps = {
  type?: OpenModalButtonType;
  title?: string;
  onOpen: (e: any) => void;
  tooltip?: string;
};

const OpenModalButton: FC<OpenModalButtonProps> = ({
  type,
  onOpen,
  title,
  tooltip = "",
}) => {
  let button: ReactJSXElement;
  switch (type) {
    case "cancel":
      button = (
        <Button
          onClick={(e) => onOpen(e)}
          variant={"outlined"}
          fullWidth={false}
          color={"inherit"}
          size={"large"}
        >
          {title ? title : "Cancel"}
        </Button>
      );
      break;
    // case 'delete':
    //   break;
    case "deleteIcon":
      button = (
        <Tooltip title={tooltip} placement={"top"} arrow>
          <IconButton size="small" onClick={onOpen}>
            <Delete fontSize="inherit" />
          </IconButton>
        </Tooltip>
      );
      break;
    case "closeIcon":
      button = (
        <Tooltip title={tooltip} placement={"top"} arrow>
          <IconButton size="small" onClick={onOpen}>
            <Close fontSize="inherit" />
          </IconButton>
        </Tooltip>
      );
      break;
    default:
      button = (
        <Tooltip title={tooltip} placement={"top"} arrow>
          <IconButton size="small" onClick={onOpen}>
            <Delete fontSize="inherit" />
          </IconButton>
        </Tooltip>
      );
      break;
  }

  return button;
};
