import React, { FC, useState } from "react";

import { Box, Button, Modal, Typography } from "@mui/material";
import { useAppDispatch } from "../../store";
import { dropboxConnect } from "../../store/features/api/workflowApi/workflowApi";
import { showErrorNotification } from "../../store/features/notification/notificationSlice";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "8px",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "40px 24px",
};

export type DropboxManageConnectionModalProps = {
  isManageConnectionModalOpen: boolean;
  setIsManageConnectionModalOpen: (value: boolean) => void;
  dropboxStatus: string;
};

export const DropboxManageConnectionModal: FC<
  DropboxManageConnectionModalProps
> = ({
  isManageConnectionModalOpen,
  setIsManageConnectionModalOpen,
  dropboxStatus,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const handleConnect = async () => {
    setIsLoading(true);
    try {
      const res = await dispatch(dropboxConnect.initiate({})).unwrap();
      setTimeout(() => {
        window.open(res?.dropboxConnectUrl, "_blank", "width=800,height=600");
      }, 100);
    } catch (error) {
      dispatch(
        showErrorNotification({
          message: (error as any)?.data?.message ?? (error as any)?.data?.error,
        }),
      );
      console.error(error);
    }
    setIsLoading(false);
    setIsManageConnectionModalOpen(false);
  };

  const handleModalClose = () => {
    setIsManageConnectionModalOpen(false);
  };

  return (
    <Modal
      open={isManageConnectionModalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ width: "100%", marginBottom: "24px" }}>
            <Typography
              component={"span"}
              variant={"h6"}
              sx={{ fontWeight: "bold" }}
            >
              Dropbox Connection Management
            </Typography>
          </Box>
          <Box sx={{ marginBottom: "24px" }}>
            {dropboxStatus !== "connected" ? (
              <Typography variant={"subtitle2"}>
                You currently do not have an active connection to Dropbox. Click
                the 'Connect' button below to establish a connection and enable
                Dropbox integration in the workflow.
              </Typography>
            ) : (
              <Typography>
                You have an active connection to Dropbox. You can manage your
                connection using the options below:
                {/*<br /> Disconnect: This will*/}
                {/*disconnect the workflow from your Dropbox account. You won't be*/}
                {/*able to access Dropbox features until you reconnect.*/}
                <br /> <strong>Reconnect:</strong> Use this option if you've
                made changes to your Dropbox account or need to update the
                connection.
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "12px",
            }}
          >
            <Button
              variant={"outlined"}
              color={"inherit"}
              onClick={handleModalClose}
              sx={{ textTransform: "capitalize", padding: "7px 17px" }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant={"contained"}
              color={"success"}
              loading={isLoading}
              sx={{ textTransform: "capitalize", padding: "7px 34px" }}
              onClick={handleConnect}
            >
              {dropboxStatus !== "connected" ? "Connect" : "Reconnect"}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
