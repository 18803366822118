import { Box, Modal } from "@mui/material";
import React, { FC, useEffect } from "react";
import { OtpWidget } from "../OtpWidget/OtpWidget";

import { OtpMethod } from "../../store/features/form/formSlice";
import { FormProvider, useForm } from "react-hook-form";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "8px",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "40px 24px",
};

export type SecurityVerificationModalProps = {
  isSecurityVerificationModalOpen: boolean;
  setIsSecurityVerificationModalOpen: (value: boolean) => void;
  handleSubmitModal: (value: Object) => void;
  otpMethods: OtpMethod[];
  isLoading: boolean;
};

export const SecurityVerificationModal: FC<SecurityVerificationModalProps> = ({
  isSecurityVerificationModalOpen,
  setIsSecurityVerificationModalOpen,
  handleSubmitModal,
  otpMethods,
  isLoading,
}) => {
  const formMethods = useForm();
  const { handleSubmit, reset } = formMethods;

  const handleModalClose = () => {
    setIsSecurityVerificationModalOpen(false);
  };

  const onSubmit = (data: any) => {
    handleSubmitModal(data?.verificationCodes);
  };

  useEffect(() => {
    reset();
  }, [isSecurityVerificationModalOpen]);

  return (
    <Modal
      open={isSecurityVerificationModalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <OtpWidget
                otpMethods={otpMethods}
                onSendCode={handleSubmitModal}
                isModal={true}
                setIsOpenModal={setIsSecurityVerificationModalOpen}
                isModalLoading={isLoading}
              />
            </form>
          </FormProvider>
        </Box>
      </Box>
    </Modal>
  );
};
