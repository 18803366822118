import { createSlice, PayloadAction } from "@reduxjs/toolkit/";
import { FieldValues } from "react-hook-form";

export type CompanyDetails = {
  name?: string;
  website?: string;
  address?: string;
  country?: string;
  region?: string;
  postalCode?: string;
  city?: string;
  subdomain?: string | null;
  unsignedDocuments?: number;
};

export type CompanyState = {
  companyDetails: CompanyDetails | null;
  userProfile: FieldValues | null;
  isNewUser: boolean;
  isSuperAdmin: boolean;
};

const initialState: CompanyState = {
  companyDetails: null,
  userProfile: null,
  isNewUser: false,
  isSuperAdmin: false,
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setSubdomain: (state, { payload }: PayloadAction<string | null>) => {
      if (!state.companyDetails) {
        state.companyDetails = {} as CompanyDetails;
      }
      state.companyDetails.subdomain = payload;
    },
    setCompanyDetails: (state, { payload }: PayloadAction<CompanyDetails>) => {
      state.companyDetails = payload;
    },
    setUserProfile: (state, { payload }: PayloadAction<FieldValues>) => {
      state.userProfile = payload;
    },
    setIsNewUser: (state, { payload }: PayloadAction<boolean>) => {
      state.isNewUser = payload;
    },
    setIsSuperAdmin: (state, { payload }: PayloadAction<boolean>) => {
      state.isSuperAdmin = payload;
    },
  },
});

export const {
  setSubdomain,
  setCompanyDetails,
  setUserProfile,
  setIsNewUser,
  setIsSuperAdmin,
} = companySlice.actions;
