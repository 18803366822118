export const convertServerToMomentFormat = (serverFormat: string) => {
  const conversionTable: Record<string, string> = {
    d: "DD",
    j: "D",
    m: "MM",
    n: "M",
    Y: "YYYY",
    y: "YY",
    H: "HH",
    G: "H",
    h: "hh",
    g: "h",
    i: "mm",
    s: "ss",
    A: "A",
    a: "a",
    "\\": "[]",
  };

  let momentFormat = "";
  let escaped = false;

  for (let i = 0; i < serverFormat.length; i++) {
    const char = serverFormat.charAt(i);

    if (char === "\\") {
      escaped = true;
      continue;
    }

    if (
      !escaped &&
      Object.prototype.hasOwnProperty.call(conversionTable, char)
    ) {
      momentFormat += conversionTable[char];
    } else {
      momentFormat += char;
    }

    escaped = false;
  }

  return momentFormat;
};
