// MenuStrikeButton.tsx
import React from "react";
import { MenuButton } from "../MenuButton";
import { Editor } from "@tiptap/core";
import { TipTapEditorContext } from "../../types";

interface MenuStrikeButtonProps {
  editor: Editor;
  editorContext: TipTapEditorContext;
}

export const MenuStrikeButton: React.FC<MenuStrikeButtonProps> = ({
  editor,
  editorContext,
}) => {
  const isActive = editorContext.state.isActiveStrike;

  const runCommand = () => {
    editor.chain().focus().toggleStrike().run();
  };

  return (
    <MenuButton
      icon="strikethrough"
      title="Strike through"
      command={runCommand}
      isActive={isActive}
    />
  );
};
