import { FC, useEffect, useState } from "react";
import Button from "../../shared/Button";
import useElementSize from "../../hooks/useElementSize";
import { useScrollDirection } from "../../hooks/useScrollDirection";
import useScrollPosition from "../../hooks/useScrollPosition";
import useIsomorphicLayoutEffect from "../../hooks/useIsomorphicLayoutEffect";

// @ts-ignore
import mainimg from "../../assets/mainimg.png";
// @ts-ignore
import youtubeicon from "../../assets/youtubeicon.svg";
// @ts-ignore
import closeiconwhite from "../../assets/closeiconwhite.svg";

interface HeroProps {
  scrollToHowItWorks: () => void;
}

const Hero: FC<HeroProps> = ({ scrollToHowItWorks }) => {
  const [isBannerFixed, setIsBannerFixed] = useState<boolean>(false);
  const [bannerInitialPosition, setBannerInitialPosition] = useState<number>(0);
  const [isShownBanner, setIsShownBanner] = useState<boolean>(true);

  const [container, { width }] = useElementSize();
  const [bannerRef, { offsetTop: bannerOffsetTop }] = useElementSize();

  const scrollDirection = useScrollDirection();
  const scrollY = useScrollPosition();

  useEffect(() => {
    if (bannerOffsetTop > 0) {
      setBannerInitialPosition(bannerOffsetTop);
    }
  }, [bannerOffsetTop]);

  useIsomorphicLayoutEffect(() => {
    if (scrollDirection === "up") {
      setIsBannerFixed(false);
    }

    if (scrollDirection === "down" && scrollY > bannerInitialPosition) {
      setIsBannerFixed(true);
    }
  }, [scrollY]);

  const handleClick = () => {
    scrollToHowItWorks();
  };

  return (
    <section id="home">
      <div
        ref={container}
        className="bg-seconary-100 xs:px-[24px] xs:py-[20px] xs:pt-[84px] sm:px-[96px] sm:py-[30px] sm:pt-[150px] md:px-[139px] md:py-[50px] md:pt-[170px] lg:flex lg:justify-between lg:px-[153px] lg:py-[100px] lg:pt-[164px]"
      >
        <div className="xs:mb-9 xs:p-0 sm:mb-12 sm:pt-4 md:mb-0 md:basis-full lg:basis-2/5">
          <div className="relative">
            <div className="relative z-10 w-full items-center gap-8 rounded-[10px] border-[1px] border-solid border-black bg-primary-010 xs:mb-[60px] xs:px-[11px] xs:py-4 sm:mb-20 sm:flex sm:justify-between sm:p-5 md:mb-14 md:px-[22px] md:py-[11px] lg:justify-center">
              <p className="xs:text-md font-poppins font-bold text-primary-700 xs:mb-4 xs:text-center sm:mb-0 sm:text-base">
                Watch Our Video
              </p>
              <button
                className={
                  "flex cursor-pointer items-center justify-center gap-2 rounded-md border-[1.5px] border-solid border-primary-400 bg-primary-010 text-base font-normal text-primary-400 xs:w-full xs:px-5 xs:py-2 sm:w-[180px] sm:px-5 sm:py-2"
                }
                onClick={handleClick}
              >
                Click Here
                <img
                  className="w-[25px]"
                  src={youtubeicon}
                  alt="externallink"
                />
              </button>
            </div>
            <span className="absolute block h-full w-full rounded-[10px] border-[1px] border-solid border-black shadow-[rgba(0,0,0,0.24)_0px_3px_8px] xs:bottom-[-5px] xs:right-[-5px] sm:bottom-[-10px] sm:right-[-10px]"></span>
          </div>
          <div className="font-poppins font-bold text-primary-700 sm:w-[440px] lg:w-[455px]">
            <h1 className="xs:mb-6 xs:text-4xl xs:leading-normal sm:mb-3 sm:text-5xl sm:leading-normal">
              Introducing WKForm
            </h1>
            <h2 className="xs:mb-6 xs:text-base sm:mb-9 sm:text-2xl">
              Over 12 million signed waivers
            </h2>
            <p className="font-roboto font-normal text-primary-300 xs:mb-6 xs:text-sm sm:mb-8 sm:text-lg">
              Empowering your business
            </p>
          </div>
          <div>
            <div className="w-fit">
              <Button
                text={"Sign up for a free 15 day trial!"}
                additionalContent={"(No credit card required)"}
                style={
                  "flex-col bg-primary-400 text-primary-010 font-normal w-64 xs:px-5 xs:py-2 gap-0"
                }
                href={"/signup"}
              />
            </div>
          </div>
        </div>
        <div className="flex items-end justify-center lg:mb-[-100px]">
          <img
            className="xs:w-[330px] sm:w-[418px] md:w-[612px] lg:w-full"
            src={mainimg}
            alt="mainimg"
          />
        </div>
      </div>
      <div
        ref={bannerRef}
        style={{
          width,
          transform: `translateY(${isBannerFixed ? "0" : "-100"}px)`,
        }}
        className={`fixed top-0 z-[1000] flex justify-center border border-b-primary-010 bg-primary-200 opacity-90 transition-transform duration-1000 ease-in-out 
        ${!isShownBanner && "hidden"}`}
      >
        <div className="flex items-center xs:px-6 xs:py-[11px] sm:gap-7 sm:px-0 sm:py-2">
          <p className="font-poppins font-medium text-primary-010 xs:text-sm sm:text-lg">
            Get Started Today with a 15-Day Free Trial!
          </p>
          <Button
            text={"Sign up"}
            style={
              "text-primary-700 font-normal text-base bg-primary-010 h-[34px] sm:mr-0 sm:ml-0 xs:mr-8 xs:ml-12 xs:min-w-[82px]"
            }
            href={"/signup"}
          />
        </div>
        <button
          className="absolute mx-[38px] cursor-pointer xs:right-[-33px] xs:top-[5px] sm:right-[-8] sm:top-[50%] sm:translate-y-[-50%] lg:right-0"
          onClick={() => setIsShownBanner(!isShownBanner)}
        >
          <img src={closeiconwhite} alt="close" />
        </button>
      </div>
    </section>
  );
};
export default Hero;
