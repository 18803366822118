import React from "react";

type Props = {
  children: React.ReactNode;
  isWhite?: boolean;
  isMarginNone?: boolean;
  // style: string;
};
const Heading = ({ children, isWhite, isMarginNone }: Props) => {
  return (
    <h1
      className={`xs:text-2xl xs:mt-[60px] mx-auto text-center font-poppins font-bold sm:mt-[80px] 
      sm:text-4xl sm:leading-normal md:mt-[50px] lg:mt-0 w-full
      
      ${isWhite ? "text-primary-010" : "text-primary-700"} ${
        isMarginNone ? "mb-[0px]" : "mb-[72px]"
      }`}
    >
      {children}
    </h1>
  );
};
export default Heading;
