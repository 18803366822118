import { FC } from "react";

import { Box } from "@mui/material";
import { useFormContext } from "react-hook-form";

import {
  InputWidget,
  InputWidgetProps,
} from "../../../FormWidgets/InputWidget";
import { FieldValue } from "../../../@types/Widget";

const signInFormData: InputWidgetProps[] = [
  {
    type: "email",
    // validator: "",//REG
    label: "Email",
    fieldId: "email",
    required: true, //boolean
    value: "",
    // validation_message: '',
    // custom_validator: {
    //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
    //   message: '',//string;
    //   flags: ''//string;
    // },
    // maxlength: 50
  },
  {
    type: "password",
    // validator: "",//REG
    label: "Password",
    fieldId: "password",
    required: true, //boolean
    value: "",
    // validation_message: '',
    // custom_validator: {
    //   value: "",//RegExp | string | ((value: FieldValue) => boolean);
    //   message: '',//string;
    //   flags: ''//string;
    // },
    // maxlength: 50
  },
  {
    type: "password",
    // validator: "",//REG
    label: "Confirm Password",
    fieldId: "passwordConfirm",
    required: true, //boolean
    value: "",
    // validation_message: '',
    custom_validator: {
      value: (value) => !value, //RegExp | string | ((value: FieldValue) => boolean);
      message: "", //string;
    },
    // maxlength: 50
  },
];
export const SignUpWidget: FC = () => {
  const { watch } = useFormContext();

  const matchPasswords = (confPass: FieldValue) =>
    watch("password") === confPass;
  signInFormData[2].custom_validator = {
    value: matchPasswords,
    message: "Your passwords do no match",
  };
  return (
    <Box sx={{ maxWidth: "350px", width: "100%" }}>
      <InputWidget {...signInFormData[0]} />
      <InputWidget {...signInFormData[1]} />
      <InputWidget {...signInFormData[2]} />
    </Box>
  );
};
