import { createSlice } from "@reduxjs/toolkit/";
import { FieldValues } from "react-hook-form";
import { PayloadAction } from "@reduxjs/toolkit";

export type DocumentType = {
  id: string;
  content: string;
  name: string;
  createdAt?: string;
  updatedAt?: string;
};

export type DocumentSliceType = {
  documents: DocumentType[];
  editDocument: FieldValues | null;
  isEdit: boolean;
};

const initialState: DocumentSliceType = {
  documents: [],
  editDocument: null,
  isEdit: false,
};

export const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    setDocuments: (state, { payload }: PayloadAction<DocumentType[]>) => {
      state.documents = payload;
    },
    removeDocument: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.documents = state.documents.filter(
        (document) => document.id !== payload.id,
      );
    },
    updateDoc: (state, { payload }: PayloadAction<DocumentType>) => {
      state.documents = state.documents.map((document) =>
        document.id === payload.id ? payload : document,
      );
    },
    addDocument: (state, { payload }: PayloadAction<DocumentType>) => {
      state.documents = state.documents.length
        ? [...state.documents, payload]
        : [payload];
    },
    setEditDocument: (
      state,
      { payload }: PayloadAction<{ id: string | number }>,
    ) => {
      state.editDocument =
        state.documents.find((document) => document.id === payload.id) ?? {};
      state.isEdit = true;
    },
    clearEditDocument: (state) => {
      state.editDocument = null;
      state.isEdit = false;
    },
  },
});

export const {
  setDocuments,
  removeDocument,
  setEditDocument,
  updateDoc,
  clearEditDocument,
  addDocument,
} = documentSlice.actions;
