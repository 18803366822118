import { mergeAttributes, Node } from "@tiptap/core";
import { Node as ProseMirrorNode } from "prosemirror-model";
import { humanFileSize } from "../utils/shared";
import moment from "moment";

export type FileAttachmentOptions = {
  HTMLAttributes: Record<string, any>;
  renderTitle: (node: ProseMirrorNode) => string;
  renderDetails: (node: ProseMirrorNode) => string;
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    fileAttachment: {
      insertFileAttachment: (options: {
        id: string;
        url: string;
        name: string;
        ext: string;
        time: number;
        size?: number;
      }) => ReturnType;
    };
  }
}

const FileAttachment = Node.create<FileAttachmentOptions>({
  name: "fileAttachment",

  addOptions() {
    return {
      HTMLAttributes: {},
      renderTitle(node) {
        return `${node.attrs.name}.${node.attrs.ext}`;
      },
      renderDetails(node) {
        const time = moment(node.attrs.time);
        return `${time.format("DD MMM YYYY, HH:mm")}${
          node.attrs.size ? " | " + humanFileSize(node.attrs.size, true) : ""
        }`;
      },
    };
  },

  group: "inline",
  inline: true,
  selectable: false,
  draggable: true,
  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-id"),
        renderHTML: (attributes) =>
          attributes.id ? { "data-id": attributes.id } : {},
      },
      url: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-url"),
        renderHTML: (attributes) =>
          attributes.url ? { "data-url": attributes.url } : {},
      },
      name: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-name"),
        renderHTML: (attributes) =>
          attributes.name ? { "data-name": attributes.name } : {},
      },
      ext: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-ext"),
        renderHTML: (attributes) =>
          attributes.ext ? { "data-ext": attributes.ext } : {},
      },
      size: {
        default: null,
        parseHTML: (element) =>
          parseInt(element.getAttribute("data-size") || ""),
        renderHTML: (attributes) =>
          attributes.size ? { "data-size": attributes.size } : {},
      },
      time: {
        default: null,
        parseHTML: (element) =>
          parseInt(element.getAttribute("data-time") || ""),
        renderHTML: (attributes) =>
          attributes.time ? { "data-time": attributes.time } : {},
      },
    };
  },

  parseHTML() {
    return [{ tag: `div[data-type="${this.name}"]` }];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      "div",
      mergeAttributes(
        {
          "data-type": this.name,
          class: "tiptap-file-attachment",
        },
        this.options.HTMLAttributes,
        HTMLAttributes,
      ),
      [
        "a",
        {
          class: "tiptap-file-attachment__link",
          href: node.attrs.url,
          target: "_blank",
        },
        ["div", { class: "tiptap-file-attachment__ext" }, node.attrs.ext],
        [
          "div",
          { class: "tiptap-file-attachment__title" },
          this.options.renderTitle(node),
        ],
        [
          "div",
          { class: "tiptap-file-attachment__details" },
          this.options.renderDetails(node),
        ],
      ],
    ];
  },

  renderText({ node }) {
    return this.options.renderTitle(node);
  },

  addCommands() {
    return {
      insertFileAttachment:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({ type: this.name, attrs: options });
        },
    };
  },
});

export default FileAttachment;
