import { Extension } from "@tiptap/core";
import type { CommandProps } from "@tiptap/core";
import { Node } from "@tiptap/pm/model";
import { WONDER_NUM_DIFF_FOR_LIST_ITEM } from "./extensionsConsts";

export type ColorOptions = {
  types: string[];
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    color: {
      /**
       * Set the text color
       */
      setColor: (color: string) => ReturnType;
      /**
       * Unset the text color
       */
      unsetColor: () => ReturnType;
    };
  }
}

export const ExtendedColor = Extension.create<any>({
  name: "color",

  addOptions() {
    return {
      types: ["textStyle"],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          color: {
            default: null,
            parseHTML: (element) => element.style.color?.replace(/['"]+/g, ""),
            renderHTML: (attributes) => {
              if (!attributes.color) {
                return {};
              }

              return {
                style: `color: ${attributes.color}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands(): any {
    return {
      setColor:
        (color: string) =>
        ({ state, tr, chain }: CommandProps) => {
          if (state.selection.empty) {
            return chain().setMark("textStyle", { color }).run();
          }
          const { from, to } = this.editor.state.selection;

          let referenceFrom = from
          tr.doc.nodesBetween(from, to, (node: Node, pos:number) => {
            if (node.isText) {
              return chain().setMark("textStyle", { color }).run();
            } else if (node.isBlock || node.isInline) {
              if (node.type.name === "listItem") {
                // when it is list, this is the wonderous number, that it is possible to rely on
                // to understand that whole listItem was selected
                if (referenceFrom - pos !== WONDER_NUM_DIFF_FOR_LIST_ITEM) return
                tr.setNodeMarkup(pos, null, {
                  ...node.attrs,
                  color: color,
                });
                // wonderous number again
                referenceFrom = node.nodeSize + pos + WONDER_NUM_DIFF_FOR_LIST_ITEM
              }
            }
          });
          return true
        },
  
      // unsetColor:
      //   () =>
      //   ({ chain }) => {
      //     return chain()
      //       .setMark("textStyle", { color: null })
      //       .removeEmptyTextStyle()
      //       .run();
      //   },
    };
  },
});