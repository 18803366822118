import { FC, useState } from "react";
import { Editor } from "@tiptap/core";
import { TipTapEditorContext } from "../../types";
import { MenuButton } from "../MenuButton";
import { Button, Menu } from "@mui/material";
import { COLOR_SET } from "tiptap/utils/color";

interface MenuHighlightButtonProps {
  editor: Editor;
  editorContext: TipTapEditorContext;
}

export const MenuHighlightButton: FC<MenuHighlightButtonProps> = ({
  editor,
  editorContext,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [color, setColor] = useState("");
  const isActive = editorContext.state.isActiveHighlight;

  const isColorSelected = (color: string) => {
    return editor.isActive("highlight", { color });
  };

  const removeColor = () => {
    setAnchorEl(null);
    editor.chain().focus().unsetHighlight().run();
  };

  const confirmColor = (color: string) => {
    setAnchorEl(null);
    editor.chain().focus().setHighlight({ color }).run();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MenuButton
        title="Text highlight"
        icon="highlighter"
        isActive={isActive}
        command={handleClick}
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="color-set">
          {COLOR_SET.map((color: string) => (
            <div key={color} className="color__wrapper">
              <div
                style={{
                  backgroundColor: color,
                }}
                className={`color ${
                  isColorSelected(color) ? "color--selected" : ""
                }`}
                onMouseDown={(event) => event.preventDefault()}
                onClick={() => confirmColor(color)}
              />
            </div>
          ))}

          <div className="color__wrapper">
            <div
              className="color color--remove"
              onMouseDown={(event) => event.preventDefault()}
              onClick={removeColor}
            />
          </div>
        </div>

        <div className="color-hex">
          <input
            value={color}
            placeholder="HEX"
            autoFocus={true}
            className="color-hex__input"
            onChange={(event) => setColor(event.target.value)}
          />

          <Button
            variant="text"
            size="small"
            className="color-hex__button"
            onClick={() => confirmColor(color)}
          >
            OK
          </Button>
        </div>
      </Menu>
    </>
  );
};
