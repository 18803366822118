// MenuRemoveLinkButton.tsx
import React from "react";
import { MenuButton } from "../../MenuButton";
import { Editor } from "@tiptap/core";

interface MenuRemoveLinkButtonProps {
  editor: Editor;
}

export const MenuRemoveLinkButton: React.FC<MenuRemoveLinkButtonProps> = ({
  editor,
}) => {
  const runCommand = () => {
    editor.chain().focus().unsetLink().run();
  };

  return <MenuButton command={runCommand} title="Unlink" icon="linkBreak" />;
};
