import { FC, forwardRef, SyntheticEvent } from "react";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useAppDispatch, useAppSelector } from "../../store";
import { closeNotification } from "../../store/features/notification/notificationSlice";

const Alert = forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  },
);

const defaultMessageBySeverity: Record<string, string> = {
  success: "Success",
  error: "Oops, something went wrong! Please talk to support.",
  warning: "Warning",
};

export const NotificationComponent: FC = () => {
  const { isOpen, message, severity } = useAppSelector(
    ({ notification }) => notification,
  );

  const dispatch = useAppDispatch();

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(closeNotification());
  };

  return (
    <>
      {isOpen && (
        <Snackbar
          open={isOpen}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message ? message : severity && defaultMessageBySeverity[severity]}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};
