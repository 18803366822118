import { ChangeEvent, FC } from "react";
import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { InputFieldTypes, InputWidgetProps } from "../InputWidget";
import { getValidationRule } from "../../../../helper/FormValidators/formHelper";
import { ErrorMessage } from "@hookform/error-message";
import { ControllerRenderProps } from "../../@types/Widget";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";

const defaultProps = {
  type: InputFieldTypes.TEXT,
  label: "FIRST",
  fieldId: "FIRST",
  required: false,
  value: "",
};

export const DomainInputWidget: FC<
  InputWidgetProps & { InputProps?: Partial<StandardInputProps> }
> = (field = defaultProps) => {
  const {
    type,
    label,
    fieldId,
    required,
    value,
    fullWidth = true,
    InputProps,
  } = field;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Controller
        render={({
          field: { value, onChange },
        }: {
          field: ControllerRenderProps<string, ChangeEvent<HTMLInputElement>>;
        }) => (
          <>
            <TextField
              id={fieldId}
              disabled={false}
              size={"small"}
              type={type}
              sx={{ padding: 0, display: "inline-box" }}
              value={value}
              error={!!errors?.[fieldId]}
              label={label}
              margin="normal"
              required={required}
              onChange={onChange}
              fullWidth={fullWidth}
              InputProps={InputProps}
            />
          </>
        )}
        control={control}
        defaultValue={value}
        name={fieldId}
        rules={getValidationRule(field)}
      />
      <ErrorMessage
        errors={errors}
        name={fieldId}
        render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
      />
    </>
  );
};
