import React from "react";
import { Editor } from "@tiptap/core";
import { TipTapEditorContext } from "../../types";
import { MenuButton } from "../MenuButton";

interface MenuTodoListButtonProps {
  editor: Editor;
  editorContext: TipTapEditorContext;
}

export const MenuTodoListButton: React.FC<MenuTodoListButtonProps> = ({
  editor,
  editorContext,
}) => {
  const isActive = editorContext.state.isActiveTodoList;

  const runCommand = () => {
    editor.chain().focus().toggleTaskList().run();
  };

  return (
    <MenuButton
      icon="list-checkList"
      title="Todo list"
      command={runCommand}
      isActive={isActive}
    />
  );
};
