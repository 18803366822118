import { FC, useState } from "react";

import { FieldValues } from "react-hook-form";
import {
  Paper,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  tableCellClasses,
  styled,
  Stack,
  IconButton,
  Box,
  Typography,
  Button,
  Modal,
} from "@mui/material";
import { Edit, Delete, ContentCopy, OpenInNew } from "@mui/icons-material";
import moment from "moment";

// @ts-ignore
import archive from "../../assets/img/archive1.svg";
//@ts-ignore
import Illustration from "../../assets/img/Illustration.svg";
import { Client } from "../../store/features/client/clientSlice";
import { openNewTab } from "helper/urlHelper";
import { RecentDocumentsIconsComponent } from "./RecentDocumentsIconsComponent";
import { getFullNameTZ } from "helper/timeZoneHelper";

type TableComponentProps = {
  type?:
    | "clients"
    | "documents"
    | "workflows"
    | "locations"
    | "clientDocuments"
    | "signedAgreements";
  rows: FieldValues[];
  headers: string[];
  isEditDelete?: boolean;
  onDelete?: (id: string) => void;
  onEdit?: (id: string | number) => void;
  onRowClick?: (id: string | number) => void;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(249, 250, 249, 1)",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "8px",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "24px",
};

export const TableComponent: FC<TableComponentProps> = ({
  type = "locations",
  headers,
  rows,
  isEditDelete = true,
  onDelete,
  onEdit,
  onRowClick,
}) => {
  const domain = localStorage.getItem("BASE_URL_DOMAIN") || "";
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteName, setDeleteName] = useState("");

  const onClickDelete = (id: string, deleteName: string) => {
    setDeleteId(id);
    setDeleteName(deleteName);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setDeleteId("");
    setDeleteName("");
  };
  const handleDelete = () => {
    onDelete && onDelete(deleteId);
    setOpen(false);
    setDeleteId("");
    setDeleteName("");
  };

  const getEditDeleteCell = (data: {
    id: string;
    deleteName: string;
  }): JSX.Element => {
    const { id, deleteName } = data;
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <IconButton
          aria-label="edit"
          size="small"
          onClick={() => onEdit && onEdit(id)}
        >
          <Edit fontSize="inherit" />
        </IconButton>
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => onClickDelete(id, deleteName)}
        >
          <Delete fontSize="inherit" />
        </IconButton>
      </Stack>
    );
  };

  const copyTextToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const getClientDocumentCell = (data: {
    document: FieldValues;
    key: string;
  }): string | JSX.Element | null => {
    const { document, key } = data;
    const value = document[key];

    switch (key) {
      case "name":
        return `${value}`;
      case "createdAt":
        return moment(value).format("MMMM D, YYYY");
      case "location":
        return "-";
      default:
        return null;
    }
  };

  const getWorkflowUrlCell = (data: {
    slug: string;
    domain: string;
  }): JSX.Element => {
    const { slug, domain } = data;
    const workflowUrl = `https://${domain}/form/${slug}`;

    return (
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>{workflowUrl}</Box>
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => copyTextToClipboard(workflowUrl)}
          >
            <ContentCopy fontSize="inherit" />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => openNewTab(workflowUrl)}
          >
            <OpenInNew fontSize="inherit" />
          </IconButton>
        </Stack>
      </Box>
    );
  };

  const getWorkflowContentCell = (data: {
    workflow: FieldValues;
    key: string;
  }): string | JSX.Element | null => {
    const { workflow, key } = data;
    const workflowDocuments = workflow.extensions.documents;
    const value = workflow[key];

    switch (key) {
      case "extensions":
        return value && workflowDocuments
          ? `${Object.keys(workflowDocuments).length}`
          : "0";
      case "createdAt":
      case "updatedAt":
        return moment(value).format("MMMM D, YYYY");
      case "slug":
        return getWorkflowUrlCell({ slug: value, domain });
      case "title":
        return value;
      case "isEditDelete":
        return isEditDelete
          ? getEditDeleteCell({
              id: workflow.id,
              deleteName: `Workflow ${workflow.title}`,
            })
          : null;
      default:
        return null;
    }
  };

  const getClientContentCell = (data: {
    client: Client;
    key: string;
  }): string | JSX.Element | null => {
    const { client, key } = data;
    // @ts-ignore
    const value = client[key];
    switch (key) {
      case "firstName":
      case "lastName":
      case "documentsCount":
      case "recentLocation":
        return `${value}`;
      case "createdAt":
        return moment(value).format("MMMM D, YYYY, hh:mm A");
      case "recentDocuments":
        return (
          <RecentDocumentsIconsComponent
            recentDocuments={value}
            clientUuid={client.uuid}
          />
        );
      case "isEditDelete":
        return isEditDelete
          ? getEditDeleteCell({
              id: client.uuid,
              deleteName: `Client ${client.firstName} ${client.lastName}`,
            })
          : null;
      default:
        return null;
    }
  };

  const getDocumentContentCell = (data: {
    document: FieldValues;
    key: string;
  }): string | JSX.Element | null => {
    const { document, key } = data;
    const value = document[key];
    switch (key) {
      case "id":
      case "name":
        return `${value}`;
      case "createdAt":
      case "updatedAt":
        return moment(value).format("MMMM D, YYYY");
      case "isEditDelete":
        return isEditDelete
          ? getEditDeleteCell({
              id: document.id,
              deleteName: `Document ${document.name}`,
            })
          : null;
      default:
        return null;
    }
  };

  const getLocationsContentCell = (data: {
    location: FieldValues;
    key: string;
  }): string | JSX.Element | null => {
    const { location, key } = data;

    const value = location[key];
    switch (key) {
      case "id":
      case "name":
      case "address":
        return `${value}`;
      case "timezone":
        return getFullNameTZ(value);
      case "createdAt":
      case "updatedAt":
        return moment(value).format("MMMM D, YYYY");
      case "isEditDelete":
        return isEditDelete
          ? getEditDeleteCell({
              id: location.id,
              deleteName: `Location ${location.title}`,
            })
          : null;
      default:
        return null;
    }
  };

  const getSignedAgreementsCell = (data: {
    agreements: FieldValues;
    key: string;
  }): string | JSX.Element | null => {
    const { agreements, key } = data;

    const value = agreements[key];
    switch (key) {
      case "name":
        return `${value}`;
      case "createdAt":
        return moment(value).format("MMMM D, YYYY");
      default:
        return null;
    }
  };

  const getContentCell = (
    row: FieldValues | Client,
    key: string,
  ): string | JSX.Element | null => {
    switch (type) {
      case "clients":
        return getClientContentCell({ client: row as Client, key });
      case "clientDocuments":
        return getClientDocumentCell({ document: row as Client, key });
      case "documents":
        return getDocumentContentCell({ document: row, key });
      case "workflows":
        return getWorkflowContentCell({ workflow: row, key });
      case "locations":
        return getLocationsContentCell({ location: row, key });
      case "signedAgreements":
        return getSignedAgreementsCell({ agreements: row, key });
      default:
        return null;
    }
  };

  const getEmptyTitle = (): string => {
    let title: string;
    switch (type) {
      case "clientDocuments":
        title = "client documents";
        break;
      case "signedAgreements":
        title = "signed agreements";
        break;
      default:
        title = type;
    }

    return title;
  };

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              display={"flex"}
              gap={"20px"}
              alignItems={"center"}
              marginTop={"30px"}
            >
              <img src={Illustration} width={"40px"} height={"40px"} alt={""} />
              <Box display={"flex"} flexDirection={"column"}>
                <Typography id="modal-modal-description">
                  Are you sure you want to delete?
                </Typography>
                <Typography id="modal-modal-description">
                  {deleteName}?
                </Typography>
              </Box>
            </Box>

            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              gap={"12px"}
              marginTop={"16px"}
            >
              <Button
                variant={"outlined"}
                color="inherit"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant={"contained"}
                color="error"
                onClick={handleDelete}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
      <TableContainer component={Paper} sx={{ paddingX: 0 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              {headers.map((header) => (
                <StyledTableCell key={header} align="center">
                  {header}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rows.length ? (
              rows.map((mainRow, mainIndex) => {
                let row = isEditDelete ? { ...mainRow, isEditDelete } : mainRow;
                return (
                  <StyledTableRow
                    onClick={() =>
                      onRowClick && onRowClick(row.id || row.uuid || "")
                    }
                    key={row?.id || row?.uuid}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      ...((type === "clients" ||
                        type === "clientDocuments" ||
                        type === "signedAgreements") && {
                        cursor: "pointer",
                        "&:hover": {
                          borderLeft: "1px solid #2E7D32",
                          transition: "0.25s",
                        },
                      }),
                    }}
                  >
                    {Object.keys(row).map((key, index) => {
                      if (row[key]) {
                        let value = getContentCell(row, key);

                        if (!value) return;

                        if (index === 0) {
                          return (
                            <TableCell
                              key={key + row[key]}
                              component="th"
                              align="center"
                              scope="row"
                            >
                              {value ? value : "-"}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={key + row[key]} align="center">
                              {value ? value : "-"}
                            </TableCell>
                          );
                        }
                      }
                    })}
                  </StyledTableRow>
                );
              })
            ) : (
              <TableRow sx={{ height: "220px" }}>
                <TableCell colSpan={6} rowSpan={6}>
                  <Box display={"flex"} justifyContent={"center"}>
                    <Box textAlign={"center"}>
                      <img
                        src={archive}
                        height={70}
                        width={70}
                        alt={"empty"}
                        style={{ margin: "auto" }}
                      />
                      <Typography>You have no {getEmptyTitle()} yet</Typography>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
