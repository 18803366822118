import { FC, RefObject, useEffect, useRef, useState } from "react";
import Navbar from "./components/navbar";
import Hero from "./scenes/hero";
import Features from "./scenes/features";
import HowItWorks from "./scenes/howItWorks";
import AboutUs from "./scenes/aboutUs";
import Pricing from "./scenes/pricing";
import FAQ from "./scenes/faq";
import ContactUs from "./scenes/contactUs";
import Footer from "./components/footer";
import SupportModal from "./modal/support";
import { Outlet, useOutletContext } from "react-router-dom";
import { LandingOutletType } from "./shared/types";
import "./index.css";

export const Landing: FC = () => {
  const [isSupportModalOpen, setIsSupportModalOpen] = useState<boolean>(false);
  const bodyStyle = document.body.style;

  useEffect(() => {
    bodyStyle.overflowY = isSupportModalOpen ? "hidden" : "auto";
  }, [isSupportModalOpen]);

  const faqRef = useRef<HTMLDivElement | null>(null);
  const contactUsRef = useRef<HTMLDivElement | null>(null);
  const featuresRef = useRef<HTMLDivElement | null>(null);
  const howItWorksRef = useRef<HTMLDivElement | null>(null);
  const pricingRef = useRef<HTMLDivElement | null>(null);
  const aboutUsRef = useRef<HTMLDivElement | null>(null);

  const scrollToRef = (ref: RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToFAQ = () => scrollToRef(faqRef);
  const scrollToContactUs = () => scrollToRef(contactUsRef);
  const scrollToFeatures = () => scrollToRef(featuresRef);
  const scrollToHowItWorks = () => scrollToRef(howItWorksRef);
  const scrollToPricing = () => scrollToRef(pricingRef);
  const scrollToAboutUs = () => scrollToRef(aboutUsRef);

  const scrollRefs = {
    scrollToFAQ,
    scrollToContactUs,
    scrollToFeatures,
    scrollToHowItWorks,
    scrollToPricing,
    scrollToAboutUs,
    setIsSupportModalOpen,
  };

  return (
    <div className="landing-wrapper">
      <div className="landing">
        <Navbar {...scrollRefs} />
        <Outlet
          context={
            {
              faqRef,
              contactUsRef,
              featuresRef,
              howItWorksRef,
              pricingRef,
              aboutUsRef,
              scrollToHowItWorks,
            } satisfies LandingOutletType
          }
        />

        <Footer {...scrollRefs} />
        {isSupportModalOpen && (
          <SupportModal setIsSupportModalOpen={setIsSupportModalOpen} />
        )}
      </div>
    </div>
  );
};

export const LandingInner: FC = () => {
  const {
    faqRef,
    contactUsRef,
    featuresRef,
    howItWorksRef,
    pricingRef,
    aboutUsRef,
    scrollToHowItWorks,
  } = useOutletContext<LandingOutletType>();
  return (
    <>
      <Hero scrollToHowItWorks={scrollToHowItWorks} />
      <Features featuresRef={featuresRef} />
      <HowItWorks howItWorksRef={howItWorksRef} />
      <Pricing pricingRef={pricingRef} />
      <AboutUs aboutUsRef={aboutUsRef} />
      <FAQ faqRef={faqRef} />
      <ContactUs contactUsRef={contactUsRef} />
    </>
  );
};
