import { ChangeEvent, FC } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { CheckboxWidgetProps } from "./CheckboxWidget.types";
import { getValidationRule } from "../../../../helper/FormValidators/formHelper";
import { ErrorMessage } from "@hookform/error-message";
import { ControllerRenderProps } from "../../@types/Widget";

const defaultProps: CheckboxWidgetProps = {
  type: "checkbox",
  label: "CHECKBOX",
  fieldId: "CHECKBOX",
  required: false,
  value: false,
};

export const CheckboxWidget: FC<CheckboxWidgetProps> = (
  field = defaultProps,
) => {
  const {
    label,
    fieldId,
    required,
    value,
    isShowErrorMessage = true,
  } = field;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Controller
        render={({
          field: { value, onChange },
        }: {
          field: ControllerRenderProps<boolean, ChangeEvent<HTMLInputElement>>;
        }) => (
          <FormControlLabel
            control={
              <Checkbox
                id={fieldId}
                value={value}
                required={required}
                checked={value}
                onChange={onChange}
                sx={{
                  color: `${errors[fieldId] ? "red" : "black"}`,
                }}
              />
            }
            aria-required={false}
            label={label}
          />
        )}
        control={control}
        defaultValue={value}
        name={fieldId}
        rules={getValidationRule(field)}
      />

      {isShowErrorMessage && (
        <ErrorMessage
          errors={errors}
          name={fieldId}
          render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
        />
      )}
    </>
  );
};
